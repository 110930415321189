// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    Popover,
    Translate,
} from '@plesk/ui-library';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { Figure } from 'client/project/components/Figure/Figure';
import { RootState } from 'client/core/store';
import { isInvitingNewMember } from 'client/project/selectors/member';
import {
    HeaderContainer,
    HeaderContent,
    HeaderIcon,
    HeaderTitle,
} from 'client/common/styles/Header';
import ProjectMemberForm from 'client/project/containers/projectItem/ProjectItemHeader/ProjectMemberForm';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { statusTooltipMapping } from 'client/project/constants/translation';

interface IProjectItemHeaderProps extends RouteComponentProps<{ id: string }> {
    project: IProjectResponse;
}

export type ProjectItemHeaderProps =
    IProjectItemHeaderProps &
    ReturnType<typeof mapStateToProps>;

export const ProjectItemHeader: React.FunctionComponent<ProjectItemHeaderProps> = ({
    project,
    match: { params },
    location: { hash },
    history,
    isInviting,
    isUserLockedOrSuspended: userLockedOrSuspended,
    userStatus,
    canCreateServers,
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);
    const handleOpen = () => setPopoverOpened(true);
    const handleClose = () => setPopoverOpened(false);

    return (
        <HeaderContainer>
            <HeaderIcon>
                <Figure title={project.name} />
            </HeaderIcon>
            <HeaderContent>
                <HeaderTitle>
                    <h1>{project.name}</h1>
                </HeaderTitle>
            </HeaderContent>

            <div>
                {hash === '#servers' && canCreateServers && (
                    <HeaderButton
                        isMobile={true}
                        icon="plus"
                        disabled={userLockedOrSuspended}
                        tooltip={userLockedOrSuspended && statusTooltipMapping[userStatus]}
                        to={`/projects/${params.id}/servers/create`}
                    >
                        <Translate content="projects.server.list.createServer" />
                    </HeaderButton>
                )}
                {hash === '#members' && (
                    <Popover
                        onClose={handleClose}
                        visible={isPopoverOpened}
                        canCloseOnOutsideClick={false}
                        target={(
                            <HeaderButton
                                isMobile={true}
                                icon="mail"
                                onClick={handleOpen}
                                tooltip={userLockedOrSuspended && statusTooltipMapping[userStatus]}
                                disabled={userLockedOrSuspended || isInviting}
                            >
                                <Translate content="projects.members.inviteMember" />
                            </HeaderButton>
                        )}
                        placement="bottom-left"
                    >
                        <ProjectMemberForm onCreated={handleClose} projectId={project.id} />
                    </Popover>
                )}
            </div>
        </HeaderContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    isInviting: isInvitingNewMember(state),
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
    userStatus: state.auth.user.status,
    canCreateServers: hasPermission(state, PERMISSION_LIST.CREATE_SERVERS, PERMISSION_LIST.MANAGE_SERVERS),
});

export default withRouter(connect(mapStateToProps)(ProjectItemHeader));
