// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import { Loader } from 'common/components';
import { VIEW_TYPE } from 'common/constants';
import { CardList } from 'client/common/components/Card';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { IApiTokenResponse } from 'common/api/resources/ApiToken';
import ApiTokenCard from 'client/account/containers/Account/ApiTokens/ApiTokenCard';
import * as apiTokenActions from 'common/modules/apiToken/actions';
import {
    List,
    Translate,
} from '@plesk/ui-library';
import { StyledList } from 'client/common/styles/List';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'client/account/constants/test';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { getActionColumnProps } from 'common/helpers/list';

export type ApiTokenListProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '15%',
    key: 'colName',
    title: <Translate content="account.tokens.list.colName" />,
}, getActionColumnProps(),
];

export const ApiTokenList: React.FC<ApiTokenListProps> = ({
    view,
    apiTokens,
    apiTokenActions: { getApiTokens, removeApiToken },
    isLoading,
}) => {
    React.useLayoutEffect(() => {
        getApiTokens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemove = (apiToken: IApiTokenResponse) => () => removeApiToken(apiToken);

    const data = apiTokens.map((item) => {
        const actionsEl = (
            <ButtonWithConfirmation
                isLoading={item.isLoading}
                translations={{
                    text: (
                        <Translate content="account.tokens.confirmationRemove.text" />
                    ),
                    button: (
                        <Translate content="account.tokens.confirmationRemove.button" />
                    ),
                    tooltip: (
                        <Translate content="account.tokens.confirmationRemove.button" />
                    ),
                }}
                handleConfirm={handleRemove(item)}
                data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
                icon="recycle"
            />
        );

        return {
            colName: <div style={{ marginLeft: '12px', fontWeight: 600 }}>{item.name}</div>,
            colActions: actionsEl,
            key: item.id.toString(),
        };
    });

    return (
        <Loader isLoading={isLoading}>
            {view === VIEW_TYPE.CARD && (
                <CardList
                    items={apiTokens}
                    component={apiToken => <ApiTokenCard key={apiToken.id} apiToken={apiToken} />}
                />
            )}
            {view === VIEW_TYPE.LIST && (
                <StyledList>
                    <List
                        columns={columns}
                        data={data}
                    />
                </StyledList>
            )}
            {!data.length && (
                <EmptyView
                    title="account.tokens.emptyView.title"
                    icon="net"
                />
            )}
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    view: state.app.viewType,
    apiTokens: state.apiTokens.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.API_TOKEN_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenList);
