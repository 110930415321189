// Copyright 2025. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { SnapshotList } from 'common/components/ServerTabs/SnapshotTab/SnapshotList';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as snapshotActions from 'common/modules/snapshot/actions';
import { VIEW_TYPE } from 'common/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import * as serverActions from 'common/modules/computeResourceVm/actions';

const mapStateToProps = (state: RootState) => ({
    view: VIEW_TYPE.LIST,
    snapshots: state.snapshot.list.data,
    server: state.computeResourceVm.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.SNAPSHOTS_LIST),
    canRevertServersSnapshot: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_SERVERS,
        PERMISSION_LIST.REVERT_SERVERS_SNAPSHOT
    ),
    canDeleteServersSnapshot: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_SERVERS,
        PERMISSION_LIST.DELETE_SERVERS_SNAPSHOT
    ),
    serverLimits: state.computeResourceVm.limits,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    snapshotActions: bindActionCreators(snapshotActions, dispatch),
    serverActions: bindActionCreators(serverActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotList);
