// Copyright 2025. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    IntentType,
    ProgressStep,
    Text,
    Translate,
} from '@plesk/ui-library';
import React from 'react';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import * as managementNodeBackupActions from 'admin/managementNodeBackup/actions';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Size } from 'common/components/Size/Size';
import ActionStatus from 'common/components/ActionStatus/ActionStatus';
import {
    IManagementNodeBackupResponse,
    ManagementNodeBackupCreationMethod,
    ManagementNodeBackupStatus,
} from 'common/api/resources/ManagementNodeBackup';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { StyledActions } from 'common/components/Actions/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { formatTableDate } from 'common/date';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import ManagementNodeBackupCard
    from 'admin/managementNodeBackup/containers/SettingsCard/SettingsCard';
import { ManagementNodeBackupCardContainer } from 'admin/managementNodeBackup/containers/ManagementNodeBackups/Styles';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { PageSection } from 'common/components/PageHeader/Styles';
import { Loader } from 'common/components';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'admin/managementNodeBackup/constants/tests';
import List from 'common/components/List/List';
import { Button } from 'admin/common/components/Button/Button';
import BackupNodeCard from 'admin/managementNodeBackup/containers/BackupNodeCard/BackupNodeCard';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';

export type ManagementNodeBackupProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const statusTextMap: Record<ManagementNodeBackupStatus, React.ReactNode> = {
    [ManagementNodeBackupStatus.CREATING]: <Translate content="managementNodeBackup.status.creating"/>,
    [ManagementNodeBackupStatus.RESTORING]: <Translate content="managementNodeBackup.status.restoring"/>,
    [ManagementNodeBackupStatus.CREATED]: <Translate content="managementNodeBackup.status.created"/>,
    [ManagementNodeBackupStatus.FAILED]: <Translate content="managementNodeBackup.status.failed"/>,
};

const intentMap: Record<ManagementNodeBackupStatus, IntentType> = {
    [ManagementNodeBackupStatus.CREATING]: INTENT_TYPE.WARNING,
    [ManagementNodeBackupStatus.RESTORING]: INTENT_TYPE.WARNING,
    [ManagementNodeBackupStatus.CREATED]: INTENT_TYPE.SUCCESS,
    [ManagementNodeBackupStatus.FAILED]: INTENT_TYPE.DANGER,
};

const creationMethodTextMap: Record<ManagementNodeBackupCreationMethod, React.ReactNode> = {
    [ManagementNodeBackupCreationMethod.AUTO]: <Translate content="managementNodeBackup.type.auto"/>,
    [ManagementNodeBackupCreationMethod.MANUAL]: <Translate content="managementNodeBackup.type.manual"/>,
};

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="managementNodeBackup.list.id" />,
}, {
    key: 'colStatus',
    title: <Translate content="managementNodeBackup.list.status" />,
}, {
    key: 'colVersion',
    title: <Translate content="managementNodeBackup.list.version" />,
}, {
    key: 'colCreatedAt',
    cellProps: {
        className: 'cell-bold',
    },
    title: <Translate content="managementNodeBackup.list.created" />,
}, {
    key: 'colCreationMethod',
    title: <Translate content="managementNodeBackup.list.creationMethod" />,
}, {
    key: 'colSize',
    title: <Translate content="managementNodeBackup.list.size" />,
},  {
    key: 'colBackupNode',
    title: <Translate content="managementNodeBackup.list.backupNode" />,
}, {
    key: 'colCreator',
    title: <Translate content="managementNodeBackup.list.creator" />,
}, getActionColumnProps(),
];

export const ManagementNodeBackups: React.FC<ManagementNodeBackupProps> = ({
    backups,
    backupSettings,
    isLoading,
    isListLoading,
    isBackupCreating,
    isBackupRemoving,
    canManageBackups,
    managementNodeBackupActions: {
        getManagementNodeBackups,
        getNextScheduledDate,
        createBackup,
        restoreFromBackup,
        deleteBackup,
        deleteBackups,
    },
}) => {
    const [selection, setSelection] = React.useState<string[]>([]);
    const handleSelectionChange = canManageBackups ? (indexes: string[]) => setSelection(indexes) : undefined;

    const loadPaginated = (page: number) => getManagementNodeBackups({ page });

    const isFirstLoading = useIsFirstLoading(isLoading);

    const handleDelete = (id: number) => async () => {
        await deleteBackup(id);
        reloadListData(backups, loadPaginated);
    };

    const handleBatchDelete = async () => {
        const result = await deleteBackups(selection.map(id => parseInt(id, 10)));
        setSelection([]);
        reloadListData(backups, loadPaginated, result.length);
    };

    React.useEffect(() => {
        getNextScheduledDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        getManagementNodeBackups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderActions = (backup: IManagementNodeBackupResponse) => {
        if (!canManageBackups) {
            return null;
        }

        const isRestoreAllowed = backup.status === ManagementNodeBackupStatus.CREATED;
        const isDeleteAllowed =
            [ManagementNodeBackupStatus.CREATED, ManagementNodeBackupStatus.FAILED].includes(backup.status);

        return (
            <StyledActions>
                <ButtonWithConfirmation
                    icon="reload"
                    translations={{
                        text: (
                            <Translate content="managementNodeBackup.confirmationRestore.confirmationText" />
                        ),
                        button: (
                            <Translate content="managementNodeBackup.confirmationRestore.button" />
                        ),
                        title: (
                            <Translate content="managementNodeBackup.confirmationRestore.title" />
                        ),
                        tooltip: isRestoreAllowed ? (<Translate content="managementNodeBackup.confirmationRestore.tooltip" />) : undefined,
                    }}
                    handleConfirm={() => restoreFromBackup(backup.id)}
                    disabled={!isRestoreAllowed}
                    data-cy={dataCySelector(backup.id, TABLE_ACTIONS.RESTORE)}
                />
                <ButtonWithConfirmation
                    icon={ICONS.RECYCLE}
                    isLoading={backup.is_deleting}
                    translations={{
                        button: (
                            <Translate content="managementNodeBackup.confirmationRemove.button" />
                        ),
                        title: (
                            <Translate content="managementNodeBackup.confirmationRemove.title" />
                        ),
                        tooltip: isDeleteAllowed ? (<Translate content="managementNodeBackup.confirmationRemove.tooltip" />) : undefined,
                    }}
                    handleConfirm={handleDelete(backup.id)}
                    disabled={!isDeleteAllowed}
                    data-cy={dataCySelector(backup.id, TABLE_ACTIONS.REMOVE)}
                />
            </StyledActions>
        );
    };

    const data = backups.data.map(backup => ({
        colId: backup.id,
        colStatus: (<ActionStatus
            statusText={statusTextMap[backup.status]}
            isInProgress={[ManagementNodeBackupStatus.CREATING, ManagementNodeBackupStatus.RESTORING].includes(backup.status)}
            progress={backup.backup_progress}
            intent={intentMap[backup.status]}
            shouldShowDetails={backup.status === ManagementNodeBackupStatus.FAILED}
            detailsDialog={{
                title: (<Translate content="managementNodeBackup.detailsDialog.title" />),
                text: (
                    <ProgressStep icon="gear" status="error">
                        {backup.backup_fail_reason}
                    </ProgressStep>
                ),
            }}
        />),
        colVersion: (
            <Text>
                {backup.version}
            </Text>
        ),
        colCreatedAt: (
            <Text>
                {formatTableDate(backup.created_at)}
            </Text>
        ),
        colCreationMethod: (
            <Text>
                {creationMethodTextMap[backup.creation_method]}
            </Text>
        ),
        colSize: (
            <Text>
                <Size size={backup.size}/>
            </Text>
        ),
        colBackupNode: (
            <Text>
                {backup.backup_node?.name}
            </Text>
        ),
        colCreator: (
            <Text>
                {backup.creator?.email}
            </Text>
        ),
        colActions: renderActions(backup),
        key: backup.id.toString(),
    }));

    const renderCreateButton = () => {
        if (!canManageBackups) {
            return null;
        }

        return (
            <Button
                icon={ICONS.BACKUP}
                onClick={createBackup}
                isLoading={isBackupCreating}
                ghost={false}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                disabled={isFirstLoading || !backupSettings.backup_node_id}
                tooltip={!backupSettings.backup_node_id
                    ? (<Translate content="managementNodeBackup.createBtn.disableTooltip" />)
                    : undefined}
            >
                <Translate content="managementNodeBackup.createBtn.title"/>
            </Button>
        );
    };

    return (
        <>
            <PageHeader
                title={(
                    <PageSection>
                        {<Translate content="managementNodeBackup.header"/>}
                    </PageSection>
                )}
                actionButton={renderCreateButton()}
            />
            <Loader isLoading={isFirstLoading}>
                <ManagementNodeBackupCardContainer>
                    <BackupNodeCard />
                    <ManagementNodeBackupCard />
                </ManagementNodeBackupCardContainer>
                <List
                    emptyView={
                        <EmptyView
                            title="managementNodeBackup.emptyView.title"
                            icon={ICONS.BACKUP}
                        />
                    }
                    loadItems={loadPaginated}
                    meta={backups.meta}
                    isLoading={isListLoading}
                    isFirstLoading={isFirstLoading}
                    columns={columns}
                    data={data}
                    onSelectionChange={handleSelectionChange}
                    selection={selection}
                    toolbar={(
                        <>
                            {canManageBackups && (
                                <ButtonWithConfirmation
                                    disabled={selection.length === 0}
                                    isLoading={isBackupRemoving}
                                    confirmationButtonGhost={false}
                                    confirmationButtonText={<Translate content="managementNodeBackup.batchDelete" />}
                                    translations={{
                                        button: (
                                            <Translate content="managementNodeBackup.confirmationRemove.button" />
                                        ),
                                        title: (
                                            <Translate content="managementNodeBackup.confirmationRemove.title" />
                                        ),
                                        tooltip: (
                                            <Translate content="managementNodeBackup.confirmationRemove.tooltip" />
                                        ),
                                    }}
                                    handleConfirm={handleBatchDelete}
                                    icon={ICONS.RECYCLE}
                                    data-cy={TABLE_ACTIONS.BATCH_REMOVE_BTN}
                                />
                            )}
                        </>
                    )}
                />
            </Loader>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    backups: state.managementNodeBackup.list,
    backupSettings: state.settings.management_node_backup,
    isLoading:
        state.app.loadingFlags.has(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_LIST) ||
        state.app.loadingFlags.has(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_NEXT_SCHEDULED_DATE) ||
        state.app.loadingFlags.has(LOADING_FLAGS.APP_SETTINGS),
    isListLoading: state.app.loadingFlags.has(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_LIST),
    isBackupCreating: state.app.loadingFlags.has(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_CREATE),
    isBackupRemoving: state.app.loadingFlags.has(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_REMOVE),
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_MANAGEMENT_NODE_BACKUPS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    managementNodeBackupActions: bindActionCreators(managementNodeBackupActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagementNodeBackups);
