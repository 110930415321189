// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IReverseDnsResponse } from 'common/api/resources/ReverseDns';
import PopoverForm from 'common/components/ServerTabs/NetworkingTab/PopoverForm';
import { ICONS } from 'common/constants';
import {
    Button,
    Translate,
} from '@plesk/ui-library';

export interface IEditReverseDnsProps {
    isPopoverOpened: boolean;
    ipId: number;
    reverseDns: IReverseDnsResponse;
    handleOpenPopover: (id: number) => void;
    onPopoverClose: () => void;
    disabled?: boolean;
    icon?: string;
    'data-cy'?: string;
}

export const EditReverseDns: React.FC<IEditReverseDnsProps> = ({
    onPopoverClose,
    isPopoverOpened,
    handleOpenPopover,
    ipId,
    reverseDns,
    disabled = false,
    icon = ICONS.SLIDERS,
    'data-cy': dataCy,
}) => (
    <PopoverForm
        onClose={onPopoverClose}
        isOpened={isPopoverOpened}
        ipId={ipId}
        reverseDns={reverseDns}
        target={
            <Button
                tooltip={
                    <Translate content="servers.tabs.networking.reverseDns.tooltip.edit" />
                }
                disabled={disabled}
                ghost={true}
                icon={icon}
                onClick={() => handleOpenPopover(ipId)}
                data-cy={dataCy}
            />
        }
    />
);
