// Copyright 2025. WebPros International GmbH. All rights reserved.

export const COLORS = {
    PRIMARY: '#28aade',
    WHITE_0: '#fff',
    GREY_10: '#fbfbfb',
    GREY_20: '#f5f5f5',
    GREY_30: '#ececec',
    GREY_40: '#dedede',
    GREY_50: '#ccc',
    GREY_60: '#b5b5b5',
    GREY_70: '#979797',
    GREY_80: '#737373',
    GREY_90: '#4b4b4b',
    GREY_100: '#222',
    RED_0: '#d02d4b',
    RED_10: '#ffdee0',
};

