// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   height: calc(100vh - 350px);
   min-height: 350px;
`;

export const Box = styled.div`
   align-self: center;
   text-align: center;
   
   .pul-icon {
       color: ${props => props.theme.primary_color};
   }
`;

export const MessageBox = styled.div`
    margin-top: 40px;
    margin-bottom: 24px;
    max-width: 480px;
`;
