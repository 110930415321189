// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const CardItemIcon = styled.div`
    width: 24px;
    max-width: 24px;
`;

export const ToolbarIcons = styled.span`
    display: flex;
    justify-content: center;

    .pul-dropdown {
        display: inherit;
    }
`;
