// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const ScrollableContent = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 10px;
  
  @media screen and (max-height: 500px) {
    max-height: 35vh;
  }

  &::-webkit-scrollbar {
    margin-left: 2px;
    width: 5px;
    background-color: #ccc !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999 !important;
  }
`;
