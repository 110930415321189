// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { CutTitle } from 'common/components';
import { CardItem } from 'client/common/components/Card';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as projectActions from 'common/modules/project/actions';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { connect } from 'react-redux';
import { Figure } from 'client/project/components/Figure/Figure';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import {
    DescriptionEditInput,
    ItemInfoDescription,
    NameEditInput,
    PrimaryIcon,
    ToolbarActionLink,
} from 'client/project/containers/projectCard/Styles';
import {
    Action,
    Button,
    Toolbar,
    ToolbarExpander,
    Translate,
    useTranslate,
    Icon,
} from '@plesk/ui-library';
import {
    ICONS,
    KEYBOARD_EVENT_KEYS,
} from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import { RootState } from 'client/core/store';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

interface IProjectProps extends RouteComponentProps {
    project: IProjectResponse;
}

export type ProjectCardProps =
    IProjectProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ProjectCard: React.FC<ProjectCardProps> = ({
    project,
    history,
    isUserLockedOrSuspended: userLockedOrSuspended,
    canCreateServers,
    projectActions: {
        removeProject,
        saveProject,
        updateProjectItem,
    },
}) => {
    const translate = useTranslate();
    const [projectRequest, setProjectRequest] = React.useState({ ...project });
    const inputName = React.useRef<HTMLInputElement>(null);

    const onNameRefSet = React.useCallback((node: HTMLInputElement|null) => node?.focus(), []);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === KEYBOARD_EVENT_KEYS.ENTER) {
            handleSave();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setProjectRequest({ ...projectRequest, [e.target.name]: e.target.value });
    const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setProjectRequest({ ...project });
        updateProjectItem({ ...project, isEdit: !project.isEdit });
    };

    const handleRemove = () => removeProject(project);

    const handleSave = () => {
        const { name, description } = projectRequest;

        if (!projectRequest.name.trim().length && inputName) {
            inputName.current!.focus();
            return;
        }

        saveProject({ ...project, name, description }, project.id);
    };

    if (project.isEdit) {
        return (
            <CardItem
                onKeyPress={handleKeyPress}
                isLoading={project.isLoading}
                icon={<Figure title={projectRequest.name} />}
                item={
                    <>
                        <span>
                            <NameEditInput
                                ref={onNameRefSet}
                                name="name"
                                onChange={handleChange}
                                placeholder={translate('projects.form.name') as string}
                                value={projectRequest.name}
                                type="text"
                                maxLength={32}
                            />
                        </span>
                        <span>
                            <DescriptionEditInput
                                name="description"
                                onChange={handleChange}
                                value={projectRequest.description}
                                placeholder={translate('projects.form.description') as string}
                                type="text"
                                maxLength={48}
                            />
                        </span>
                    </>
                }
                footer={
                    <Toolbar>
                        <Button
                            data-cy={dataCySelector(1, 'save-button')}
                            onClick={handleSave}
                            ghost={true}
                            icon={ICONS.CHECK_MARK}
                            disabled={!projectRequest.name.trim().length}
                        >
                            <Translate content="projects.form.save" />
                        </Button>
                        {project.id > 0 && (
                            <Button
                                data-cy={dataCySelector(1, 'edit-button')}
                                onClick={handleEdit}
                                ghost={true}
                                icon={ICONS.REMOVE}
                            >
                                <Translate content="projects.form.cancel" />
                            </Button>
                        )}
                        <ToolbarExpander/>
                        {project.is_owner && (<Button
                            onClick={handleRemove}
                            ghost={true}
                            icon={ICONS.RECYCLE}
                            disabled={project.is_default}
                        />)}
                    </Toolbar>
                }
            />
        );
    }

    return (
        <CardItem
            isLoading={project.isLoading}
            icon={<Figure title={project.name} />}
            cardTitle={
                <>
                    <Action
                        component={Link}
                        to={pathTo(`projects/${project.id}`)}
                    >
                        <CutTitle title={project.name} />
                    </Action>
                    <Action
                        onClick={handleEdit}
                        className="item__edit-action"
                        icon={
                            <Icon
                                className="pul-icon pul-action__icon"
                                name={ICONS.PENCIL}
                            />
                        }
                    />
                </>
            }
            item={
                <ItemInfoDescription>{project.description}</ItemInfoDescription>
            }
            footer={
                <Toolbar>
                    {project.is_owner && (<PrimaryIcon name={ICONS.RIBBON} />)}
                    <ToolbarExpander/>
                    {project.servers > 0 && (
                        <ToolbarActionLink
                            to={pathTo(`projects/${project.id}#servers`)}
                        >
                            <Icon name={ICONS.RESOURCE}/>
                            <Translate content="projects.form.serverList" params={{ count: project.servers }}/>
                        </ToolbarActionLink>
                    )}
                    {(project.servers === 0 && canCreateServers) && (
                        <ToolbarActionLink
                            $isDisabled={userLockedOrSuspended}
                            to={pathTo(`projects/${project.id}/servers/create`)}
                        >
                            <Icon name={ICONS.RESOURCE}/>
                            <Translate content="projects.form.createServer"/>
                        </ToolbarActionLink>
                    )}
                    <ToolbarActionLink
                        to={pathTo(`projects/${project.id}#members`)}
                    >
                        <Icon name={ICONS.USER}/>
                        <Translate content="projects.form.membersList" params={{ count: project.members }}/>
                    </ToolbarActionLink>
                    <ToolbarExpander/>
                    {project.is_owner && (
                        <ButtonWithConfirmation
                            data-cy={dataCySelector(1, 'remove-popover')}
                            translations={{
                                text: (
                                    <Translate content="projects.form.deleteConfirmationText" />
                                ),
                                button: (
                                    <Translate content="projects.form.deleteButton" />
                                ),
                                title: (
                                    <Translate content="projects.form.deleteTitle" />
                                ),
                                tooltip: (
                                    <Translate content={project.is_default
                                        ? 'projects.form.defaultProjectRemovalTooltip'
                                        : 'projects.form.deleteTooltip'
                                    } />
                                ),
                            }}
                            disabled={project.is_default}
                            handleConfirm={handleRemove}
                            icon={ICONS.RECYCLE}
                        />
                    )}
                </Toolbar>
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
    canCreateServers: hasPermission(state, PERMISSION_LIST.CREATE_SERVERS, PERMISSION_LIST.MANAGE_SERVERS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectActions: bindActionCreators(projectActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectCard));
