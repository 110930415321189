// Copyright 2025. WebPros International GmbH. All rights reserved.

import { Translate } from '@plesk/ui-library';
import { createCustomAction } from 'typesafe-actions';
import {
    ADD_TOAST,
    FADE_TIMEOUT,
    REMOVE_TOAST,
} from 'common/modules/app/toaster/constants';
import { IToast } from 'common/modules/app/toaster/reducer';
import { Dispatch } from 'redux';
import { INTENT_TYPE } from 'common/constants';

export const addToast = createCustomAction(
    ADD_TOAST,
    (toast: IToast) => ({ payload: toast })
);
export const removeToast = createCustomAction(
    REMOVE_TOAST,
    (key: string) => ({ payload: key })
);
export const dropToast = (key: string) => (dispatch: Dispatch) => dispatch(removeToast(key));

export const bakeForegroundToast = (
    intent: INTENT_TYPE,
    message: string,
    params: { [s: string]: string|number } | undefined = undefined,
    shouldTranslate: boolean = true
) => (dispatch: Dispatch) => {
    bakeToast(intent, message, params, shouldTranslate, false)(dispatch);
};

export const bakeBackgroundToast = (
    intent: INTENT_TYPE,
    message: string,
    params: { [s: string]: string|number } | undefined = undefined,
    shouldTranslate: boolean = true
) => (dispatch: Dispatch) => {
    bakeToast(intent, message, params, shouldTranslate, true)(dispatch);
};

export const bakeToast = (
    intent: INTENT_TYPE,
    message: string,
    params: { [s: string]: string|number } | undefined = undefined,
    shouldTranslate: boolean,
    background: boolean
) => (dispatch: Dispatch) => {
    const toastKey: string = Math.random().toString(36).substring(7);

    dispatch(addToast({
        intent,
        message: shouldTranslate ? <Translate content={message} params={params} /> : message,
        key: toastKey,
        background,
    }));

    setTimeout(() => dispatch(removeToast(toastKey)), FADE_TIMEOUT);
};
