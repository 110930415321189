// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import { useInterval } from 'common/hooks/useInterval';
import {
    ElapsedTimeText,
    PasswordLabelContainer,
    ResendLinkContainer,
    ResendLinkText,
    ResetPasswordContainer,
} from 'common/modules/auth/components/PasswordLabel/Styles';

export interface IPasswordLabel {
    elapsed: number;
    setElapsed: (val : (value: number) => number) => void;
}

export const PasswordLabel: React.FC<IPasswordLabel> = ({
    setElapsed,
    elapsed,
}) => {
    useInterval(() => {
        setElapsed(t => t - 1);
    }, elapsed > 0 ? 1000 : undefined);

    const getFormattedTime = (total: number) => {
        const minutes = Math.floor(total / 60);
        const seconds = total - minutes * 60;

        const format = (value: number) => value < 10 ? `0${value}` : value;

        return `${format(minutes)}:${format(seconds)}`;
    };

    return (
        <PasswordLabelContainer>
            <Translate content="auth.login" />
            {elapsed > 0 && (
                <ResetPasswordContainer>
                    <ResendLinkContainer>
                        <ResendLinkText>
                            <Translate content="auth.resendText" />
                        </ResendLinkText>
                        <ElapsedTimeText>
                            {getFormattedTime(elapsed)}
                        </ElapsedTimeText>
                    </ResendLinkContainer>
                </ResetPasswordContainer>
            )}
        </PasswordLabelContainer>
    );
};
