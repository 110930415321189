// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IPlanLimit } from 'common/api/resources/Plan';
import { INetworkTraffic } from 'common/api/resources/ComputeResourceVm';
import { colorBetween } from 'common/helpers/color';
import { COLORS } from 'common/theme';
import {
    ICONS,
    INTENT_TYPE,
    ITEM_VIEW,
} from 'common/constants';
import {
    Item,
    ProgressBar,
} from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/Styles';
import {
    Alert,
    Icon,
} from '@plesk/ui-library';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import TrafficProgress from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/TrafficProgress';

export interface ITrafficCardProps {
    networkTraffic: INetworkTraffic;
    limit: IPlanLimit<DataUnit>;
    title: React.ReactNode;
    description: React.ReactNode;
    exceededDescription: React.ReactNode;
}

export const TrafficCard: React.FC<ITrafficCardProps> = ({
    networkTraffic: {
        is_exceeded: isExceeded,
        value,
    },
    limit,
    title,
    description,
    exceededDescription,
}) => {
    const convertedValue = convertToDataUnit(value, limit.unit);
    const progressPercent = limit.limit ? (100 * convertedValue) / limit.limit : 0;
    const progress = progressPercent >= 100 ? 100 : progressPercent;

    return (
        <Item
            icon={isExceeded && (
                <Icon
                    size={24}
                    name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED}
                    intent={INTENT_TYPE.WARNING}
                />
            )}
            title={
                <TrafficProgress
                    limit={limit}
                    title={title}
                    component="h3"
                    value={convertedValue}
                    isExceeded={isExceeded}
                />
            }
            view={ITEM_VIEW.CARD}
            footer={
                <div>
                    <ProgressBar
                        color={colorBetween(COLORS.SUCCESS, COLORS.WARNING, progress)}
                        progress={limit.is_enabled ? progress : 0}
                    />
                    {isExceeded ? (
                        <Alert intent={INTENT_TYPE.WARNING}>
                            {exceededDescription}
                        </Alert>
                    ) : description}
                </div>
            }
        />
    );
};

export default TrafficCard;
