// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    Button,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { IVmDiskResponse } from 'common/api/resources/ComputeResourceVm';
import { TABLE_ACTIONS } from 'common/components/ServerTabs/DisksTab/constants/test';

export interface IDisksActionsProps {
    item: IVmDiskResponse;
    hasResizePermission: boolean;
    hasDeletePermission: boolean;
    canDelete: boolean;
    isResizing: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    handleEdit: () => void;
    handleResize: () => void;
    handleRemove: () => void;
}

export const DisksActions: React.FC<IDisksActionsProps> = ({
    item,
    hasResizePermission,
    hasDeletePermission,
    canDelete,
    isResizing,
    isUpdating,
    isDeleting,
    handleEdit,
    handleResize,
    handleRemove,
}) => (
    <StyledActions>
        {hasResizePermission && (
            <Button
                ghost={true}
                icon={<Icon name={ICONS.ARROW_UP_IN} size={18} />}
                tooltip={item.is_primary
                    ? (
                        <Translate content="servers.tabs.disks.tooltips.resizePrimary" />
                    ) : (
                        <Translate content="servers.tabs.disks.tooltips.resize"/>
                    )}
                onClick={handleResize}
                data-cy={dataCySelector(item.id, TABLE_ACTIONS.RESIZE)}
                disabled={item.is_primary}
                state={isResizing ? 'loading' : undefined}
            />
        )}
        <Button
            ghost={true}
            icon={<Icon name={ICONS.PENCIL} size={18} />}
            tooltip={<Translate content="servers.tabs.disks.tooltips.edit"/>}
            onClick={handleEdit}
            data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
            state={isUpdating ? 'loading' : undefined}
        />
        {hasDeletePermission && (
            <ButtonWithConfirmation
                isLoading={item.isDeleting || isDeleting}
                translations={{
                    title: (
                        <Translate content="servers.tabs.disks.removePopover.title" />
                    ),
                    button: (
                        <Translate content="servers.tabs.disks.removePopover.button" />
                    ),
                    text: (
                        <Translate content="servers.tabs.disks.removePopover.text" />
                    ),
                    tooltip: (
                        item.is_primary
                            ? (
                                <Translate content="servers.tabs.disks.removePopover.forbiddenPrimary" />
                            ) : canDelete
                                ? (
                                    <Translate content="servers.tabs.disks.removePopover.tooltip" />
                                ) : (
                                    <Translate content="servers.guestToolsMissing" />
                                )
                    ),
                }}
                handleConfirm={handleRemove}
                data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
                icon={ICONS.RECYCLE}
                disabled={item.is_primary || !canDelete}
            />
        )}
    </StyledActions>
);