// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceActions from 'admin/computeResource/actions';
import { connect } from 'react-redux';
import { Loader } from 'common/components';
import {
    Translate,
    Heading,
} from '@plesk/ui-library';
import { UsageBlock } from 'admin/computeResource/page/components/UsageTab/UsageBlock/UsageBlock';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import { getStatus } from 'common/api/resources/ComputeResource';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';

export type UsageTabProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const UsageTab: React.FC<UsageTabProps> = ({
    computeResource,
    computeResourceActions: {
        getComputeResourceMetrics,
        getComputeResourceUsage,
    },
    usage,
    metrics,
}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const getMetrics = async () => {
        if (getStatus(computeResource.status) === COMPUTE_RESOURCE_STATUS.ACTIVE) {
            try {
                setIsLoading(true);
                await getComputeResourceMetrics(computeResource.id);
                await getComputeResourceUsage(computeResource.id);
            } finally {
                setIsLoading(false);
            }
        }
    };

    React.useLayoutEffect(() => {
        getMetrics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const metricsUsage = metrics.map((metric, index) => (
        <UsageBlock
            key={index}
            header={<Translate content={`computeResource.usageAndLimits.${metric.name.toLowerCase()}`} />}
            body={`${metric.used.toString()} GiB`}
            footer={<>
                <Translate content="computeResource.usageAndLimits.of" /> {metric.total} GiB
            </>}
            progress={metric.used * 100 / metric.total}
            tooltip={metric.name.toLowerCase() === 'disk'
                ? (<Translate content="computeResource.usageAndLimits.diskTooltip" />)
                : undefined
            }
        />
    ));

    const serverProgress = computeResource.settings.limits.vm.unlimited
        ? 100
        : getProgress(usage.server_count, computeResource.settings.limits.vm.total);

    const cpuProgress = computeResource.settings.limits.vcpu.unlimited
        ? 100
        : getProgress(usage.cpu, computeResource.settings.limits.vcpu.total);

    const ramProgress = computeResource.settings.limits.ram.unlimited
        ? 100
        : getProgress(usage.ram, computeResource.settings.limits.ram.total);

    const diskProgress = computeResource.settings.limits.hdd.unlimited
        ? 100
        : getProgress(usage.disk, computeResource.settings.limits.hdd.total);

    return (
        <>
            <Loader isLoading={isLoading}>
                <Heading level={3}>
                    <Translate content="computeResource.usageAndLimits.allocatedUsage" />
                </Heading>
                <UsageBlock
                    header={<Translate content="computeResource.usageAndLimits.servers" />}
                    body={usage.server_count.toString()}
                    footer={
                        computeResource.settings.limits.vm.unlimited ?
                            <Translate content="computeResource.usageAndLimits.unlimited" /> :
                            <>
                                <Translate content="computeResource.usageAndLimits.of" /> {computeResource.settings.limits.vm.total}
                            </>
                    }
                    progress={serverProgress}
                />
                <UsageBlock
                    header={<Translate content="computeResource.usageAndLimits.virtualCPU" />}
                    body={usage.cpu.toString()}
                    footer={
                        computeResource.settings.limits.vcpu.unlimited ?
                            <Translate content="computeResource.usageAndLimits.unlimited" /> :
                            <>
                                <Translate content="computeResource.usageAndLimits.of" /> {computeResource.settings.limits.vcpu.total}
                            </>
                    }
                    progress={cpuProgress}
                />
                <UsageBlock
                    header={<Translate content="computeResource.usageAndLimits.virtualMemory" />}
                    body={`${convertToDataUnit(usage.ram, DataUnit.GiB)} GiB`}
                    footer={
                        computeResource.settings.limits.ram.unlimited ?
                            <Translate content="computeResource.usageAndLimits.unlimited" /> :
                            <>
                                <Translate content="computeResource.usageAndLimits.of" />
                                {` ${convertToDataUnit(computeResource.settings.limits.ram.total, DataUnit.GiB)} GiB`}
                            </>
                    }
                    progress={ramProgress}
                />
                <UsageBlock
                    header={<Translate content="computeResource.usageAndLimits.storage" />}
                    body={`${usage.disk} GiB`}
                    footer={
                        computeResource.settings.limits.hdd.unlimited ?
                            <Translate content="computeResource.usageAndLimits.unlimited" /> :
                            <>
                                <Translate content="computeResource.usageAndLimits.of" /> {computeResource.settings.limits.hdd.total} GiB
                            </>
                    }
                    progress={diskProgress}
                />
                <br/>
                <Heading level={3}>
                    <Translate content="computeResource.usageAndLimits.physicalUsage" />
                </Heading>
                {metricsUsage}
            </Loader>
        </>
    );
};

const getProgress = (part: number, total: number): number => part * 100 / total;

const mapStateToProps = (state: RootState) => ({
    computeResource: state.computeResource.item,
    metrics: state.computeResource.metrics,
    usage: state.computeResource.usage,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageTab);
