// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as licenseActions from 'admin/license/solusIO/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { ILicenseResponse } from 'common/api/resources/License/solusIO';

interface ISolusIOLicenseReducer {
    exist: boolean;
    item: ILicenseResponse;
}

export type SolusIOLicenseAction = ActionType<typeof licenseActions>;
export type SolusIOLicenseState = Readonly<ISolusIOLicenseReducer>;

export const initialSolusIOLicenseState: ILicenseResponse = {
    key: '',
    expiration_date: '',
    is_active: false,
    cpu_cores: 0,
    cpu_cores_in_use: 0,
    key_type: '',
    product: '',
    update_date: '',
};

export default combineReducers<SolusIOLicenseState, SolusIOLicenseAction>({
    exist: (state = false, action: SolusIOLicenseAction) => {
        switch (action.type) {
        case getType(licenseActions.setLicenseExist):
            return action.payload;
        default:
            return state;
        }
    },
    item: (state = initialSolusIOLicenseState, action: SolusIOLicenseAction) => {
        switch (action.type) {
        case getType(licenseActions.setLicenseItem):
            return action.payload;
        default:
            return state;
        }
    },
});
