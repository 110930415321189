// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    OptionProps,
    ValueType,
    components,
} from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import * as React from 'react';
import {
    computeResources,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import {
    ComputeResourceFilterOptionHost,
    FixedFormField,
} from 'admin/common/components/Filters/Styles';
import { Translate } from '@plesk/ui-library';
import { createOptionsLoader } from 'common/components/Select/helpers';

export interface IComputeResourceFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
    value?: ValueType<ISelectRequiredOption>;
}

export interface IOptionType {
    label: string;
    value: string;
    host: string;
}

const OptionComponent = (props: OptionProps<IOptionType>) => (
    <components.Option {...props}>
        <div>{props.data.label}</div>
        <ComputeResourceFilterOptionHost>{props.data.host}</ComputeResourceFilterOptionHost>
    </components.Option>
);

export const ComputeResourceFilter: React.FC<IComputeResourceFilterProps> = ({
    onChange,
    value,
}) => {
    const loadComputeResources = createOptionsLoader(
        computeResources.list,
        (item: IComputeResourceResponse) => ({
            label: item.name,
            value: item.id,
            host: item.host,
        })
    );

    return (
        <FixedFormField data-cy={FILTERS.COMPUTE_RESOURCE}>
            <AsyncSelectInput
                isClearable={true}
                placeholder={<Translate content="filters.computeResource"/>}
                loadOptions={loadComputeResources}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
                value={value}
                menuPosition="absolute"
                menuPortalTarget={document.body}
                components={{
                    Option: OptionComponent,
                }}
            />
        </FixedFormField>
    );
};
