// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IProjectCreateVmRequest,
    IProjectInviteResponse,
    IProjectJoinRequest,
    IProjectMemberRequest,
    IProjectMemberResponse,
    IProjectRequest,
    IProjectResponse,
    IProjectTokenPricingResponse,
} from 'common/api/resources/Project/model';
import {
    IComputeResourceVmListRequest,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    ISshKeyRequest,
    ISshKeyResponse,
} from 'common/api/resources/SshKey';
import {
    IPlanListRequest,
    IPlanResponse,
} from 'common/api/resources/Plan';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { CancelTokenSource } from 'axios';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const projects = {
    create: (data: IProjectRequest) => api.post<IApiResponse<IProjectResponse>>('projects', data),
    list: () => api.get<IPaginateApiResponse<IProjectResponse[]>>('projects'),
    item: (projectId: number) => api.get<IApiResponse<IProjectResponse>>(`projects/${projectId}`),
    remove: (projectId: number) => api.delete(`projects/${projectId}`),
    leave: (projectId: number) => api.post(`projects/${projectId}/leave`),
    join: (
        projectId: number, data: IProjectJoinRequest
    ) => api.post<IApiResponse<IProjectInviteResponse>>(`projects/${projectId}/join`, data),
    update: (projectId: number, data: IProjectRequest) => api.put<IApiResponse<IProjectResponse>>(`projects/${projectId}`, data),
    vmList: (projectId: number, params?: IComputeResourceVmListRequest, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IVmResponse[]>>(`projects/${projectId}/servers`, {
        params: new QueryBuilder(params).build(),
        cancelToken: cancelToken?.token,
    }),
    createVm: (
        projectId: number, data: IProjectCreateVmRequest
    ) => api.post<IApiResponse<IVmResponse>>(`projects/${projectId}/servers`, data),

    sshKeys: {
        list: (id: number) => api.get<IApiResponse<ISshKeyResponse[]>>(`projects/${id}/ssh_keys`),
        create: (id: number, data: ISshKeyRequest) => api.post<IApiResponse<ISshKeyResponse>>(`projects/${id}/ssh_keys`, data),
    },

    members: {
        list: (id: number, params?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IProjectMemberResponse[]>>(`projects/${id}/members`, {
            params: new QueryBuilder(params).build(),
            cancelToken: cancelToken?.token,
        }),
        remove: (id: number, memberId: number) => api.delete(`projects/${id}/members/${memberId}`),
        create: (id: number, data: IProjectMemberRequest) => api.post<IApiResponse<IProjectMemberResponse>>(`projects/${id}/members`, data),
        resendInvite: (id: number, memberId: number) => api.post(`projects/${id}/members/${memberId}/resend_invite`),
    },

    plans: {
        list: (id: number, params?: IPlanListRequest) => api.get<IPaginateApiResponse<IPlanResponse[]>>(`projects/${id}/plans`, {
            params: new QueryBuilder(params).build(),
        }),
    },

    tokenPricing: (id: number) => api.get<IApiResponse<IProjectTokenPricingResponse>>(`projects/${id}/token_pricing`),
};
