// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const StyledClientLayout = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    min-width: 400px;
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 36px 40px 50px 148px;

    @media (max-width: 560px) {
      padding: 0 16px;
    }

    @media (min-width: 1600px) {
        padding: 36px 30px 50px 160px;
    }
`;
