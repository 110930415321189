// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import * as settingsActions from 'common/modules/settings/actions';
import { HTTP_CODES } from 'common/api/constants';
import { INTENT_TYPE } from 'common/constants';
import { Label } from 'admin/settings/containers/Settings/Styles';
import {
    DNSType,
    ISettingsResponse,
} from 'common/api/resources/Settings';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    FormFieldCheckbox,
    FormFieldSelect,
    FormFieldText,
    Section,
    StatusMessage,
    Translate,
} from '@plesk/ui-library';
import {
    IVariable,
    SystemVariablesPopover,
} from 'admin/common/components/SystemVariablesPopover/SystemVariablesPopover';

export type DNSSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const humanDNSTypeMap = {
    [DNSType.NULL]: 'None',
    [DNSType.POWERDNS]: 'PowerDNS',
};

const dnsTypeOptions = Object.values(DNSType).map((value) => (
    <option key={value} value={value}>
        {humanDNSTypeMap[value]}
    </option>
));

const hostnameVariables: IVariable[] = [{
    variable: 'random-prefix',
    description: <Translate content="settings.dns.hostname.randomPrefix" />,
}];

const reverseDnsVariables: IVariable[] = [{
    variable: 'hostname',
    description: <Translate content="settings.dns.reverseDns.hostname" />,
}, {
    variable: 'ip',
    description: <Translate content="settings.dns.reverseDns.ip" />,
}, {
    variable: 'ip-reversed',
    description: <Translate content="settings.dns.reverseDns.ipReversed" />,
}, {
    variable: 'ip-dashed',
    description: <Translate content="settings.dns.reverseDns.ipDashed" />,
}, {
    variable: 'ip-reversed-dashed',
    description: <Translate content="settings.dns.reverseDns.ipDashedReversed" />,
}];

export const DNSSettings: React.FC<DNSSettingsProps> = ({
    settings,
    settingsActions: { saveSettings },
    responseError,
}) => {
    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            dns: values.dns,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.dns"
            onSubmit={handleSubmit}
        >
            {responseError.code === HTTP_CODES.BAD_REQUEST && (
                <StatusMessage intent={INTENT_TYPE.DANGER}>
                    {responseError.error}
                </StatusMessage>
            )}
            <Section>
                <FormFieldSelect
                    size="xl"
                    name="dns[type]"
                    required={true}
                    label={<Translate content="settings.dns.type" />}
                >
                    {dnsTypeOptions}
                </FormFieldSelect>
                {settings.dns.type !== DNSType.NULL && (
                    <>
                        <FormFieldCheckbox
                            name="dns[register_fqdn_on_server_create]"
                            label={
                                <Translate content="settings.dns.registerFqdnOnServerCreate" />
                            }
                        />
                        <FormFieldText
                            size="xl"
                            name="dns[server_hostname_template]"
                            disabled={!settings.dns.register_fqdn_on_server_create}
                            label={
                                <Label>
                                    <Translate content="settings.dns.hostname.domain" />
                                    <SystemVariablesPopover variables={hostnameVariables} />
                                </Label>
                            }
                            placeholder="{{random-prefix}}"
                            required={false}
                        />
                        <FormFieldText
                            size="xl"
                            name="dns[reverse_dns_domain_template]"
                            label={
                                <Label>
                                    <Translate content="settings.dns.reverseDns.domain" />
                                    <SystemVariablesPopover variables={reverseDnsVariables} />
                                </Label>
                            }
                            placeholder="static.{{ip}}.hostname.com"
                            required={false}
                        />
                        <FormFieldText
                            size="xl"
                            name="dns[ttl]"
                            label={<Translate content="settings.dns.ttl" />}
                            required={true}
                        />
                    </>
                )}
                {settings.dns.type === DNSType.POWERDNS && (
                    <>
                        <FormFieldText
                            size="xl"
                            name="dns[drivers][powerdns][host]"
                            label={<Translate content="settings.dns.powerdns.host" />}
                            required={true}
                        />
                        <FormFieldCheckbox
                            name="dns[drivers][powerdns][https]"
                            label={
                                <Translate content="settings.dns.powerdns.https" />
                            }
                        />
                        <FormFieldText
                            size="xl"
                            name="dns[drivers][powerdns][api_key]"
                            label={<Translate content="settings.dns.powerdns.apiKey" />}
                            required={true}
                        />
                        <FormFieldText
                            size="xl"
                            name="dns[drivers][powerdns][port]"
                            label={<Translate content="settings.dns.powerdns.port" />}
                        />
                    </>
                )}
            </Section>
        </SettingsWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
    responseError: state.app.responseError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DNSSettings);
