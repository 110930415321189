// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as locationActions from 'common/modules/location/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { addToPaginated } from 'common/reducers/list';
import { ILocationResponse } from 'common/api/resources/Location';

interface ILocation {
    list: IPaginateApiResponse<ILocationResponse[]>;
    item: ILocationResponse;
}

export type LocationAction = ActionType<typeof locationActions>;
export type LocationState = ILocation;

export const initialLocationState: ILocationResponse = {
    id: 0,
    name: '',
    description: '',
    icon: null,
    compute_resources: [],
    is_visible: true,
    is_default: false,
    is_loading: false,
    is_deleting: false,
    position: 0,
    available_plans: [],
};

export default combineReducers<LocationState, LocationAction>({
    list: (state = paginateInitialState, action: LocationAction) => {
        switch (action.type) {
        case getType(locationActions.appendLocations):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(locationActions.addLocationItem):
            return addToPaginated(state, action.payload);
        case getType(locationActions.setLocationList):
            return action.payload;
        case getType(locationActions.removeLocationItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(locationActions.updateLocationItem):
        case getType(locationActions.updateLocationItemPosition):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }

                    return item;
                }),
            };
        case getType(locationActions.clearDefault):
            return {
                ...state,
                data: state.data.map(item => ({ ...item, is_default: false })),
            };
        case getType(locationActions.setLocationItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_loading: true };
                    }

                    return item;
                })],
            };
        case getType(locationActions.unsetLocationItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_loading: false };
                    }

                    return item;
                })],
            };
        case getType(locationActions.setLocationItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = initialLocationState, action) => {
        switch (action.type) {
        case getType(locationActions.setLocationItem):
            return action.payload;
        case getType(locationActions.unsetLocationItem):
            return { ...initialLocationState };
        default:
            return state;
        }
    },
});
