// Copyright 2025. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import NetworkingTab from 'common/components/ServerTabs/NetworkingTab/NetworkingTab';

const mapStateToProps = (state: RootState) => ({
    server: state.computeResourceVm.item,
    vpcNetworkInterfaces: state.computeResourceVm.vpcNetworkInterfaces,
});

export default connect(mapStateToProps)(NetworkingTab);
