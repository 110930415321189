// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as activityLogActions from 'admin/activityLog/actions';
import ActivityLogTable from 'admin/activityLog/containers/ActivityLogTable';
import { IActivityLogFilters } from 'common/api/resources/ActivityLog';
import { RootState } from 'admin/core/store';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { ICONS } from 'common/constants';

interface IVmActivityLogProps {
    uuid: string;
}

export type VmActivityLogProps =
    IVmActivityLogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const VmActivityLogsTab: React.FC<VmActivityLogProps> = ({
    uuid,
    list,
    isLoadingList,
    activityLogActions: {
        getActivityLogs,
    },
}) => {
    const filters: IActivityLogFilters = {
        search: uuid,
    };

    React.useEffect(() => {
        getActivityLogs({ filters });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // We have to do it because we use filter (by server uuid) that is hidden from users,
    // and empty view for filtered result is different from general result.
    if (list.data.length === 0 && !isLoadingList) {
        return (
            <EmptyView
                title="activityLog.emptyView.title"
                icon={ICONS.NET}
            />
        );
    }

    return (
        <ActivityLogTable
            filters={filters}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.activityLog.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.ACTIVITY_LOG_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    activityLogActions: bindActionCreators(activityLogActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VmActivityLogsTab);
