// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as computeResourceActions from 'admin/computeResource/actions';
import { connect } from 'react-redux';
import {
    IStorageRequest,
    IStorageResponse,
} from 'common/api/resources/Storage';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import * as storagesActions from 'admin/storage/actions';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { StorageType } from 'common/api/resources/StorageType';
import {
    Form,
    FormField,
    Switch,
    Translate,
} from '@plesk/ui-library';
import * as storageTagActions from 'admin/storageTag/actions';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { IShortStorageTagResponse } from 'common/api/resources/StorageTag';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { ISelectOption as ICommonSelectOption } from 'common/components';

interface IStorageUpdateDialogProps {
    storage: IStorageResponse;
    onSubmit: () => void;
}

export type StorageUpdateDialogProps =
    IStorageUpdateDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IStorageTagSelectOption extends ICommonSelectOption {
    value: number;
    label: string;
}
const storageTagToSelectOption = (tag: IShortStorageTagResponse): IStorageTagSelectOption => ({
    label: tag.name,
    value: tag.id,
});

export const StorageUpdateDialog: React.FC<StorageUpdateDialogProps> = ({
    storage,
    computeResource,
    onSubmit,
    formErrors,
    storageTagActions: {
        getStorageTags,
    },
    computeResourceActions: {
        updateStorage: updateLocalStorage,
    },
    storagesActions: {
        updateStorage: updateRemoteStorage,
    },
    isSaving,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IStorageRequest>({
        name: storage.name,
        mount: storage.mount,
        credentials: storage.credentials,
        type: storage.type.name,
        is_available_for_balancing: storage.is_available_for_balancing,
        storage_tag_id: storage.storage_tag ? storage.storage_tag.id : null,
    });
    const [selectedStorageTag, setSelectedStorageTag] = React.useState(
        storage.storage_tag ? storageTagToSelectOption(storage.storage_tag) : null
    );

    const handleToggleIsAvailableForBalancing = () => {
        setSubmitValues({
            ...submitValues,
            is_available_for_balancing: !submitValues.is_available_for_balancing,
        });
    };

    const storageTagsLoader = createOptionsLoader(
        getStorageTags,
        storageTagToSelectOption
    );

    const handleStorageTagChange = (option: IStorageTagSelectOption) => {
        setSelectedStorageTag(option);
        setSubmitValues((prevValues) => ({
            ...prevValues,
            storage_tag_id: option ? option.value : null,
        }));
    };

    const handleUpdate = async (values: IStorageRequest) => {
        try {
            values.type !== StorageType.NFS
                ? await updateLocalStorage(computeResource.id, storage.id, {
                    storage_tag_id: values.storage_tag_id,
                    is_available_for_balancing: values.is_available_for_balancing,
                })
                : await updateRemoteStorage(storage.id, values);
            onSubmit();
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <Form
                id="storageUpdateForm"
                onSubmit={handleUpdate}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
                footerClassName="hidden"
            >
                <FormField
                    name="storage_tag_id"
                    label={<Translate content="storage.form.storageTag" />}
                >
                    {({ getId }) => (
                        <AsyncSelectInput
                            inputId={getId()}
                            value={selectedStorageTag}
                            loadOptions={storageTagsLoader}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                            onChange={handleStorageTagChange}
                            isClearable={true}
                        />
                    )}
                </FormField>
                {submitValues.type !== StorageType.NFS && (
                    <FormField label={<Translate content="computeResource.storages.form.availableForBalancing" />}>
                        <Switch
                            checked={submitValues.is_available_for_balancing}
                            onChange={handleToggleIsAvailableForBalancing}
                        />
                    </FormField>
                )}
            </Form>
            <Button
                type="submit"
                form="storageUpdateForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isSaving}
            >
                <Translate content="computeResource.storages.form.createBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    computeResource: state.computeResource.item,
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_COMPUTE_RESOURCE_STORAGE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    storagesActions: bindActionCreators(storagesActions, dispatch),
    storageTagActions: bindActionCreators(storageTagActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageUpdateDialog);
