// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { OnboardingStepper } from 'admin/onboarding/components/OnboardingStepper';
import {
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';
import {
    OnboardingHeader,
    OnboardingLead,
    OnboardingContentFooter,
} from 'admin/onboarding/components/Styled';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import LicenseActivationForm, { LicenseActivationFormFields } from 'admin/license/solusVM/containers/LicenseActivationForm';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';

export interface IOnboardingStepActivateLicenseProps {
    onNext: () => void;
}

export type OnboardingStepActivateLicenseProps =
    IOnboardingStepActivateLicenseProps &
    ReturnType<typeof mapStateToProps>;

export const OnboardingStepActivateLicense: React.FC<OnboardingStepActivateLicenseProps> = ({
    onNext,
    isActivating,
}) => {
    const formRef = React.useRef<FormInstanceHandles>(null);
    const { close } = useOnboarding();

    return (
        <OnboardingStep
            sidebar={(<OnboardingStepper/>)}
        >
            <>
                <OnboardingHeader>
                    <Translate content={'onboarding.clusterSetup.steps.activateLicense.header'}/>
                </OnboardingHeader>
                <OnboardingLead>
                    <Translate content={'onboarding.clusterSetup.steps.activateLicense.lead'}/>
                </OnboardingLead>
                <p><Translate content={'onboarding.clusterSetup.steps.activateLicense.paragraphs.0'}/></p>
                <LicenseActivationForm
                    ref={formRef}
                    onSubmit={onNext}
                    fields={[
                        LicenseActivationFormFields.ACTIVATION_CODE,
                    ]}
                />
                <p>
                    <Translate
                        content={'onboarding.clusterSetup.steps.activateLicense.hints.trial'}
                        params={{
                            trialLink: (
                                <strong style={{ fontWeight: 'bold' }}>
                                    <a
                                        href="https://www.soluslabs.com/clients/cart.php?a=add&pid=29"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Translate
                                            content={'onboarding.clusterSetup.steps.activateLicense.hints.trialLinkTitle'}
                                        />
                                    </a>
                                </strong>
                            ),
                        }}
                    />
                </p>
                <p>
                    <Translate
                        content={'onboarding.clusterSetup.steps.activateLicense.hints.obtainKey'}
                        params={{
                            portalLink: (
                                <a
                                    href="https://www.soluslabs.com/clients/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate
                                        content={'onboarding.clusterSetup.steps.activateLicense.hints.portalLinkTitle'}
                                    />
                                </a>
                            ),
                        }}
                    />
                </p>
                <p>
                    <Translate
                        content={'onboarding.clusterSetup.steps.activateLicense.hints.support'}
                        params={{
                            supportLink: (
                                <a
                                    href="https://support.solusvm.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate
                                        content={'onboarding.clusterSetup.steps.activateLicense.hints.supportLinkTitle'}/>
                                </a>
                            ),
                        }}
                    />
                </p>
                <p>
                    <Translate
                        content={'onboarding.clusterSetup.steps.activateLicense.hints.settings'}
                        params={{
                            settingsLink: (
                                <Link
                                    to={pathTo('license')}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate
                                        content={'onboarding.clusterSetup.steps.activateLicense.hints.settingsLinkTitle'}/>
                                </Link>
                            ),
                        }}
                    />
                </p>
                <OnboardingContentFooter>
                    <Button
                        size={SIZE.LG}
                        intent={'secondary'}
                        onClick={close}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.activateLicense.buttons.close'}/>
                    </Button>
                    <Button
                        size={SIZE.LG}
                        intent={'primary'}
                        onClick={() => formRef.current?.submit()}
                        isLoading={isActivating}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.activateLicense.buttons.continue'}/>
                    </Button>
                </OnboardingContentFooter>
            </>
        </OnboardingStep>
    );
};

const mapStateToProps = (state: RootState) => ({
    isActivating: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_ACTIVATION),
});

export default connect(mapStateToProps)(OnboardingStepActivateLicense);