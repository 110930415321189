// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const SshKeyFormContainer = styled.div`
    .pul-section__title {
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 26px !important;
        width: 100%;
    }

    .generate {
        float: right; 
        font-size: 14px;
        padding: 3px;
        margin-left: auto;
    }

    textarea {
        min-height: 180px !important;
    }

    input {
        height: 40px;
    }

    input, textarea {
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;
        border-radius: 2px;
        font-size: 14px;
        line-height: 20px;
        padding: 10px !important;
    }

    button {
        border-radius: 2px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        padding: 10px;
        text-align: center;
    }

    .pul-form__footer {
        display: none;
    }
`;
