// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Icon,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    ItemParamLabel,
    ItemParamValue,
} from 'common/modules/computeResourceVm/components/Styles';
import { IPlanResponse } from 'common/api/resources/Plan';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';
import {
    IOfferResponse,
    OfferPriceMode,
    OfferType,
} from 'common/api/resources/Offer';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';
import {
    offerToPricePerHour,
    offerToPricePerMonth,
} from 'common/helpers/ProjectTokenCalculator';

export const OfferPrice: React.FC<{
    plan?: IPlanResponse;
    tokenPricing?: IProjectTokenPricingResponse;
    offer: IOfferResponse;
    sizeGib?: number;
}> = ({
    plan,
    tokenPricing,
    offer,
    sizeGib,
}) => {
    if (!tokenPricing) {
        return (<></>);
    }

    const symbol = (tokenPricing.currency_prefix
        || tokenPricing.currency_suffix
        || tokenPricing.currency_code
    ).replace(/\s/g, '');

    const warning = !plan && (
        offer.price_mode === OfferPriceMode.PERCENT
        || (offer.type === OfferType.PRIMARY_DISK && offer.price_mode === OfferPriceMode.PER_GIB)
    );

    const perMonth = warning
        ? (
            <>
                {offer.price_mode === OfferPriceMode.PERCENT && (offer.per_month + '%')}
                {offer.price_mode === OfferPriceMode.PER_GIB && (
                    formatPricePerHour(offer.per_month, tokenPricing) + '/GiB'
                )}
                &nbsp;
                <Tooltip title={<Translate content="servers.offer.notice.selectPlanForPrice" />}>
                    <Icon intent={INTENT_TYPE.WARNING} name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED} />
                </Tooltip>
            </>
        )
        : formatPricePerMonth(offerToPricePerMonth(offer, plan, sizeGib)!, tokenPricing);
    const perHour = warning
        ? (
            <>
                {offer.price_mode === OfferPriceMode.PERCENT && (offer.per_hour + '%')}
                {offer.price_mode === OfferPriceMode.PER_GIB && (
                    formatPricePerHour(offer.per_hour, tokenPricing) + '/GiB'
                )}
                &nbsp;
                <Tooltip title={<Translate content="servers.offer.notice.selectPlanForPrice" />}>
                    <Icon intent={INTENT_TYPE.WARNING} name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED} />
                </Tooltip>
            </>
        )
        : formatPricePerHour(offerToPricePerHour(offer, plan, sizeGib)!, tokenPricing);

    return (
        <>
            <ItemParamLabel>
                <Translate content="servers.offer.card.priceMonthLabel" params={{ symbol }} />
            </ItemParamLabel>
            <ItemParamValue>{perMonth}</ItemParamValue>
            <ItemParamLabel>
                <Translate content="servers.offer.card.priceHourLabel" params={{ symbol }} />
            </ItemParamLabel>
            <ItemParamValue>{perHour}</ItemParamValue>
        </>
    );
};
