// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const FailedTaskContainer = styled.div`
    .pul-status {
        font-size: 14px;
        color: ${COLORS.GREY_100}!important;
    }

    .pul-status--compact .pul-status--danger:before {
        background-color: 
        ${COLORS.RED};
    }

    .pul-button {
        margin-left: 4px;

        .pul-icon {
            color: ${COLORS.GREY_80}!important;
        }
    }
`;

export const ErrorText = styled.div`
    font-size: 12px;
    line-height: 16px;
    margin-left: 12px;
    margin-bottom: 8px;
    word-break: break-word;
    color: ${COLORS.GREY_80}
`;
