// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import * as computeResourceActions from 'admin/computeResource/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { Translate } from '@plesk/ui-library';
import { ComputeResourceInfoBlock } from 'admin/computeResource/list/components';
import {
    Loader,
    ProgressStates,
} from 'common/components';
import {
    getStatus,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import ComputeResourceForm, { FIELDS } from 'admin/computeResource/list/containers/ComputeResourceForm/ComputeResourceForm';
import { NOT_SELECTED_ID } from 'common/api/constants';
import Axios from 'axios';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { getComputeResourceInstallSteps } from 'admin/computeResource/selectors';

interface IComputeResourceInstallProps {
    item: IComputeResourceResponse;
    fields?: FIELDS[];
}

export type ComputeResourceInstallProps =
    IComputeResourceInstallProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceInstall: React.FC<ComputeResourceInstallProps> = ({
    item,
    installSteps,
    isItemCreating,
    isLoadingItem,
    isLoadingInstallSteps,
    isProcessing,
    computeResourceActions: {
        getInstallSteps,
    },
    fields,
}) => {
    React.useEffect(() => {
        const source = Axios.CancelToken.source();

        if (item.id !== NOT_SELECTED_ID) {
            // Compute resource is fetched when user clicks on details button.
            getInstallSteps(item.id, source);
        }

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const installStepsEl = installSteps
        .map(({ id, progress, status_text, ...step }) => ({
            key: id.toString(),
            progress: -1,
            statusText: status_text,
            ...step,
        }));

    return (
        <Loader isLoading={isLoadingItem || isLoadingInstallSteps} center={false}>
            {isProcessing && (
                <>
                    <ComputeResourceInfoBlock item={item} isLoading={isLoadingItem} />
                    {item.id > 0 &&
                        <ProgressStates
                            isOpen={true}
                            steps={installStepsEl}
                        />
                    }
                </>
            )}
            {!isProcessing && <ComputeResourceForm item={item} fields={fields} />}
            {(isProcessing || isItemCreating) &&
                <Button
                    isLoading={true}
                    state="loading"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                >
                    <Translate content="computeResource.actionDialog.processingBtn" />
                </Button>
            }
            {(!isProcessing && !isItemCreating) &&
                <Button
                    type="submit"
                    form="computeResourceForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                    isLoading={isItemCreating}
                >
                    <Translate content={item.id
                        ? 'computeResource.actionDialog.retryBtn'
                        : 'computeResource.actionDialog.createBtn'
                    } />
                </Button>
            }
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => {
    const item = state.computeResource.item;
    const isProcessing = item.id > 0 && getStatus(item.status) === COMPUTE_RESOURCE_STATUS.COMMISSIONING;
    const isItemCreating = state.app.loadingFlags.has(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE);

    return {
        isProcessing,
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_ITEM),
        isLoadingInstallSteps: state.app.loadingFlags.has(LOADING_FLAGS.INSTALL_STEPS_LIST),
        installSteps: getComputeResourceInstallSteps(state, state.computeResource.item.id),
        isItemCreating,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceInstall);
