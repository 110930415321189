// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as computeResourceActions from 'admin/computeResource/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import { IStorageCreateRequest } from 'common/api/resources/Storage';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as storagesSelectors from 'admin/computeResource/selectors';
import * as storageTypeActions from 'admin/storageType/actions';
import * as storagesActions from 'admin/storage/actions';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import SelectInput from 'common/components/Select/SelectInput';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    STORAGE_TYPES_TRANSLATION_MAP,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    Form,
    FormField,
    FormFieldText,
    Switch,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import { getStatus } from 'common/api/resources/ComputeResource';
import * as storageTagActions from 'admin/storageTag/actions';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { IShortStorageTagResponse } from 'common/api/resources/StorageTag';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { ISelectOption as ICommonSelectOption } from 'common/components';

export interface ISelectOption {
    label: string;
    value: string;
}

interface IStorageCreateDialogProps {
    onClose: () => void;
}

export type StorageCreateDialogProps =
    IStorageCreateDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: IStorageCreateRequest = Object.freeze({
    type: StorageType.FB,
    path: '',
    is_available_for_balancing: true,
});

interface IStorageTagSelectOption extends ICommonSelectOption {
    value: number;
    label: string;
}
const storageTagToSelectOption = (tag: IShortStorageTagResponse): IStorageTagSelectOption => ({
    label: tag.name,
    value: tag.id,
});

export const StorageCreateDialog: React.FC<StorageCreateDialogProps> = ({
    onClose,
    computeResource,
    formErrors,
    storageTypeActions: { getStorageTypes },
    formErrorsActions: { setFormErrors },
    storagesActions: { getStorages },
    computeResourceActions: {
        mountComputeResourceStorage,
        createStorage,
        setPhysicalVolumes,
        getComputeResourcePhysicalVolumes,
        getComputeResourceThinPools,
        getStorages: getComputeResourceStorages,
    },
    storageTagActions: {
        getStorageTags,
    },
    storages,
    computeResourceStorages,
    pvs,
    lvs,
    storageTypes,
    isCreating,
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });
    const [selectedStorageTag, setSelectedStorageTag] = React.useState<IStorageTagSelectOption | undefined>();
    const translate = useTranslate();
    const [storageTypeButtons, setStorageTypeButtons] = React.useState(storageTypes.map(item => ({
        value: item.name,
        title: STORAGE_TYPES_TRANSLATION_MAP[item.name],
    })));

    React.useEffect(() => {
        getComputeResourceStorages(computeResource.id);
        getStorages({
            filters: {
                type: StorageType.NFS,
            },
        });

        getStorageTypes();

        if (getStatus(computeResource.status) === COMPUTE_RESOURCE_STATUS.ACTIVE) {
            getComputeResourcePhysicalVolumes(computeResource.id);
            getComputeResourceThinPools(computeResource.id);
        }

        return () => {
            setFormErrors({});
            setPhysicalVolumes([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        handleTypeChange(submitValues.type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pvs, lvs]);

    React.useEffect(() => {
        const isVZSupportedByCR = computeResource.capabilities.vz;
        const isVZStorageExists = computeResourceStorages.some(item => item.type.name === StorageType.VZ);

        setStorageTypeButtons(storageTypes.map(item => ({
            value: item.name,
            title: STORAGE_TYPES_TRANSLATION_MAP[item.name],
            disabled: item.name === StorageType.VZ && (!isVZSupportedByCR || isVZStorageExists),
            tooltip: getStorageTypeTooltip(item.name, isVZSupportedByCR, isVZStorageExists),
        })));
    }, [computeResourceStorages, computeResource, storageTypes]);

    const getStorageTypeTooltip = (type: StorageType, isVZSupportedByCR: boolean, isVZStorageExists: boolean): JSX.Element | null => {
        if (type !== StorageType.VZ) {
            return null;
        }

        if (isVZStorageExists) {
            return <Translate content="computeResource.storages.tooltip.vzStorageAlreadyExists"/>;
        }

        if (!isVZSupportedByCR) {
            return <Translate content="computeResource.storages.tooltip.vzIsNotSupportedByCR"/>;
        }

        return null;
    };

    const pvOptions: ISelectOption[] = pvs.map(volume => ({
        value: volume.vg_name,
        label: `${volume.vg_name} (${volume.vg_free}/${volume.vg_size}Gb)`,
    }));

    const lvOptions: ISelectOption[] = lvs.map((volume, index) => ({
        value: index.toString(),
        label: `${volume.vg_name}(${volume.lv_name} - ${volume.data_percent}% used)`,
    }));

    const remoteStoragesOptions: ISelectOption[] = storages.map(item => ({
        value: item.id.toString(),
        label: item.name,
    }));

    const handleTypeChange = (value: StorageType) => {
        setSubmitValues(prevSubmitValues => {
            let path = value === prevSubmitValues.type ? prevSubmitValues.path : '';
            if (value === StorageType.VZ) {
                // Should be synced with DEFAULT_VZ_STORAGE_PATH in `backend/api/v1/Storage/Models/Storage.php`
                path = '/vz/private/';
            }

            return ({
                ...prevSubmitValues,
                path,
                type: value,
            });
        });
    };

    const handlePatchChange = (value: string) => {
        setSubmitValues({
            type: submitValues.type,
            path: value,
            is_available_for_balancing: submitValues.is_available_for_balancing,
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLvChange = (value: any) => {
        if (lvs[value.value] !== undefined) {
            setSubmitValues({
                ...submitValues,
                path: lvs[value.value].vg_name,
                thin_pool: lvs[value.value].lv_name,
            });
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (value: any) => setSubmitValues(prevState => ({
        ...prevState,
        path: value?.value,
    }));

    const handleToggleIsAvailableForBalancing = () => {
        setSubmitValues({
            ...submitValues,
            is_available_for_balancing: !submitValues.is_available_for_balancing,
        });
    };

    const storageTagsLoader = createOptionsLoader(
        getStorageTags,
        storageTagToSelectOption
    );

    const handleStorageTagChange = (option: IStorageTagSelectOption) => {
        setSelectedStorageTag(option);
        setSubmitValues((prevValues) => ({
            ...prevValues,
            storage_tag_id: option ? option.value : null,
        }));
    };

    const create = async (values: IStorageCreateRequest) => {
        try {
            values.type !== StorageType.NFS ?
                await createStorage(computeResource.id, values) :
                // in case of NFS storage we are using path as storage id
                // to be consistent and for validation purposes
                await mountComputeResourceStorage(Number(values.path), computeResource.id);
            onClose();
        } catch (e) {
            throw e;
        }
    };

    const handleCreate = async (values: IStorageCreateRequest) => {
        const errors = validate<IStorageCreateRequest>(values, {
            type: requiredRule(<Translate content="validate.fieldRequired" />),
            path: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await create(values);
    };

    return (
        <>
            <Form
                id="storageCreateForm"
                onSubmit={handleCreate}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
                footerClassName="hidden"
            >
                <FormField name="type" value={submitValues.type}>
                    <SegmentedControl
                        buttons={storageTypeButtons}
                        selected={submitValues.type}
                        onChange={handleTypeChange}
                    />
                </FormField>
                {submitValues.type === StorageType.FB &&
                    <FormFieldText
                        size="fill"
                        name="path"
                        label={<Translate content="computeResource.storages.form.path" />}
                        onChange={handlePatchChange}
                    />
                }
                {submitValues.type === StorageType.LVM &&
                    <FormField name="path">
                        <SelectInput<ISelectOption>
                            placeholder={translate('computeResource.storages.selectors.lvm')}
                            options={pvOptions}
                            isClearable={true}
                            onChange={handleChange}
                        />
                    </FormField>
                }
                {submitValues.type === StorageType.THIN_LVM &&
                    <FormField name="path">
                        <SelectInput<ISelectOption>
                            placeholder={translate('computeResource.storages.selectors.thinLvm')}
                            options={lvOptions}
                            isClearable={true}
                            onChange={handleLvChange}
                        />
                    </FormField>
                }
                {submitValues.type === StorageType.NFS && (
                    <FormField name="path">
                        <SelectInput<ISelectOption>
                            placeholder={translate('computeResource.storages.selectors.nfs')}
                            options={remoteStoragesOptions}
                            isClearable={true}
                            onChange={handleChange}
                            menuPosition="fixed"
                        />
                    </FormField>
                )}
                {submitValues.type === StorageType.VZ && (
                    <FormFieldText
                        size="fill"
                        name="path"
                        label={<Translate content="computeResource.storages.form.path" />}
                        disabled={true}
                    />
                )}
                <FormField
                    name="storage_tag_id"
                    label={<Translate content="storage.form.storageTag" />}
                >
                    {({ getId }) => (
                        <AsyncSelectInput
                            inputId={getId()}
                            value={selectedStorageTag}
                            loadOptions={storageTagsLoader}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                            onChange={handleStorageTagChange}
                            isDisabled={submitValues.type === StorageType.VZ}
                            isClearable={true}
                        />
                    )}
                </FormField>
                {submitValues.type !== StorageType.NFS && (
                    <FormField label={<Translate content="computeResource.storages.form.availableForBalancing" />}>
                        <Switch
                            checked={submitValues.is_available_for_balancing}
                            onChange={handleToggleIsAvailableForBalancing}
                        />
                    </FormField>
                )}
            </Form>
            <Button
                type="submit"
                form="storageCreateForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isCreating}
            >
                <Translate content="computeResource.storages.form.createBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    computeResource: state.computeResource.item,
    isCreating: state.app.loadingFlags.has(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_STORAGE),
    pvs: storagesSelectors.getAvailablePhysicalVolumes(state),
    lvs: storagesSelectors.getAvailableLogicalVolumes(state),
    storageTypes: state.storageType,
    storages: storagesSelectors.availableStorages(state),
    computeResourceStorages: state.computeResource.storages,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    storageTypeActions: bindActionCreators(storageTypeActions, dispatch),
    storagesActions: bindActionCreators(storagesActions, dispatch),
    storageTagActions: bindActionCreators(storageTagActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageCreateDialog);
