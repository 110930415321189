// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { MigrationFormMetricTitle } from 'admin/computeResourceVm/containers/MigrationForm/Styles';

interface IMigrationFormMetricProps {
    title: string | JSX.Element;
    value: number | string | JSX.Element;
}

export type MigrationFormMetricProps = IMigrationFormMetricProps;

const MigrationFormMetric: React.FC<MigrationFormMetricProps> = ({
    title,
    value,
}) => (
    <>
        <MigrationFormMetricTitle>{title}</MigrationFormMetricTitle>
        &nbsp;
        {value}
    </>
);

export default MigrationFormMetric;
