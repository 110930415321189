// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Translate } from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';

export const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const MainTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MainTitle = styled(Translate)`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${COLORS.GREY_100};
  margin-right: 15px;
`;

export const SubTitleContainer = styled.div`
  margin-top: 5px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  div:last-of-type {
    margin-right: 0;
  }
`;

export const SubTitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const SubTitleSectionLabel = styled.span`
  color: ${COLORS.GREY_80};
  margin-right: 5px;
`;

export const ActionsContainer = styled.div`
  button {
    margin-right: 10px;

    :last-of-type {
      margin-right: 0;
    }

    max-height: inherit !important;
  }
`;
