// Copyright 2025. WebPros International GmbH. All rights reserved.
import * as React from 'react';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    Button,
    Input,
    useTranslate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import Axios, { CancelTokenSource } from 'axios';
import {
    search,
    SearchEntityType,
    SearchItemResponse,
} from 'common/api/resources/Search';
import SearchBarResults, { ISearchBarResultsHandle } from 'common/modules/search/components/SearchBarResults';
import {
    SearchBarContainer,
    SearchBarResultsContainer,
} from 'common/modules/search/components/Styles';
import SearchBarHint from 'common/modules/search/components/SearchBarHint';
import { pathTo } from 'common/helpers/core';

const isEditingContent = (event: KeyboardEvent): boolean => {
    const element = event.target;
    if (!element || !(element instanceof HTMLElement)) {
        return false;
    }

    const tagName = element.tagName;
    return (
        element.isContentEditable ||
        tagName === 'INPUT' ||
        tagName === 'SELECT' ||
        tagName === 'TEXTAREA'
    );
};

export interface ISearchBarProps {
    onOpen?: () => void;
    onClose?: () => void;
    displayResults?: number;
}

export type SearchBarProps = ISearchBarProps & RouteComponentProps;

export const formatPath: Record<SearchEntityType, (item: SearchItemResponse) => string> = {
    [SearchEntityType.ComputeResource]: (i) => pathTo(`compute_resources/${i.id}`),
    [SearchEntityType.Server]: (i) => pathTo(`servers/${i.id}`),
    [SearchEntityType.User]: (i) => pathTo(`users?id=${i.id}`),
};

const SearchBar: React.FC<SearchBarProps> = ({
    onOpen,
    onClose,
    history: {
        push,
    },
    ...props
}) => {
    const translate = useTranslate();

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = React.useState(false);
    const [isResultsHovered, setIsResultsHovered] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const resultsRef = React.useRef<ISearchBarResultsHandle>(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [searchResults, setSearchResults] = React.useState<SearchItemResponse[]>([]);

    // Mount '/' key press listener
    React.useEffect(() => {

        const handleKeyDown = (event: KeyboardEvent) => {
            // on '/' or 'ctrl+k' key press
            if ((!isEditingContent(event) && event.key === '/')
                || (event.ctrlKey && event.key === 'k')) {
                // We need to leave a trace in the console to help us in future
                // eslint-disable-next-line no-console
                console.debug('SearchBar intercepted "/" key press');

                event.preventDefault();
                inputRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const fetchSearchResults = React.useCallback(async (query: string, cancelToken?: CancelTokenSource) => {
        setIsLoading(true);

        try {
            if (query.length === 0) {
                // sleep for 250ms to prevent flickering
                await new Promise((resolve) => setTimeout(resolve, 250));
                setSearchResults([]);
                setIsLoading(false);
                return;
            }

            const results = await search.search({ query }, cancelToken);

            setSearchResults(results.data.data);
        } catch (e) {
            if (Axios.isCancel(e)) {
                // we don't need to set state if request was cancelled
                return;
            }

            throw e;
        } finally {
            setIsLoading(false);
        }
    }, [setSearchResults, setIsLoading]);

    React.useEffect(() => {
        const cancelToken = Axios.CancelToken.source();

        fetchSearchResults(searchValue, cancelToken);

        return () => {
            cancelToken.cancel();
        };
    }, [fetchSearchResults, searchValue]);

    const clearValue = () => {
        setSearchValue('');
    };

    const handleArrowNavigation = (event: React.KeyboardEvent<HTMLInputElement>) => {
        resultsRef.current?.handleArrowNavigation(event);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            inputRef.current?.blur();
        }

        if (event.key === 'Enter' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            handleArrowNavigation(event);
        }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };

    React.useEffect(() => {
        if (isFocused && onOpen) {
            onOpen();
        } else if (!isFocused && onClose) {
            onClose();
        }
    }, [isFocused, onOpen, onClose]);

    const handleResultsMouseOver = () => {
        setIsResultsHovered(true);
    };
    const handleResultsMouseOut = () => {
        setIsResultsHovered(false);
    };

    const handleSelected = React.useCallback((item: SearchItemResponse) => {
        inputRef.current?.blur();
        setIsResultsHovered(false);
        push(formatPath[item.type](item));
    }, [push, setIsResultsHovered]);

    const handleHint = (isAppend: boolean) => (value: string) => {
        setSearchValue((prev) => isAppend ? value + prev : prev + value);
        inputRef.current?.focus();
    };

    return (
        <SearchBarContainer focused={isFocused || isResultsHovered}>
            <Input
                innerRef={inputRef}
                value={searchValue}
                size={'fill'}
                onKeyDown={handleKeyDown}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value) }
                onFocus={handleFocus}
                onBlur={handleBlur}
                prefix={(
                    <Button
                        icon={ICONS.SEARCH}
                        ghost
                        state={isLoading ? 'loading' : undefined}
                        onClick={() => inputRef.current?.focus()}
                    />
                )}
                suffix={
                    searchValue.length > 0
                        ? (
                            <Button
                                icon={ICONS.REMOVE}
                                ghost
                                onClick={() => clearValue()}
                            />
                        )
                        : undefined
                }
                placeholder={translate('search.placeholder') as string}
            />
            <SearchBarResultsContainer
                visible={isFocused || isResultsHovered}
                onMouseOver={handleResultsMouseOver}
                onMouseOut={handleResultsMouseOut}
            >
                {
                    searchValue.length > 0
                        ? (
                            <SearchBarResults
                                ref={resultsRef}
                                results={searchResults}
                                onSelected={handleSelected}
                                isLoading={isLoading}
                                formatPath={formatPath}
                            />
                        )
                        : (
                            <SearchBarHint
                                prepend={handleHint(false)}
                                append={handleHint(true)}
                            />
                        )
                }
            </SearchBarResultsContainer>
        </SearchBarContainer>
    );
};

export default withRouter(SearchBar);