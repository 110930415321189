// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import NotificationItem from 'admin/notification/containers/Items/NotificationItem';
import moment from 'moment';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';

export interface IManagementNodeBackupRecoveryItemProps {
    item: INotificationResponse;
    header: string;
    description: string;
}

export const ManagementNodeBackupRecoveryItem: React.FC<IManagementNodeBackupRecoveryItemProps> = ({
    item,
    header,
    description,
}) => (
    <NotificationItem
        item={item}
        icon={<Icon name={ICONS.CHECK_MARK_CIRCLE_FILLED} size="16" intent={INTENT_TYPE.SUCCESS} />}
        header={<Translate content={header} />}
        description={(
            <Translate
                content={description}
                params={{
                    backupNodeName: item.data.backup_node_name,
                    mnbCount: item.data.management_node_backup_count,
                    date: moment(item.data.date).format('D-M-Y'),
                    time: moment(item.data.date).format('HH:mm:ss'),
                }}
            />
        )}
    />
);

export default ManagementNodeBackupRecoveryItem;
