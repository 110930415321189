// Copyright 2025. WebPros International GmbH. All rights reserved.

import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Dispatch } from 'redux';
import { SET_STORAGE_TYPES_LIST } from 'admin/storageType/constants';
import { createCustomAction } from 'typesafe-actions';
import { list } from 'common/actions/actionsWrapper';
import { IAppState } from 'admin/core/store';
import {
    IStorageTypeResponse,
    storageTypes,
} from 'common/api/resources/StorageType';

export const setList = createCustomAction(
    SET_STORAGE_TYPES_LIST,
    (data: IStorageTypeResponse[]) => ({ payload: data })
);

export const getStorageTypes = () => async (dispatch: Dispatch, getState: () => IAppState) => {
    const storageTypeList = getState().storageType;
    if (storageTypeList.length > 0) {
        return storageTypeList;
    }

    return await list({
        dispatch,
        loadingFlag: LOADING_FLAGS.STORAGE_TYPE_LIST,
        action: setList,
        apiCall: storageTypes.list,
    });
};
