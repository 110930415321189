// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    Icon,
    Label,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import {
    ComputeResourceVmNameContainer,
    MigrationFormTitleLink,
    MigrationFromTitle,
} from 'admin/computeResourceVm/containers/ComputeResourceVmTable/Styles';
import {
    getViewableIps,
    IComputeResourceVmListFilters,
    IComputeResourceVmListRequest,
} from 'common/api/resources/ComputeResourceVm';
import CopyText from 'common/containers/CopyText/CopyText';
import {
    statusMappings,
    VmStatus,
} from 'common/components/VmStatus';
import ComputeResourceVmRow from 'admin/computeResourceVm/containers/ComputeResourceVmRow/ComputeResourceVmRow';
import Usage from 'admin/common/components/Usage/Usage';
import { SERVER } from 'admin/computeResource/constants/tests';
import { COLORS } from 'admin/core/theme';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import ComputeResourceVmActions
    from 'admin/computeResourceVm/containers/ComputeResourceVmActions/ComputeResourceVmActions';
import * as serversMigrationActions from 'admin/serversMigration/actions';
import { Dialog } from 'common/components/Dialog/Dialog';
import MigrationForm from 'admin/computeResourceVm/containers/MigrationForm/MigrationForm';
import { formatTableDate } from 'common/date';
import { IListRow } from 'common/list';
import { VMS_TABS } from 'admin/computeResourceVm/constants';
import { areNetworkLimitsExceeded } from 'common/api/resources/Plan';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import ComputeResourceVmOperations
    from 'admin/computeResourceVm/containers/ComputeResourceVmTable/ComputeResourceVmOperations';
import List from 'common/components/List/List';
import { ValueType } from 'react-select';
import {
    ISelectRequiredOption,
    Loader,
} from 'common/components';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import ServerEditDialog from 'admin/common/containers/ServerEditDialog/ServerEditDialog';
import { pathTo } from 'common/helpers/core';
import { Link } from 'react-router-dom';
import useQuery from 'common/hooks/useQuery';
import {
    computeResources,
    VIRTUALIZATION_TYPE_TRANSLATION_MAP,
} from 'common/api/resources/ComputeResource';
import { users } from 'common/api/resources/User';
import SyncImagePresetsForm from 'admin/computeResourceVm/containers/SyncImagePresetsForm/SyncImagePresetsForm';

export interface IComputeResourceVmFilters {
    status?: ValueType<ISelectRequiredOption>;
    computeResource?: ValueType<ISelectRequiredOption>;
    user?: ISelectRequiredOption;
    virtualizationType?: ValueType<ISelectRequiredOption>;
}

interface IComputeResourceVmTableProps extends RouteComponentProps {
    excludedColumns?: string[];
    withFilters?: boolean;
    computeResourceId?: number;
    emptyView: React.ReactElement;
}

export type ComputeResourceVmTableProps =
    IComputeResourceVmTableProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    title: <Translate content="computeResource.servers.list.id"/>,
    width: '1%',
}, {
    key: 'colName',
    title: <Translate content="computeResource.servers.list.name"/>,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '15%',
    key: 'colStatus',
    title: <Translate content="computeResource.servers.list.status"/>,
}, {
    key: 'colComputeResource',
    title: <Translate content="computeResource.servers.list.computeResource"/>,
}, {
    key: 'colIp',
    title: <Translate content="computeResource.servers.list.ip"/>,
}, {
    key: 'colCpu',
    title: <Translate content="computeResource.servers.list.cpu"/>,
}, {
    key: 'colOwner',
    title: <Translate content="computeResource.servers.list.owner"/>,
}, {
    key: 'colCreated',
    title: <Translate content="computeResource.servers.list.created"/>,
}, getActionColumnProps({ width: '5%' }),
];

/**
 * Convert tags query params to object
 * @param inputTags - tags query param
 * @example
 * transformTagsStringToQueryObject('color:blue,red;size:md,lg')
 * // returns {color: ['blue', 'red'], size: ['md', 'lg']}
 */
const transformQueryTagToObject = (inputTags: string): Record<string, string[]> => {
    try {
        return inputTags
            .split(';')
            .reduce<Record<string, string[]>>((result, item) => {
                const [key, value] = item.split(':');

                if (!result[key]) {
                    result[key] = [];
                }

                result[key].push(value);

                return result;
            }, {});
    } catch {
        return {};
    }
};

/**
 * Convert tags array to query object
 * @param inputTags - tags state value
 * @example
 * transformTagsArrayToQueryParam(['color:blue', 'color:red', 'size:md', 'size:lg'])
 * // returns color:blue,red;size:md,lg
 */
const transformTagsArrayToQueryParam = (inputTags: string[]): string => {
    const obj: Record<string, string[]> = inputTags
        .map(item => item.split(':'))
        .reduce((result, [key, value]) => {
            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(value);

            return result;
        }, {});

    return Object
        .entries(obj)
        .map(([tagKey, tagValues]) => `${tagKey}:${(tagValues.join(','))}`)
        .join(';');
};

interface IComputeResourceVmListFiltersWithTags extends IComputeResourceVmListFilters {
    tags?: string;
}

export const ComputeResourceVmTable: React.FC<ComputeResourceVmTableProps> = ({
    computeResourceVms,
    computeResourceVmActions: {
        getComputeResourceVms,
        deleteComputeResourceVm,
        deleteComputeResourceVms,
    },
    serversMigrationActions: {
        preCheckMigration,
    },
    excludedColumns,
    isLoading,
    isMigrationPreChecking,
    history,
    withFilters,
    computeResourceId,
    emptyView,
}) => {
    const [selectedVm, setSelectedVm] = React.useState<number>(0);
    const [selection, setSelection] = React.useState<string[]>([]);
    const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
    const [filters, setFilters] = useQuery<IComputeResourceVmListFiltersWithTags>();
    const [isServerEditDialogOpened, setServerEditDialogOpened] = React.useState(false);
    const [isMigrationDialogOpened, setMigrationDialogOpened] = React.useState(false);
    const [selectedVmStatus, setSelectedVmStatus] = React.useState<ISelectRequiredOption>();
    const [selectedCr, setSelectedCr] = React.useState<ISelectRequiredOption>();
    const [selectedUser, setSelectedUser] = React.useState<ISelectRequiredOption>();
    const [selectedVirtualizationType, setSelectedVirtualizationType] = React.useState<ISelectRequiredOption>();
    const [tags, setTags] = React.useState<string[]>([]);
    const [isSyncImagePresetsDialogOpened, setIsSyncImagePresetsDialogOpened] = React.useState(false);

    const translate = useTranslate();

    const getQueryFilters = (): IComputeResourceVmListRequest => {
        const queryFilters = {
            compute_resource_id: filters.compute_resource_id ?? computeResourceId,
            status: filters.status,
            user_id: filters.user_id,
            virtualization_type: filters.virtualization_type,
        };

        const queryTags = filters.tags
            ? transformQueryTagToObject(filters.tags)
            : undefined;

        return {
            filters: queryFilters,
            tags: queryTags,
            exclude: ['plan'],
        };
    };

    const isFirstLoading = useRequestCancellationEffect(
        async (token) => {
            await getComputeResourceVms(getQueryFilters(), token);
        },
        [filters]
    );

    React.useEffect(() => {
        if (filters.status && filters.status !== selectedVmStatus?.value) {
            const statusValue = filters.status;

            setSelectedVmStatus({
                label: translate(statusMappings[statusValue]) as string,
                value: statusValue,
            });
        }

        if (filters.compute_resource_id && Number(filters.compute_resource_id) !== Number(selectedCr?.value)) {
            const computeResourceIdParam = filters.compute_resource_id;

            (async () => {
                const {
                    data: {
                        data: {
                            id,
                            name,
                        },
                    },
                } = await computeResources.item(Number(computeResourceIdParam));

                setSelectedCr({ value: id.toString(), label: name  });
            })();
        }

        if (filters.user_id && Number(filters.user_id) !== Number(selectedUser?.value)) {
            const userId = filters.user_id;

            (async () => {
                const {
                    data: {
                        data: {
                            id,
                            email,
                        },
                    },
                } = await users.item(userId);

                setSelectedUser({ value: id.toString(), label: email });
            })();
        }

        if (filters.virtualization_type && filters.virtualization_type !== selectedVirtualizationType?.value) {
            const virtualizationType = filters.virtualization_type;

            setSelectedVirtualizationType({
                label: VIRTUALIZATION_TYPE_TRANSLATION_MAP[virtualizationType],
                value: virtualizationType,
            });
        }

        if (filters.tags && filters.tags !== transformTagsArrayToQueryParam(tags)) {
            try {
                const res = filters.tags
                    .split(';')
                    .map(item => item.split(':'))
                    .reduce((result, [key, values]) => {
                        const valuesArr = values.split(',');

                        valuesArr.forEach(v => {
                            result.push(`${key}:${v}`);
                        });

                        return result;
                    }, []);

                setTags(res);
            } catch {
                // skip set filters tags string to tags state
            }
        }
    }, [filters, selectedVmStatus, selectedCr, selectedUser, selectedVirtualizationType, tags]);

    const filteredCount = React.useMemo(
        () => Object.values(filters).filter((value) => !!value).length,
        [filters]
    );

    const openMigrationDialog = () => {
        preCheckMigration(selection.map(id => parseInt(id, 10)));
        setMigrationDialogOpened(true);
    };

    const openSyncImagePresetsDialog = () => {
        setIsSyncImagePresetsDialogOpened(true);
    };

    const handleSyncImagePresetDialogClose = () => {
        setIsSyncImagePresetsDialogOpened(false);
    };

    const handleMigrationDialogClose = () => {
        setMigrationDialogOpened(false);
        setSelection([]);
    };

    const handleMigrationSubmit = () => {
        handleMigrationDialogClose();
        history.push(pathTo(`servers/${VMS_TABS.MIGRATIONS_TAB}`));
    };

    const handleServerEditDialogDialog = () => {
        setServerEditDialogOpened(false);
    };

    const loadPaginated = (page: number) => getComputeResourceVms({ page, ...getQueryFilters() });

    const handleDelete = async (id: number) => {
        await deleteComputeResourceVm(id, false);
        reloadListData(computeResourceVms, loadPaginated);
    };

    const handleBatchDelete = async () => {
        const result = await deleteComputeResourceVms(selection.map(id => parseInt(id, 10)));
        setSelection([]);
        reloadListData(computeResourceVms, loadPaginated, result.length);
    };

    const handleVmStatusFilterChange = (option: ValueType<ISelectRequiredOption>) => {
        const typedOption = option as ISelectRequiredOption;

        setSelectedVmStatus(typedOption);

        setFilters(prev => ({
            ...prev,
            status: typedOption ? typedOption.value : undefined,
        }));
    };

    const handleCrFilterChange = (option: ValueType<ISelectRequiredOption>) => {
        const typedOption = option as ISelectRequiredOption;

        setSelectedCr(typedOption);

        setFilters(prev => ({
            ...prev,
            compute_resource_id: typedOption ? Number(typedOption.value) : undefined,
        }));
    };

    const handleUserFilterChange = (option: ValueType<ISelectRequiredOption>) => {
        const typedOption = option as ISelectRequiredOption;

        setSelectedUser(typedOption);

        setFilters(prev => ({
            ...prev,
            user_id: typedOption ? Number(typedOption.value) : undefined,
        }));
    };

    const handleVirtualizationTypeFilterChange = (option: ValueType<ISelectRequiredOption>) => {
        const typedOption = option as ISelectRequiredOption;

        setSelectedVirtualizationType(typedOption);

        setFilters(prev => ({
            ...prev,
            virtualization_type: typedOption ? typedOption.value : undefined,
        }));
    };

    const handleTagOptionsFilterChange = (options: Array<ValueType<ISelectRequiredOption>>) => {
        const typedOptions = options as ISelectRequiredOption[];

        setTags(typedOptions.map(item => item.value));

        setFilters(prev => ({
            ...prev,
            tags: transformTagsArrayToQueryParam(typedOptions.map(item => item.value)),
        }));
    };

    const listData = computeResourceVms.data.map((item) => {
        const handleUpdate = () => {
            setSelectedVm(item.id);
            setServerEditDialogOpened(true);
        };

        return {
            colId: item.id,
            colName: (
                <ComputeResourceVmNameContainer>
                    {item.settings.os_image.icon && (
                        <img src={item.settings.os_image.icon} alt=""/>
                    )}
                    <Action
                        component={Link}
                        to={pathTo(`servers/${item.id}`)}
                    >
                        {item.name}
                    </Action>
                    {item.is_suspended && (
                        <>
                            &nbsp;
                            <Label intent={INTENT_TYPE.WARNING}><Translate content="servers.status.suspended"/></Label>
                        </>
                    )}
                </ComputeResourceVmNameContainer>
            ),
            colStatus: (
                <>
                    {areNetworkLimitsExceeded(item) && (
                        <Icon
                            name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED}
                            intent={INTENT_TYPE.WARNING}
                            style={{ paddingRight: '5px' }}
                        />
                    )}
                    <VmStatus
                        status={item.status}
                        compact={true}
                        progress={item.progress}
                        isProcessing={item.is_processing}
                    />
                </>
            ),
            colComputeResource: item.compute_resource && (
                <>
                    <div>
                        <Action
                            component={Link}
                            to={pathTo(`compute_resources/${item.compute_resource.id}`)}
                        >
                            {item.compute_resource.name}
                        </Action>
                    </div>
                    <CopyText>{item.compute_resource.host}</CopyText>
                </>
            ),
            colIp: getViewableIps(item).map(ip => <CopyText key={ip.id}>{ip.ip}</CopyText>),
            colCpu: <Usage
                progress={item.usage.cpu}
                title={item.usage.cpu + '%'}
                startColor={COLORS.GREEN}
                endColor={COLORS.RED}
            />,
            colOwner: item?.user.email,
            colCreated: formatTableDate(item.created_at),
            colActions: <ComputeResourceVmActions item={item} iconSize="16" onUpdate={handleUpdate}
                onDelete={handleDelete}/>,
            key: item.id.toString(),
        };
    });

    const renderRow = (row: IListRow) => {
        const computeResourceVm = computeResourceVms.data.find(item => item.id.toString() === row.key);
        if (computeResourceVm === undefined) {
            return;
        }

        const isItemLoading = !!computeResourceVm.isLoading && !isServerEditDialogOpened;

        return (<ComputeResourceVmRow isLoading={isItemLoading} item={computeResourceVm}/>);
    };

    return (
        <>
            <List
                isLoading={isLoading}
                isFirstLoading={isFirstLoading}
                loadItems={loadPaginated}
                emptyView={emptyView}
                columns={columns.filter(
                    (column) => !excludedColumns || !excludedColumns.includes(column.key)
                )}
                data={listData}
                selection={selection}
                onSelectionChange={setSelection}
                renderRowBody={renderRow}
                onExpandedRowsChange={setExpandedRows}
                expandedRows={expandedRows}
                filtered={!!filteredCount || isLoading}
                meta={computeResourceVms.meta}
                toolbar={(
                    <ComputeResourceVmOperations
                        selectedVmStatus={selectedVmStatus}
                        onSelectedVmStatusFilterChange={handleVmStatusFilterChange}
                        selectedCr={selectedCr}
                        onSelectedCrFilterChange={handleCrFilterChange}
                        selectedUser={selectedUser}
                        onSelectedUserFilterChange={handleUserFilterChange}
                        selectedVirtualizationType={selectedVirtualizationType}
                        onSelectedVirtualizationTypeFilterChange={handleVirtualizationTypeFilterChange}
                        selectedTags={tags}
                        onSelectedTagOptionsFilterChange={handleTagOptionsFilterChange}
                        filteredCount={filteredCount}
                        selection={selection.map(id => parseInt(id, 10))}
                        setSelection={setSelection}
                        withFilters={withFilters}
                        openMigrationDialog={openMigrationDialog}
                        handleBatchDelete={handleBatchDelete}
                        openSyncImagePresetsDialog={openSyncImagePresetsDialog}
                    />
                )}
            />
            <Dialog
                data-cy={SERVER.MIGRATION_DIALOG}
                heading={
                    <MigrationFromTitle>
                        <Translate content="computeResource.servers.migrationForm.title"/>
                        <MigrationFormTitleLink
                            href="https://docs.solusvm.com/v2/administrator-guide/Migration%2Bof%2BServers%2BBetween%2BCompute%2BResources.html"
                            target="_blank">
                            <Icon name="info-circle" size={12}/>
                            &nbsp;<Translate content="computeResource.servers.migrationForm.documentationLinkTitle"/>
                        </MigrationFormTitleLink>
                    </MigrationFromTitle>
                }
                closeHandler={handleMigrationDialogClose}
                isOpen={isMigrationDialogOpened}
                size="xs"
            >
                <Loader isLoading={isMigrationPreChecking} center={false}>
                    <MigrationForm
                        servers={computeResourceVms.data.filter(item => selection.find(id => item.id.toString() === id))}
                        onSubmit={handleMigrationSubmit}
                    />
                </Loader>
            </Dialog>
            <Dialog
                heading={<Translate content="computeResource.servers.form.titleEdit"/>}
                closeHandler={handleServerEditDialogDialog}
                isOpen={isServerEditDialogOpened}
                size="xs"
            >
                <ServerEditDialog computeResourceVmId={selectedVm} onClose={handleServerEditDialogDialog}/>
            </Dialog>
            <Dialog
                closeHandler={handleSyncImagePresetDialogClose}
                isOpen={isSyncImagePresetsDialogOpened}
                size="xs"
                heading={<Translate content="computeResource.servers.syncImagePresetsForm.title"/>}
            >
                <SyncImagePresetsForm
                    servers={computeResourceVms.data.filter(item => selection.find(id => item.id.toString() === id))}
                    onSubmit={handleSyncImagePresetDialogClose}
                />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    computeResourceVms: state.computeResourceVm.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_LIST),
    isMigrationPreChecking: state.app.loadingFlags.has(LOADING_FLAGS.SERVERS_MIGRATION_PRE_CHECK),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    serversMigrationActions: bindActionCreators(serversMigrationActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComputeResourceVmTable));
