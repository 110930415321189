// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const CreateButton = styled.a`
    width: 100px;
    padding: 10px 68px;
    background: #28AADE;
    box-shadow: 0 1px 1px rgba(40,170,222,0.5);
    border-radius: 2px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 5px;

   &:hover {
        text-decoration: none;
        color: #fff;
   }
`;

export const HostnameCell = styled.div`
    display: flex;
    align-items: center;

    .pul-action {
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            color: #222;
            font-weight: 600;
        }
    }

    img:first-child {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const HostnameActionContainer = styled.div`
    width: 0;
    min-width: 200px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
`;
