// Copyright 2025. WebPros International GmbH. All rights reserved.
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm/model';

export const canChangeHostname = (server: IVmResponse): boolean => {
    if (server.virtualization_type === VirtualizationType.KVM) {
        return server.settings.guest_tools_installed === true;
    }

    return true;
};

export const canChangePassword = (server: IVmResponse): boolean => {
    if (server.virtualization_type === VirtualizationType.KVM) {
        return server.settings.guest_tools_installed === true;
    }

    return true;
};

export const canModifyNetwork = (server: IVmResponse): boolean => {
    if (server.virtualization_type === VirtualizationType.KVM) {
        return server.settings.guest_tools_installed === true;
    }

    return true;
};

export const canAddOrDeleteAdditionalDisk = (server: IVmResponse): boolean => {
    if (server.virtualization_type === VirtualizationType.KVM) {
        return server.settings.guest_tools_installed === true;
    }

    return false;
};
