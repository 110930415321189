// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as authActions from 'common/modules/auth/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { initialUserState } from 'admin/user/reducer';
import { IUserCredentials } from 'common/api/resources/Auth';
import { IUserResponse } from 'common/api/resources/User';

interface IAuth {
    authData: IUserCredentials;
    user: IUserResponse;
    isAuth: boolean;
}

export type AuthAction = ActionType<typeof authActions>;
export type AuthState = Readonly<IAuth>;

export const initialAuthState = {
    authData: {
        token_type: 'Bearer',
        access_token: '',
    },
    isAuth: false,
    user: initialUserState,
};

export default combineReducers<AuthState, AuthAction>({
    authData: (state = initialAuthState.authData, action) => {
        switch (action.type) {
        case getType(authActions.setUserCredentials):
            return action.payload;
        default:
            return state;
        }
    },
    isAuth: (state = initialAuthState.isAuth, action) => {
        switch (action.type) {
        case getType(authActions.setIsAuth):
            return action.payload;
        default:
            return state;
        }
    },
    user: (state = initialAuthState.user, action) => {
        // All reducers which may change language should be added to `languageUpdateMiddleware`
        // middleware in `frontend/src/common/redux/middleware/languageUpdateMiddleware.ts`.
        switch (action.type) {
        case getType(authActions.updateUser):
            if (action.payload.id === state.id) {
                return {
                    ...state,
                    ...action.payload,
                };
            }

            return state;
        case getType(authActions.setUser):
            return action.payload;
        case getType(authActions.setUserTwoFactorAuth):
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
        }
    },
});
