// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const ItemInfoDescription = styled.span`
    color: #979797;
    font-size: 12px;
    line-height: 16px;
    min-height: 17px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ItemInfoDetails = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    div {
      line-height: 20px;
    }
`;

export const DetailsIp = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 16px;
    color: #222;
    fill: #737373;
    
    .pul-icon {
        width: 12px;
    }
    
    span:first-child {
        color: #979797;
        margin-right: 2px;
    }
`;

export const IP = styled.div`
    margin-right: 5px;
`;
