// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import Item from 'admin/menu/components/Navigation/Item';
import { IMenuItem } from 'common/modules/app/menu/reducer';
import { Translate } from '@plesk/ui-library';

export interface INavItemProps {
    item: IMenuItem;
    isNested?: boolean;
}

export const NavItem: React.FC<INavItemProps> = ({
    item,
    isNested,
}) => (
    <Item
        icon={item.icon}
        isActive={item.isActive}
        isNested={isNested}
        isAlerted={item.isAlerted}
        isHighlighted={item.isHighlighted}
        to={item.path}
    >
        <Translate content={item.title} />
    </Item>
);

export default NavItem;
