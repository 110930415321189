// Copyright 2025. WebPros International GmbH. All rights reserved.

import axios from 'axios';
import {
    isProduction,
    isAdminSite,
} from 'common/helpers/core';

// Helper function to ensure the URL parts are properly joined
export const joinUrlParts = (base?: string, path?: string) => {
    // Remove any trailing slashes from base and leading slashes from path
    const trimmedBase = base?.replace(/\/+$/, '');
    const trimmedPath = path?.replace(/^\/+/, '');
    return `${trimmedBase}/${trimmedPath}`;
};

export const getUrl = (prefix: string) => isProduction() ? prefix : joinUrlParts(process.env.REACT_APP_API_URL!, prefix);

export const api = axios.create({
    headers: {
        Application: 'UI',
        Source: isAdminSite() ? 'admin-area' : 'client-area',
    },
    baseURL: getUrl('/api/v1/'),
});
