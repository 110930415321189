// Copyright 2025. WebPros International GmbH. All rights reserved.
import React from 'react';
import { connect } from 'react-redux';
import {
    Form,
    Section,
    setIn,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as imagePresetActions from 'admin/imagePreset/actions';
import { IImagePresetResponse } from 'common/api/resources/ImagePreset';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';

interface IImagePresetFormProps {
    servers: IVmResponse[];
    onSubmit?: () => void;
}

export type ImagePresetFormProps =
    IImagePresetFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IImagePresetFromState {
    image_preset_ids: number[];
}

const SyncImagePresetsForm: React.FC<ImagePresetFormProps> = ({
    servers,
    formErrors,
    isLoadingImagePresets,
    isSaving,
    imagePresetActions: {
        getImagePresets,
    },
    computeResourceVmActions: {
        synComputeResourceVmsWithImagePresets,
    },
    formErrorsActions: {
        clearFormErrors,
        setFormErrors,
    },
    onSubmit,
}) => {

    React.useEffect(() => () => { clearFormErrors(); }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [submitValues, setSubmitValues] = React.useState<IImagePresetFromState>({
        image_preset_ids: [],
    });

    const translate = useTranslate();

    const handleSubmit = async (values: IImagePresetFromState) => {
        if (!values.image_preset_ids.length) {
            setFormErrors({
                image_preset_ids: {
                    message: translate('computeResource.servers.syncImagePresetsForm.requiredErrorMessage'),
                },
            });
            return;
        }

        await synComputeResourceVmsWithImagePresets(servers.map(item => item.id), values.image_preset_ids);

        onSubmit && onSubmit();
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    return (
        <Form
            id="syncImagePresetsForm"
            footerClassName="hidden"
            onSubmit={handleSubmit}
            onFieldChange={handleFieldChange}
            values={submitValues}
            errors={formErrors}
            vertical={true}
            style={{ maxWidth: '420px' }}
        >
            <Section>
                <SelectWithDataLoader
                    name="image_preset_ids"
                    label="computeResource.servers.syncImagePresetsForm.imagePresets"
                    buttonLabel="computeResource.servers.syncImagePresetsForm.addAllImagePresets"
                    loadItems={(params) => getImagePresets({
                        ...params,
                        filters: {
                            ...params?.filters,
                        },
                    })}
                    mapper={(imagePreset: IImagePresetResponse) => ({
                        label: imagePreset.name,
                        value: imagePreset.id,
                    })}
                    onChange={(image_preset_ids: number[]) => setSubmitValues(values => ({
                        ...values,
                        image_preset_ids,
                    }))}
                />
            </Section>

            <Button
                type="submit"
                form="syncImagePresetsForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                disabled={isLoadingImagePresets}
                isLoading={isSaving}
            >
                <Translate content="computeResource.servers.syncImagePresetsForm.submitButton" />
            </Button>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isLoadingImagePresets: state.app.loadingFlags.has(LOADING_FLAGS.IMAGE_PRESET_LIST),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_SYNC_IMAGE_PRESETS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    imagePresetActions: bindActionCreators(imagePresetActions, dispatch),
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SyncImagePresetsForm);
