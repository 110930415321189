// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import { GridCol } from 'common/components/ServerTabs/NetworkingTab/GridCol';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import { Grid } from '@plesk/ui-library';
import NetworkUsage from 'common/components/ServerTabs/NetworkingTab/NetworkUsage';
import ServerIpAddresses from 'common/components/ServerTabs/NetworkingTab/ServerIpAddresses';
import VpcInterfaces from 'common/components/ServerTabs/NetworkingTab/VpcInterfaces';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IComputeResourceVmVpcNetworkInterfaceResponse } from 'common/api/resources/VpcNetwork';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Loader } from 'common/components/Loader/Loader';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import { VerticalIndent } from 'common/components/styles/VerticalIndent';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export interface INetworkingTabProps {
    server: IVmResponse;
    vpcNetworkInterfaces: IPaginateApiResponse<IComputeResourceVmVpcNetworkInterfaceResponse[]>;
}

export type NetworkingTabProps =
    INetworkingTabProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

export const NetworkingTab: React.FC<NetworkingTabProps> = ({
    isLoadingVpcInterfaces,
    server,
    vpcNetworkInterfaces,
    getVpcNetworkInterfaces,
    canGetVpcNetworks,
}) => {
    React.useEffect(() => {
        if (server.id > 0) {
            getVpcNetworkInterfaces(server.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.id]);

    const isFirstLoading = useIsFirstLoading(isLoadingVpcInterfaces);

    // We should show imported network interfaces even without permission.
    const shouldShowVpcNetworks = (canGetVpcNetworks || vpcNetworkInterfaces.data.length > 0)
        && server.virtualization_type === VirtualizationType.KVM;

    return (
        <Loader isLoading={isFirstLoading}>
            <Grid gap="md">
                <GridCol>
                    <ServerIpAddresses
                        server={server}
                    />
                </GridCol>
                <GridCol>
                    <NetworkUsage
                        server={server}
                    />
                </GridCol>
                {shouldShowVpcNetworks && (
                    <GridCol>
                        <VerticalIndent />
                        <VpcInterfaces
                            server={server}
                            vpcNetworkInterfaces={vpcNetworkInterfaces}
                        />
                    </GridCol>
                )}
            </Grid>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    isLoadingVpcInterfaces : state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_VPC_NETWORK_INTERFACE_LIST),
    canGetVpcNetworks: hasPermission(
        state,
        PERMISSION_LIST.GET_VPC_NETWORKS,
        PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS,
        PERMISSION_LIST.MANAGE_OWNED_VPC_NETWORKS
    ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getVpcNetworkInterfaces: bindActionCreators(computeResourceVmActions.getVpcNetworkInterfaces, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkingTab);
