// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    Action,
    Button,
    IntentType,
    Text,
    Translate,
    Status,
} from '@plesk/ui-library';
import * as React from 'react';
import { INTENT_TYPE } from 'common/constants';
import { ButtonContainer } from 'admin/computeResource/common/Status/Styles';
import { Dialog } from 'common/components/Dialog/Dialog';

export interface IActionStatusProps {
    statusText: React.ReactNode;
    isMutedStatusText?: boolean;
    isInProgress: boolean;
    progress?: number;
    intent?: IntentType;
    shouldShowDetails: boolean;
    detailsDialog?: {
        title: React.ReactNode;
        text: React.ReactNode;
    };
    'data-cy'?: string;
}

export const ActionStatus: React.FC<IActionStatusProps> = ({
    statusText,
    isMutedStatusText,
    isInProgress,
    progress,
    intent,
    shouldShowDetails,
    detailsDialog,
    'data-cy': dataCy,
}) => {
    const [isOpenDetailsDialog, setIsOpenDetailsDialog] = React.useState(false);

    if (isInProgress) {
        return (
            <Action progress={true}>
                {statusText} {progress ? progress : 0}%
            </Action>
        );
    }

    return (
        <Status intent={intent} compact={true} data-cy={dataCy}>
            <Text intent={isMutedStatusText ? INTENT_TYPE.MUTED : undefined}>{statusText}</Text>
            {shouldShowDetails && detailsDialog && (
                <>
                    <ButtonContainer>
                        <Button
                            icon="box-diagonal-top-in"
                            ghost={true}
                            tooltip={<Translate content="actionStatus.viewDetails" />}
                            onClick={() => setIsOpenDetailsDialog(true)}
                        />
                    </ButtonContainer>
                    <Dialog
                        heading={detailsDialog.title}
                        closeHandler={() => setIsOpenDetailsDialog(false)}
                        isOpen={isOpenDetailsDialog}
                        size="xs"
                    >
                        {detailsDialog.text}
                    </Dialog>
                </>
            )}
        </Status>
    );
};

export default ActionStatus;
