// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import * as notificationActions from 'admin/notification/actions';
import { NOTIFICATION_TYPE } from 'common/api/resources/Notification';
import ComputeResourceStatusChangeItem from 'admin/notification/containers/Items/ComputeResourceStatusChangeItem';
import { selectHasMoreUnreads } from 'admin/notification/selectors';
import { Loader } from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import NewVersionAvailableItem from 'admin/notification/containers/Items/NewVersionAvailableItem';
import VersionUpdatedItem from 'admin/notification/containers/Items/VersionUpdatedItem';
import { COLORS } from 'admin/core/theme';
import BackupItem from 'admin/notification/containers/Items/BackupItem';
import TrafficExceeded from 'admin/notification/containers/Items/TrafficExceeded';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import {
    NotificationListContainer,
    ShowMoreContainer,
    EmptyList,
} from 'admin/notification/containers/NotificationList/Styles';
import {
    Icon,
    Link,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { ManagementNodeBackupItem } from 'admin/notification/containers/Items/ManagementNodeBackupItem';
import ManagementNodeBackupRecoveryItem from 'admin/notification/containers/Items/ManagementNodeBackupRecoveryItem';

export type NotificationListProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const NotificationList: React.FC<NotificationListProps> = ({
    items,
    hasMoreUnreads,
    isLoadingList,
    notificationActions: {
        loadMoreNotifications,
    },
}) => {
    const elements = React.useMemo(() => items.map((item) => {
        if (item.type === NOTIFICATION_TYPE.COMPUTE_RESOURCE_STATUS_CHANGE) {
            return (
                <ComputeResourceStatusChangeItem key={item.id} item={item} />
            );
        }

        if (item.type === NOTIFICATION_TYPE.NEW_VERSION_AVAILABLE) {
            return (
                <NewVersionAvailableItem key={item.id} item={item} />
            );
        }

        if (item.type === NOTIFICATION_TYPE.VERSION_UPDATED) {
            return (
                <VersionUpdatedItem key={item.id} item={item} />
            );
        }

        if (item.type === NOTIFICATION_TYPE.BACKUP_CREATED) {
            return (
                <BackupItem
                    key={item.id}
                    item={item}
                    translations={{
                        title: 'notification.item.backupCreated.title',
                        description: 'notification.item.backupCreated.description',
                    }}
                />
            );
        }

        if (item.type === NOTIFICATION_TYPE.BACKUP_RESTORED) {
            return (
                <BackupItem
                    key={item.id}
                    item={item}
                    translations={{
                        title: 'notification.item.backupRestored.title',
                        description: 'notification.item.backupRestored.description',
                    }}
                />
            );
        }

        if (item.type === NOTIFICATION_TYPE.INCOMING_TRAFFIC_EXCEEDED) {
            return (
                <TrafficExceeded
                    key={item.id}
                    item={item}
                    header="notification.item.incomingTrafficExceeded.title"
                    description="notification.item.incomingTrafficExceeded.description"
                />
            );
        }

        if (item.type === NOTIFICATION_TYPE.OUTGOING_TRAFFIC_EXCEEDED) {
            return (
                <TrafficExceeded
                    key={item.id}
                    item={item}
                    header="notification.item.outgoingTrafficExceeded.title"
                    description="notification.item.outgoingTrafficExceeded.description"
                />
            );
        }

        if (item.type === NOTIFICATION_TYPE.MANAGEMENT_NODE_BACKUP_CREATED) {
            return (
                <ManagementNodeBackupItem
                    key={item.id}
                    item={item}
                    header="notification.item.managementNodeBackupCreated.title"
                    description="notification.item.managementNodeBackupCreated.description"
                />
            );
        }

        if (item.type === NOTIFICATION_TYPE.MANAGEMENT_NODE_BACKUP_RESTORED) {
            return (
                <ManagementNodeBackupItem
                    key={item.id}
                    item={item}
                    header="notification.item.managementNodeBackupRestored.title"
                    description="notification.item.managementNodeBackupRestored.description"
                />
            );
        }

        if (item.type === NOTIFICATION_TYPE.MANAGEMENT_NODE_BACKUP_RECOVERED) {
            return (
                <ManagementNodeBackupRecoveryItem
                    key={item.id}
                    item={item}
                    header="notification.item.managementNodeBackupRecovered.title"
                    description="notification.item.managementNodeBackupRecovered.description"
                />
            );
        }

        return null;
    }), [items]);

    const handleShowMore = () => loadMoreNotifications();
    const isListEmpty = !hasMoreUnreads && !elements.length;

    return (
        <NotificationListContainer>
            {isListEmpty && (
                <EmptyList>
                    <Icon
                        name={ICONS.BELL}
                        style={{ color: COLORS.PRIMARY }}
                    />
                    <Translate content="notification.emptyList" />
                </EmptyList>
            )}
            {elements}
            {hasMoreUnreads && (
                <ShowMoreContainer>
                    <Loader isLoading={isLoadingList}>
                        <Link pseudo={true} onClick={handleShowMore}>
                            <Translate content="notification.showMore" />
                        </Link>
                    </Loader>
                </ShowMoreContainer>
            )}
        </NotificationListContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    items: state.notification.list.data,
    hasMoreUnreads: selectHasMoreUnreads(state),
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.NOTIFICATION_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    notificationActions: bindActionCreators(notificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
