// Copyright 2025. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'common/modules/offer/constants/types';
import {
    IOfferListRequest,
    IOfferPatchRequest,
    IOfferRequest,
    IOfferResponse,
} from 'common/api/resources/Offer/model';
import { offer } from 'common/api/resources/Offer/api';
import { ICommonState } from 'common/store';

export const setOfferList = createCustomAction(
    types.SET_OFFER_LIST,
    (data: IPaginateApiResponse<IOfferResponse[]>) => ({ payload: data })
);
export const setOfferItem = createCustomAction(
    types.SET_OFFER_ITEM,
    (data: IOfferResponse) => ({ payload: data })
);
export const unsetOfferItem = createCustomAction(types.UNSET_OFFER_ITEM);
export const appendOffers = createCustomAction(
    types.APPEND_OFFERS,
    (data: IPaginateApiResponse<IOfferResponse[]>) => ({ payload: data })
);
export const addOfferItem = createCustomAction(
    types.ADD_OFFER_ITEM,
    (data: IOfferResponse) => ({ payload: data })
);
export const updateOfferItem = createCustomAction(
    types.UPDATE_OFFER_ITEM,
    (data: IOfferResponse) => ({ payload: data })
);
export const removeOfferItem = createCustomAction(
    types.REMOVE_OFFER_ITEM,
    (id: number) => ({ payload: id })
);
export const setOfferItemIsDeleting = createCustomAction(
    types.SET_OFFER_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getOffers = (request?: IOfferListRequest) => async (dispatch: Dispatch) => {
    const apiCall = () => offer.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.OFFER_LIST,
        action: setOfferList,
        apiCall,
    });
};

export const getOffersNextPage = () => async (dispatch: Dispatch, getState: () => ICommonState) => {
    const state = getState();
    const nextPage = state.offer.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.OFFER_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendOffers,
        loadingFlag: LOADING_FLAGS.OFFER_LIST,
    });
};

export const createOffer = (data: IOfferRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.OFFER_ITEM_SAVE,
    action: addOfferItem,
    apiCall: offer.create,
    translations: {
        success: 'offer.toasts.saved',
    },
});

export const getOffer = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: offer.item,
    action: setOfferItem,
    loadingFlag: LOADING_FLAGS.OFFER_ITEM,
});

export const updateOffer = (id: number, data: IOfferRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: offer.update,
    action: updateOfferItem,
    loadingFlag: LOADING_FLAGS.OFFER_ITEM_SAVE,
    translations: {
        success: 'offer.toasts.saved',
    },
});

export const patchOffer = (id: number, data: IOfferPatchRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: offer.patch,
    action: updateOfferItem,
    loadingFlag: LOADING_FLAGS.OFFER_ITEM_SAVE,
    translations: {
        success: 'offer.toasts.saved',
    },
});

export const removeOffer = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: offer.remove,
    setLoadingAction: setOfferItemIsDeleting,
    action: removeOfferItem,
    loadingFlag: LOADING_FLAGS.OFFER_ITEM_REMOVE,
    translations: {
        success: 'offer.toasts.deleted',
    },
});
