// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Icon,
    Translate,
} from '@plesk/ui-library';
import {
    DiskImageType,
    IVmSettings,
} from 'common/api/resources/ComputeResourceVm';
import { ApplicationLoginLinkType } from 'common/api/resources/Application';
import sandbox from 'client/project/components/ApplicationLoginLink/sandbox';
import { APPLICATION_LOGIN_LINK } from 'client/project/constants/tests';
import {
    IFrame,
    Popover,
} from 'client/project/components/ApplicationLoginLink/Styles';

export interface IApplicationLoginLinkProps {
    settings: IVmSettings;
}

const codeExecutionTimeout = 30;

const ApplicationLoginLink: React.FC<IApplicationLoginLinkProps> = ({
    settings,
}) => {
    const [loading, setLoading] = React.useState(false);
    const [infoShown, setInfoShown] = React.useState(false);

    if (settings.os_image.type !== DiskImageType.APPLICATION) {
        return null;
    }

    if (settings.application_login_link && settings.application_login_link.type === ApplicationLoginLinkType.NONE) {
        return null;
    }

    const handleLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        const loginLink = settings.application_login_link!;

        switch (loginLink.type) {
        case ApplicationLoginLinkType.URL:
            window.open(loginLink.content, '_blank');
            break;

        case ApplicationLoginLinkType.JS_CODE:
            setLoading(true);
            sandbox.run(loginLink.content, codeExecutionTimeout, () => {
                setLoading(false);
            });
            break;

        case ApplicationLoginLinkType.INFO:
            setInfoShown(true);
            break;
        }
    };

    let btn = (
        <Button
            ghost={true}
            icon={<Icon name="arrow-right-in" size="16"/>}
            state={loading ? 'loading' : undefined}
            onClick={handleLogin}
            tooltip={<Translate content="projects.server.links.login" />}
            data-cy={APPLICATION_LOGIN_LINK.TEST_BUTTON}
        />
    );

    if (settings.application_login_link!.type === ApplicationLoginLinkType.INFO) {
        const handleClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();
        const handleClose = () => setInfoShown(false);

        btn = (
            <Popover
                onClick={handleClick}
                onClose={handleClose}
                visible={infoShown}
                target={btn}
                placement="bottom"
                width="sm"
                height={250}
            >
                <IFrame
                    data-cy={APPLICATION_LOGIN_LINK.IFRAME}
                    src={`data:text/html;charset=utf-8,${escape(settings.application_login_link!.content)}`}
                    sandbox="allow-popups"
                />
            </Popover>
        );
    }

    return btn;
};

export default ApplicationLoginLink;
