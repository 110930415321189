// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { StyledCardList } from 'client/common/components/Card/Styles';
import { Grid as UIGrid } from '@plesk/ui-library';
import { ReactNode } from 'react';
export interface ICardListProps<T> {
    items: T[];
    component: (item: T, key: number) => React.ReactElement;
}

const Grid: React.FC<{ children: ReactNode }> = ({ children }) => (
    <UIGrid minColumnWidth={380} gap="lg" style={{ paddingTop: 5, paddingLeft: 3 }}>
        {children}
    </UIGrid>
);

export function CardList<T>(props: ICardListProps<T>) {
    const {
        items,
        component,
    } = props;

    return (
        <StyledCardList>
            <Grid>
                {items.map(component)}
            </Grid>
        </StyledCardList>
    );
}
