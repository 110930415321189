// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as licenseActions from 'admin/license/solusIO/actions';
import { ILicenseActivateRequest } from 'common/api/resources/License/solusIO';
import NetworkFormError from 'common/containers/NetworkFormError/NetworkFormError';

export enum LicenseFormFields {
    ACTIVATION_CODE = 'activation_code',
    SUBMIT = 'submit',
}

interface ILicenseFormProps {
    onSubmit: (values?: ILicenseActivateRequest) => void;
    fields?: LicenseFormFields[];
}

export type LicenseFormProps =
    ILicenseFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LicenseForm = React.forwardRef<FormInstanceHandles, LicenseFormProps>(({
    isItemSaving,
    errors,
    formErrorsActions: { setFormErrors, clearFormErrors },
    licenseActions: { activateLicense },
    onSubmit,
    fields = [
        LicenseFormFields.ACTIVATION_CODE,
        LicenseFormFields.SUBMIT,
    ],
}, ref) => {
    React.useEffect(() => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: ILicenseActivateRequest) => {
        const validationErrors = validate<ILicenseActivateRequest>(values, {
            activation_code: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            await activateLicense(values);
            onSubmit(values);
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <Form
                ref={ref}
                id="licenseForm"
                onSubmit={handleSubmit}
                errors={errors}
                footerClassName="hidden"
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                {fields.includes(LicenseFormFields.ACTIVATION_CODE) && (<>
                    <FormFieldText
                        name="activation_code"
                        size="fill"
                        label={<Translate content="license.form.activationCode" />}
                        required={true}
                    />
                    <NetworkFormError />
                </>)}
            </Form>
            {fields.includes(LicenseFormFields.SUBMIT) && (
                <Button
                    type="submit"
                    form="licenseForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                    isLoading={isItemSaving}
                >
                    <Translate content="license.form.activateBtn" />
                </Button>
            )}
        </>
    );
});

const mapStateToProps = (state: RootState) => ({
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.LICENSE_ACTIVATION),
    errors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    licenseActions: bindActionCreators(licenseActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
    forwardRef: true,
})(LicenseForm);
