// Copyright 2025. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    auth,
    IEnableTwoFactorRequest,
    ITwoFactorAuthSecretResponse,
    ITwoFactorLoginRequest,
} from 'common/api/resources/Auth';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { createCustomAction } from 'typesafe-actions';

import {
    SET_TWO_FACTOR_AUTH_RECOVERY_CODES,
    SET_TWO_FACTOR_AUTH_SECRET,
    UNSET_TWO_FACTOR_AUTH_RECOVERY_CODES,
    UNSET_TWO_FACTOR_AUTH_SECRET,
    SET_SHOULD_GENERATE_TWO_FACTOR_AUTH_RECOVERY_CODES,
} from 'common/modules/auth/twoFactorAuth/constants';
import { HTTP_CODES } from 'common/api/constants';
import {
    setIsAuth,
    setUser,
    setUserCredentials,
    setUserTwoFactorAuth,
} from 'common/modules/auth/actions';
import { CODE_LENGTH } from 'common/modules/account/constants/constants';

export const setTwoFactorAuthSecret = createCustomAction(
    SET_TWO_FACTOR_AUTH_SECRET,
    (data: ITwoFactorAuthSecretResponse) => ({ payload: data })
);
export const unsetTwoFactorAuthSecret = createCustomAction(UNSET_TWO_FACTOR_AUTH_SECRET);
export const setTwoFactorAuthRecoveryCodes = createCustomAction(
    SET_TWO_FACTOR_AUTH_RECOVERY_CODES,
    (recoveryCodes: string[]) => ({ payload: recoveryCodes })
);
export const unsetTwoFactorAuthRecoveryCodes = createCustomAction(UNSET_TWO_FACTOR_AUTH_RECOVERY_CODES);
export const setShouldGenerateTwoFactorAuthRecoveryCodes = createCustomAction(
    SET_SHOULD_GENERATE_TWO_FACTOR_AUTH_RECOVERY_CODES,
    (shouldGenerate: boolean) => ({ payload: shouldGenerate })
);
export const getTwoFactorAuthSecret = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_SECRET));
    try {
        const result = await auth.twoFactorAuthSecret();
        dispatch(setTwoFactorAuthSecret(result.data.data));
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_SECRET));
    }
};

export const enableTwoFactorAuth = (data: IEnableTwoFactorRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_SWITCH));
    try {
        const result = await auth.enableTwoFactorAuth(data);
        dispatch(setUserTwoFactorAuth({
            is_two_factor_auth_enabled: true,
        }));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'commonAccount.toasts.twoFactorAuthEnabled')(dispatch);
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_SWITCH));
    }
};

export const disableTwoFactorAuth = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_SWITCH));
    try {
        const result = await auth.disableTwoFactorAuth();
        dispatch(setUserTwoFactorAuth({
            is_two_factor_auth_enabled: false,
            two_factor_auth_recovery_code_count: 0,
        }));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'commonAccount.toasts.twoFactorAuthDisabled')(dispatch);
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_SWITCH));
    }
};

export const generateNewTwoFactorAuthRecoveryCodes = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_GENERATE_RECOVERY_CODES));
    try {
        const result = await auth.generateNewTwoFactorAuthRecoveryCodes();
        dispatch(setTwoFactorAuthRecoveryCodes(result.data.data.recovery_codes));
        dispatch(setUserTwoFactorAuth({
            two_factor_auth_recovery_code_count: result.data.data.recovery_codes.length,
        }));
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_GENERATE_RECOVERY_CODES));
    }
};

export const twoFactorLogin = (data: ITwoFactorLoginRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_LOGIN));

    try {
        const result = await auth.twoFactorLogin(data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setUserCredentials(result.data.data.credentials));
            dispatch(setUser(result.data.data.user));
            dispatch(setIsAuth(true));
            if (data.code.length !== CODE_LENGTH) {
                bakeForegroundToast(INTENT_TYPE.INFO, 'commonAccount.toasts.twoFactorAuthRecoveryCodeCountLeft', {
                    count: result.data.data.user.two_factor_auth_recovery_code_count,
                })(dispatch);
            }
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.TWO_FACTOR_AUTH_LOGIN));
    }
};
