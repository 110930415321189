// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    CloseLink,
    StyledDialog,
} from 'common/components/Dialog/Styles';
import {
    Button,
    Heading,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';

interface IDialogProps {
    closeHandler: () => void;
    isOpen: boolean;
    size: string;
    heading: React.ReactNode | string;
}

export const Dialog: React.FC<React.PropsWithChildren<IDialogProps>> = ({
    children,
    closeHandler,
    heading,
    ...rest
}) => (
    <StyledDialog onClose={closeHandler} {...rest}>
        <CloseLink>
            <Button onClick={closeHandler} ghost={true} icon={ICONS.CROSS_MARK} />
        </CloseLink>
        <Heading level={3}>
            {heading}
        </Heading>
        {children}
    </StyledDialog>
);
