// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const LocationPlaceholderContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const LocationPlaceholderText = styled.span`
    color: #737373;
    margin-left: 8px;
`;
