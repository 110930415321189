// Copyright 2025. WebPros International GmbH. All rights reserved.

import { combineReducers } from 'redux';
import formErrorsReducer,
{ FormErrorsState } from 'common/modules/app/formErrors/reducer';
import loadingFlagsReducer,
{ LoadingFlagsState } from 'common/modules/app/loadingFlags/reducer';
import toasterReducer,
{ ToasterState } from 'common/modules/app/toaster/reducer';
import responseErrorReducer,
{ ResponseErrorState } from 'common/modules/app/responseError/reducer';
import notificationAlertReducer,
{ NotificationAlertState } from 'common/modules/app/notificationAlert/reducer';
import viewTypeReducer,
{ ViewTypeState } from 'common/modules/app/viewType/reducer';
import menuReducer,
{ MenuState } from 'common/modules/app/menu/reducer';
import metaReducer,
{ MetaState } from 'common/modules/app/meta/reducer';
import localeReducer,
{ LanguageState } from 'common/modules/app/language/reducer';
import featuresReducer,
{ FeaturesState } from 'common/modules/app/features/reducer';
import lastUsedSshKeysReducer,
{ LastUsedSshKeysState } from 'common/modules/app/lastUsedSshKeys/reducer';

interface IApp {
    formErrors: FormErrorsState;
    loadingFlags: LoadingFlagsState;
    toaster: ToasterState;
    responseError: ResponseErrorState;
    viewType: ViewTypeState;
    notificationAlert: NotificationAlertState;
    menu: MenuState;
    meta: MetaState;
    language: LanguageState;
    features: FeaturesState;
    lastUsedSshKeys: LastUsedSshKeysState;
}

export type AppState = Readonly<IApp>;

export default combineReducers({
    formErrors: formErrorsReducer,
    loadingFlags: loadingFlagsReducer,
    toaster: toasterReducer,
    responseError: responseErrorReducer,
    viewType: viewTypeReducer,
    lastUsedSshKeys: lastUsedSshKeysReducer,
    notificationAlert: notificationAlertReducer,
    menu: menuReducer,
    meta: metaReducer,
    language: localeReducer,
    features: featuresReducer,
});
