// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    IIcon,
    Text,
    Alert,
    MenuDivider,
    Progress,
    ProgressStep,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    PROGRESS_STATUS,
} from 'common/constants';
import { ProgressWrapper } from 'common/components/ProgressStates/Style';

/**
 * `ProgressStates` is used for showing progress of performing long actions.
 */

const initialStatus = {
    isFailed: false,
    isFinished: false,
    isFinishedWithWarnings: false,
};

export interface IStep {
    key: string;
    title: string;
    icon?: IIcon | string;
    status: PROGRESS_STATUS;
    progress: number;
    statusText: string;
}

export interface IProgressStatesProps {
    isOpen: boolean;
    isFailed?: boolean;
    isFinished?: boolean;
    steps: IStep[];
    errors: string[];
    warnings: string[];
    onFinish?: () => void;
    onFail?: () => void;
}

export interface IProgressStatesState {
    isFailed: boolean;
    isFinished: boolean;
    isFinishedWithWarnings: boolean;
    steps: IStep[];
}

export class ProgressStates extends React.Component<IProgressStatesProps, IProgressStatesState> {
    static defaultProps = {
        isOpen: false,
        errors: [],
        warnings: [],
        steps: [],
    };

    constructor(props: IProgressStatesProps) {
        super(props);

        this.state = {
            steps: props.steps,
            ...initialStatus,
        };
    }

    componentDidUpdate(prevProps: IProgressStatesProps) {
        if (prevProps.steps !== this.props.steps) {
            this.updateStatus();
        }
    }

    updateStatus = () => {
        const {
            steps,
            onFinish,
            onFail,
        } = this.props;
        let {
            isFailed,
            isFinished,
            isFinishedWithWarnings,
        } = initialStatus;

        if (steps) {
            isFinished = !steps.some(step => step.status === PROGRESS_STATUS.RUNNING
                || step.status === PROGRESS_STATUS.NOT_STARTED);
            isFailed = steps.some(step => step.status === PROGRESS_STATUS.ERROR);
            isFinishedWithWarnings = steps.some(step => step.status === PROGRESS_STATUS.WARNING);

            if (isFailed) {
                steps.forEach(step => {
                    if (step.status === PROGRESS_STATUS.NOT_STARTED) {
                        step.status = PROGRESS_STATUS.CANCELED;
                    }
                });
            }

            this.setState({
                isFailed,
                isFinished,
                isFinishedWithWarnings,
                steps,
            }, () => {
                if (this.state.isFinished && this.state.isFailed && onFail) {
                    onFail();
                } else if (this.state.isFinished && onFinish) {
                    onFinish();
                }
            });
        }
    };

    renderErrors = () => {
        const {
            errors,
        } = this.props;

        if (errors.length === 0) {
            return null;
        }

        return (
            <Text fontSize="sm" intent="danger">
                <ul>
                    {errors.map(error => (<li key={error}>{error}</li>))}
                </ul>
            </Text>
        );
    };

    renderWarnings = () => {
        const {
            warnings,
        } = this.props;

        if (warnings.length === 0) {
            return null;
        }

        return (
            <Text fontSize="sm" intent="warning">
                <ul>
                    {warnings.map(warning => (<li key={warning}>{warning}</li>))}
                </ul>
            </Text>
        );
    };

    render() {
        const {
            isOpen,
            errors,
            warnings,
        } = this.props;
        const {
            steps,
            isFailed,
            isFinishedWithWarnings,
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <ProgressWrapper>
                <MenuDivider />
                <Progress>
                    {steps.map(step => {
                        const { key, title: stepTitle, status, statusText } = step;
                        const progress = Number(step.progress);
                        const showErrors = status === PROGRESS_STATUS.ERROR;
                        const showWarnings = status === PROGRESS_STATUS.WARNING;

                        return (
                            <ProgressStep
                                key={key}
                                title={<Text fontSize="md">{stepTitle}</Text>}
                                status={status}
                                progress={progress}
                            >
                                {showErrors && statusText}
                                {showErrors && this.renderErrors()}
                                {showWarnings && this.renderWarnings()}
                            </ProgressStep>
                        );
                    })}
                    {!isFailed && errors.length > 0 && (
                        <Alert intent={INTENT_TYPE.DANGER}>{this.renderErrors()}</Alert>
                    )}
                    {!isFinishedWithWarnings && warnings.length > 0 && (
                        <Alert intent={INTENT_TYPE.WARNING}>{this.renderWarnings()}</Alert>
                    )}
                </Progress>
            </ProgressWrapper>
        );
    }
}
