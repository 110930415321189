// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

interface ILoaderProps {
    center: boolean;
    indicatorSize?: string;
}

export const LoaderWrapper = styled.div<ILoaderProps>`
    margin: 10px auto;

    ${({ center }) => center && `
        position: absolute !important;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    `}

    .pul-content-loader__text {
        color: ${props => props.theme.secondary_color || 'white'};
    }

    ${({ indicatorSize }) => indicatorSize && `
        .pul-content-loader__indicator {
            width: ${indicatorSize};
            height: ${indicatorSize};
        }
    `}
`;
