// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    IAdditionalOptions,
    ILoadOptions,
    ISelectOption,
} from 'common/components';
import {
    IKeyValueTag,
    ITagResponse,
    tag,
} from 'common/api/resources/Tag';
import {
    Button,
    Link,
    Paragraph,
    Translate,
    Text,
} from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    ReactElement,
    SyntheticEvent,
} from 'react';
import {
    TagRow,
    TagValueFormField,
} from 'admin/tag/components/Styles';

interface ITag {
    selectedTagKeyOption: ISelectOption|null;
    selectedTagValueOption: ISelectOption|null;
}

const loadTagKeyOptions = (excludes: number[]): ILoadOptions => async (search, _, { page } : IAdditionalOptions) => {
    const response = await tag.list({
        page,
        filters: {
            search,
        },
    });

    return {
        options: response.data.data
            .filter((tagKey) => !excludes.includes(tagKey.id))
            .map((tagKey: ITagResponse): ISelectOption => ({
                value: tagKey.id,
                label: tagKey.key,
            })),
        hasMore: !!response.data.links.next,
        additional: {
            page: page + 1,
        },
    };
};

const loadTagValueOptions = (tagKeyId: number): ILoadOptions => async (search) => {
    const response = await tag.item(tagKeyId);

    return {
        options: response.data.data.values
            .filter((tagValue) => tagValue.value.includes(search))
            .map((tagValue): ISelectOption => ({
                value: tagValue.id,
                label: tagValue.value,
            })),
    };
};

const pluckTagValuesIds = (tags: ITag[]): Array<number|undefined> => tags.map((v) => v?.selectedTagValueOption?.value as number);

export interface IEntityTagInputsProps {
    tags: IKeyValueTag[];
    onChange: (tagValueIds: Array<number|undefined>) => void;
    emptyViewText: ReactElement;
}

export const EntityTagInputs: React.FC<IEntityTagInputsProps> = ({
    tags,
    onChange,
    emptyViewText,
}) => {
    const [selectedTags, setSelectedTags] = React.useState<ITag[]>((): ITag[] =>
        tags.map(((t): ITag => ({
            selectedTagKeyOption: {
                value: t.key.id,
                label: t.key.key,
            },
            selectedTagValueOption: {
                value: t.value.id,
                label: t.value.value,
            },
        })))
    );

    const handleSelectedTagKeyChange = (option: ISelectOption, idx: number) => {
        const updatedTags = selectedTags.map((v, k) => k !== idx ? v : {
            ...v,
            selectedTagKeyOption: {
                value: option.value,
                label: option.label,
            },
            selectedTagValueOption: null,
        });
        setSelectedTags(updatedTags);
        onChange(pluckTagValuesIds(updatedTags));
    };

    const handleSelectedTagValueChange = (option: ISelectOption, idx: number) => {
        const updatedTags = selectedTags.map((v, k) => k !== idx ? v : {
            ...v,
            selectedTagValueOption: option,
        });
        setSelectedTags(updatedTags);
        onChange(pluckTagValuesIds(updatedTags));
    };

    const handleAdd = (event: SyntheticEvent<HTMLElement>) => {
        event.preventDefault();

        const updatedTags = [
            ...selectedTags,
            {
                selectedTagKeyOption: null,
                selectedTagValueOption: null,
            },
        ];
        setSelectedTags(updatedTags);
        onChange(pluckTagValuesIds(updatedTags));
    };

    const handleDelete = (idx: number) => {
        const updatedTags = selectedTags.filter((_, k) => k !== idx);
        setSelectedTags(updatedTags);
        onChange(pluckTagValuesIds(updatedTags));
    };

    return (
        <>
            {selectedTags.length === 0 && <Paragraph><Text intent="muted">{emptyViewText}</Text></Paragraph>}
            {selectedTags.map((t, idx) => (
                <TagRow key={idx}>
                    <div><Translate content="entityTagInputs.tag" params={{ num: idx + 1 }} /></div>
                    <AsyncSelectInput
                        menuPosition="fixed"
                        value={t.selectedTagKeyOption}
                        loadOptions={loadTagKeyOptions(selectedTags.map(v => v.selectedTagKeyOption?.value as number))}
                        onChange={(option: ISelectOption) => handleSelectedTagKeyChange(option, idx)}
                        debounceTimeout={1000}
                        additional={{ page: 1 }}
                        cacheUniqs={[selectedTags.filter(v => v.selectedTagKeyOption?.value).length]}
                    />
                    <TagValueFormField
                        name={`tag_value_ids[${idx}]`}
                        size={SIZE.FILL}
                        label={null}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId}
                                menuPosition="fixed"
                                value={t.selectedTagValueOption}
                                loadOptions={t.selectedTagKeyOption ? loadTagValueOptions(t.selectedTagKeyOption!.value as number) : null}
                                onChange={(option: ISelectOption) => handleSelectedTagValueChange(option, idx)}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                cacheUniqs={[t.selectedTagKeyOption?.value]}
                                isDisabled={t.selectedTagKeyOption === null}
                            />
                        )}
                    </TagValueFormField>
                    <Button
                        onClick={() => handleDelete(idx)}
                        icon={ICONS.CROSS_MARK}
                        ghost={true}
                        tooltip={<Translate content="entityTagInputs.deleteTooltip" />}
                    />
                </TagRow>
            ))}
            <Link onClick={handleAdd}>
                <Translate content="entityTagInputs.add" />
            </Link>
        </>
    );
};