// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { IShortOsImageVersionResponse } from 'common/api/resources/OsImageVersion';
import { IShortApplicationResponse } from 'common/api/resources/Application';

export enum ImagePresetType {
    OS_IMAGE_VERSION = 'os_image_version',
    APPLICATION = 'application',
}

export const IMAGE_PRESET_TYPE_TRANSLATION_MAP: Record<ImagePresetType, string> = {
    [ImagePresetType.OS_IMAGE_VERSION]: 'imagePreset.imageType.osImageVersion',
    [ImagePresetType.APPLICATION]: 'imagePreset.imageType.application',
};

export interface IImagePresetRequest {
    name: string;
    description?: string;
    image_type: ImagePresetType;
    virtualization_type: VirtualizationType;
    os_image_version_ids?: number[];
    application_ids?: number[];
}

export interface IImagePresetResponse {
    id: number;
    name: string;
    description?: string;
    image_type: ImagePresetType;
    virtualization_type: VirtualizationType;
    os_image_versions?: IShortOsImageVersionResponse[];
    applications?: IShortApplicationResponse[];
    is_default: boolean;
    is_deleting?: boolean;
}

export interface IShortImagePresetResponse {
    id: number;
    name: string;
}

export interface IImagePresetListFilters {
    search?: string;
}

export interface IImagePresetListRequest extends IRequest<IImagePresetListFilters>, IPaginated {}
