// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { CLASS_COLUMN_FIXED_RIGHT } from 'common/styles/variables';

interface IStyledTable {
    borderless?: boolean;
}

export const StyledTable = styled.div<IStyledTable>`
    height: 100% !important;

    .pul-toolbar {
        margin-bottom: 16px!important;
    }

    .pul-list {
        font-size: 14px;
        line-height: 16px;
        color: #222;

        td {
            border-top: 1px solid #ececec;
            white-space: nowrap;
            vertical-align: middle !important;
            &:first-child {
                ${({ borderless }) => borderless ? 'border-left: none !important;' : 'border-left: 1px solid #ececec;'}
            }

            &:last-child {
                ${({ borderless }) => borderless ? 'border-right: none !important;' : 'border-right: 1px solid #ececec;'}
            }
        }

        & .${CLASS_COLUMN_FIXED_RIGHT} {
            position: sticky;
            right: 0;
        }

        .pul-list__row-body {
            &>td {
                padding: 0;
            }
        }

        .pul-list__row-expander {
            min-height: 16px;
            min-width: 16px;
            line-height: 16px;
        }

        .pul-list__row--expanded {
            td {
                background: #f5f5f5;
            }
        }

        .pul-list__cell-expander {
            padding: 8px 4px !important;
        }

        .pul-list__row-expander-icon {
            margin-top: 0;
            vertical-align: middle;
        }

        .pul-list__table-thead .pul-list__cell-expander {
            padding-left: 4px!important;
        }

        .pul-list__table {
            ${({ borderless }) => !borderless && 'border-bottom: 1px solid #ececec!important;'}
            ${({ borderless }) => borderless && 'border-bottom: unset!important;'}

            th {
                border-bottom: 0;
    
                .pul-list__row-expander-icon {
                    margin-left: 2px;
                }
            }
        } 

        .pul-icon-button {
            font-size: 14px;
        }

        tr {
            .is-selected>td {
                background-color: #f5f5f5!important;
            }

            &:first-child>td {
                border-top: 1px solid #ececec;
            }

            &:hover {
                >td {
                    background-color: #fff!important;
                }
            }
        }

        tr.is-selected {
            &>td {
                background-color: #f5f5f5!important;
            }
        }

        .pul-section-item {
            padding: 0;
        }

        .pul-section-item__title {
            width: auto!important;
            padding-right: 0!important;
        }
    }

    .pul-form {
        padding: 0!important;
        height: auto!important;
    }
    
    .pul-action__content:focus {
      outline-width: 0;
    }
`;
