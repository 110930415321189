// Copyright 2025. WebPros International GmbH. All rights reserved.

import { NetworkType } from 'common/api/resources/ComputeResource';

export enum COMPUTE_RESOURCE_STATUS {
    CONFIGURE_NETWORK = 'configure_network',
    CONFIGURING_NETWORK = 'configuring network',
    COMMISSIONING = 'commissioning',
    ACTIVE = 'active',
    FAILED = 'failed',
    UNAVAILABLE = 'unavailable'
}

export enum COMPUTE_RESOURCE_DIALOG {
    NONE,
    COMPUTE_RESOURCE_CREATE,
    STORAGE,
    SETTINGS,
    COMPUTE_RESOURCE_EDIT,
    COMPUTE_RESOURCE_SETTINGS,
}

export enum COMPUTE_RESOURCE_AUTH_TYPE {
    LKEY = 'lkey',
    LPASS = 'lpass',
}

const humanNetworkTypeMap = {
    [NetworkType.BRIDGED]: 'bridged',
    [NetworkType.ROUTED]: 'routed',
    [NetworkType.VZ_ROUTED]: 'vz routed',
};

export const humanizeNetworkType = (type: NetworkType): string => humanNetworkTypeMap[type];
