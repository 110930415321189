// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as ToasterActions from 'common/modules/app/toaster/actions';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { INTENT_TYPE } from 'common/constants';

export interface IToast {
    intent: INTENT_TYPE;
    message: string | JSX.Element;
    background: boolean;
    key: string;
}

export type ToasterAction = ActionType<typeof ToasterActions>;
export type ToasterState = Readonly<IToast[]>;

export default (state: ToasterState = [], action: ToasterAction) => {
    switch (action.type) {
    case getType(ToasterActions.addToast):
        return [...state, action.payload];
    case getType(ToasterActions.removeToast):
        return state.filter(item => item.key !== action.payload);
    default:
        return state;
    }
};
