// Copyright 2025. WebPros International GmbH. All rights reserved.
import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { OnboardingStepper } from 'admin/onboarding/components/OnboardingStepper';
import {
    getStatus,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';
import {
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import {
    OnboardingContentFooter,
    OnboardingHeader,
    OnboardingLead,
} from 'admin/onboarding/components/Styled';
import ComputeResource from 'admin/computeResource/list/containers/ComputeResource';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import { FIELDS } from 'admin/computeResource/list/containers/ComputeResourceForm/ComputeResourceForm';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';

export interface IOnboardingStepConnectComputeResourceProps {
    onNext: () => void;
    item: IComputeResourceResponse;
}

export type OnboardingStepConnectComputeResourceProps = IOnboardingStepConnectComputeResourceProps
    & ReturnType<typeof mapStateToProps>;

export const OnboardingStepConnectComputeResource: React.FC<OnboardingStepConnectComputeResourceProps> = ({
    onNext,
    item,
    isNetworksLoading,
}) => {
    const formRef = React.useRef<FormInstanceHandles>(null);
    const { close } = useOnboarding();

    const buttonDisabled = isNetworksLoading
        || item.is_configuring_network
        || getStatus(item.status) === COMPUTE_RESOURCE_STATUS.COMMISSIONING;

    return (
        <OnboardingStep sidebar={(<OnboardingStepper/>)}>
            <>
                <OnboardingHeader>
                    <Translate content={'onboarding.clusterSetup.steps.connectComputeResource.header'}/>
                </OnboardingHeader>
                { getStatus(item.status) === COMPUTE_RESOURCE_STATUS.CONFIGURE_NETWORK && (
                    <>
                        <OnboardingLead>
                            <Translate content={'onboarding.clusterSetup.steps.connectComputeResource.lead'}/>
                        </OnboardingLead>
                        <p>
                            <Translate content={'onboarding.clusterSetup.steps.connectComputeResource.paragraphs.0'}/>
                        </p>
                    </>
                )}
                { getStatus(item.status) !== COMPUTE_RESOURCE_STATUS.ACTIVE
                    ? (<ComputeResource
                        networkFormRef={formRef}
                        item={item}
                        onClose={() => {
                            onNext();
                        }}
                        buttons={false}
                        hideUnavailable={true}
                        fields={[
                            FIELDS.NAME,
                            FIELDS.HOST,
                            FIELDS.AGENT_PORT,
                            FIELDS.LOGIN,
                            FIELDS.PORT,
                            FIELDS.TYPE,
                            FIELDS.LICENSE_TYPE,
                        ]}
                    />)
                    : (
                        <div />
                    )}
                { getStatus(item.status) === COMPUTE_RESOURCE_STATUS.CONFIGURE_NETWORK && (
                    <>
                        <p>
                            <Translate
                                content={'onboarding.clusterSetup.steps.connectComputeResource.hints.networkBridged'}
                            />
                        </p>
                        <p>
                            <Translate
                                content={'onboarding.clusterSetup.steps.connectComputeResource.hints.networkRouted'}
                            />
                        </p>
                    </>
                )}
                <OnboardingContentFooter>
                    <Button
                        size={SIZE.LG}
                        intent={'secondary'}
                        onClick={close}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.connectComputeResource.buttons.close'}/>
                    </Button>
                    <Button
                        disabled={buttonDisabled}
                        size={SIZE.LG}
                        intent={'primary'}
                        onClick={() => {
                            if (getStatus(item.status) !== COMPUTE_RESOURCE_STATUS.ACTIVE) {
                                formRef.current?.submit();
                            } else {
                                onNext();
                            }
                        }}
                        state={item.is_configuring_network ? 'loading' : undefined}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.connectComputeResource.buttons.continue'}/>
                    </Button>
                </OnboardingContentFooter>
            </>
        </OnboardingStep>
    );
};

const mapStateToProps = (state: RootState) => ({
    isNetworksLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_NETWORKS),
});

export default connect(mapStateToProps)(OnboardingStepConnectComputeResource);