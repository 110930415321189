// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IIconListRequest,
    IIconRequest,
    IIconResponse,
} from 'common/api/resources/Icon/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';

export const icons = {
    list: (params?: IIconListRequest) => api.get<IPaginateApiResponse<IIconResponse[]>>('icons', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IIconResponse>>(`icons/${id}`),
    remove: (id: number) => api.delete(`icons/${id}`),
    create: (data: IIconRequest) => api.post<IApiResponse<IIconResponse>>('icons', data),
    update: (id: number, data: IIconRequest) => api.put<IApiResponse<IIconResponse>>(`icons/${id}`, data),
};
