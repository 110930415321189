// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as apiTokenActions from 'admin/apiToken/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { IApiTokenResponse } from 'common/api/resources/ApiToken';
import { initialUserState } from 'admin/user/reducer';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    updateInPaginated,
} from 'common/reducers/list';

interface IApiToken {
    list: IPaginateApiResponse<IApiTokenResponse[]>;
    item: IApiTokenResponse;
}

export type ApiTokenAction = ActionType<typeof apiTokenActions>;
export type ApiTokenState = IApiToken;

export const initialApiTokenState: IApiTokenResponse = {
    id: '',
    name: '',
    user: initialUserState,
    isLoading: false,
    created_at: '',
};

export default combineReducers<ApiTokenState, ApiTokenAction>({
    list: (state = paginateInitialState, action: ApiTokenAction) => {
        switch (action.type) {
        case getType(apiTokenActions.appendApiTokens):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(apiTokenActions.updateApiTokenItem):
            return updateInPaginated(state, action.payload);
        case getType(apiTokenActions.appendApiTokenItem):
            return addToPaginated(state, action.payload);
        case getType(apiTokenActions.setApiTokensList):
            return action.payload;
        case getType(apiTokenActions.removeApiTokenItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(apiTokenActions.unsetItemIsLoading):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(apiTokenActions.setItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: true };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = initialApiTokenState, action) => {
        switch (action.type) {
        case getType(apiTokenActions.setApiToken):
            return action.payload;
        case getType(apiTokenActions.unsetApiToken):
            return { ...initialApiTokenState };
        default:
            return state;
        }
    },
});
