// Copyright 2025. WebPros International GmbH. All rights reserved.

export enum OnboardingStep {
    INITIAL = 'initial',
    WELCOME = 'welcome',
    ACTIVATE_LICENSE = 'activate_license',
    ADD_COMPUTE_RESOURCE = 'add_compute_resource',
    CONNECT_COMPUTE_RESOURCE = 'connect_compute_resource',
    ADD_IP_BLOCK = 'add_ip_block',
    ADD_VM_PLAN = 'add_vm_plan',
    MAIL_SETTINGS = 'mail_settings',
    CREATE_FIRST_VM = 'create_first_vm',
    DONE = 'done',
    SKIP = 'skip',
}

export interface IOnboardingStepState {
    submitted: boolean;
    prerequisites: boolean;
}

export interface IOnboarding {
    step: OnboardingStep;
    compute_resource_id: number | null;
    all_steps: Record<OnboardingStep, IOnboardingStepState>;
}