// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IconType } from 'common/api/resources/Icon';
import { Translate } from '@plesk/ui-library';

export const typeMappings = {
    [IconType.OS]: <Translate content="icon.types.os"/>,
    [IconType.APPLICATION]: <Translate content="icon.types.application"/>,
    [IconType.FLAGS]: <Translate content="icon.types.location"/>,
};
