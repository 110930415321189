// Copyright 2025. WebPros International GmbH. All rights reserved.

export interface ISearchRequest {
    query: string;
}

export enum SearchEntityType {
    Server = 'server',
    ComputeResource = 'compute-resource',
    User = 'user',
}

export interface IServerSearchItemResponse {
    type: SearchEntityType.Server;
    id: number;
    name: string;
}

export interface IComputeResourceSearchItemResponse {
    type: SearchEntityType.ComputeResource;
    id: number;
    name: string;
}

export interface IUserSearchItemResponse {
    type: SearchEntityType.User;
    id: number;
    email: string;
}

export interface IEntityTypeToSearchItemResponseMap {
    [SearchEntityType.Server]: IServerSearchItemResponse;
    [SearchEntityType.ComputeResource]: IComputeResourceSearchItemResponse;
    [SearchEntityType.User]: IUserSearchItemResponse;
}

export type SearchItemResponse = {
    type: SearchEntityType;
} & (IServerSearchItemResponse | IComputeResourceSearchItemResponse | IUserSearchItemResponse);