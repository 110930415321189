// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SETTINGS = {
    FORM: 'settings-form',
};

export const NOTIFICATION_SETTINGS = {
    ENABLED: 'enabled',
    ACTION_EDIT: 'action-edit',
    EDIT_DIALOG: 'edit-dialog',
};

export const NOTIFICATION_EDIT_DIALOG = {
    DEFAULT_BUTTON: 'default-btn',
};
