// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { SIZE } from 'common/constants';
import {
    Column,
    Columns,
    Translate,
} from '@plesk/ui-library';

const TokensColumns: React.FC = () => (
    <Columns gap={SIZE.MD} vertical={false}>
        <Column width={219}>
            <FormFieldNumber
                name="tokens_per_hour"
                label={<Translate content="plan.actionDialog.planTokenPerHour" />}
                fullDescription={<Translate content="plan.actionDialog.planTokenPerHourDescription" />}
                vertical
                min={0}
                size={SIZE.MD}
            />
        </Column>
        <Column width={219}>
            <FormFieldNumber
                name="tokens_per_month"
                label={<Translate content="plan.actionDialog.planTokenPerMonth" />}
                fullDescription={<Translate content="plan.actionDialog.planTokenPerMonthDescription" />}
                vertical
                min={0}
                size={SIZE.MD}
            />
        </Column>
    </Columns>
);

export default TokensColumns;
