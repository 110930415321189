// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import moment from 'moment';
import {
    GridCol,
    Icon,
    Item,
} from '@plesk/ui-library';
import {
    GraphTitle,
    TitleWrapper,
} from 'common/components/ServerTabs/GraphsTab/Styles';
import {
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';

export interface IXY {
    x: string;
    y: number;
}

export interface IXYZ extends IXY {
    z: number;
}

export interface IGraphContainerProps {
    data: Array<IXYZ | IXY>;
    title: string | JSX.Element;
    icon: string;
}

export const GraphContainer: React.FC<React.PropsWithChildren<IGraphContainerProps>> = ({
    data,
    title,
    icon,
    children,
}) => {
    const formatTime = (timeStr: string) => moment(timeStr).format('HH:mm:ss');

    return (
        <GridCol lg={12}>
            <Item view="card">
                <TitleWrapper>
                    <Icon name={icon} size="32" />
                    <GraphTitle>{title}</GraphTitle>
                </TitleWrapper>
                <ResponsiveContainer width="100%" height={200}>
                    <AreaChart data={data} margin={{ left: -10 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="x" tickFormatter={formatTime} interval={30} />
                        <YAxis/>
                        <Legend />
                        {children}
                    </AreaChart>
                </ResponsiveContainer>
            </Item>
        </GridCol>
    );
};
