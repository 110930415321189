// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import {
    AlertMessage,
    PageHeader,
} from 'client/common/components';
import {
    Text,
    Translate,
} from '@plesk/ui-library';

export type DemoProps =
    ReturnType<typeof mapStateToProps>;

export const Demo: React.FC<DemoProps> = ({ email }) => (
    <>
        <PageHeader>
            <Translate content="account.title" />
        </PageHeader>
        <AlertMessage
            title={<Translate content="account.demo.title" />}
            message={
                <Translate
                    content="account.demo.content"
                    params={{ email: <Text bold={true}>{email}</Text> }}
                />
            }
        />
    </>
);

const mapStateToProps = (state: RootState) => ({
    email: state.auth.user.email,
});

export default connect(mapStateToProps)(Demo);
