// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import {
    Item as UILibraryItem,
    ProgressBar as UILibraryProgressBar,
} from '@plesk/ui-library';

export interface IProgressBarProps {
    color: string;
}

export const Title = styled.div`
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  
  h3, span {
    display: inline-block;
  }
`;

export const Usage = styled.div`
  display: inline-block;
`;

export const Item = styled(UILibraryItem)`
  .pul-item__footer {
    border: none;
    margin: 0;
    padding: 0;
  }
`;

export const ProgressBar = styled(UILibraryProgressBar)<IProgressBarProps>`
  border-radius: 4px;
  height: 4px !important;
  margin-top: 8px;
  margin-bottom: 8px;
  
  .pul-progress-bar__indicator {
    background-color: ${props => props.color};
  }
`;
