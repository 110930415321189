// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const LabelWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .pul-button--ghost span {
    color: ${props => props.theme.primary_color} !important;
  }
`;