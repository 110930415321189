// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IBackupNodeCreateRequest,
    IBackupNodeResponse,
} from 'common/api/resources/BackupNode/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const backupNodes = {
    create: (data: IBackupNodeCreateRequest) => api.post<IApiResponse<IBackupNodeResponse>>('backup_nodes', data),
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IBackupNodeResponse[]>>('backup_nodes', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IBackupNodeResponse>>(`backup_nodes/${id}`),
    update: (id: number, data: IBackupNodeCreateRequest) => api.put<IApiResponse<IBackupNodeResponse>>(`backup_nodes/${id}`, data),
    remove: (id: number) => api.delete(`backup_nodes/${id}`),
};
