// Copyright 2025. WebPros International GmbH. All rights reserved.

import { uuid4 } from '@sentry/utils';

export const popupCenter = (
    url: string,
    width: number,
    height: number
) => {
    // eslint-disable-next-line no-restricted-globals
    const left = (screen.width / 2) - (width / 2);
    // eslint-disable-next-line no-restricted-globals
    const top = (screen.height / 2) - (height / 2);
    return window.open(
        url,
        uuid4(),
        `toolbar=no,
        location=no,
        directories=no,
        status=no,
        menubar=no,
        scrollbars=no,
        copyhistory=no,
        width=${width},
        height=${height},
        top=${top},
        left=${left}`
    );
};
