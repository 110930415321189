// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_VPC_NETWORK_LIST = 'vpcNetwork/SET_VPC_NETWORK_LIST';
export const APPEND_VPC_NETWORKS = 'vpcNetwork/APPEND_VPC_NETWORKS';
export const ADD_VPC_NETWORK_ITEM = 'vpcNetwork/ADD_VPC_NETWORK_ITEM';
export const SET_VPC_NETWORK_ITEM = 'vpcNetwork/SET_VPC_NETWORK_ITEM';
export const UNSET_VPC_NETWORK_ITEM = 'vpcNetwork/UNSET_VPC_NETWORK_ITEM';
export const REMOVE_VPC_NETWORK_ITEM = 'vpcNetwork/REMOVE_VPC_NETWORK_ITEM';
export const UPDATE_VPC_NETWORK_ITEM = 'vpcNetwork/UPDATE_VPC_NETWORK_ITEM';
export const SET_VPC_NETWORK_ITEM_IS_DELETING = 'vpcNetwork/SET_VPC_NETWORK_ITEM_IS_DELETING';
export const SET_VPC_NETWORK_IPS = 'vpcNetwork/SET_VPC_NETWORK_IP_LIST';
export const APPEND_VPC_NETWORK_IPS = 'vpcNetwork/APPEND_VPC_NETWORK_IPS';
export const ADD_VPC_NETWORK_IP = 'vpcNetwork/ADD_VPC_NETWORK_IP';
export const ADD_VPC_NETWORK_IPS = 'vpcNetwork/ADD_VPC_NETWORK_IPS';
export const REMOVE_VPC_NETWORK_IP = 'vpcNetwork/REMOVE_VPC_NETWORK_IP';
export const REMOVE_VPC_NETWORK_IPS = 'vpcNetwork/REMOVE_VPC_NETWORK_IPS';
export const SET_VPC_NETWORK_IP_ITEM_IS_DELETING = 'vpcNetwork/SET_VPC_NETWORK_IP_ITEM_IS_DELETING';
