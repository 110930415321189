// Copyright 2025. WebPros International GmbH. All rights reserved.

import forge from 'node-forge';
import { promisify } from 'util';

export interface ISSHKeypair {
    private: string;
    public: string;
}

export const generateSSHKeypair = async (pubKeyComment: string): Promise<ISSHKeypair> => {
    const result = await promisify(forge.pki.rsa.generateKeyPair)({ bits: 2048, e: 65537 });

    return {
        private: forge.pki.privateKeyToPem(result.privateKey),
        public: forge.ssh.publicKeyToOpenSSH(result.publicKey, pubKeyComment),
    };
};
