// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IRoleResponse } from 'common/api/resources/Role';
import { Dialog } from 'common/components/Dialog/Dialog';
import { IPermissionResponse } from 'common/api/resources/Permission';
import { Translate } from '@plesk/ui-library';

export interface IRoleInfoProps {
    role: IRoleResponse | null;
    onClose: () => void;
}

const renderBody = (permissions: IPermissionResponse[]) => {
    if (permissions.length === 0) {
        return <Translate content="user.info.role.empty" />;
    }

    return (
        <ul>
            {permissions.map((permission) => (
                <li key={permission.id}>
                    {permission.name}
                </li>
            ))}
        </ul>
    );
};

const RoleInfo: React.FC<IRoleInfoProps> = ({
    role,
    onClose,
}) => {
    if (!role) {
        return null;
    }

    return (
        <Dialog
            heading={
                <Translate
                    content="user.info.role.title"
                    params={{ name: role.name }}
                />
            }
            closeHandler={onClose}
            isOpen={true}
            size="xs"
        >
            {renderBody(role.permissions)}
        </Dialog>
    );
};

export default RoleInfo;
