// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    ILocationCreateRequest,
    ILocationResponse,
    ILocationUpdateRequest,
} from 'common/api/resources/Location/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const locations = {
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<ILocationResponse[]>>('locations', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<ILocationResponse>>(`locations/${id}`),
    remove: (id: number) => api.delete(`locations/${id}`),
    create: (data: ILocationCreateRequest) => api.post<IApiResponse<ILocationResponse>>('locations', data),
    update: (id: number, data: ILocationCreateRequest) => api.put<IApiResponse<ILocationResponse>>(`locations/${id}`, data),
    patch: (id: number, data: ILocationUpdateRequest) => api.patch<IApiResponse<ILocationResponse>>(`locations/${id}`, data),
};
