// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import { Loader } from 'common/components';
import { VIEW_TYPE } from 'common/constants';
import { CardList } from 'client/common/components/Card';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { ISshKeyResponse } from 'common/api/resources/SshKey';
import SshKeyCard from 'client/account/containers/Account/SshKeys/SshKeyCard';
import * as sshKeyActions from 'client/account/actions/sshKeys';
import {
    List,
    Translate,
} from '@plesk/ui-library';
import { StyledList } from 'client/common/styles/List';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import CopyText from 'common/containers/CopyText/CopyText';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'client/account/constants/test';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { getActionColumnProps } from 'common/helpers/list';

export type SshKeyListProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '15%',
    key: 'colName',
    title: <Translate content="account.sshKeys.list.colName" />,
}, {
    width: '50%',
    key: 'colContent',
    title: <Translate content="account.sshKeys.list.colContent" />,
}, getActionColumnProps(),
];

export const SshKeyList: React.FC<SshKeyListProps> = ({
    view,
    sshKeys,
    sshKeyActions: { getSshKeys, removeSshKey },
    isLoading,
}) => {
    React.useLayoutEffect(() => {
        getSshKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemove = (sshKey: ISshKeyResponse) => () => removeSshKey(sshKey);

    const data = sshKeys.map((item) => {
        const actionsEl = (
            <ButtonWithConfirmation
                isLoading={item.isLoading}
                translations={{
                    text: (
                        <Translate content="account.sshKeys.confirmationRemove.text" />
                    ),
                    button: (
                        <Translate content="account.sshKeys.confirmationRemove.button" />
                    ),
                    tooltip: (
                        <Translate content="account.sshKeys.confirmationRemove.button" />
                    ),
                }}
                handleConfirm={handleRemove(item)}
                data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
                icon="recycle"
            />
        );

        return {
            colName: <div style={{ marginLeft: '12px', fontWeight: 600 }}>{item.name}</div>,
            colContent: (
                <CopyText width="450px" text={item.body}>
                    {item.body}
                </CopyText>
            ),
            colActions: actionsEl,
            key: item.id.toString(),
        };
    });

    return (
        <Loader isLoading={isLoading}>
            {view === VIEW_TYPE.CARD && (
                <CardList
                    items={sshKeys}
                    component={sshKey => <SshKeyCard key={sshKey.id} sshKey={sshKey} />}
                />
            )}
            {view === VIEW_TYPE.LIST && (
                <StyledList>
                    <List
                        columns={columns}
                        data={data}
                    />
                </StyledList>
            )}
            {!data.length && (
                <EmptyView
                    title="account.sshKeys.emptyView.title"
                    icon="key"
                />
            )}
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    view: state.app.viewType,
    sshKeys: state.sshKeys.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.AUTH_SSH_KEY_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    sshKeyActions: bindActionCreators(sshKeyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SshKeyList);
