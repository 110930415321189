// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const HeaderIcon = styled.div`
    margin-top: 4px;
    margin-right: 12px;
`;

export const HeaderContent = styled.div`
    flex: 1;
    display: inline-block;
    flex-direction: column;
`;

export const HeaderTitle = styled.div`
    display: flex;
    white-space: nowrap;

    h1 {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0;
    }

    div:first-child {
        margin-top: 4px;
        margin-right: 12px;
    }
`;

export const HeaderSubtitle = styled.div`
    margin-left: 2px;
    
    .pul-action {
        margin-right: 22px;
        font-weight: 600;
        font-size: 14px;
    }
    
    span {
     color: ${props => props.theme.primary_color} !important;
    }
`;
