// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    SubTitleRow,
    SubTitleSection,
    SubTitleSectionLabel,
} from 'common/components/PageHeader/Styles';
import { ICONS } from 'common/constants';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import CopyText from 'common/containers/CopyText/CopyText';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import LocationCell from 'common/components/IconCell/LocationCell';
import { COLORS } from 'client/core/theme';
import { CopyTexts } from 'common/containers/CopyText/Styles';
import { primaryIps } from 'common/api/resources/Ip';
import { PlanDescription } from 'common/components/plan/components/PlanDescription/PlanDescription';
import { isFeatureEnabled } from 'common/selectors';
import { FEATURE } from 'common/api/resources/Settings';
import { getServerBootModeName } from 'common/components/ServerBootMode/ServerBootModeName';
import { ServerBootModeIcon } from 'common/components/ServerBootMode/ServerBootModeIcon';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export type ProjectServerDescriptionProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps>;

export const ProjectServerDescription: React.FC<ProjectServerDescriptionProps> = ({
    item,
    hidePlanName,
    canGetComputeResourceName,
}) => (
    <>
        <SubTitleRow>
            <SubTitleSection>
                <SubTitleSectionLabel>
                    <ServerBootModeIcon server={item} />
                </SubTitleSectionLabel>
                {getServerBootModeName(item)}
            </SubTitleSection>
            {canGetComputeResourceName && (
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Icon name={ICONS.RESOURCE} />
                    </SubTitleSectionLabel>
                    {item.compute_resource_name}
                </SubTitleSection>
            )}
            <SubTitleSection>
                <SubTitleSectionLabel>
                    <Icon name={ICONS.PLAN} />
                </SubTitleSectionLabel>
                <PlanDescription
                    planName={item.plan.is_custom || hidePlanName ? undefined : item.plan.name}
                    vcpu={item.specifications.vcpu}
                    disk={item.specifications.disk}
                    ram={item.specifications.ram}
                />
            </SubTitleSection>
        </SubTitleRow>
        <SubTitleRow>
            {item.location && (
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <LocationCell color={COLORS.GREY_100} location={item.location} />
                    </SubTitleSectionLabel>
                </SubTitleSection>
            )}
            <SubTitleSection>
                <SubTitleSectionLabel>
                    <span><Translate content="servers.description.ips"/></span>
                </SubTitleSectionLabel>
                <CopyTexts>
                    {primaryIps(item.ips).map(ip => (
                        <CopyText key={ip.id}>{ip.ip}</CopyText>
                    ))}
                </CopyTexts>
            </SubTitleSection>
        </SubTitleRow>
    </>
);

const mapStateToProps = (state: RootState) => ({
    item: state.project.servers.item,
    hidePlanName: isFeatureEnabled(FEATURE.HIDE_PLAN_NAME, state),
    canGetComputeResourceName: hasPermission(state, PERMISSION_LIST.GET_COMPUTE_RESOURCE_NAME),
});

export default withRouter(connect(mapStateToProps)(ProjectServerDescription));
