// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { Icon } from '@plesk/ui-library';
import {
    StatusText,
    StatusIconContainer,
    UpdateErrorsContainer,
} from 'admin/update/containers/VersionState/Styles';
import { INTENT_TYPE } from 'common/constants';
import { ITaskResponse } from 'common/api/resources/Task';
import FailedTask from 'admin/update/components/FailedTask/FailedTask';

export interface IVersionUpdateFailedProps {
    task: ITaskResponse;
    title: JSX.Element | string;
    retryUpdate: () => void;
}

export const VersionUpdateFailed: React.FC<IVersionUpdateFailedProps> = ({
    task,
    title,
    retryUpdate,
}) => {
    const handleRetry = () => retryUpdate();

    return (
        <>
            <StatusIconContainer>
                <Icon
                    name="exclamation-mark-circle-filled"
                    intent={INTENT_TYPE.DANGER}
                />
            </StatusIconContainer>
            <div>
                <StatusText>
                    {title}
                </StatusText>
                <UpdateErrorsContainer>
                    <FailedTask
                        task={task}
                        onRetry={handleRetry}
                    />
                </UpdateErrorsContainer>
            </div>
        </>
    );
};

export default VersionUpdateFailed;
