// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const GraphTitle = styled.h2`
    vertical-align: middle;
    line-height: 26px;
    font-weight: 600;
    font-size: 26px;
    display: inline-block;
    padding-left: 15px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    color: ${props => props.theme.primary_color};
`;
