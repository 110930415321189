// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import Item from 'admin/menu/components/Navigation/Item';
import {
    NavGroupChevron,
    NavGroupContainer,
} from 'admin/menu/components/Navigation/Styles';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import Navigation from 'admin/menu/components/Navigation/Navigation';
import { IUserResponse } from 'common/api/resources/User';
import { IMenuItem } from 'common/modules/app/menu/reducer';

export interface INavGroupProps {
    user: IUserResponse;
    item: IMenuItem;
    isNested?: boolean;
    onClick?: () => void;
    onNestedToggle?: (idx: number[], isActive: boolean) => void;
    onChevronClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onNestedNavigate?: (href: string) => void;
}

export const NavGroup: React.FC<INavGroupProps> = ({
    user,
    item,
    isNested,
    onClick,
    onNestedToggle,
    onNestedNavigate,
    onChevronClick,
}) => (
    <NavGroupContainer>
        <Item
            icon={item.icon}
            isActive={item.isOpened}
            isNested={isNested}
            isHighlighted={item.isHighlighted}
            to={item.path}
            onClick={onClick}
        >
            <Translate content={item.title}/>
            <NavGroupChevron onClick={onChevronClick}>
                <Icon size="16" name={item.isOpened ? 'chevron-up' : 'chevron-down' }/>
            </NavGroupChevron>
        </Item>
        <Navigation
            user={user}
            items={item.subItems as IMenuItem[]}
            isOpened={item.isOpened}
            isNested={true}
            onNavigate={onNestedNavigate}
            onNestedToggle={onNestedToggle}
        />
    </NavGroupContainer>
);

export default NavGroup;
