// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const SimpleRegisterContainer = styled.div`
    display: flex;
    flex: 1;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: center;
`;
