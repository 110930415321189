// Copyright 2025. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    IUpdateUserTask,
    TASK_STATUS,
    TASK_STATUS_TRANSLATION,
} from 'common/api/resources/Task';
import { INTENT_TYPE } from 'common/constants';
import { bakeBackgroundToast } from 'common/modules/app/toaster/actions';

export const updateUserTaskAction = (data: IUpdateUserTask) => async (dispatch: Dispatch) => {
    if ([TASK_STATUS.DONE, TASK_STATUS.FAILED].includes(data.task.status) &&
        TASK_STATUS_TRANSLATION[data.task.action] !== undefined &&
        TASK_STATUS_TRANSLATION[data.task.action][data.task.status] !== undefined
    ) {
        const intent = data.task.status === TASK_STATUS.DONE ? INTENT_TYPE.SUCCESS : INTENT_TYPE.DANGER;
        bakeBackgroundToast(intent, TASK_STATUS_TRANSLATION[data.task.action][data.task.status])(dispatch);
    }
};