// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IPlanResponse } from 'common/api/resources/Plan';
import { COLORS } from 'common/theme';
import {
    Icon,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { ItemPlan } from 'common/components/plan/Styles';
import { ICONS } from 'common/constants';
import Action from 'common/components/Action/Action';
import { PlanCardDescription } from 'common/components/plan/components/PlanCardDescription';
import { dataCySelector } from 'common/tests/selectors';
import { CUSTOMIZE_PLAN } from 'common/components/plan/constants/tests';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';
import { Tooltip as UITooltip } from 'common/components';
import { useDisabledEntitiesManager } from 'common/helpers/ManagerOfDisabledEntities';

export interface IPlanCardProps extends React.HTMLProps<HTMLDivElement> {
    project: IProjectResponse;
    plan: IPlanResponse;
    isSelected: boolean;
    onItemClicked: (plan: IPlanResponse) => void;
    showName?: boolean;
    onCustomize: (plan: IPlanResponse) => void;
    canCustomizeItem?: boolean;
}

export const PlanCard: React.FC<IPlanCardProps> = ({
    project,
    plan,
    onItemClicked,
    onCustomize,
    isSelected,
    showName,
    canCustomizeItem,
}) => {
    const manager = useDisabledEntitiesManager();

    const planStatus = React.useMemo(
        () => manager.getPlanStatus(plan),
        [manager, plan]
    );

    const tooltips = planStatus?.isDisabled
        ? planStatus
            .conflictMessages
            .map((conflictMessage, ind) => (
                <div key={ind}>
                    <Translate content={conflictMessage.messageKey} params={conflictMessage.params}/>
                </div>
            ))
        : null;

    const isDisabled = !!planStatus?.isDisabled ?? false;

    const handleClick = (item: IPlanResponse) => () => !isDisabled && onItemClicked(item);
    const planPrice = React.useMemo(() => {
        if (project.token_pricing) {
            return {
                per_month: formatPricePerMonth(plan.tokens_per_month, project.token_pricing),
                per_hour: formatPricePerHour(plan.tokens_per_hour, project.token_pricing),
            };
        }

        return undefined;
    }, [plan, project]);

    return (
        <UITooltip shown={isDisabled} title={tooltips}>
            <ItemPlan
                isSelected={isSelected}
                isDisabled={isDisabled}
                onClick={handleClick(plan)}
                data-cy={plan.name}
            >
                <PlanCardDescription
                    name={plan.name}
                    ram={plan.params.ram}
                    vcpu={plan.params.vcpu}
                    disk={plan.params.disk}
                    showName={showName}
                    price={planPrice}
                    showInnerTooltip={!tooltips}
                />
                {!plan.is_visible && (
                    <Icon name={ICONS.EYE_CLOSED} size="16" style={{
                        color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60,
                        marginLeft: 'auto',
                    }} />
                )}
                {canCustomizeItem && (
                    <Tooltip title={<Translate content="servers.create.plan.customize"/>}>
                        <Action
                            disabled={isDisabled}
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.stopPropagation();
                                onCustomize(plan);
                            }}
                            icon={
                                <Icon name={ICONS.COPY} size="32" style={{
                                    color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60,
                                    position: 'absolute',
                                    bottom: '7px',
                                    right: '12px',
                                }} />
                            }
                            data-cy={dataCySelector(plan.id, CUSTOMIZE_PLAN)}
                        />
                    </Tooltip>
                )}
            </ItemPlan>
        </UITooltip>
    );
};
