// Copyright 2025. WebPros International GmbH. All rights reserved.

import { FormField } from '@plesk/ui-library';
import styled from 'styled-components';

export const TagValueFormField = styled(FormField)`
    width: 100%;
    margin-bottom: 0 !important;
`;

export const TagRow = styled.div`
    display: flex;
    text-wrap: nowrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;

    .pul-form-field__error {
        position: absolute;    
    }
`;