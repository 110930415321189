// Copyright 2025. WebPros International GmbH. All rights reserved.

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
};

export const FORM = {
    TYPE_SELECTOR: 'type-selector',
};

export const TABLE = {
    USED_FOR_MANAGEMENT_NODE_BACKUPS_ICON: 'used-for-management-node-backups-icon',
};
