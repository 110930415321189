// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Icon } from '@plesk/ui-library';
import { CutTitle } from 'common/components';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    ContentName,
    ItemContent,
    LogoContainer,
    CardItem,
} from 'common/modules/computeResourceVm/components/Styles';
import { IVpcNetworkResponse } from 'common/api/resources/VpcNetwork';

export interface IVpcNetworkCardProps {
    vpcNetwork: IVpcNetworkResponse;
    icon: ICONS;
    isSelected: boolean;
    onItemClicked?: () => void;
    disabled?: boolean;
    showInternalTooltips?: boolean;
    onClick?: () => void;
}

export const VpcNetworkCard: React.FC<IVpcNetworkCardProps> = ({
    vpcNetwork,
    icon,
    isSelected,
    onItemClicked,
    disabled,
    showInternalTooltips= true,
    onClick,
}) => {
    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    return (
        <CardItem isSelected={isSelected} isDisabled={disabled} onClick={handleClick}>
            <div>
                <LogoContainer>
                    <Icon
                        intent={isSelected ? undefined : INTENT_TYPE.INACTIVE}
                        name={icon}
                    />
                </LogoContainer>
            </div>
            <ItemContent>
                <ContentName>
                    <CutTitle title={vpcNetwork.name} withTooltip={showInternalTooltips} />
                </ContentName>
            </ItemContent>
        </CardItem>
    );
};
