// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    IManagementNodeBackupResponse,
    ManagementNodeBackupCreationMethod,
    ManagementNodeBackupStatus,
} from 'common/api/resources/ManagementNodeBackup/model';
import { combineReducers } from 'redux';
import * as managementNodeBackupActions from 'admin/managementNodeBackup/actions';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    addToPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import { initialUserState } from 'admin/user/reducer';
import { initialBackupNodeState } from 'admin/backupNode/reducer';

export interface IManagementNodeBackupReducer {
    list: IPaginateApiResponse<IManagementNodeBackupResponse[]>;
    nextScheduledDate: string;
}

export const initialManagementNodeBackupState: IManagementNodeBackupResponse = {
    id: 0,
    status: ManagementNodeBackupStatus.CREATING,
    version: '',
    created_at: '',
    creation_method: ManagementNodeBackupCreationMethod.AUTO,
    size: 0,
    backup_node: { ...initialBackupNodeState },
    creator: { ...initialUserState },
    backup_fail_reason: '',
    is_deleting: false,
};

export type ManagementNodeBackupState = Readonly<IManagementNodeBackupReducer>;
export type ManagementNodeBackupActions = ActionType<typeof managementNodeBackupActions>;

export default combineReducers<ManagementNodeBackupState, ManagementNodeBackupActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(managementNodeBackupActions.setManagementNodeBackupList):
            return action.payload;
        case getType(managementNodeBackupActions.addManagementNodeBackupItem):
            return addToPaginated(state, action.payload);
        case getType(managementNodeBackupActions.updateManagementNodeBackupItem):
            return updateInPaginated(state, action.payload);
        case getType(managementNodeBackupActions.updateManagementNodeBackupItemProgress):
            return updateInPaginated(state, {
                id: action.payload.id,
                backup_progress : action.payload.progress,
            });
        case getType(managementNodeBackupActions.setManagementNodeBackupItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    nextScheduledDate: (state = '', action) => {
        switch(action.type) {
        case getType(managementNodeBackupActions.setNextScheduledManagementNodeBackupDate):
            return action.payload;
        default:
            return state;
        }
    },
});
