// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { CardItem } from 'common/components/styles/CardItem';

// @ts-ignore
export const IpTypeItem = styled(CardItem)`
    border-radius: 2px;
    height: 66px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ContentName = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222;
`;
