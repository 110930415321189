// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IIpBlockCreateRequest,
    IIpBlockResponse,
} from 'common/api/resources/IpBlock/model';
import {
    IReserveIpRequest,
    IIpResponse,
    IAddIpRequest,
    IAddIpsRequest,
} from 'common/api/resources/Ip';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';

export const ipBlocks = {
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IIpBlockResponse[]>>('ip_blocks', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IIpBlockResponse>>(`ip_blocks/${id}`),
    create: (data: IIpBlockCreateRequest) => api.post<IApiResponse<IIpBlockResponse>>('ip_blocks', data),
    update: (id: number, data: IIpBlockCreateRequest) => api.put<IApiResponse<IIpBlockResponse>>(`ip_blocks/${id}`, data),
    remove: (id: number) => api.delete(`ip_blocks/${id}`),
    removeBatch: (ids: number[]) => api.delete('ip_blocks', { data: { ids } }),

    ips: {
        list: (id: number, params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IIpResponse[]>>(`ip_blocks/${id}/ips`, {
            params: new QueryBuilder(params).build(),
        }),
        reserve: (id: number, data: IReserveIpRequest) => api.post<IApiResponse<IIpResponse>>(`ip_blocks/${id}/ips`, data),
        addSingle: (id: number, data: IAddIpRequest) => api.post<IApiResponse<IIpResponse>>(`ip_blocks/${id}/add_ip`, data),
        addMultiple: (id: number, data: IAddIpsRequest) => api.post<IApiResponse<IIpResponse[]>>(`ip_blocks/${id}/add_ips`, data),
    },
};
