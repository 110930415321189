// Copyright 2025. WebPros International GmbH. All rights reserved.

export const COMPUTE_RESOURCES = {
    TABLE: 'cr-table',
};

export const COMPUTE_RESOURCE = {
    SETTINGS: 'cr-settings',
    IS_LOCKED: 'cr-locked',
    EDIT: 'edit-button',
    DELETE: 'delete-button',
    STATUS: 'cr-status',
    IS_IN_MAINTENANCE: 'cr-in-maintenance',
};

export const COMPUTE_RESOURCE_FORM = {
    FORM: 'compute-resource-form',
    KEY: 'compute-resource-key',
    PASSWORD: 'compute-resource-password',
    AUTH_TYPE: 'auth-type',
};

export const TABS = {
    SERVERS: 'servers',
    STORAGES: 'storages',
    TASKS: 'tasks',
    USAGE: 'usage',
};

export const TASKS = {
    TABLE: 'tasks-table',
};

export const SERVER = {
    ACTIONS: {
        VNC: 'server-vnc',
        START: 'server-start',
        RESTART: 'server-restart',
        STOP: 'server-stop',
        REMOVE: 'server-remove',
        LOGIN: 'server-login',
        EDIT: 'edit',
        SHUTDOWN: 'shutdown',
    },
    BATCH_ACTIONS: {
        START: 'server-batch-start',
        RESTART: 'server-batch-restart',
        STOP: 'server-batch-stop',
        MIGRATE: 'server-migrate',
        REMOVE: 'server-batch-remove',
        INSTALL_GUEST_TOOLS: 'server-batch-install-guest-tools',
        SYNC_IMAGE_PRESETS: 'server-batch-sync-image-presets',
    },
    DIALOG: 'create-server-dialog',
    MIGRATION_DIALOG: 'servers-migrate-dialog',
};

export const COMPUTE_RESOURCE_SETTINGS = {
    VS_DISK_CACHE_MODE: 'vs_disk_cache_mode',
};
