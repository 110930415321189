// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import * as authActions from 'common/modules/auth/actions';
import { IAuthLinkRequest } from 'common/api/resources/Auth';
import { Loader } from 'common/components';
import { Translate } from '@plesk/ui-library';

export type AuthByLinkProps =
    RouteComponentProps &
    ReturnType<typeof mapDispatchToProps>;

export const AuthByLink: React.FC<AuthByLinkProps> = ({
    location,
    authActions: { authByLink },
}) => {
    const [shouldRedirect, setShouldRedirect] = React.useState(false);

    React.useLayoutEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        const email = new URLSearchParams(location.search).get('email');
        const authRequest = async (data: IAuthLinkRequest) => {
            try {
                await authByLink(data);
            } finally {
                setShouldRedirect(true);
            }
        };

        if (token && email) {
            authRequest({
                email: email.toLowerCase(),
                token,
            });
        } else {
            setShouldRedirect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (shouldRedirect) {
        return <Redirect to="/admin" />;
    }

    return <Loader text={<Translate content="auth.authorizing" />} isLoading={true} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(AuthByLink));
