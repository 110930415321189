// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IServersMigrationResponse } from 'common/api/resources/ServersMigration';
import { TASK_STATUS_INTENT_MAP } from 'admin/task/constants';
import {
    Action,
    Translate,
} from '@plesk/ui-library';
import { Status } from 'common/components/styles/Status';
import {
    isFinished,
    TASK_STATUS,
} from 'common/api/resources/Task';
import { SEVER_MIGRATION_STATUS } from 'admin/serversMigration/constants/tests';

export type ServersMigrationStatusProps = IServersMigrationStatusProps;

interface IServersMigrationStatusProps {
    serverMigration: IServersMigrationResponse;
}

export const TASK_STATUS_MAP = {
    [TASK_STATUS.PENDING]: <Translate content="serversMigration.status.pending" />,
    [TASK_STATUS.FAILED]: <Translate content="serversMigration.status.failed" />,
    [TASK_STATUS.DONE]: <Translate content="serversMigration.status.done" />,
    [TASK_STATUS.DONE_WITH_ERRORS]: <Translate content="serversMigration.status.failed" />,
};

const ServersMigrationStatus: React.FC<ServersMigrationStatusProps> = ({ serverMigration }) => {
    const task = serverMigration.task;
    const children = serverMigration.children;
    if (!task || !children) {
        return null;
    }

    if ([TASK_STATUS.QUEUED, TASK_STATUS.RUNNING].includes(task.status as TASK_STATUS)) {
        return (
            <Action progress={true}>
                <Translate content="serversMigration.status.processing" params={{
                    curr: children.filter(isFinished).length,
                    total: children.length,
                }}/>
            </Action>
        );
    }
    return (
        <Status data-cy={SEVER_MIGRATION_STATUS} intent={TASK_STATUS_INTENT_MAP[task.status]} compact={true}>
            {TASK_STATUS_MAP[task.status]}
        </Status>
    );
};

export default ServersMigrationStatus;
