// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import DisableEnableSegmentedControl,
{ IDisableEnableSegmentedControlProps } from 'common/components/DisabledEnabledSegmentedControl';

export interface IVZPPPSegmentedControlProps extends
    Omit<IDisableEnableSegmentedControlProps, 'confirmationPopoverTitle'> {
}

const VZPPPSegmentedControl: React.FC<IVZPPPSegmentedControlProps> = (props) => (
    <DisableEnableSegmentedControl
        confirmationPopoverTitle={<Translate content="components.vzPPPSegmentedControl.confirmationPopoverTitle" />}
        {...props}
    />
);

export default VZPPPSegmentedControl;