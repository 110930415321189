// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { IAccountUpdateRequest } from 'common/api/resources/Account/model';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    ISshKeyRequest,
    ISshKeyResponse,
} from 'common/api/resources/SshKey';
import { IUserResponse } from 'common/api/resources/User';
import {
    IApiTokenCreateRequest,
    IApiTokenResponse,
} from 'common/api/resources/ApiToken';
import { ILoginResponse } from 'common/api/resources/Auth';
import { CancelTokenSource } from 'axios';

export const accounts = {
    item: (cancelToken?: CancelTokenSource) => api.get<IApiResponse<IUserResponse>>('account', {
        cancelToken: cancelToken?.token,
    }),
    update: (data: IAccountUpdateRequest) => api.patch<IApiResponse<IUserResponse|ILoginResponse>>('account', data),

    tokens: {
        list: () => api.get<IApiResponse<IApiTokenResponse[]>>('account/tokens'),
        create: (data: IApiTokenCreateRequest) => api.post('account/tokens', data),
    },

    sshKeys: {
        list: () => api.get<IApiResponse<ISshKeyResponse[]>>('account/ssh_keys'),
        create: (data:  ISshKeyRequest) => api.post('account/ssh_keys', data),
    },

    notifications: {
        list: () => api.get<IPaginateApiResponse<INotificationResponse[]>>('account/notifications'),
        showMore: (from: number) => api.get<IPaginateApiResponse<INotificationResponse[]>>(`account/notifications?from=${from}`),
        clearAll: () => api.delete('account/notifications'),
    },
};
