// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    List,
    Translate,
} from '@plesk/ui-library';
import { TaskStatus } from 'admin/task/components/TaskStatus';
import { ITaskResponse } from 'common/api/resources/Task';
import { IServersMigrationResponse } from 'common/api/resources/ServersMigration';
import { ServersMigrationRowWrapper } from 'admin/serversMigration/containers/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as taskActions from 'admin/task/actions';
import { connect } from 'react-redux';
import ComputeResourceTaskDialog from 'admin/task/containers/TaskDialog';

export type ServersMigrationRowProps =
    IServersMigrationRowProps &
    ReturnType<typeof mapDispatchToProps>;

export interface IServersMigrationRowProps {
    serverMigration: IServersMigrationResponse;
}

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="serversMigration.list.row.id" />,
}, {
    width: '15%',
    key: 'colName',
    title: <Translate content="serversMigration.list.row.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '15%',
    key: 'colStatus',
    title: <Translate content="serversMigration.list.row.status" />,
}, {
    key: 'colSource',
    title: <Translate content="serversMigration.list.row.source" />,
}];

export const ServersMigrationRow: React.FC<ServersMigrationRowProps> = ({
    serverMigration,
    taskActions: {
        setTask,
    },
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleTaskStatusOpen = (task: ITaskResponse) => () => {
        setTask(task);
        setIsOpen(true);
    };
    const handleClose = () => setIsOpen(false);

    const listData = serverMigration.children?.map((item) => ({
        colId: item.compute_resource_vm?.id,
        colName: item.compute_resource_vm &&
            <CopyText>{item.compute_resource_vm.name}</CopyText>,
        colStatus: <TaskStatus item={item} onOpen={handleTaskStatusOpen(item)}/>,
        colSource: item.compute_resource?.name,
        key: item.id.toString(),
    }));

    if (serverMigration.children?.length === 0) {
        return null;
    }

    return (
        <>
            <ServersMigrationRowWrapper>
                <StyledTable borderless={true}>
                    <List columns={columns} data={listData}/>
                </StyledTable>
            </ServersMigrationRowWrapper>
            <ComputeResourceTaskDialog handleClose={handleClose} isOpen={isOpen} />
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ServersMigrationRow);
