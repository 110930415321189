// Copyright 2025. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    requestAction,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'common/modules/vpcNetwork/constants/types';
import {
    IVpcNetworkAddIpRequest,
    IVpcNetworkAddIpsRequest,
    IVpcNetworkAttachServersRequest,
    IVpcNetworkDetachServersRequest,
    IVpcNetworkIpResponse,
    IVpcNetworkListRequest,
    IVpcNetworkRequest,
    IVpcNetworkResponse,
} from 'common/api/resources/VpcNetwork/model';
import { vpcNetwork } from 'common/api/resources/VpcNetwork/api';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import { CancelTokenSource } from 'axios';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { ICommonState } from 'common/store';

export const setVpcNetworkList = createCustomAction(
    types.SET_VPC_NETWORK_LIST,
    (data: IPaginateApiResponse<IVpcNetworkResponse[]>) => ({ payload: data })
);
export const appendVpcNetworks = createCustomAction(
    types.APPEND_VPC_NETWORKS,
    (data: IPaginateApiResponse<IVpcNetworkResponse[]>) => ({ payload: data })
);
export const setVpcNetworkItem = createCustomAction(
    types.SET_VPC_NETWORK_ITEM,
    (data: IVpcNetworkResponse) => ({ payload: data })
);
export const unsetVpcNetworkItem = createCustomAction(types.UNSET_VPC_NETWORK_ITEM);
export const addVpcNetworkItem = createCustomAction(
    types.ADD_VPC_NETWORK_ITEM,
    (data: IVpcNetworkResponse) => ({ payload: data })
);
export const updateVpcNetworkItem = createCustomAction(
    types.UPDATE_VPC_NETWORK_ITEM,
    (data: IVpcNetworkResponse) => ({ payload: data })
);
export const removeVpcNetworkItem = createCustomAction(
    types.REMOVE_VPC_NETWORK_ITEM,
    (id: number) => ({ payload: id })
);
export const setVpcNetworkItemIsDeleting = createCustomAction(
    types.SET_VPC_NETWORK_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const setVpcNetworkIps = createCustomAction(
    types.SET_VPC_NETWORK_IPS,
    (data: IPaginateApiResponse<IVpcNetworkIpResponse[]>) => ({ payload: data })
);
export const appendVpcNetworkIps = createCustomAction(
    types.APPEND_VPC_NETWORK_IPS,
    (data: IPaginateApiResponse<IVpcNetworkIpResponse[]>) => ({ payload: data })
);
export const addVpcNetworkIp = createCustomAction(
    types.ADD_VPC_NETWORK_IP,
    (data: IVpcNetworkIpResponse) => ({ payload: data })
);
export const addVpcNetworkIps = createCustomAction(
    types.ADD_VPC_NETWORK_IPS,
    (data: IVpcNetworkIpResponse[]) => ({ payload: data })
);
export const removeVpcNetworkIp = createCustomAction(
    types.REMOVE_VPC_NETWORK_IP,
    (id: number) => ({ payload: id })
);
export const removeVpcNetworkIps = createCustomAction(
    types.REMOVE_VPC_NETWORK_IPS,
    (ids: number[]) => ({ payload: ids })
);
export const setVpcNetworkIpItemIsDeleting = createCustomAction(
    types.SET_VPC_NETWORK_IP_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getVpcNetworks = (request?: IVpcNetworkListRequest, cancelToken?: CancelTokenSource) => async (dispatch: Dispatch) => {
    const apiCall = () => vpcNetwork.list(request, cancelToken);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.VPC_NETWORK_LIST,
        action: setVpcNetworkList,
        apiCall,
    });
};

export const getVpcNetworksNextPage = () => async (dispatch: Dispatch, getState: () => ICommonState) => {
    const state = getState();
    const nextPage = state.vpcNetwork.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendVpcNetworks,
        loadingFlag: LOADING_FLAGS.VPC_NETWORK_LIST,
    });
};

export const createVpcNetwork = (data: IVpcNetworkRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.VPC_NETWORK_ITEM_SAVE,
    action: addVpcNetworkItem,
    apiCall: vpcNetwork.create,
    translations: {
        success: 'vpcNetwork.toasts.saved',
    },
});

export const getVpcNetwork = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: vpcNetwork.item,
    action: setVpcNetworkItem,
    loadingFlag: LOADING_FLAGS.VPC_NETWORK_ITEM,
});

export const updateVpcNetwork = (id: number, data: IVpcNetworkRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: vpcNetwork.update,
    action: updateVpcNetworkItem,
    loadingFlag: LOADING_FLAGS.VPC_NETWORK_ITEM_SAVE,
    translations: {
        success: 'vpcNetwork.toasts.saved',
    },
});

export const removeVpcNetwork = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: vpcNetwork.remove,
    setLoadingAction: setVpcNetworkItemIsDeleting,
    action: removeVpcNetworkItem,
    loadingFlag: LOADING_FLAGS.VPC_NETWORK_ITEM_REMOVE,
    translations: {
        success: 'vpcNetwork.toasts.removed',
    },
});

export const getVpcNetworkIps = (id: number, params?: IPaginatedWithSearch) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.VPC_NETWORK_IP_LIST));

    try {
        const result = await vpcNetwork.ips.list(id, params);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setVpcNetworkIps(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.VPC_NETWORK_IP_LIST));
    }
};

export const addIpToVpcNetwork = (id: number, data: IVpcNetworkAddIpRequest) => async (dispatch: Dispatch) => {
    const apiCall = async (request: IVpcNetworkAddIpRequest) => vpcNetwork.ips.addSingle(id, request);
    return await create({
        data,
        dispatch,
        loadingFlag: LOADING_FLAGS.VPC_NETWORK_IP_ADDING,
        action: addVpcNetworkIp,
        apiCall,
        translations: {
            success: 'vpcNetwork.ip.toasts.added',
        },
    });
};

export const addIpsToVpcNetwork = (id: number, data: IVpcNetworkAddIpsRequest) => async (dispatch: Dispatch) => {
    const apiCall = async (request: IVpcNetworkAddIpsRequest) => vpcNetwork.ips.addMultiple(id, request);
    return await create({
        data,
        dispatch,
        loadingFlag: LOADING_FLAGS.VPC_NETWORK_IP_ADDING,
        action: addVpcNetworkIps,
        apiCall,
        translations: {
            success: 'vpcNetwork.ip.toasts.addedBatch',
        },
    });
};

export const removeIpFromVpcNetwork = (vpcNetworkId: number, id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: (idToDelete: number) => vpcNetwork.ips.remove(vpcNetworkId, idToDelete),
    setLoadingAction: setVpcNetworkIpItemIsDeleting,
    action: removeVpcNetworkIp,
    loadingFlag: LOADING_FLAGS.VPC_NETWORK_IP_REMOVE,
    translations: {
        success: 'vpcNetwork.ip.toasts.deleted',
    },
});

export const removeIpsFromVpcNetwork = (vpcNetworkId: number, ids: number[]) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.VPC_NETWORK_IP_REMOVE));

    try {
        const result = await vpcNetwork.ips.removeBatch(vpcNetworkId, ids);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            bakeForegroundToast(
                INTENT_TYPE.SUCCESS,
                'vpcNetwork.ip.toasts.deletedBatch',
                { count: result.data.length }
            )(dispatch);
            dispatch(removeVpcNetworkIps(ids));
        }

        return result.data;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.VPC_NETWORK_IP_REMOVE));
    }
};

export const attachServersToVpcNetwork = (id: number, data: IVpcNetworkAttachServersRequest) => async (dispatch: Dispatch) => await requestAction({
    dispatch,
    loadingFlag: LOADING_FLAGS.VPC_NETWORK_SERVER_ATTACH,
    apiCall: async () => await vpcNetwork.attachServers(id, data),
    data,
});

export const detachServersFromVpcNetwork = (id: number, data: IVpcNetworkDetachServersRequest) => async (dispatch: Dispatch) => await requestAction({
    dispatch,
    loadingFlag: LOADING_FLAGS.VPC_NETWORK_SERVER_DETACH,
    apiCall: async () => await vpcNetwork.detachServers(id, data),
    data,
});