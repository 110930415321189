// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { HeaderButtonContainer } from 'client/common/components/HeaderButton/Styles';
import { Button } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import { ButtonProps } from '@plesk/ui-library/types/src/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';

type HeaderButtonProps = ButtonProps & {
    isMobile?: boolean;
    isLoading?: boolean;
    to?: React.ComponentProps<typeof Link>['to'];
};

export const HeaderButton: React.FC<HeaderButtonProps> = ({ isMobile, ...rest }) => {
    const { isLoading, ...other } = rest;

    return (
        <HeaderButtonContainer isMobile={isMobile}>
            <Button
                state={rest.isLoading ? 'loading' : undefined}
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                component={'to' in rest ? Link : 'button'}
                {...other}
            />
        </HeaderButtonContainer>
    );
};
