// Copyright 2025. WebPros International GmbH. All rights reserved.

import { api } from 'common/api/resources/Response';
import { IDocumentationResponse } from 'common/api/resources/Documentation/model';

const isAdmin = window.location.pathname.includes('admin');
const url = `/storage/api_doc/${isAdmin ? 'admin-docs.json' : 'client-docs.json'}`;

export const documentation = {
    get: () => api.get<IDocumentationResponse>(url),
};
