// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as imagePresetActions from 'admin/imagePreset/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    IImagePresetRequest,
    IMAGE_PRESET_TYPE_TRANSLATION_MAP,
    ImagePresetType,
} from 'common/api/resources/ImagePreset/model';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import {
    VIRTUALIZATION_TYPE_TRANSLATION_MAP,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import {
    IOsImageListRequest,
    IOsImageResponse,
} from 'common/api/resources/OsImage';
import { IApplicationResponse } from 'common/api/resources/Application';
import * as osImageActions from 'common/modules/osImage/actions';
import * as applicationActions from 'common/modules/application/actions';

interface IImagePresetFormProps {
    onSubmit: () => void;
}

export type ImagePresetFormProps =
    IImagePresetFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const imageTypeButtons = Object.keys(IMAGE_PRESET_TYPE_TRANSLATION_MAP).map((type) => ({
    title: <Translate content={IMAGE_PRESET_TYPE_TRANSLATION_MAP[type]} />,
    value: type as ImagePresetType,
}));

const virtualizationTypeButtons = Object.keys(VIRTUALIZATION_TYPE_TRANSLATION_MAP).map(type => ({
    title: VIRTUALIZATION_TYPE_TRANSLATION_MAP[type],
    value: type as VirtualizationType,
}));

export const ImagePresetForm: React.FC<ImagePresetFormProps> = ({
    item,
    isItemSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    imagePresetActions: {
        unsetImagePresetItem,
        createImagePreset,
        updateImagePreset,
    },
    onSubmit,
    getOsImages,
    getApplications,
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        ...item,
        os_image_version_ids: item.os_image_versions?.map(osImageVersion => osImageVersion.id) || [],
        application_ids: item.applications?.map(application => application.id) || [],
    });

    React.useEffect(() => () => {
        clearFormErrors();
        unsetImagePresetItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleImageTypeChange = (value: ImagePresetType) => {
        setSubmitValues(values => ({
            ...values,
            image_type: value,
            virtualization_type:
                (value === ImagePresetType.APPLICATION)
                    ? VirtualizationType.KVM
                    : values.virtualization_type,
            os_image_version_ids: [],
            application_ids: [],
        }));
    };

    const handleOsImageVersionsChange = (os_image_version_ids: number[]) => setSubmitValues(values => ({
        ...values,
        os_image_version_ids,
    }));

    const handleApplicationsChange = (application_ids: number[]) => setSubmitValues(values => ({
        ...values,
        application_ids,
    }));

    const handleSubmit = async (values: IImagePresetRequest) => {
        switch (values.image_type) {
        case ImagePresetType.OS_IMAGE_VERSION:
            delete values.application_ids;
            break;
        case ImagePresetType.APPLICATION:
            delete values.os_image_version_ids;
            break;
        }

        const errors = validate<IImagePresetRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        item.id
            ? await updateImagePreset(item.id, values)
            : await createImagePreset(values);
        onSubmit();
    };
    return (
        <>
            <Form
                id="imagePresetForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size={SIZE.FILL}
                        label={<Translate content="imagePreset.form.name" />}
                        required={true}
                    />
                    <FormFieldText
                        name="description"
                        multiline={true}
                        size={SIZE.FILL}
                        label={<Translate content="imagePreset.form.description" />}
                    />
                    <FormField
                        name="image_type"
                        label={<Translate content="imagePreset.form.imageType" />}
                        required={true}
                    >
                        <SegmentedControl
                            buttons={imageTypeButtons.map((button) => ({
                                ...button,
                                disabled: !!item.id,
                            }))}
                            selected={submitValues.image_type}
                            onChange={handleImageTypeChange}
                        />
                    </FormField>
                    <FormField
                        name="virtualization_type"
                        label={<Translate content="imagePreset.form.virtualizationType" />}
                        required={true}
                    >
                        <SegmentedControl
                            buttons={virtualizationTypeButtons.map((button) => ({
                                ...button,
                                disabled: !!item.id || (submitValues.image_type === ImagePresetType.APPLICATION && button.value === VirtualizationType.VZ),
                            }))}
                            selected={submitValues.virtualization_type}
                            onChange={(value) => handleFieldChange('virtualization_type', value)}
                        />
                    </FormField>
                    { submitValues.image_type === ImagePresetType.OS_IMAGE_VERSION && (
                        <SelectWithDataLoader
                            name="os_image_version_ids"
                            label="imagePreset.form.osImageVersions"
                            buttonLabel="imagePreset.form.addAllOsImageVersions"
                            loadItems={(params?: IOsImageListRequest) => getOsImages({
                                ...params,
                                filters: {
                                    ...params?.filters,
                                    virtualization_type: submitValues.virtualization_type,
                                },
                            })}
                            mapper={(image: IOsImageResponse) => ({
                                label: image.name,
                                options: image.versions.map((version) => ({
                                    label: version.version,
                                    value: version.id,
                                })),
                            })}
                            onChange={handleOsImageVersionsChange}
                            values={
                                item.os_image_versions?.map(osImageVersion => ({
                                    label: osImageVersion.name,
                                    value: osImageVersion.id,
                                }))
                            }
                            resetItems={item.virtualization_type !== submitValues.virtualization_type}
                            cacheUniqs={[submitValues.virtualization_type]}
                        />
                    )}
                    { submitValues.image_type === ImagePresetType.APPLICATION && (
                        <SelectWithDataLoader
                            name="application_ids"
                            label="imagePreset.form.applications"
                            buttonLabel="imagePreset.form.addAllApplications"
                            loadItems={getApplications}
                            mapper={(application: IApplicationResponse) => ({
                                label: application.name,
                                value: application.id,
                            })}
                            onChange={handleApplicationsChange}
                            disabled={submitValues.virtualization_type === VirtualizationType.VZ}
                            values={
                                item.applications?.map(application => ({
                                    label: application.name,
                                    value: application.id,
                                }))
                            }
                            resetItems={item.virtualization_type !== submitValues.virtualization_type}
                            cacheUniqs={[submitValues.virtualization_type]}
                        />
                    )}
                </Section>
            </Form>
            <Button
                type="submit"
                form="imagePresetForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="imagePreset.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.imagePreset.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.IMAGE_PRESET_ITEM_SAVE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    imagePresetActions: bindActionCreators(imagePresetActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    getOsImages: bindActionCreators(osImageActions.getOsImages, dispatch),
    getApplications: bindActionCreators(applicationActions.getApplications, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagePresetForm);
