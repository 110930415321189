// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    setIn,
    Text,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Loader } from 'common/components';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import { Button } from 'admin/common/components/Button/Button';
import * as computeResourceActions from 'admin/computeResource/actions';
import * as usersActions from 'admin/user/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import { IUserResponse } from 'common/api/resources/User';
import { IMassMailUsersRequest } from 'common/api/resources/MassMailing';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as massMailingActions from 'admin/massMailing/actions';
import { HTTP_CODES } from 'common/api/constants';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import { connect } from 'react-redux';
import {
    IVariable,
    SystemVariablesPopover,
} from 'admin/common/components/SystemVariablesPopover/SystemVariablesPopover';
import {
    ErrorMessage,
    MassMailingFormFooter,
    SelectWithDescription,
} from 'admin/massMailing/containers/Styles';

const defaultValues: IMassMailUsersRequest = {
    related_compute_resource_ids: [],
    user_ids: [],
    message: '',
    subject: '',
};

export type MassMailingProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const MassMailingForm: React.FC<MassMailingProps> = ({
    isSubmittingForm,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    massMailingActions: {
        sendEmailToSelectedUsers,
    },
    computeResourceActions: {
        getComputeResources,
    },
    usersActions: {
        getUsers,
    },
}) => {

    const [submitValues, setSubmitValues] = React.useState(defaultValues);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const emailBodyVariables: IVariable[] = [{
        variable: 'email',
        description: <Translate content="massMailing.form.variables.email" />,
    }];

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: IMassMailUsersRequest) => {

        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        clearFormErrors();
        setErrorMessage('');

        try {
            const rules = {
                message: requiredRule(<Translate content="validate.fieldRequired" />),
            };

            if (values.related_compute_resource_ids.length === 0) {
                rules['user_ids'] = requiredRule(<Translate content="validate.fieldRequired" />);
            }

            if (values.user_ids.length === 0) {
                rules['related_compute_resource_ids'] = requiredRule(<Translate content="validate.fieldRequired" />);
            }

            const errors = validate<IMassMailUsersRequest>(values, rules);

            if (Object.keys(errors).length) {
                setFormErrors(errors);
                return;
            }

            await sendEmailToSelectedUsers(values);
        } catch (e) {
            if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
                if (e.response.data.errors.related_compute_resource_ids) {
                    setErrorMessage(e.response.data.errors.related_compute_resource_ids);
                }

                if (e.response.data.errors.user_ids) {
                    setErrorMessage(e.response.data.errors.user_ids);
                }

                if (e.response.data.errors.message) {
                    setErrorMessage(e.response.data.errors.message);
                }

                if (e.response.data.errors.subject) {
                    setErrorMessage(e.response.data.errors.subject);
                }

                return;
            }

            if (e.response.status >= HTTP_CODES.BAD_REQUEST) {
                setErrorMessage(e.response.data.message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    return (
        <>
            <PageHeader
                isButtonShown={false}
                title={<Translate content="massMailing.form.header"/>}
            />
            <Loader isLoading={false}>
                <Form
                    id="massMailUsersForm"
                    errors={formErrors}
                    values={submitValues}
                    onSubmit={handleSubmit}
                    hideRequiredLegend={true}
                    applyButton={false}
                    cancelButton={false}
                    submitButton={false}
                    vertical={true}
                    onFieldChange={handleFieldChange}
                    style={{ maxWidth: '420px' }}
                >
                    <SelectWithDescription>
                        <SelectWithDataLoader
                            name="related_compute_resource_ids"
                            label="massMailing.form.relatedComputeResources"
                            loadItems={getComputeResources}
                            buttonLabel="massMailing.form.selectAllComputeResources"
                            mapper={(cr: IComputeResourceResponse) => ({
                                label: cr.name,
                                value: cr.id,
                            })}
                            onChange={(related_compute_resource_ids: number[]) => setSubmitValues({
                                ...submitValues,
                                related_compute_resource_ids,
                            })}
                            values={[]}
                        />
                        <Text
                            fontSize={SIZE.SM}
                            intent={INTENT_TYPE.MUTED}
                        >
                            <Translate content="massMailing.form.relatedComputeResourcesDescription" />
                        </Text>
                    </SelectWithDescription>
                    <SelectWithDataLoader
                        name="user_ids"
                        label="massMailing.form.users"
                        loadItems={getUsers}
                        mapper={(user: IUserResponse) => ({
                            label: user.email,
                            value: user.id,
                        })}
                        onChange={(selectedUsers: number[]) => setSubmitValues({
                            ...submitValues,
                            user_ids: selectedUsers,
                        })}
                        values={[]}
                    />
                    <FormFieldText
                        name="subject"
                        label={<Translate content="massMailing.form.subject" />}
                        size={SIZE.FILL}
                    />
                    <FormFieldText
                        name="message"
                        label={<Translate content="massMailing.form.message" />}
                        multiline
                        rows={12}
                        size={SIZE.FILL}
                        description={(
                            <Translate
                                content="massMailing.form.messageTemplateDescription"
                                params={{ twig: <a rel="noreferrer" href="https://twig.symfony.com/doc/3.x/" target="_blank">Twig</a> }}
                            />
                        )}
                    />
                    <MassMailingFormFooter>
                        <Button
                            type="submit"
                            form="massMailUsersForm"
                            fill={false}
                            intent={INTENT_TYPE.PRIMARY}
                            size={SIZE.LG}
                            isLoading={isSubmittingForm}
                        >
                            <Translate content="massMailing.form.submitBtn" />
                        </Button>
                        <SystemVariablesPopover variables={emailBodyVariables} />
                    </MassMailingFormFooter>
                    <Text
                        fontSize={SIZE.SM}
                        intent={INTENT_TYPE.MUTED}
                    >
                        <Translate content="massMailing.form.info" />
                    </Text>
                    <Text>
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    </Text>
                </Form>
            </Loader>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isSubmittingForm: state.app.loadingFlags.has(LOADING_FLAGS.MASS_MAIL_SENDING),
    formErrors: getProcessedErrors(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    massMailingActions: bindActionCreators(massMailingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MassMailingForm);
