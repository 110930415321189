// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import * as osImageActions from 'common/modules/osImage/actions';
import * as osImageVersionActions from 'common/modules/osImage/actionsVersions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as planActions from 'common/modules/plan/actions';
import {
    Form,
    FormField,
    FormFieldSelect,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { IOsImageVersionRequest } from 'common/api/resources/OsImageVersion';
import { cloudInitVersions } from 'common/modules/osImage/constants/versions';
import SelectInput from 'common/components/Select/SelectInput';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { POSITION_INCREMENT } from 'common/helpers/position';
import { FormFieldSwitch } from 'common/components/Form/FormFieldSwitch/FormFieldSwitch';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import {
    VIRTUALIZATION_TYPE_TRANSLATION_MAP,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import {
    IPlanListRequest,
    IPlanResponse,
} from 'common/api/resources/Plan';
import { ISelectOption } from 'common/components';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import { DiskDriver } from 'common/api/resources/ComputeResourceVm';

interface IOsImageVersionFormProps {
    osImage: IOsImageResponse;
    onCreated: () => void;
}

export type OsImageVersionFormProps =
    IOsImageVersionFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const diskDriverOptions = Object.values(DiskDriver).map((value) => (
    <option key={value} value={value}>
        {value}
    </option>
));

export const OsImageVersionForm: React.FC<OsImageVersionFormProps> = ({
    item,
    osImage,
    isItemSaving,
    osImageActions: {
        createOsImageVersion,
    },
    osImageVersionActions: {
        updateOsImageVersion,
    },
    planActions: {
        getPlans,
    },
    formErrors,
    formErrorsActions: {
        setFormErrors,
    },
    isLoadingPlans,
    onCreated,
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        ...item,
        available_plans: item?.available_plans ? item?.available_plans.map(plan => plan.id) : [],
    });
    const isCreateForm = item.id === 0;

    const handleSubmit = async (values: IOsImageVersionRequest) => {
        const rules = {
            version: requiredRule(<Translate content="validate.fieldRequired" />),
            url: requiredRule(<Translate content="validate.fieldRequired" />),
            virtualization_type: requiredRule(<Translate content="validate.fieldRequired" />),
        };

        if (submitValues.virtualization_type === VirtualizationType.KVM) {
            rules['cloud_init_version'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }

        const errors = validate<IOsImageVersionRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            if (isCreateForm) {
                const position = osImage.versions.length > 0
                    ? osImage.versions[osImage.versions.length - 1].position + POSITION_INCREMENT
                    : POSITION_INCREMENT;
                await createOsImageVersion(osImage.id, { ...values, position });
            } else {
                await updateOsImageVersion(item.id, { ...values, position: item.position });
            }

            onCreated();
        } catch (e) {
            throw e;
        }
    };

    const onFieldChange = (key: string, value: string) => setSubmitValues(setIn(submitValues, key, value));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setValueCommand = (setValue: (value: string) => void) => (option: any) => setValue(option.value);
    const getCloudInitVersionValue = (value: string) => cloudInitVersions.find((version) => version.value === value);

    const handleChangeVirtualizationType = (option: VirtualizationType) => {
        setSubmitValues(values => ({
            ...values,
            virtualization_type: option,
            available_plans: [],
        }));
    };

    return (
        <>
            <Form
                id="osImageVersionForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={onFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    {isCreateForm && (
                        <FormField
                            name="virtualization_type"
                            value={submitValues.virtualization_type}
                            label={<Translate content="osImageVersion.virtualizationType" />}
                            required={true}
                        >
                            <SegmentedControl
                                buttons={Object.keys(VIRTUALIZATION_TYPE_TRANSLATION_MAP).map(type => ({
                                    title: VIRTUALIZATION_TYPE_TRANSLATION_MAP[type],
                                    value: type as VirtualizationType,
                                }))}
                                selected={submitValues.virtualization_type}
                                onChange={handleChangeVirtualizationType}
                            />
                        </FormField>
                    )}
                    <FormFieldText
                        size="fill"
                        name="version"
                        label={<Translate content="osImageVersion.version" />}
                        required={true}
                    />
                    <FormFieldText
                        size="fill"
                        name="url"
                        label={<Translate content={
                            submitValues.virtualization_type === VirtualizationType.KVM
                                ? 'osImageVersion.url'
                                : 'osImageVersion.template'
                        } />}
                        placeholder={submitValues.virtualization_type === VirtualizationType.VZ
                            ? 'centos-8-x86_64'
                            : ''
                        }
                        required={true}
                    />
                    {submitValues.virtualization_type === VirtualizationType.KVM && (
                        <FormField
                            name="cloud_init_version"
                            label={<Translate content="osImageVersion.cloudInitVersion" />}
                            required={true}
                        >
                            {({ setValue, getValue, getId }) => (
                                <SelectInput<ISelectOption>
                                    inputId={getId()}
                                    options={cloudInitVersions}
                                    value={getCloudInitVersionValue(getValue())}
                                    onChange={setValueCommand(setValue)}
                                    menuPosition="fixed"
                                />
                            )}
                        </FormField>
                    )}
                    <SelectWithDataLoader
                        name="available_plans"
                        label="osImageVersion.availablePlans"
                        buttonLabel="osImageVersion.addAllPlans"
                        loadItems={(params?: IPlanListRequest) => getPlans({
                            ...params,
                            filters: {
                                ...params?.filters,
                                virtualization_type: submitValues.virtualization_type,
                            },
                        })}
                        mapper={(plan: IPlanResponse) => ({
                            label: plan.name,
                            value: plan.id.toString(),
                        })}
                        onChange={(available_plans: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_plans,
                        }))}
                        values={item?.available_plans?.map(plan => ({
                            label: plan.name,
                            value: plan.id.toString(),
                        }))}
                        resetItems={submitValues.virtualization_type !== item.virtualization_type}
                    />
                    {submitValues.virtualization_type === VirtualizationType.KVM && (
                        <FormFieldSelect
                            size={SIZE.FILL}
                            name="vs_disk_driver"
                            description={<Translate content="osImageVersion.vsDiskDriverDescription" />}
                            clearable={true}
                            label={<Translate content="osImageVersion.vsDiskDriver" />}
                        >
                            {diskDriverOptions}
                        </FormFieldSelect>
                    )}
                    <FormFieldSwitch name="is_visible" label={<Translate content="osImageVersion.visibility"/>} />
                </Section>
            </Form>
            <Button
                type="submit"
                form="osImageVersionForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemSaving}
                disabled={isLoadingPlans}
            >
                <Translate content="osImageVersion.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.osImageVersion.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM),
    isLoadingPlans: state.app.loadingFlags.has(LOADING_FLAGS.PLAN_LIST),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    osImageActions: bindActionCreators(osImageActions, dispatch),
    osImageVersionActions: bindActionCreators(osImageVersionActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    planActions: bindActionCreators(planActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OsImageVersionForm);
