// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { CardItem as UICardItem } from 'common/components/styles/CardItem';
import { COLORS } from 'common/theme';

export const FormDescription = styled.div`
  margin-bottom: 16px;
`;

export const ItemContent = styled.div`
    margin-left: 12px;
    flex: 2 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const ContentName = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LogoContainer = styled.div`
    height: 34px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
        height: 24px !important;
        width: 24px !important;
    }
`;

export const DescriptionText = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #737373;
    display: flex;
    align-content: center;
`;

export const ItemParams = styled.dl`
    display: grid;
    grid-template-columns: fit-content(6ch) 1fr;
    column-gap: 12px;
    row-gap: 8px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
`;

export const ItemParamLabel = styled.dt`
    color: ${COLORS.GREY_80};
    margin: 0;
    padding: 0;
`;

export const ItemParamValue = styled.dd`
    padding: 0;
    margin: 0;
`;

export const CardItem = styled(UICardItem)`
    padding: 12px 20px;
    
    ${ItemParamLabel} {
      opacity: ${props => props.isSelected ? 0.8 : 1};
    }
`;