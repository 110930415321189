// Copyright 2025. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import {
    Form,
    FormField,
    Section,
    Translate,
    FormFieldCheckbox,
    setIn,
    ISegmentedControlButton,
    Hint,
} from '@plesk/ui-library';
import Schedule from 'common/containers/Schedule/Schedule';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as settingsActions from 'common/modules/settings/actions';
import {
    ISchedule,
    ScheduleType,
} from 'common/api/resources/model';
import { ISettingsServerBackup } from 'common/api/resources/Settings';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    requiredRule,
    validate,
} from 'common/validator';
import {
    createChangeHandler,
    createOptionsLoader,
} from 'common/components/Select/helpers';
import {
    computeResources,
    IComputeResourceResponse,
    IShortComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    ISelectOption,
    Tooltip,
} from 'common/components';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { InlineFormField } from 'common/components/Limit/Styles';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import {
    computeResourceVms,
    IShortVmResponse,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';

interface ISettingsFormProps {
    onSubmit: () => void;
}

export type SettingsFormProps =
    ISettingsFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const GlobalVsBackupSettingsForm: React.FC<SettingsFormProps> = ({
    backupSettings,
    formErrors,
    isSaving,
    onSubmit,
    settingsActions: { saveSettings },
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const [values, setValues] = React.useState<ISettingsServerBackup>({
        ...backupSettings,
        compute_resources: backupSettings.compute_resources.map((cr: IShortComputeResourceResponse) => cr.id),
        excluded_servers: backupSettings.excluded_servers.map((vs: IShortVmResponse) => vs.id),
    });
    const [selectedCRs, setSelectedCRs] = React.useState<ISelectOption[]>(
        backupSettings.compute_resources.map((cr: IShortComputeResourceResponse) => ({
            label: cr.name,
            value: cr.id,
        }))
    );
    const [selectedExcludedServers, setSelectedExcludedServers] = React.useState<ISelectOption[]>(
        backupSettings.excluded_servers.map((vs: IShortVmResponse) => ({
            label: vs.name,
            value: vs.id,
            crId: vs.compute_resource!.id,
        }))
    );

    const handleFieldChange = (field: string, value: string) => {
        setValues(setIn(values, field, value));
    };

    const handleScheduleChange = (schedule: ISchedule) => {
        clearFormErrors();
        setValues({
            ...values,
            schedule,
        });
    };

    const loadComputeResourceOptions = createOptionsLoader(
        computeResources.list,
        (cr: IComputeResourceResponse) => ({
            label: cr.name,
            value: cr.id,
        })
    );

    const loadServerOptions = createOptionsLoader(
        computeResourceVms.list,
        (vs: IVmResponse) => ({
            label: vs.name,
            value: vs.id,
            crId: vs.compute_resource!.id,
        }),
        true,
        {
            compute_resource_id: values.compute_resources.length > 0
                ? values.compute_resources
                : -1,
        }
    );

    const handleExcludedServersChange = createChangeHandler(
        setValues,
        setSelectedExcludedServers,
        'excluded_servers'
    );

    const handleComputeResourcesChange = (options: ISelectOption[]) => {
        createChangeHandler(
            setValues,
            setSelectedCRs,
            'compute_resources'
        )(options);

        handleExcludedServersChange(selectedExcludedServers.filter(vs => options.map(o => o.value).includes(vs.crId)));
    };

    const limitButtons: Array<ISegmentedControlButton<boolean>> = [
        {
            title: <Translate content="limitForm.unlimited" />,
            value: false,
            disabled: values.is_incremental_backup_enabled,
            tooltip: values.is_incremental_backup_enabled
                ? <Translate content="backups.settingsForm.backupLimitOnly" />
                : null,
        },
        {
            title: <Translate content="limitForm.limit" />,
            value: true,
        },
    ];

    const handleSubmit = async (newBackupSettings: ISettingsServerBackup) => {
        if (!validateSettings(newBackupSettings)) {
            return;
        }

        await saveSettings({
            server_backup: newBackupSettings,
        }, LOADING_FLAGS.BACKUP_SAVE_SETTINGS);
        onSubmit();
    };

    const validateSettings = (newBackupSettings: ISettingsServerBackup): boolean => {
        if (![ScheduleType.MONTHLY, ScheduleType.WEEKLY].includes(newBackupSettings.schedule.type)) {
            return true;
        }

        const rules = {};
        rules['schedule.days'] = requiredRule(<Translate content="validate.fieldRequired" />);

        const errors = validate(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }

        clearFormErrors();
        return true;
    };

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Section>
                <Form
                    id="settingsForm"
                    footerClassName="hidden"
                    onSubmit={handleSubmit}
                    values={values}
                    errors={formErrors}
                    hideRequiredLegend={true}
                    submitButton={false}
                    cancelButton={false}
                    applyButton={false}
                    vertical={true}
                    onFieldChange={handleFieldChange}
                >
                    <Schedule
                        schedule={values.schedule}
                        fieldName="schedule"
                        onChange={handleScheduleChange}
                        types={[ScheduleType.MONTHLY, ScheduleType.WEEKLY, ScheduleType.DAILY]}
                    />
                    <FormField
                        name="limit[limit]"
                        label={<Translate content="backups.settingsForm.limitLabel" />}
                    >
                        <InlineFormField>
                            <FormField name="limit[is_enabled]">
                                {({ setValue }) => (
                                    <SegmentedControl
                                        buttons={limitButtons}
                                        selected={values.limit.is_enabled}
                                        onChange={setValue}
                                    />
                                )}
                            </FormField>
                            <FormFieldNumber
                                name="limit[limit]"
                                min={1}
                                value={values.limit.limit}
                                disabled={!values.limit.is_enabled}
                            />
                        </InlineFormField>
                    </FormField>
                    <>
                        <Tooltip
                            shown={!values.limit.is_enabled}
                            title={<Translate content="backups.settingsForm.incrementalBackupCantBeEnabled" />}
                        >
                            <FormFieldCheckbox
                                name="is_incremental_backup_enabled"
                                disabled={!values.limit.is_enabled}
                                label={
                                    <>
                                        <Translate content="backups.settingsForm.isIncrementalBackupEnabled" /><br/>
                                        <Hint><Translate content="backups.settingsForm.incrementalBackupHint" /></Hint>
                                    </>
                                }
                            />
                        </Tooltip>
                        <FormFieldNumber
                            label={<Translate content="backups.settingsForm.incrementalCount" />}
                            name="incremental_backups_limit"
                            min={1}
                            disabled={!values.limit.is_enabled || !values.is_incremental_backup_enabled}
                            value={values.incremental_backups_limit}
                        />
                    </>
                    <FormField
                        name="compute_resources"
                        label={<Translate content="backups.settingsForm.computeResources" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                value={selectedCRs}
                                isMulti={true}
                                loadOptions={loadComputeResourceOptions}
                                onChange={handleComputeResourcesChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                            />
                        )}
                    </FormField>
                    <FormField
                        name="excluded_servers"
                        label={<Translate content="backups.settingsForm.excludedServers" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                value={selectedExcludedServers}
                                isMulti={true}
                                loadOptions={loadServerOptions}
                                onChange={handleExcludedServersChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                cacheUniqs={[values.compute_resources]}
                            />
                        )}
                    </FormField>
                </Form>
            </Section>
            <Button
                type="submit"
                form="settingsForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isSaving}
            >
                <Translate content="backups.settingsForm.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    backupSettings: state.settings.server_backup,
    formErrors: nestStringProperties(state)['server_backup'],
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_SAVE_SETTINGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
    formErrorsActions: bindActionCreators({
        ...formErrorsActions,
        setFormErrors: (errors: Record<string, unknown>) =>
            formErrorsActions.setFormErrors({ server_backup: errors }),
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalVsBackupSettingsForm);