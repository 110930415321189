// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_APPLICATION_LIST = 'application/SET_APPLICATION_LIST';
export const APPEND_APPLICATIONS = 'application/APPEND_APPLICATIONS';
export const ADD_APPLICATION_ITEM = 'application/ADD_APPLICATION_ITEM';
export const SET_APPLICATION_ITEM = 'application/SET_APPLICATION_ITEM';
export const UNSET_APPLICATION_ITEM = 'application/UNSET_APPLICATION_ITEM';
export const REMOVE_APPLICATION_ITEM = 'application/REMOVE_APPLICATION_ITEM';
export const UPDATE_APPLICATION_ITEM = 'application/UPDATE_APPLICATION_ITEM';
export const UPDATE_APPLICATION_ITEM_POSITION = 'application/UPDATE_APPLICATION_ITEM_POSITION';
export const CLEAR_DEFAULT = 'application/CLEAR_DEFAULT';
export const SET_APPLICATION_ITEM_IS_LOADING = 'application/SET_APPLICATION_ITEM_IS_LOADING';
export const UNSET_APPLICATION_ITEM_IS_LOADING = 'application/UNSET_APPLICATION_ITEM_IS_LOADING';
export const REMOVE_APPLICATION_ITEMS = 'application/REMOVE_APPLICATION_ITEMS';
export const SET_APPLICATION_ITEM_IS_DELETING = 'application/SET_APPLICATION_ITEM_IS_DELETING';
