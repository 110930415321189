// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IDefaultTemplateListRequest,
    IDefaultTemplateRequest,
    IDefaultTemplateResponse,
} from 'common/api/resources/DefaultTemplate/model';

export const defaultTemplate = {
    list: (params?: IDefaultTemplateListRequest) => api.get<IPaginateApiResponse<IDefaultTemplateResponse[]>>('default_templates', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IDefaultTemplateResponse>>(`default_templates/${id}`),
    remove: (id: number) => api.delete(`default_templates/${id}`),
    create: (data: IDefaultTemplateRequest) => api.post<IApiResponse<IDefaultTemplateResponse>>('default_templates', data),
    update: (id: number, data: IDefaultTemplateRequest) => api.put<IApiResponse<IDefaultTemplateResponse>>(`default_templates/${id}`, data),
};
