// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { NotFoundSvg } from 'common/components/ErrorPages/Styles';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';

interface IInternalErrorProps {
    canSeeActualError: boolean;
}

export type InternalErrorProps =
    IInternalErrorProps &
    ReturnType<typeof mapStateToProps>;

export const InternalError: React.FC<InternalErrorProps> = ({ responseError, canSeeActualError }) => (
    <EmptyView
        title="errorPages.internalError.title"
        description={canSeeActualError ? responseError.error : undefined}
        customIcon={<NotFoundSvg />}
    />
);

const mapStateToProps = (state: ICommonState) => ({
    responseError: state.app.responseError,
});

export default connect(mapStateToProps)(InternalError);
