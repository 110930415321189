// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_OFFER_LIST = 'offer/SET_OFFER_LIST';
export const APPEND_OFFERS = 'offer/APPEND_OFFERS';
export const ADD_OFFER_ITEM = 'offer/ADD_OFFER_ITEM';
export const SET_OFFER_ITEM = 'offer/SET_OFFER_ITEM';
export const UNSET_OFFER_ITEM = 'offer/UNSET_OFFER_ITEM';
export const REMOVE_OFFER_ITEM = 'offer/REMOVE_OFFER_ITEM';
export const UPDATE_OFFER_ITEM = 'offer/UPDATE_OFFER_ITEM';
export const SET_OFFER_ITEM_IS_DELETING = 'offer/SET_OFFER_ITEM_IS_DELETING';
