// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IPlanListRequest,
    IPlanPatchRequest,
    IPlanCreateRequest,
    IPlanResponse,
    PlanUpdateRequest,
} from 'common/api/resources/Plan/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { CancelTokenSource } from 'axios';

export const plans = {
    create: (data: IPlanCreateRequest) => api.post<IApiResponse<IPlanResponse>>('plans', data),
    update: (id: number, data: PlanUpdateRequest) => api.put<IApiResponse<IPlanResponse>>(`plans/${id}`, data),
    list: (params?: IPlanListRequest, cancelToken?: CancelTokenSource) =>
        api.get<IPaginateApiResponse<IPlanResponse[]>>('plans', {
            params: new QueryBuilder(params).build(),
            cancelToken: cancelToken?.token,
        }),
    item: (id: number) => api.get<IApiResponse<IPlanResponse>>(`plans/${id}`),
    remove: (id: number) => api.delete(`plans/${id}`),
    removeBatch: (ids: number[]) => api.delete('plans', { data: { ids } }),
    patch: (id: number, data: IPlanPatchRequest) => api.patch<IApiResponse<IPlanResponse>>(`plans/${id}`, data),
};
