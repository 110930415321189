// Copyright 2025. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'admin/isoImage/constants/types';
import {
    IIsoImageListRequest,
    IIsoImageRequest,
    IIsoImageResponse,
} from 'common/api/resources/IsoImage/model';
import { isoImage } from 'common/api/resources/IsoImage/api';

export const setIsoImageList = createCustomAction(
    types.SET_ISO_IMAGE_LIST,
    (data: IPaginateApiResponse<IIsoImageResponse[]>) => ({ payload: data })
);
export const setIsoImageItem = createCustomAction(
    types.SET_ISO_IMAGE_ITEM,
    (data: IIsoImageResponse) => ({ payload: data })
);
export const unsetIsoImageItem = createCustomAction(types.UNSET_ISO_IMAGE_ITEM);
export const addIsoImageItem = createCustomAction(
    types.ADD_ISO_IMAGE_ITEM,
    (data: IIsoImageResponse) => ({ payload: data })
);
export const updateIsoImageItem = createCustomAction(
    types.UPDATE_ISO_IMAGE_ITEM,
    (data: IIsoImageResponse) => ({ payload: data })
);
export const removeIsoImageItem = createCustomAction(
    types.REMOVE_ISO_IMAGE_ITEM,
    (id: number) => ({ payload: id })
);
export const setIsoImageItemIsDeleting = createCustomAction(
    types.SET_ISO_IMAGE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getIsoImages = (request?: IIsoImageListRequest) => async (dispatch: Dispatch) => {
    const apiCall = () => isoImage.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.ISO_IMAGE_LIST,
        action: setIsoImageList,
        apiCall,
    });
};

export const createIsoImage = (data: IIsoImageRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.ISO_IMAGE_ITEM_SAVE,
    action: addIsoImageItem,
    apiCall: isoImage.create,
    translations: {
        success: 'isoImage.toasts.saved',
    },
});

export const getIsoImage = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: isoImage.item,
    action: setIsoImageItem,
    loadingFlag: LOADING_FLAGS.ISO_IMAGE_ITEM,
});

export const updateIsoImage = (id: number, data: IIsoImageRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: isoImage.update,
    action: updateIsoImageItem,
    loadingFlag: LOADING_FLAGS.ISO_IMAGE_ITEM_SAVE,
    translations: {
        success: 'isoImage.toasts.saved',
    },
});

export const removeIsoImage = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: isoImage.remove,
    setLoadingAction: setIsoImageItemIsDeleting,
    action: removeIsoImageItem,
    loadingFlag: LOADING_FLAGS.ISO_IMAGE_ITEM_REMOVE,
    translations: {
        success: 'isoImage.toasts.deleted',
    },
});
