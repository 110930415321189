// Copyright 2025. WebPros International GmbH. All rights reserved.

export const BACKUP_NODE_LIST_SET = 'backup-node/BACKUP_NODE_LIST_SET';
export const BACKUP_NODE_ADD_ITEM = 'backup-node/BACKUP_NODE_ADD_ITEM';
export const BACKUP_NODE_SET_ITEM = 'backup-node/BACKUP_NODE_SET_ITEM';
export const BACKUP_NODE_UNSET_ITEM = 'backup-node/BACKUP_NODE_UNSET_ITEM';
export const BACKUP_NODE_LIST_APPEND = 'backup-node/BACKUP_NODE_LIST_APPEND';
export const BACKUP_NODE_REMOVE_ITEM = 'backup-node/BACKUP_NODE_REMOVE_ITEM';
export const BACKUP_NODE_UPDATE_ITEM = 'backup-node/BACKUP_NODE_UPDATE_ITEM';
export const SET_BACKUP_NODE_ITEM_IS_DELETING = 'backup-node/SET_BACKUP_NODE_ITEM_IS_DELETING';
