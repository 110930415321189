// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';

export const useIsFirstLoading = (isLoading: boolean): boolean => {
    const loadsCount = React.useRef(0);

    React.useMemo(() => {
        if (isLoading) {
            loadsCount.current++;
        }

        return loadsCount.current;
    }, [isLoading]);

    return isLoading && loadsCount.current === 1;
};
