// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    IOsImageVersionResponse,
    IOsImageVersionRequest,
    IOsImageVersionPatchRequest,
} from 'common/api/resources/OsImageVersion/model';

export const osImageVersions = {
    item: (id: number) => api.get<IApiResponse<IOsImageVersionResponse>>(`os_image_versions/${id}`),
    update: (id: number, data: IOsImageVersionRequest) => api.put<IApiResponse<IOsImageVersionResponse>>(`os_image_versions/${id}`, data),
    patch: (id: number, data: IOsImageVersionPatchRequest) => api.patch<IApiResponse<IOsImageVersionResponse>>(`os_image_versions/${id}`, data),
    remove: (id: number) => api.delete(`os_image_versions/${id}`),
};
