// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_TAG_LIST = 'tag/SET_TAG_LIST';
export const ADD_TAG_ITEM = 'tag/ADD_TAG_ITEM';
export const SET_TAG_ITEM = 'tag/SET_TAG_ITEM';
export const UNSET_TAG_ITEM = 'tag/UNSET_TAG_ITEM';
export const REMOVE_TAG_ITEM = 'tag/REMOVE_TAG_ITEM';
export const UPDATE_TAG_ITEM = 'tag/UPDATE_TAG_ITEM';
export const SET_TAG_ITEM_IS_DELETING = 'tag/SET_TAG_ITEM_IS_DELETING';
export const SET_TAGGABLE_ENTITY = 'tag/SET_TAGGABLE_ENTITY';
export const UNSET_TAGGABLE_ENTITY = 'tag/UNSET_TAGGABLE_ENTITY';
