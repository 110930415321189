// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Grid,
    GridCol,
} from '@plesk/ui-library';
import { LocationCard } from 'common/components/location/LocationCard';
import { PageSubHeader } from 'client/common/components';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { RootState } from 'client/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { loadLocationsOnScroll } from 'common/modules/location/actions';
import { connect } from 'react-redux';
import { SIZE } from 'common/constants';

interface ILocationListProps {
    selectedId: number;
    onItemClicked: (id: number) => void;
    disableLocationWithoutComputeResources: boolean;
}

export type LocationListProps =
    ILocationListProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LocationList: React.FC<LocationListProps> = ({
    locations,
    selectedId,
    onItemClicked,
    loadOnScroll,
    disableLocationWithoutComputeResources,
}) => (
    <>
        <PageSubHeader title="servers.create.location" />
        <InfiniteScroll
            loadMore={loadOnScroll}
            hasMore={!!locations.links.next}
        >
            <Grid minColumnWidth={190} gap={SIZE.LG} style={{ marginBottom: '-24px' }}>
                {locations.data.map((location) => (
                    <GridCol key={location.id}>
                        <LocationCard
                            onItemClicked={onItemClicked}
                            location={location}
                            isSelected={location.id === selectedId}
                            disableLocationWithoutComputeResources={disableLocationWithoutComputeResources}
                        />
                    </GridCol>
                ))}
            </Grid>
        </InfiniteScroll>
    </>
);

const mapStateToProps = (state: RootState) => ({
    locations: state.location.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadOnScroll: bindActionCreators(loadLocationsOnScroll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationList);
