// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { OnboardingStepper } from 'admin/onboarding/components/OnboardingStepper';
import {
    OnboardingHeader,
    OnboardingLead,
    OnboardingContentFooter,
} from 'admin/onboarding/components/Styled';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import { SIZE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { Translate } from '@plesk/ui-library';

export interface IOnboardingStepCreateFirstVmProps {
    onNext: () => void;
}

export const OnboardingStepCreateFirstVm: React.FC<IOnboardingStepCreateFirstVmProps> = ({
    onNext,
}) => {
    const { close } = useOnboarding();

    return (
        <OnboardingStep
            sidebar={(<OnboardingStepper/>)}
        >
            <>
                <OnboardingHeader>
                    <Translate content={'onboarding.clusterSetup.steps.createFirstVm.header'}/>
                </OnboardingHeader>
                <OnboardingLead>
                    <Translate content={'onboarding.clusterSetup.steps.createFirstVm.lead'}/>
                </OnboardingLead>
                <p style={{ marginBottom: '16px' }}>
                    <Translate content={'onboarding.clusterSetup.steps.createFirstVm.paragraphs.0'}/>
                </p>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '16px',
                    marginBottom: '16px',
                }}>
                    <p>
                        <Translate content={'onboarding.clusterSetup.steps.createFirstVm.paragraphs.1'}/>
                    </p>
                    <div>
                        <Button
                            size={SIZE.LG}
                            intent={'primary'}
                            to={'/admin/servers/create'}
                            target={'_blank'}
                        >
                            <Translate content={'onboarding.clusterSetup.steps.createFirstVm.buttons.adminCreateVm'}/>
                        </Button>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '16px',
                }}>
                    <p>
                        <Translate content={'onboarding.clusterSetup.steps.createFirstVm.paragraphs.2'}/>
                    </p>
                    <div>
                        <Button
                            size={SIZE.LG}
                            intent={'primary'}
                            to={'/projects'}
                            target={'_blank'}
                        >
                            <Translate content={'onboarding.clusterSetup.steps.createFirstVm.buttons.userCreateVm'}/>
                        </Button>
                    </div>
                </div>
                <OnboardingContentFooter>
                    <Button
                        size={SIZE.LG}
                        intent={'secondary'}
                        onClick={close}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.createFirstVm.buttons.close'}/>
                    </Button>
                    <Button
                        size={SIZE.LG}
                        intent={'primary'}
                        onClick={onNext}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.createFirstVm.buttons.finish'}/>
                    </Button>
                </OnboardingContentFooter>
            </>
        </OnboardingStep>
    );
};