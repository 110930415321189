// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { ILocationCreateRequest } from 'common/api/resources/Location';
import * as locationActions from 'common/modules/location/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as planActions from 'common/modules/plan/actions';
import { ISelectOption } from 'common/components';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import {
    computeResources,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { IconSelector } from 'admin/icon/components/IconSelector/IconSelector';
import { IconType } from 'common/api/resources/Icon';
import LocationPlaceholder from 'admin/location/components/LocationPlaceholder/LocationPlaceholder';
import { FormFieldSwitch } from 'common/components/Form/FormFieldSwitch/FormFieldSwitch';
import {
    createChangeHandler,
    createOptionsLoader,
} from 'common/components/Select/helpers';
import { IPlanResponse } from 'common/api/resources/Plan';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';

interface ILocationFormProps {
    closeDialog: () => void;
}

export type LocationFormProps =
    ILocationFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LocationForm: React.FC<LocationFormProps> = ({
    closeDialog,
    location: { item },
    errors,
    isItemSaving,
    isLoadingPlans,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    locationActions: { unsetLocationItem, updateLocation, createLocation },
    planActions: { getPlans },
}) => {
    React.useEffect(() => () => {
        clearFormErrors();
        unsetLocationItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [selectedComputeResources, setSelectedComputeResources] = React.useState<ISelectOption[]>(
        item?.compute_resources.map(cr => ({
            label: cr.name,
            value: cr.id.toString(),
        }))
    );
    const [submitValues, setSubmitValues] = React.useState({
        ...item,
        compute_resources: item?.compute_resources.map(cr => cr.id),
        available_plans: item?.available_plans ? item?.available_plans.map(plan => plan.id) : [],
    });

    const handleSubmit = async (values: ILocationCreateRequest) => {
        const rules = validate<ILocationCreateRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            description: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(rules).length) {
            setFormErrors(rules);
            return;
        }

        try {
            item.id ? await updateLocation(item.id, values) : await createLocation(values);
            closeDialog();
        } catch (e) {
            throw e;
        }
    };

    const loadComputeResourceOptions = createOptionsLoader(
        computeResources.list,
        (cr: IComputeResourceResponse) => ({
            label: cr.name,
            value: cr.id.toString(),
        })
    );

    const handleFieldChange = (field: string, value: string|number) => {
        setSubmitValues(setIn(submitValues, field, value));
    };

    const createOnPropertyChange = (field: string) => (value: string|number) => {
        handleFieldChange(field, value);
    };

    const handleComputeResourcesChange = createChangeHandler(
        setSubmitValues,
        setSelectedComputeResources,
        'compute_resources'
    );

    return (
        <>
            <Form
                id="locationForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                values={submitValues}
                errors={errors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        size="fill"
                        name="name"
                        label={<Translate content="location.form.name" />}
                        required={true}
                    />
                    <FormFieldText
                        size="fill"
                        name="description"
                        label={<Translate content="location.form.description" />}
                        required={true}
                    />
                    <FormFieldSwitch
                        name="is_visible"
                        label={<Translate content="location.form.visibility"/>}
                    />
                    <FormField
                        name="icon_id"
                        label={<Translate content="osImage.form.icon" />}
                    >
                        {({ getId }) => (
                            <IconSelector
                                inputId={getId()}
                                placeholder={<LocationPlaceholder />}
                                type={IconType.FLAGS}
                                onChange={createOnPropertyChange('icon_id')}
                                selected={submitValues.icon}
                                menuPosition="fixed"
                            />
                        )}
                    </FormField>
                    <FormField
                        name="compute_resources"
                        label={<Translate content="location.form.computeResources" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                value={selectedComputeResources}
                                isMulti={true}
                                loadOptions={loadComputeResourceOptions}
                                onChange={handleComputeResourcesChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                            />
                        )}
                    </FormField>
                    <SelectWithDataLoader
                        name="available_plans"
                        label="location.form.availablePlans"
                        buttonLabel="location.form.addAllPlans"
                        loadItems={getPlans}
                        mapper={(plan: IPlanResponse) => ({
                            label: plan.name,
                            value: plan.id.toString(),
                        })}
                        onChange={(available_plans: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_plans,
                        }))}
                        values={item?.available_plans?.map(plan => ({
                            label: plan.name,
                            value: plan.id.toString(),
                        }))}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="locationForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemSaving}
                disabled={isLoadingPlans}
            >
                <Translate content="location.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    location: state.location,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_LOCATION_ITEM),
    isLoadingPlans: state.app.loadingFlags.has(LOADING_FLAGS.PLAN_LIST),
    errors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    locationActions: bindActionCreators(locationActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    planActions: bindActionCreators(planActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
