// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IRoleResponse,
    roles,
} from 'common/api/resources/Role';
import {
    ILimitGroupResponse,
    limitGroups,
} from 'common/api/resources/LimitGroup';
import { createOptionsLoader } from 'common/components/Select/helpers';

export const rolesToArrayOfIds = (items: IRoleResponse[]) => items.map((role) => role.id);

export const roleToOption = (role: IRoleResponse) => ({
    label: role.name,
    value: role.id,
});

export const limitGroupToOption = (limitGroup: ILimitGroupResponse) => ({
    label: limitGroup.name,
    value: limitGroup.id,
});

export const loadRolesOptions = createOptionsLoader<IRoleResponse>(roles.list, roleToOption);
export const loadLimitGroupsOptions = createOptionsLoader<ILimitGroupResponse>(limitGroups.list, limitGroupToOption);
