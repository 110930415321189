// Copyright 2025. WebPros International GmbH. All rights reserved.

// cspell:disable-next-line
interface IJWTPayload {
    jti: string;
}

// cspell:disable-next-line
export const parseJwt = (token: string): IJWTPayload => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};