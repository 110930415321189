// Copyright 2025. WebPros International GmbH. All rights reserved.

import { RootState } from 'client/core/store';
import { ComputeResourceVmStatus } from 'common/api/resources/ComputeResourceVm';

export const isServerSectionAvailable = (state: RootState): boolean =>
    ![ComputeResourceVmStatus.NOT_EXIST.toString(), ComputeResourceVmStatus.UNAVAILABLE.toString()].includes(state.project.servers.item.status);

export const isCreatingNewProject = (state: RootState): boolean => {
    const projects = state.project.projects.list.data;

    return projects.length > 0 && projects[0].id === 0;
};
