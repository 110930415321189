// Copyright 2025. WebPros International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import * as React from 'react';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import SelectInput from 'common/components/Select/SelectInput';
import { statusMappings } from 'common/components/VmStatus';
import {
    Translate,
    useTranslate,
} from '@plesk/ui-library';

export interface IComputeResourceVmStatusFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
    value?: ValueType<ISelectRequiredOption>;
}

export const ComputeResourceVmStatusFilter: React.FC<IComputeResourceVmStatusFilterProps> = ({
    onChange,
    value,
}) => {
    const translate = useTranslate();

    const statuses: ISelectRequiredOption[] = React.useMemo(() => Object.entries(statusMappings).map(([key, label]) => ({
        value: key,
        label: translate(label) as string,
    })), [translate]);

    return (
        <FixedFormField data-cy={FILTERS.COMPUTE_RESOURCE_VM_STATUS}>
            <SelectInput<ISelectRequiredOption>
                inputId="compute-resource-vm-status-filter"
                placeholder={<Translate content="filters.computeResourceVmStatus"/>}
                options={statuses}
                isClearable={true}
                onChange={onChange}
                value={value}
                menuPosition="absolute"
                menuPortalTarget={document.body}
            />
        </FixedFormField>
    );
};
