// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'client/core/theme';

export const PriceInfo = styled.div`
  padding: 0 20px;
  >div {
    display: block;
  }
`;

export const TotalPrice = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
`;

export const AdditionalPrice = styled.span`
  font-size: 26px;
  
  > div {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const PriceItem = styled.div`
  > span {
    display: block;
    
    &:first-child {
      font-size: 16px;
      line-height: 16px;
    }
    
    &:last-child {
     font-size: 12px;
     line-height: 14px;
     color: ${COLORS.GREY_80};
     font-weight: normal;
   }
  }
`;

export const PriceTaxes = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${COLORS.GREY_80};
`;

export const TermsAndConditions = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${COLORS.GREY_80};
`;

