// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const PasswordStrengthContainer = styled.span`
    width: 220px;
    margin-top: 5px;
`;

export const PasswordStrengthRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
`;
