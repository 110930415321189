// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { colorBetween } from 'common/helpers/color';
import { COLORS } from 'common/theme';
import {
    ICONS,
    INTENT_TYPE,
    ITEM_VIEW,
} from 'common/constants';
import {
    Item,
    ProgressBar,
} from 'common/components/ServerTabs/components/LimitCard/Styles';
import {
    Alert,
    Icon,
    Translate,
} from '@plesk/ui-library';
import LimitProgress from 'common/components/ServerTabs/components/LimitCard/LimitProgress';
import { IServerLimitResponse } from 'common/api/resources/ComputeResourceVm';

export interface ILimitCardProps {
    limit: IServerLimitResponse;
}

export const LimitCard: React.FC<ILimitCardProps> = ({
    limit,
}) => {
    const progress = limit.limit.is_enabled
        ? Math.min((100 * limit.used) / limit.limit.limit, 100)
        : 0;

    return (
        <Item
            icon={limit.is_exceeded ? (
                <Icon
                    size={24}
                    name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED}
                    intent={INTENT_TYPE.WARNING}
                />
            ) : ''}
            title={<LimitProgress
                limit={limit}
                title={<Translate content={`servers.limits.${limit.name}.title`} />}
                component="h3"
            />}
            view={ITEM_VIEW.CARD}
            footer={
                <div>
                    <ProgressBar
                        color={colorBetween(COLORS.SUCCESS, COLORS.WARNING, progress)}
                        progress={progress}
                    />
                    {limit.is_exceeded ? (
                        <Alert intent={INTENT_TYPE.WARNING}>
                            <Translate content={`servers.limits.${limit.name}.exceededDescription`} />
                        </Alert>
                    ) : <Translate content={`servers.limits.${limit.name}.description`} />}
                </div>
            }
        />
    );
};

export default LimitCard;
