// Copyright 2025. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/project/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';
import {
    ISnapshotRequest,
    ISnapshotResponse,
    snapshots,
} from 'common/api/resources/Snapshot';
import { computeResourceVms } from 'common/api/resources/ComputeResourceVm';

export const addSnapshot = createCustomAction(
    types.ADD_SNAPSHOT,
    (data: ISnapshotResponse) => ({ payload: data })
);
export const removeSnapshotItem = createCustomAction(
    types.REMOVE_SNAPSHOT_ITEM,
    (id: number) => ({ payload: id })
);
export const setItemIsLoading = createCustomAction(
    types.SET_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const unsetItemIsLoading = createCustomAction(
    types.UNSET_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const setSnapshots = createCustomAction(
    types.SET_SNAPSHOTS_LIST,
    (data: IPaginateApiResponse<ISnapshotResponse[]>) => ({ payload: data })
);
export const updateSnapshotItem = createCustomAction(
    types.UPDATE_SNAPSHOT_ITEM_BY_UUID,
    (data: ISnapshotResponse) => ({ payload: data })
);
export const setSnapshotItemIsProcessing = createCustomAction(
    types.SET_SNAPSHOT_ITEM_IS_PROCESSING,
    (id: number) => ({ payload: id })
);
export const unsetSnapshotItemIsProcessing = createCustomAction(
    types.UNSET_SNAPSHOT_ITEM_IS_PROCESSING,
    (id: number) => ({ payload: id })
);

export const getSnapshots = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SNAPSHOTS_LIST));

    try {
        const result = await computeResourceVms.snapshots.list(id);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setSnapshots({
                ...result.data,
                data: result.data.data.map(item => ({
                    ...item,
                    isEdit: false,
                    isLoading: false,
                })),
            }));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SNAPSHOTS_LIST));
    }
};

export const revertSnapshot = (data: ISnapshotResponse) => async (dispatch: Dispatch) => {
    dispatch(setSnapshotItemIsProcessing(data.id));
    dispatch(clearFormErrors());

    try {
        return await snapshots.revert(data.id);
    } catch (e) {
        dispatch(unsetSnapshotItemIsProcessing(data.id));

        throw e;
    }
};

export const createSnapshot = (serverId: number, data: ISnapshotRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SNAPSHOTS_ITEM));
    dispatch(clearFormErrors());

    try {
        const result = await computeResourceVms.snapshots.create(serverId, { name: data.name });

        if (result.status === HTTP_CODES.CREATED) {
            dispatch(addSnapshot({
                ...result.data.data,
                isEdit: false,
            }));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SNAPSHOTS_ITEM));
    }
};

export const removeSnapshot = (data: ISnapshotResponse) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(data.id));

    try {
        const result = await snapshots.remove(data.id);

        if (result.status === HTTP_CODES.OK) {
            dispatch(removeSnapshotItem(data.id));
        }

        return result;
    } finally {
        dispatch(unsetItemIsLoading(data.id));
    }
};
