// Copyright 2025. WebPros International GmbH. All rights reserved.

export const IP_BLOCKS = {
    TOOLBAR: 'ipBlocks-toolbar',
    TABLE: 'ipBlocks-table',
    BATCH_DELETE_BTN: 'ipBlocks-batch-delete',
};

export const IPS = {
    BATCH_DELETE_BTN: 'ips-batch-delete',
};

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
    IPS: 'ips',
};
