// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    HeaderContainer,
    HeaderContent,
    HeaderTitle,
} from 'client/common/styles/Header';
import {
    List,
    Popover,
    Translate,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';
import * as isoImageActions from 'common/modules/isoImage/actions';
import { Loader } from 'common/components';
import {
    ICONS,
    VIEW_TYPE,
} from 'common/constants';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';
import ViewSelect from 'common/components/ViewSelect';
import { CardList } from 'client/common/components/Card';
import { StyledList } from 'client/common/styles/List';
import { setViewType } from 'common/modules/app/viewType/actions';
import { getActionColumnProps } from 'common/helpers/list';
import CellIcon from 'admin/icon/components/CellIcon/CellIcon';
import { Size } from 'common/components/Size/Size';
import { IsoImageCard } from 'client/isoImage/containers/IsoImageCard';
import { IsoImageVisibility } from 'common/api/resources/IsoImage';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { TabsExpander } from 'client/common/styles/Tabs';
import IsoImageForm, { FIELDS } from 'common/containers/IsoImageForm/IsoImageForm';
import { StyledActions } from 'common/components/Actions/Styles';
import { IsoImageEdit } from 'client/isoImage/components/IsoImageEdit';
import { IsoImageDelete } from 'client/isoImage/components/IsoImageDelete';

enum IsoImageTableColumns {
    NAME = 'colName',
    OS_TYPE = 'colOSType',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    SIZE = 'colSize',
    ACTIONS = 'colActions',
}

const columns = [{
    width: '15%',
    key: IsoImageTableColumns.NAME,
    title: <Translate content="isoImage.list.name" />,
}, {
    key: IsoImageTableColumns.OS_TYPE,
    title: <Translate content="isoImage.list.osType" />,
}, {
    key: IsoImageTableColumns.SIZE,
    title: <Translate content="isoImage.list.size" />,
}, getActionColumnProps(),
];

export type IsoImagesProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const IsoImages: React.FC<IsoImagesProps> = ({
    selectedView,
    setSelectedView,
    list,
    isLoadingList,
    isUserLockedOrSuspended: userLockedOrSuspended,
    isoImageActions: {
        getIsoImages,
        setIsoImageItem,
        unsetIsoImageItem,
        removeIsoImage,
    },
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    const handleViewChange = (value: string) => setSelectedView(VIEW_TYPE[value]);

    React.useEffect(() => {
        getIsoImages(/* { filters: { search: 'qwe' } } */);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreate = () => {
        unsetIsoImageItem();
        setPopoverOpened(!isPopoverOpened);
    };

    const handleRemove = (id: number) => async () => {
        await removeIsoImage(id);
    };

    const data = list.data.map((isoImage) => ({
        [IsoImageTableColumns.NAME]: (
            <CellIcon icon={isoImage.icon} name={isoImage.name} />
        ),
        [IsoImageTableColumns.OS_TYPE]: isoImage.os_type,
        [IsoImageTableColumns.SIZE]: (
            <Size size={isoImage.size}/>
        ),
        [IsoImageTableColumns.ACTIONS]: (
            <StyledActions>
                <IsoImageEdit
                    item={isoImage}
                    disabled={isoImage.visibility === IsoImageVisibility.PUBLIC}
                    setIsoImageItem={setIsoImageItem}
                />
                <IsoImageDelete
                    item={isoImage}
                    disabled={isoImage.visibility === IsoImageVisibility.PUBLIC}
                    handleRemove={handleRemove(isoImage.id)}
                />
            </StyledActions>
        ),
        key: isoImage.id.toString(),
    }));

    return (
        <Loader isLoading={isLoadingList}>
            <HeaderContainer>
                <HeaderContent>
                    <HeaderTitle>
                        <h1><Translate content="isoImage.title" /></h1>
                    </HeaderTitle>
                </HeaderContent>
                <Popover
                    onClose={() => setPopoverOpened(!isPopoverOpened)}
                    canCloseOnOutsideClick={false}
                    visible={isPopoverOpened}
                    target={(
                        <HeaderButton
                            isMobile={true}
                            onClick={handleCreate}
                            icon={ICONS.PLUS}
                            tooltip={userLockedOrSuspended}
                            disabled={userLockedOrSuspended}
                        >
                            <Translate content="isoImage.addButton" />
                        </HeaderButton>
                    )}
                >
                    <IsoImageForm
                        onSubmit={() => setPopoverOpened(false)}
                        fields={[
                            FIELDS.NAME,
                            FIELDS.ICON,
                            FIELDS.OS,
                            FIELDS.USE_TLS,
                            FIELDS.URL,
                            FIELDS.CHECKSUM_METHOD,
                            FIELDS.CHECKSUM,
                        ]}
                    />
                </Popover>
            </HeaderContainer>
            <Tabs defaultIndex={0}>
                <TabList>
                    <Tab style={{ display: 'none' }}><Translate content="isoImage.list" /></Tab>
                    <TabsExpander />
                    <ViewSelect
                        selected={selectedView}
                        onChange={handleViewChange}
                    />
                </TabList>
                <TabPanel>
                    {selectedView === VIEW_TYPE.CARD && (
                        <CardList
                            items={list.data}
                            component={(isoImage, key) => (
                                <IsoImageCard
                                    key={isoImage.id}
                                    item={isoImage}
                                    isLoading={false}
                                    setIsoImageItem={setIsoImageItem}
                                    handleRemove={handleRemove(isoImage.id)}
                                />
                            )}
                        />
                    )}
                    {selectedView === VIEW_TYPE.LIST && (
                        <StyledList>
                            <List
                                columns={columns}
                                data={data}
                            />
                        </StyledList>
                    )}
                    {!data.length && (
                        <EmptyView
                            title="isoImage.emptyView.title"
                            icon={ICONS.CD}
                        />
                    )}
                </TabPanel>
            </Tabs>
        </Loader>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
    selectedView: state.app.viewType,
    list: state.isoImage.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.ISO_IMAGE_LIST),
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSelectedView: bindActionCreators(setViewType, dispatch),
    isoImageActions: bindActionCreators(isoImageActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IsoImages));