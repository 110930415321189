// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const LinkText = styled.div`
    color: #737373;
`;

export const InfoBlock = styled.div`
  word-wrap: break-word;
  font-size: 12px;
  line-height: 16px;
`;

export const FieldWithLoader = styled.div`
  .loader {
    position: absolute;
    top: 39px;
    right: 10px;
    z-index: 9999;
  }
`;
