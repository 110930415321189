// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormField,
    Input,
    FormFieldProps,
} from '@plesk/ui-library';
import {
    useEffect,
    useState,
    useRef,
} from 'react';
import { SIZE } from 'common/constants';

export interface IFormFieldEmailProps extends Omit<FormFieldProps, 'size'> {
    size: number | 'sm' | 'md' | 'lg' | 'xl' | 'fill';
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
}

export const FormFieldEmail: React.FC<IFormFieldEmailProps> = ({
    name,
    label,
    size,
    disabled,
    placeholder,
    autoFocus,
    ...props
}) => {
    const [caret, setCaret] = useState(0);
    const ref = useRef<HTMLInputElement>(null);

    // We need to control the caret position because the input was losing the caret position
    // after entering an uppercase character not at the end of the line.
    useEffect(() => {
        ref.current?.setSelectionRange(caret, caret);
    }, [ref, caret]);

    const handleChangeCommand = (command: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
        // Force conversion of user input to lower case
        command(event.currentTarget.value.toLowerCase());
        setCaret(event.target.selectionStart || 0);
    };

    return (
        <FormField
            size={SIZE.FILL}
            name={name}
            label={label}
            {...props}
        >
            {({ setValue, getValue }) => (
                // Cannot use type 'email' because this type does not have caret position properties.
                <Input
                    id={name}
                    innerRef={ref}
                    type="text"
                    name={name}
                    value={getValue()}
                    onChange={handleChangeCommand(setValue)}
                    size={size}
                    autoComplete="username"
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            )}
        </FormField>
    );
};
