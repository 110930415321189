// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as languageActions from 'common/modules/language/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { ILanguageResponse } from 'common/api/resources/Language';
import { initialIconState } from 'admin/icon/reducer';
import {
    appendToPaginated,
    updateAllInPaginated,
    updateInPaginated,
} from 'common/reducers/list';

interface ILanguage {
    list: IPaginateApiResponse<ILanguageResponse[]>;
}

export type LanguageAction = ActionType<typeof languageActions>;
export type LanguageState = Readonly<ILanguage>;

export const initialLanguageState: ILanguageResponse = {
    id: 0,
    name: '',
    locale: '',
    country: '',
    icon: initialIconState,
    is_default: false,
    is_visible: true,
    users_count: 0,
};

export default combineReducers<LanguageState, LanguageAction>({
    list: (state = paginateInitialState, action: LanguageAction) => {
        switch (action.type) {
        case getType(languageActions.setLanguageList):
            return action.payload;
        case getType(languageActions.appendLanguages):
            return appendToPaginated(state, action.payload);
        case getType(languageActions.clearDefault):
            return updateAllInPaginated(state, { is_default: false });
        case getType(languageActions.updateLanguageItem):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
});
