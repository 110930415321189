// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import RFB, { RfbOptions } from '@novnc/novnc/core/rfb';
import KeyTable from '@novnc/novnc/core/input/keysym'; // cspell:disable-line
import { Loader } from 'common/components';
import CopyText from 'common/containers/CopyText/CopyText';
import LocationCell from 'common/components/IconCell/LocationCell';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    getViewableIps,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    AlertContainer,
    VncDialogContainer,
    VncDialogHeader,
    VncDialogHeaderItem,
    VncInstance,
    VncDialogHeaderIpList,
} from 'common/components/VncDialog/Styles';
import {
    Action,
    Alert,
    Translate,
    Icon,
    useTranslate,
} from '@plesk/ui-library';

export interface IVncDialogProps {
    vm: IVmResponse;
    isSettingVncUp: boolean;
    directConnection?: boolean;
}

let failCounter = 0;

export const VncDialog: React.FC<IVncDialogProps> = ({
    vm,
    isSettingVncUp,
    directConnection = false,
}) => {
    const translate = useTranslate();
    const rfbEl = React.useRef<HTMLDivElement>(null);
    const [viewer, setViewer] = React.useState<HTMLCanvasElement>();
    const [errorMessage, setErrorMessage] = React.useState<string | React.ReactNode>();
    const [rfbInstance, setRfbInstance] = React.useState<RFB>();

    const initVnc = React.useCallback(() => {
        if (rfbInstance || !vm.id || isSettingVncUp || !vm.vnc_url || !rfbEl.current) {
            return;
        }

        document.title = `${vm.name} - VNC`;

        try {
            const options: RfbOptions = {
                credentials: {
                    password: vm.settings.vnc_password,
                },
                shared: true,
                showDotCursor: true,
            };

            const instance = new RFB(
                rfbEl.current,
                directConnection
                    ? `wss://${vm.vnc_url}`
                    : `${process.env.REACT_APP_VNC_PROXY_URL}${vm.vnc_url}`,
                options
            );

            instance.addEventListener('connect', onConnect);
            instance.addEventListener('disconnect', onDisconnect);
            instance.scaleViewport = true;
            instance.resizeSession = true;
            instance.focusOnClick = true;

            instance.clipboardPasteFrom = clipboardPasteFrom.bind(instance);

            setRfbInstance(instance);
        } catch (e) {
            setErrorMessage(e.response.data.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vm, rfbInstance, isSettingVncUp]);

    React.useEffect(() => {
        initVnc();

        return () => {
            disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initVnc]);

    const clipboardPasteFrom = function (this: RFB, text: string) {
        if (text === '') {
            return;
        }

        // fixing ctrl-pressed or shift-pressed
        this.sendKey(KeyTable.XK_Control_L, 'ControlLeft', false);
        this.sendKey(KeyTable.XK_Control_R, 'ControlRight', false);
        this.sendKey(KeyTable.XK_Shift_L, 'ShiftLeft', false);
        this.sendKey(KeyTable.XK_Shift_R, 'ShiftRight', false);

        for (let i = 0; i < text.length; i++) {
            if (text.charAt(i).match(/[A-Z!@#$%^&*()_+{}:"<>?~|]/)) {
                this.sendKey(KeyTable.XK_Shift_L, 'ShiftLeft', true);
                this.sendKey(text.charCodeAt(i));
                this.sendKey(KeyTable.XK_Shift_L, 'ShiftLeft', false);
            } else {
                this.sendKey(text.charCodeAt(i));
            }
        }
    };

    const onConnect = function (this: RFB) {
        if (!rfbEl.current) {
            return;
        }

        const canvas = rfbEl.current.getElementsByTagName('canvas')[0];
        canvas.addEventListener('keydown', onKeydown.bind(this));
        document.addEventListener('paste', onPaste.bind(this));
        setViewer(canvas);

        this.focus();
        setErrorMessage('');
        failCounter = 0;
    };

    const disconnect = () => {
        failCounter++;
        if (!rfbInstance) {
            return;
        }

        rfbInstance.disconnect();
        rfbInstance.removeEventListener('connect', onConnect);
        rfbInstance.removeEventListener('disconnect', onDisconnect);
        document.removeEventListener('paste', onPaste.bind(rfbInstance));

        if (viewer) {
            viewer.removeEventListener('keydown', onKeydown.bind(rfbInstance));
        }
    };

    const onDisconnect = function (this: RFB) {
        disconnect();

        if (failCounter < 10) {
            initVnc();
        } else {
            setErrorMessage(<Translate content="vncDialog.connectionFailed"/>);
        }
    };

    const onKeydown = function (this: RFB, event: KeyboardEvent) {
        if (
            (event.ctrlKey && event.code === 'KeyV') // Ctrl+V
            || (event.shiftKey && event.code === 'Insert') // Shift+Insert
        ) {
            event.preventDefault();
            onPaste.apply(this);
        }
    };

    const onPaste = function (this: RFB) {
        this.focus();

        if (navigator.clipboard.readText) {
            navigator.clipboard.readText().then(this.clipboardPasteFrom);
        } else {
            const text = prompt(translate('vncDialog.paste.promptTitle').toString()) || '';
            this.clipboardPasteFrom(text);
        }
    };

    const handleSendCtrlAltDel = () => {
        if (rfbInstance) {
            rfbInstance.sendCtrlAltDel();
        }
    };

    const handlePaste = () => {
        if (rfbInstance) {
            onPaste.apply(rfbInstance);
        }
    };

    return (
        <VncDialogContainer>
            <VncDialogHeader>
                <VncDialogHeaderItem>
                    <img
                        width={16}
                        src={vm.settings.os_image.icon}
                        alt={vm.settings.os_image.name}
                    />
                    {vm.name}
                </VncDialogHeaderItem>
                <VncDialogHeaderItem>
                    <Icon
                        name={ICONS.LOCATION}
                        intent={INTENT_TYPE.INACTIVE}
                    />
                    <VncDialogHeaderIpList>
                        {getViewableIps(vm).map(ip => <CopyText key={ip.id}>{ip.ip}</CopyText>)}
                    </VncDialogHeaderIpList>
                </VncDialogHeaderItem>
                {vm.location && (
                    <VncDialogHeaderItem>
                        <LocationCell location={vm.location} />
                    </VncDialogHeaderItem>
                )}
                <VncDialogHeaderItem>
                    <Icon
                        name={ICONS.USER}
                        intent={INTENT_TYPE.INACTIVE}
                    />
                    {vm.settings.user}
                </VncDialogHeaderItem>
                <VncDialogHeaderItem>
                    <Loader
                        isLoading={isSettingVncUp}
                        text={
                            <div>
                                <Translate content="vncDialog.connecting" />
                            </div>
                        }
                    >
                        <Action onClick={handleSendCtrlAltDel}>Ctrl+Alt+Del</Action>
                        <Action onClick={handlePaste}>Ctrl+V</Action>
                    </Loader>
                </VncDialogHeaderItem>
            </VncDialogHeader>
            {errorMessage && (
                <AlertContainer>
                    <Alert intent="danger">
                        {errorMessage}
                    </Alert>
                </AlertContainer>
            )}
            {!errorMessage && (
                <VncInstance
                    ref={rfbEl}
                    id="noVNC-canvas"
                />
            )}
        </VncDialogContainer>
    );
};
