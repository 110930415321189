// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const SubInputs = styled.div`
  margin-top: -15px;
  margin-left: 30px;
  margin-bottom: 10px;
`;

export const VerticalFiltersContainer = styled.div`
    & > div {
        margin: 0;
    }
`;
