// Copyright 2025. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import * as graphActions from 'common/modules/graph/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { GraphsTab } from 'common/components/ServerTabs/GraphsTab/GraphsTab';

const mapStateToProps = (state: RootState) => ({
    server: state.project.servers.item,
    chartsData: {
        cpu: state.graph.cpu.items.map(item => ({
            x: item.time,
            y: item.load_average,
        })),
        network: state.graph.network.items.map(item => ({
            x: item.time,
            y: item.derivative.read_kb,
            z: item.derivative.write_kb,
        })),
        disks: state.graph.disks.items.map(item => ({
            x: item.time,
            y: item.derivative.read_kb,
            z: item.derivative.write_kb,
        })),
        memory: state.graph.memory.items.map(item => ({
            x: item.time,
            y: item.memory,
        })),
    },
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_SERVER_GRAPHS),
    echoCredentials: `${state.auth.authData.token_type} ${state.auth.authData.access_token}`,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    graphActions: bindActionCreators(graphActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphsTab);
