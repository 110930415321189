// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IImagePresetListRequest,
    IImagePresetRequest,
    IImagePresetResponse,
} from 'common/api/resources/ImagePreset/model';

export const imagePreset = {
    list: (params?: IImagePresetListRequest) => api.get<IPaginateApiResponse<IImagePresetResponse[]>>('image_presets', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IImagePresetResponse>>(`image_presets/${id}`),
    remove: (id: number) => api.delete(`image_presets/${id}`),
    create: (data: IImagePresetRequest) => api.post<IApiResponse<IImagePresetResponse>>('image_presets', data),
    update: (id: number, data: IImagePresetRequest) => api.put<IApiResponse<IImagePresetResponse>>(`image_presets/${id}`, data),
};
