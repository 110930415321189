// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    FC,
    useState,
    useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
    Form,
    FormFieldSelect,
    FormFieldText,
    Section,
    SelectOption,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as vlanActions from 'admin/vlan/actions';
import * as ipBlockActions from 'admin/ipBlock/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    IVlanRequest,
    IVlanResponse,
    VlanMode,
} from 'common/api/resources/Vlan';
import FormFieldTags from 'common/components/Form/FormFieldTags/FormFieldTags';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import { IIpBlockResponse } from 'common/api/resources/IpBlock';
import {
    requiredRule,
    validate,
} from 'common/validator';

export interface IVlanFormProps {
    onSubmit: () => void;
}

export type VlanFormProps =
    & IVlanFormProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

const vlanToRequest = (vlan: IVlanResponse): IVlanRequest => {
    const {
        id,
        ip_blocks,
        ...request
    } = vlan;
    return {
        ...request,
        ip_block_ids: ip_blocks?.map(ipBlock => ipBlock.id),
    };
};

export const VlanForm: FC<VlanFormProps> = ({
    errors: formErrors,
    isItemSaving,
    vlan,
    vlanActions: {
        updateVlan,
        createVlan,
        unsetVlanItem,
    },
    ipBlockActions: {
        getIpBlocks,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    onSubmit,
}) => {
    useEffect(() => {
        clearFormErrors();
    }, [clearFormErrors]);
    const [submitValues, setSubmitValues] = useState<IVlanRequest>(vlanToRequest(vlan));
    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));
    const handleSubmit = async (values: IVlanRequest) => {
        const rules = {
            'name': requiredRule(<Translate content="validate.fieldRequired"/>),
            'tag': requiredRule(<Translate content="validate.fieldRequired" />),
            'mode': requiredRule(<Translate content="validate.fieldRequired" />),
        };

        const errors = validate<IVlanRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        clearFormErrors();

        if (vlan.id === 0) {
            await createVlan(values);
        } else {
            await updateVlan(vlan.id, values);
        }

        onSubmit();
    };

    return (
        <>
            <Form
                id="vlanForm"
                values={submitValues}
                onFieldChange={handleFieldChange}
                onSubmit={handleSubmit}
                footerClassName="hidden"
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
                errors={formErrors}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size={SIZE.FILL}
                        label={<Translate content="vlan.form.name" />}
                        required
                    />
                    <FormFieldText
                        name="tag"
                        size={SIZE.FILL}
                        label={<Translate content="vlan.form.tag" />}
                        required
                        inputProps={{
                            min: 0,
                            max: 4094,
                            pattern: '[0-9]*',
                        }}
                    />
                    <FormFieldSelect
                        size={SIZE.FILL}
                        label={<Translate content="vlan.form.mode" />}
                        required
                        vertical
                        name={'mode'}
                    >
                        {Object.values(VlanMode).map(mode => (
                            <SelectOption key={mode} value={mode}>
                                {mode}
                            </SelectOption>
                        ))}
                    </FormFieldSelect>
                    <FormFieldTags
                        name={'trunks'}
                        size={SIZE.FILL}
                        label={<Translate content="vlan.form.trunks" />}
                        vertical
                        delimiters={[',', 'Enter', ' ']}
                    />
                    <SelectWithDataLoader
                        name="ip_block_ids"
                        label="vlan.form.ipBlocks"
                        loadItems={getIpBlocks}
                        mapper={(location: IIpBlockResponse) => ({
                            label: location.name,
                            value: location.id,
                        })}
                        onChange={(ip_block_ids: number[]) => setSubmitValues(values => ({
                            ...values,
                            ip_block_ids,
                        }))}
                        values={
                            vlan.ip_blocks?.map((location: IIpBlockResponse) => ({
                                label: location.name,
                                value: location.id,
                            }))
                        }
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="vlanForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="vlan.form.saveBtn" />
            </Button>
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    vlan: state.vlan.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.VLAN_ITEM_SAVE),
    errors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vlanActions: bindActionCreators(vlanActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    ipBlockActions: bindActionCreators(ipBlockActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VlanForm);
