// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import { Optional } from 'common/types';

export interface ITagValue {
    id: number;
    value: string;
}

export interface ITagOption {
    tag_full_name: string;
    tag_key_id: number;
    tag_key: string;
    tag_value_id: number;
    tag_value: string;
}

// Should be synced with `backend/api/v1/Tag/Enums/EntityType.php`
export enum EntityType {
    SERVER = 'server',
    TOKEN = 'token',
}

export interface ITagRequest {
    key: string;
    values: Array<Optional<ITagValue, 'id'>>;
}

export interface ITagResponse {
    id: number;
    key: string;
    values: ITagValue[];
    is_deleting?: boolean;
}

export interface IKeyValueTag {
    key: {
        id: number;
        key: string;
    };
    value: {
        id: number;
        value: string;
    };
}

export interface ITaggableEntityResponse {
    id: number|string;
    tags: IKeyValueTag[];
}

export interface ISetEntityTagsRequest {
    id: number|string;
    type: EntityType;
    tag_value_ids: number[];
}

export interface ITagListFilters {
    search?: string;
}

export interface ITagListRequest extends IRequest<ITagListFilters>, IPaginated {}
