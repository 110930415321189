// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    IIsoImageResponse,
    IsoImageVisibility,
} from 'common/api/resources/IsoImage';
import { CardItem } from 'client/common/components/Card';
import { Icon as Img } from 'admin/icon/components/CellIcon/Styles';
import {
    Toolbar,
    ToolbarExpander,
    Translate,
} from '@plesk/ui-library';
import { CutTitle } from 'common/components';
import { IsoImageEdit } from 'client/isoImage/components/IsoImageEdit';
import { IsoImageDelete } from 'client/isoImage/components/IsoImageDelete';
import { IsoImageDescription } from 'common/components/IsoImageDescription/IsoImageDescription';
import { Size } from 'common/components/Size/Size';

export interface IIsoImageCardProps {
    item: IIsoImageResponse;
    isLoading: boolean;
    setIsoImageItem: (item: IIsoImageResponse) => void;
    handleRemove: () => void;
}

export const IsoImageCard: React.FC<IIsoImageCardProps> = ({
    item,
    isLoading,
    setIsoImageItem,
    handleRemove,
}) => {
    const disabled = item.visibility === IsoImageVisibility.PUBLIC;

    return (
        <CardItem
            icon={item.icon?.url && (
                <Img
                    width={32}
                    height={32}
                    src={item.icon.url}
                    alt={item.icon.name}
                />
            )}
            cardTitle={
                <>
                    <CutTitle title={item.name}/>
                    <IsoImageEdit
                        item={item}
                        disabled={disabled}
                        setIsoImageItem={setIsoImageItem}
                    />
                </>
            }
            item={(
                <IsoImageDescription
                    width="250px"
                    url={item.iso_url}
                    useTls={item.use_tls}
                    checksum={item.iso_checksum}
                    showUrlAndChecksum={item.show_url_and_checksum}
                    showTls={item.show_tls}
                />
            )}
            footer={
                <Toolbar>
                    <Translate content="isoImage.size" params={{
                        value: <Size size={item.size} />,
                    }} />
                    <ToolbarExpander/>
                    <IsoImageDelete
                        item={item}
                        disabled={disabled}
                        handleRemove={handleRemove}
                    />
                </Toolbar>
            }
            isLoading={isLoading}
        />
    );
};
