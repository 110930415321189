// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IPlanLimit } from 'common/api/resources/Plan';
import { INTENT_TYPE } from 'common/constants';
import { Usage } from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServerCard/NetworkLimitExceedDetails/Styles';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import {
    Status,
    Text,
} from '@plesk/ui-library';

export interface INetworkLimitExceedDetailsProps {
    limit: IPlanLimit<DataUnit>;
    usage: number;
    text: React.ReactNode;
}

const NetworkLimitExceedDetails: React.FC<INetworkLimitExceedDetailsProps> = ({
    usage,
    limit,
    text,
}) => (
    <>
        <Status intent="warning">
            <Text intent="muted">
                {text}
            </Text>
        </Status>
        <Usage>
            <Text intent={INTENT_TYPE.WARNING} component="h3">
                {convertToDataUnit(usage, limit.unit)} {limit.unit}
            </Text>
            <Text intent="muted" component="h3">
                &nbsp;/ {limit.limit} {limit.unit}
            </Text>
        </Usage>
    </>
);

export default NetworkLimitExceedDetails;
