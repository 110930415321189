// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Header,
    Title,
} from 'client/common/components/PageHeader/Styles';

export interface ITitleProps extends React.HTMLProps<HTMLDivElement> {}

export const PageHeader: React.FC<ITitleProps> = props => {
    const { children } = props;

    return (
        <Header>
            <Title>
                {children}
            </Title>
        </Header>
    );
};
