// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { VIEW_TYPE } from 'common/constants';
import * as actions from 'common/modules/app/viewType/actions';

export type ViewTypeAction = ActionType<typeof actions>;
export type ViewTypeState = VIEW_TYPE;

export default (state: VIEW_TYPE = VIEW_TYPE.CARD, action: ViewTypeAction) => {
    switch (action.type) {
    case getType(actions.setViewType):
        return action.payload;
    default:
        return state;
    }
};
