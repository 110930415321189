// Copyright 2025. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'admin/defaultTemplate/constants/types';
import {
    IDefaultTemplateListRequest,
    IDefaultTemplateRequest,
    IDefaultTemplateResponse,
} from 'common/api/resources/DefaultTemplate/model';
import { defaultTemplate } from 'common/api/resources/DefaultTemplate/api';

export const setDefaultTemplateList = createCustomAction(
    types.SET_DEFAULT_TEMPLATE_LIST,
    (data: IPaginateApiResponse<IDefaultTemplateResponse[]>) => ({ payload: data })
);
export const setDefaultTemplateItem = createCustomAction(
    types.SET_DEFAULT_TEMPLATE_ITEM,
    (data: IDefaultTemplateResponse) => ({ payload: data })
);
export const unsetDefaultTemplateItem = createCustomAction(types.UNSET_DEFAULT_TEMPLATE_ITEM);
export const addDefaultTemplateItem = createCustomAction(
    types.ADD_DEFAULT_TEMPLATE_ITEM,
    (data: IDefaultTemplateResponse) => ({ payload: data })
);
export const updateDefaultTemplateItem = createCustomAction(
    types.UPDATE_DEFAULT_TEMPLATE_ITEM,
    (data: IDefaultTemplateResponse) => ({ payload: data })
);
export const removeDefaultTemplateItem = createCustomAction(
    types.REMOVE_DEFAULT_TEMPLATE_ITEM,
    (id: number) => ({ payload: id })
);
export const setDefaultTemplateItemIsDeleting = createCustomAction(
    types.SET_DEFAULT_TEMPLATE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getDefaultTemplates = (request?: IDefaultTemplateListRequest) => async (dispatch: Dispatch) => {
    const apiCall = () => defaultTemplate.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.DEFAULT_TEMPLATE_LIST,
        action: setDefaultTemplateList,
        apiCall,
    });
};

export const createDefaultTemplate = (data: IDefaultTemplateRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.DEFAULT_TEMPLATE_ITEM_SAVE,
    action: addDefaultTemplateItem,
    apiCall: defaultTemplate.create,
    translations: {
        success: 'defaultTemplate.toasts.saved',
    },
});

export const getDefaultTemplate = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: defaultTemplate.item,
    action: setDefaultTemplateItem,
    loadingFlag: LOADING_FLAGS.DEFAULT_TEMPLATE_ITEM,
});

export const updateDefaultTemplate = (id: number, data: IDefaultTemplateRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: defaultTemplate.update,
    action: updateDefaultTemplateItem,
    loadingFlag: LOADING_FLAGS.DEFAULT_TEMPLATE_ITEM_SAVE,
    translations: {
        success: 'defaultTemplate.toasts.saved',
    },
});

export const removeDefaultTemplate = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: defaultTemplate.remove,
    setLoadingAction: setDefaultTemplateItemIsDeleting,
    action: removeDefaultTemplateItem,
    loadingFlag: LOADING_FLAGS.DEFAULT_TEMPLATE_ITEM_REMOVE,
    translations: {
        success: 'defaultTemplate.toasts.deleted',
    },
});
