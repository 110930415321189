// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as authActions from 'common/modules/auth/actions';
import * as solusIOLicenseActions from 'admin/license/solusIO/actions';
import * as solusVmLicenseActions from 'admin/license/solusVM/actions';
import * as metaActions from 'common/modules/app/meta/actions';
import * as updateActions from 'admin/update/actions';
import * as notificationActions from 'admin/notification/actions';
import * as languageActions from 'common/modules/language/actions';
import * as settingsActions from 'common/modules/settings/actions';
import axios,
{ CancelTokenSource } from 'axios';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { LicenseModel } from 'common/api/resources/Settings';

export type StartupProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Startup: React.FC<React.PropsWithChildren<StartupProps>> = ({
    isAuthenticated,
    settingsLoaded,
    licenseModel,
    getUser,
    getUpdateData,
    getMeta,
    getSolusIOLicense,
    getSolusVMLicense,
    getNotifications,
    getLanguages,
    getSettings,
    children,
    favicon,
}) => {
    const [cancelToken, setCancelToken] = React.useState<CancelTokenSource>();
    React.useEffect(() => {
        if (cancelToken) {
            cancelToken.cancel();
        }
        const source = axios.CancelToken.source();
        getMeta();
        getSettings();

        if (isAuthenticated) {
            getNotifications(source);
            getUpdateData(source);
            getUser(source);

            setCancelToken(source);
        }
        // We should update languages every time when login or logout 'cause we
        // may get wrong result if user with manage settings will logout.
        getLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    React.useEffect(() => {
        if (!isAuthenticated || !settingsLoaded) {
            return;
        }

        switch (licenseModel) {
        case LicenseModel.SOLUS_IO:
            getSolusIOLicense();
            break;
        case LicenseModel.SOLUS_VM_V1:
            getSolusVMLicense();
            break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, settingsLoaded, licenseModel]);

    React.useEffect(() => {
        const link = document.querySelector('link[rel~=\'icon\']');
        if (link && link instanceof HTMLLinkElement) {
            if (favicon) {
                link.href = favicon;
            } else if (favicon === null) {
                link.href = '/favicon.ico';
            }
        }

    }, [favicon]);

    return (
        <>
            {children}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuth,
    settingsLoaded: state.app.loadingFlags.has(LOADING_FLAGS.APP_SETTINGS_LOADED),
    licenseModel: state.settings.license?.model,
    favicon: state.settings.theme.favicon,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUser: bindActionCreators(authActions.getUser, dispatch),
    getSolusIOLicense: bindActionCreators(solusIOLicenseActions.getLicense, dispatch),
    getSolusVMLicense: bindActionCreators(solusVmLicenseActions.getLicense, dispatch),
    getUpdateData: bindActionCreators(updateActions.getUpdateData, dispatch),
    getMeta: bindActionCreators(metaActions.getMeta, dispatch),
    getNotifications: bindActionCreators(notificationActions.getNotifications, dispatch),
    getLanguages: bindActionCreators(languageActions.getLanguages, dispatch),
    getSettings: bindActionCreators(settingsActions.getSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Startup);
