// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import {
    Grid,
    GridCol,
    Icon,
    Item,
    Translate,
} from '@plesk/ui-library';
import { OVERVIEW_TAB } from 'client/project/constants/tests';
import {
    HourPrice,
    MonthPrice,
    PackageBox,
    PackageRow,
    ServerParam,
    Wrapper,
} from 'client/project/containers/projectServer/ProjectServerItem/tabs/OverviewTab/Styles';
import { SIZE } from 'common/constants';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';

export type OverviewTabProps = ReturnType<typeof mapStateToProps>;

export const OverviewTab: React.FC<OverviewTabProps> = ({ plan, project }) =>  {
    const planPrice = React.useMemo(() => {
        if (project.token_pricing) {
            return {
                per_month: formatPricePerMonth(plan.tokens_per_month, project.token_pricing),
                per_hour: formatPricePerHour(plan.tokens_per_hour, project.token_pricing),
            };
        }

        return undefined;
    }, [plan, project]);

    return (
        <Wrapper>
            <Grid gap={SIZE.MD} minColumnWidth={350}>
                <GridCol>
                    <Item
                        style={{ minHeight: '175px' }}
                        icon={<Icon size="32" name="package" />}
                        view="card"
                        title={<Translate content="projects.server.package.title"/>}
                    >
                        <PackageBox>
                            {planPrice && (
                                <PackageRow style={{ flex: 1 }}>
                                    <MonthPrice>
                                        {planPrice.per_month}
                                    </MonthPrice>
                                    <div>
                                        <Translate content="projects.server.package.perMonth"/>
                                    </div>
                                    <HourPrice>
                                        {planPrice.per_hour}
                                        <Translate content="projects.server.package.perHour"/>
                                    </HourPrice>
                                </PackageRow>
                            )}
                            <PackageRow style={{ flex: 0.2 }}>
                                <ServerParam style={{ paddingTop: '20px' }}>
                                    <Translate content="projects.server.package.vcpu"/>
                                </ServerParam>
                                <ServerParam>
                                    <Translate content="projects.server.package.ram"/>
                                </ServerParam>
                                <ServerParam>
                                    <Translate content="projects.server.package.disk"/>
                                </ServerParam>
                            </PackageRow>
                            <PackageRow>
                                <div data-cy={OVERVIEW_TAB.CPU} style={{ paddingTop: '20px' }}>{plan.params.vcpu}</div>
                                <div>{convertToDataUnit(plan.params.ram, DataUnit.MiB)} MiB</div>
                                <div>{plan.params.disk} GiB</div>
                            </PackageRow>
                        </PackageBox>
                    </Item>
                </GridCol>
            </Grid>
        </Wrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    plan: state.project.servers.item.plan,
    project: state.project.projects.item,
});

export default connect(mapStateToProps)(OverviewTab);
