// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { ISelectRequiredOption } from 'common/components';
import { ValueType } from 'react-select';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import { FILTERS } from 'admin/common/constants/tests';
import { Translate } from '@plesk/ui-library';
import SelectInput from 'common/components/Select/SelectInput';
import { VIRTUALIZATION_TYPE_TRANSLATION_MAP } from 'common/api/resources/ComputeResource';

export interface IVirtualizationTypeFilterProps {
    onChange?: (option : ValueType<ISelectRequiredOption>) => void;
    value?: ValueType<ISelectRequiredOption>;
}

export const VirtualizationTypeFilter: React.FC<IVirtualizationTypeFilterProps> = ({
    onChange,
    value,
}) => {
    const virtualizationTypes: ISelectRequiredOption[] = Object.keys(VIRTUALIZATION_TYPE_TRANSLATION_MAP).map(type => ({
        label: VIRTUALIZATION_TYPE_TRANSLATION_MAP[type],
        value: type,
    }));

    return (
        <FixedFormField data-cy={FILTERS.VIRTUALIZATION_TYPE}>
            <SelectInput
                inputId="virtualization-type-filter"
                placeholder={<Translate content="filters.virtualizationType"/>}
                options={virtualizationTypes}
                isClearable={true}
                onChange={onChange}
                value={value}
                menuPosition="absolute"
                menuPortalTarget={document.body}
            />
        </FixedFormField>
    );
};

