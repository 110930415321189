// Copyright 2025. WebPros International GmbH. All rights reserved.

import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as clusterImportActions from 'admin/clusterImport/actions';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as React from 'react';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { ICONS } from 'common/constants';
import List from 'common/components/List/List';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import {
    Loader,
    Tooltip,
} from 'common/components';
import { VIRTUALIZATION_TYPE_TRANSLATION_MAP } from 'common/api/resources/ComputeResource';
import { CLUSTER_IMPORT_STATUS } from 'common/api/resources/ClusterImport';

export type ClusterImportComputeResourcesProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    title: <Translate content="clusterImport.computeResources.list.id" />,
    width: '1%',
}, {
    key: 'colName',
    title: <Translate content="clusterImport.computeResources.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colType',
    title: <Translate content="clusterImport.computeResources.list.type" />,
}, {
    key: 'colSourceId',
    title: <Translate content="clusterImport.computeResources.list.sourceId" />,
}, {
    key: 'colServers',
    title: <Translate content="clusterImport.computeResources.list.serverCount" />,
}, {
    key: 'colImportable',
    title: <Translate content="clusterImport.computeResources.list.importable" />,
}, {
    key: 'colIsImported',
    title: <Translate content="clusterImport.computeResources.list.isImported" />,
}];

export const ClusterImportComputeResources: React.FC<ClusterImportComputeResourcesProps> = ({
    id,
    isLoading,
    clusterImport,
    computeResources,
    clusterImportActions: {
        getClusterImport,
        getClusterImportComputeResources,
        runClusterImport,
    },
    history,
}) => {
    const [selection, setSelection] = React.useState<string[]>([]);
    const isFirstLoading = useIsFirstLoading(isLoading);

    const loadPaginated = (page: number) => getClusterImportComputeResources(clusterImport.id, { page });

    React.useEffect(() => {
        if (!clusterImport.id) {
            getClusterImport(id);
        }

        getClusterImportComputeResources(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = computeResources.data.map(item => ({
        colId: item.id,
        colName: item.name,
        colType: VIRTUALIZATION_TYPE_TRANSLATION_MAP[item.type] ?? item.type,
        colSourceId: item.source_id,
        colServers: item.server_count,
        colImportable: item.is_importable ? (
            <Icon name={ICONS.CHECK_MARK} />
        ) : (
            item.not_importable_reasons.length > 0 ? (
                <Tooltip title={item.not_importable_reasons.join(', ')}>
                    <Icon name={ICONS.CROSS_MARK} />
                </Tooltip>
            ) : (
                <Icon name={ICONS.CROSS_MARK} />
            )
        ),
        colIsImported: item.is_imported && (
            <Icon name={ICONS.CHECK_MARK} />
        ),
        disabled: !item.is_importable,
        key: item.id.toString(),
    }));

    const handleRunImport = async () => {
        if (selection.length === 0) {
            return;
        }

        await runClusterImport(clusterImport, selection.map(crId => parseInt(crId, 10)));

        history.push(`${ADMIN_ROUTE_PREFIX}/cluster_imports`);
    };

    return (
        <Loader isLoading={isLoading}>
            <PageHeader
                title={<Translate content="clusterImport.computeResources.title" params={{
                    name: clusterImport.name,
                }}/>}
                isButtonShown={false}
            />
            <List
                isLoading={isLoading}
                isFirstLoading={isFirstLoading}
                loadItems={loadPaginated}
                emptyView={(
                    <EmptyView
                        title="clusterImport.computeResources.emptyView.title"
                        description="clusterImport.computeResources.emptyView.description"
                        icon={ICONS.TRANSFER}
                    />
                )}
                columns={columns}
                selection={selection}
                onSelectionChange={clusterImport.is_ready ? setSelection : undefined}
                data={data}
                meta={computeResources.meta}
                toolbar={(
                    <ButtonWithConfirmation
                        disabled={!clusterImport.is_ready || !selection.length}
                        confirmationButtonGhost={false}
                        confirmationButtonText={(
                            <Translate content="clusterImport.computeResources.runButton.title"/>
                        )}
                        translations={{
                            button: (
                                <Translate content="clusterImport.computeResources.runButton.confirmation.button"/>
                            ),
                            title: (
                                <Translate content="clusterImport.computeResources.runButton.confirmation.title"/>
                            ),
                            tooltip: (
                                <Translate content="clusterImport.computeResources.runButton.tooltip"/>
                            ),
                        }}
                        handleConfirm={handleRunImport}
                        icon={ICONS.START}
                        isLoading={clusterImport.status === CLUSTER_IMPORT_STATUS.IMPORTING}
                    />
                )}
            />
        </Loader>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps<{ id: string }>) => ({
    id: parseInt(ownProps.match.params.id, 10),
    clusterImport: state.clusterImport.item,
    computeResources: state.clusterImport.computeResources,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.CLUSTER_IMPORT_ITEM)
        || state.app.loadingFlags.has(LOADING_FLAGS.CLUSTER_IMPORT_COMPUTE_RESOURCES),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clusterImportActions: bindActionCreators(clusterImportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClusterImportComputeResources);