// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Translate } from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';

interface INotificationPaneContainerProps {
    isOpen: boolean;
}

export const NotificationPaneContainer = styled.div<INotificationPaneContainerProps>`
    ${({ isOpen }) => isOpen ? 'display: flex;' : 'display: none;'};
    position: fixed;

    box-sizing: border-box;
    width: 400px;
    height: calc(100% - 60px);
    top: 61px;
    right: 0;
    background: #fff;
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.33);
    flex-direction: column;

    .pul-button {
        color: ${COLORS.PRIMARY};

        &:hover {
            color: ${COLORS.PRIMARY};
        }
    }
`;

export const NotificationsContent = styled.div`
    flex: 1;
    overflow: auto;
`;

export const HeaderContainer = styled.div`
    display: flex;
    background: ${COLORS.GREY_20};
    padding: 20px 12px 16px 20px;
`;

HeaderContainer.displayName = 'HeaderContainer';

export const HeaderTitle = styled(Translate)`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
`;
