// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import InfiniteScroll  from 'react-infinite-scroller';
import { Loader } from 'common/components';

export interface IInfinityScrollProps extends React.HTMLProps<InfiniteScroll> {
    children: React.ReactNode;
    hasMore: boolean;
    loadMore(page: number): void;
}

const InfinityScroll: React.FC<IInfinityScrollProps> = ({
    children,
    loadMore,
    hasMore,
}) => (
    <InfiniteScroll
        hasMore={hasMore}
        loadMore={loadMore}
        className="infinity-scroll"
        useWindow={true}
        threshold={1}
        loader={
            <Loader
                center={false}
                key={1}
                isLoading={true}
            />
        }
    >
        {children}
    </InfiniteScroll>
);

export default InfinityScroll;
