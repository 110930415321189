// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Dialog as UiLibDialog } from '@plesk/ui-library';

export const StyledDialog = styled(UiLibDialog)<{
    $showPadding?: boolean;
}>`
  background: transparent;
  
  .pul-dialog__content {
    background: #fbfbfb;
    padding: ${({ $showPadding }) => $showPadding === false ? '0' : '30px 40px'};
    overflow-y: auto;

    > .pul-dialog__buttons {
      display: none;
    }
    
    &::-webkit-scrollbar {
      width: 8px;
    }
        
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #C4C4C4 !important;
    }
  }
`;

export const CloseLink = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  transform: translate3d(0, 0, 0);
  
  a {
    color: #737373;
  }
`;
