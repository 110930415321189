// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FigureContent,
    FigureTitle,
} from 'client/project/components/Figure/Styles';

import one from 'client/project/components/Figure/assets/1.svg';
import two from 'client/project/components/Figure/assets/2.svg';
import three from 'client/project/components/Figure/assets/3.svg';
import four from 'client/project/components/Figure/assets/4.svg';
import five from 'client/project/components/Figure/assets/5.svg';
import six from 'client/project/components/Figure/assets/6.svg';
import seven from 'client/project/components/Figure/assets/7.svg';
import eight from 'client/project/components/Figure/assets/8.svg';
import nine from 'client/project/components/Figure/assets/9.svg';

const figures = {
    0: one,
    1: one,
    2: two,
    3: three,
    4: four,
    5: five,
    6: six,
    7: seven,
    8: eight,
    9: nine,
};

interface IFigureProps extends React.HTMLProps<HTMLImageElement> {
    title: string;
    hideTitle?: boolean;
}

export const Figure: React.FC<IFigureProps> = ({
    title,
    hideTitle,
    style,
}) => {
    const figure = title ? figures[title.charCodeAt(0) % 10] : figures[0];

    return (
        <FigureContent url={figure} style={style}>
            {!hideTitle && (<FigureTitle>{title.charAt(0).toUpperCase()}</FigureTitle>)}
        </FigureContent>
    );
};
