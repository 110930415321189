// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    ItemContainer,
    ItemContainerLink,
    ItemContent,
    ItemContentNested,
} from 'admin/menu/components/Navigation/Styles';
import {
    Icon,
    Status,
} from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import { LinkProps } from 'react-router-dom';

export interface IItemProps {
    icon?: ICONS;
    isNested?: boolean;
    isActive?: boolean;
    isHighlighted?: boolean;
    isAlerted?: boolean;
    children?: React.ReactNode;
    to?: LinkProps['to'];
    onClick?: () => void;
}

const Item: React.FC<IItemProps> = ({
    icon,
    isNested,
    isActive,
    isHighlighted,
    isAlerted,
    children,
    onClick,
    to,
}) => {
    const ContainerComponent = to ? ItemContainerLink : ItemContainer;
    const containerProps = to ? { to } : { onClick };

    return (
        // @ts-ignore
        <ContainerComponent
            $isNested={!!isNested}
            $isActive={!!isActive}
            $isHighlighted={!!isHighlighted}
            {...containerProps}
        >
            {
                isNested
                    ? (<>
                        {isActive && <Icon size="12" name="chevron-right" />}
                        <ItemContentNested isActive={!!isActive}>
                            {children}
                        </ItemContentNested>
                    </>)
                    : (<>
                        {icon && <Icon name={icon} />}
                        <ItemContent>
                            {children}
                        </ItemContent>
                        {isAlerted && (
                            <div style={{ marginLeft: '8px' }}>
                                <Status compact={true} intent={INTENT_TYPE.DANGER} />
                            </div>
                        )}
                    </>)
            }
        </ContainerComponent>
    );
};

export default Item;
