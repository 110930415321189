// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const MessageContainer = styled.div`
    display: flex;
    max-width: 640px;
`;

interface IMessageProps {
    error?: boolean;
}

export const Message = styled.div<IMessageProps>`
    border: 1px solid #ececec;
    border-left: 2px solid #28aade;
    position: relative;
    padding: 20px;
    margin: 20px 0;
    flex: 1;
    flex-basis: 380px;
    
    span.close {
       width: 10px;
       color: #999;
       position: absolute;
       right: 0;
       top: 0;
       padding: 10px;
    }

    ${({ error }) => error && `
        border-left-color: #d02d48;
    `}
`;

export const MessageTitle = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
`;

export const MessageContent = styled.div`
    margin-top: 16px;
    font-size: 14px;
    line-height: 20px; 
`;
