// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as applicationActions from 'common/modules/application/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as planActions from 'common/modules/plan/actions';
import { cloudInitVersions } from 'common/modules/osImage/constants/versions';
import {
    ApplicationLoginLinkType,
    IApplicationCreateRequest,
    IApplicationResponse,
} from 'common/api/resources/Application';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { FormLayout } from 'admin/application/components/FormLayout/FormLayout';
import {
    Label,
    Columns,
} from 'admin/application/containers/ApplicationForm/Styles';
import { IconSelector } from 'admin/icon/components/IconSelector/IconSelector';
import {
    RouteComponentProps,
    StaticContext,
} from 'react-router';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import {
    IVariable,
    SystemVariablesPopover,
} from 'admin/common/components/SystemVariablesPopover/SystemVariablesPopover';
import {
    CodeEditor,
    Column,
    Form,
    FormField,
    FormFieldSelect,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { LoginLinkContentPopover } from 'admin/application/components/LoginLinkContentPopover/LoginLinkContentPopover';
import { FormFieldSwitch } from 'common/components/Form/FormFieldSwitch/FormFieldSwitch';
import { IconType } from 'common/api/resources/Icon';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    IExternalIntegrationResponse,
    externalIntegrations,
    ExternalIntegrationType,
} from 'common/api/resources/ExternalIntegration';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { ISelectOption } from 'common/components';
import { FORM_ACTIONS } from 'admin/application/constants/tests';
import { PrefilledPropertiesPopover } from 'admin/application/components/PrefilledPropertiesPopover/PrefilledPropertiesPopover';
import {
    IPlanListRequest,
    IPlanResponse,
} from 'common/api/resources/Plan';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { StyledCodeEditor } from 'admin/common/styles/CodeEditor';

export type ApplicationFormProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const loginLinkTypeTranslations = {
    [ApplicationLoginLinkType.NONE]: <Translate content="application.loginLink.type.none"/>,
    [ApplicationLoginLinkType.URL]: <Translate content="application.loginLink.type.url"/>,
    [ApplicationLoginLinkType.JS_CODE]: <Translate content="application.loginLink.type.jsCode"/>,
    [ApplicationLoginLinkType.INFO]: <Translate content="application.loginLink.type.info"/>,
};

const loginLinkTypeOptions = Object.values(ApplicationLoginLinkType).map((type) => ({
    title: loginLinkTypeTranslations[type],
    value: type as ApplicationLoginLinkType,
}));

const externalIntegrationToSelectOption = (externalIntegration: IExternalIntegrationResponse) => ({
    label: externalIntegration.name,
    value: externalIntegration.id.toString(),
    meta: { type: externalIntegration.type },
});

// Should be synced with backend/api/v1/ComputeResourceVm/DTO/TemplateGlobalVariablesDTO.php
const variables: IVariable[] = [
    {
        variable: 'hostname',
        description: <Translate content="application.variables.hostname" />,
    },
    {
        variable: 'ip',
        description: <Translate content="application.variables.ip" />,
    },
    {
        variable: 'uuid',
        description: <Translate content="application.variables.uuid" />,
    },
    {
        variable: 'address',
        description: <Translate content="application.variables.address" />,
    },
    {
        variable: 'fqdns',
        description: <Translate content="application.variables.fqdns" />,
    },
    {
        variable: 'super_user_password|raw',
        description: <Translate content="application.variables.superUserPassword" />,
    },
];

const getSystemVariablesForExternalIntegration = (type: ExternalIntegrationType): IVariable[] => {
    const values = {
        [ExternalIntegrationType.PLESK]: [
            {
                variable: 'externalIntegrationResponse',
                description: <Translate content="application.variables.plesk.externalIntegrationResponse" />,
            },
            {
                variable: 'externalIntegrationResponse.keyIdentifiers.activationCode',
                description: <Translate content="application.variables.plesk.activationCode" />,
            },
        ],
        [ExternalIntegrationType.CPANEL]: [],
    };

    return values[type];
};

const convertApplicationToRequest = (application: IApplicationResponse, isClone: boolean): IApplicationCreateRequest => ({
    name: isClone ? '' : application.name,
    icon_id: application.icon ? application.icon.id : null,
    url: application.url,
    cloud_init_version: application.cloud_init_version,
    user_data_template: application.user_data_template,
    json_schema: application.json_schema,
    login_link: application.login_link,
    external_integration_id: application.external_integration ? application.external_integration.id : null,
    is_visible: application.is_visible,
    available_plans: application?.available_plans ? application?.available_plans.map(plan => plan.id) : [],
});

export const ApplicationForm: React.FC<ApplicationFormProps> = ({
    applicationId,
    isCloneMode,
    application: { item },
    loadingFlags: { isItemSaving, isLoadingItem, isLoadingPlans },
    history,
    errors,
    formErrorsActions: { clearFormErrors, setFormErrors },
    applicationActions: {
        getApplication,
        unsetApplicationItem,
        updateApplication,
        createApplication,
    },
    planActions: {
        getPlans,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState(convertApplicationToRequest(item, isCloneMode));
    const [systemVariables, setSystemVariables] = React.useState(variables);
    const [selectedExternalIntegration, setSelectedExternalIntegration] = React.useState<ISelectOption | null>(
        item.external_integration ? externalIntegrationToSelectOption(item.external_integration) : null
    );

    React.useEffect(() => {
        if (applicationId > 0) {
            getApplication(applicationId);
        }

        return () => {
            clearFormErrors();
            unsetApplicationItem();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);

    React.useEffect(() => {
        setSubmitValues(convertApplicationToRequest(item, isCloneMode));

        if (item.external_integration) {
            setSelectedExternalIntegration(externalIntegrationToSelectOption(item.external_integration));
            setSystemVariables([
                ...variables,
                ...getSystemVariablesForExternalIntegration(item.external_integration.type),
            ]);
        }
    }, [isCloneMode, item]);

    const handleSubmit = async (values: IApplicationCreateRequest) => {
        const rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            url: requiredRule(<Translate content="validate.fieldRequired" />),
            cloud_init_version: requiredRule(<Translate content="validate.fieldRequired" />),
        };

        if (values.login_link.type !== ApplicationLoginLinkType.NONE) {
            rules['login_link.content'] = requiredRule(<Translate content="validate.fieldRequired"/>);
        }

        const formErrors = validate<IApplicationCreateRequest>(values, rules);

        if (Object.keys(formErrors).length) {
            setFormErrors(formErrors);
            return;
        }

        try {
            !isCloneMode && item.id ? await updateApplication(item.id, values) : await createApplication(values);

            history.push(`${ADMIN_ROUTE_PREFIX}/applications`);
        } catch (e) {
            throw e;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createOnPropertyChange = (property: string) => (value: any) => {
        setSubmitValues((state) => ({
            ...state,
            [property]: value,
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createOnLoginLinkPropertyChange = (property: string) => (value: any) => {
        setSubmitValues((state) => ({
            ...state,
            login_link: {
                ...state.login_link,
                [property]: value,
            },
        }));
    };

    const setApplicationLoginLinkType = (type: ApplicationLoginLinkType) => {
        setSubmitValues({
            ...submitValues,
            login_link: {
                type,
                content: '',
            },
        });
    };

    const getTitle = () => {
        if (isCloneMode) {
            return <Translate content="application.form.titleClone" />;
        }

        if (item.id) {
            return <Translate content="application.form.titleEdit" />;
        }

        return <Translate content="application.form.titleAdd" />;
    };

    const renderLoginLinkContent = () => {
        switch (submitValues.login_link.type) {
        case ApplicationLoginLinkType.JS_CODE:
            return (
                <FormField
                    name="login_link[content]"
                    label={
                        <Label>
                            <Translate content="application.form.loginLink.jsCode" />
                            <LoginLinkContentPopover />
                        </Label>
                    }
                >
                    <StyledCodeEditor height={400}>
                        <CodeEditor
                            mode="javascript"
                            onChange={createOnLoginLinkPropertyChange('content')}
                        >
                            {submitValues.login_link.content}
                        </CodeEditor>
                    </StyledCodeEditor>
                </FormField>
            );

        case ApplicationLoginLinkType.INFO:
            return (
                <FormField
                    name="login_link[content]"
                    label={<Translate content="application.form.loginLink.info" />}
                >
                    <StyledCodeEditor height={400}>
                        <CodeEditor
                            mode="htmlmixed"
                            onChange={createOnLoginLinkPropertyChange('content')}
                        >
                            {submitValues.login_link.content}
                        </CodeEditor>
                    </StyledCodeEditor>
                </FormField>
            );
        }

        return undefined;
    };

    const handleToggleSwitch = () => {
        setSubmitValues({
            ...submitValues,
            is_visible: !submitValues.is_visible,
        });
    };

    const loadExternalIntegrationOptions = createOptionsLoader(
        externalIntegrations.list,
        externalIntegrationToSelectOption
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleExternalIntegrationChange = (option: any) => {
        setSelectedExternalIntegration(option);

        if (!option) {
            setSystemVariables([ ...variables ]);
            setSubmitValues(values => ({
                ...values,
                external_integration_id: null,
            }));

            return;
        }

        setSubmitValues(values => ({
            ...values,
            external_integration_id: option.value,
        }));
        setSystemVariables([
            ...variables,
            ...getSystemVariablesForExternalIntegration(option.meta.type),
        ]);
    };

    return (
        <FormLayout
            title={getTitle()}
            isLoading={isLoadingItem}
            isSaving={isItemSaving}
            submitBtn={{
                text: <Translate content="application.form.saveBtn" />,
                disabled: (!isCloneMode && item.is_buildin) || isLoadingPlans,
                help: !isCloneMode && item.is_buildin
                    ? <Translate content="application.form.buildin_notice" />
                    : undefined,
            }}
        >
            <Form
                id="form"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                values={submitValues}
                errors={errors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        size="fill"
                        name="name"
                        label={<Translate content="application.form.name" />}
                        required={true}
                        onChange={createOnPropertyChange('name')}
                    />
                    <FormField
                        name="icon_id"
                        size="fill"
                        required={true}
                        label={<Translate content="application.form.icon" />}
                    >
                        {({ getId }) => (
                            <IconSelector
                                inputId={getId()}
                                type={IconType.APPLICATION}
                                onChange={createOnPropertyChange('icon_id')}
                                selected={item.icon}
                            />
                        )}
                    </FormField>
                    <FormFieldSelect
                        size="fill"
                        name="cloud_init_version"
                        label={<Translate content="application.form.cloud_init_version" />}
                        required={true}
                        onChange={createOnPropertyChange('cloud_init_version')}
                    >
                        {cloudInitVersions.map((version, key) => (
                            <option key={key} value={version.value}>{version.label}</option>
                        ))}
                    </FormFieldSelect>
                    <FormFieldText
                        size="fill"
                        name="url"
                        label={<Translate content="application.form.url" />}
                        required={true}
                        onChange={createOnPropertyChange('url')}
                    />
                    <FormField
                        name="external_integration_id"
                        label={<Translate content="application.form.externalIntegration" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                isClearable={true}
                                onChange={handleExternalIntegrationChange}
                                value={selectedExternalIntegration}
                                loadOptions={loadExternalIntegrationOptions}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                            />
                        )}
                    </FormField>
                    <SelectWithDataLoader
                        name="available_plans"
                        label="application.form.availablePlans"
                        buttonLabel="application.form.addAllPlans"
                        loadItems={(params?: IPlanListRequest) => getPlans({
                            ...params,
                            filters: {
                                ...params?.filters,
                                virtualization_type: VirtualizationType.KVM,
                            },
                        })}
                        mapper={(plan: IPlanResponse) => ({
                            label: plan.name,
                            value: plan.id.toString(),
                        })}
                        onChange={(available_plans: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_plans,
                        }))}
                        values={item?.available_plans?.map(plan => ({
                            label: plan.name,
                            value: plan.id.toString(),
                        }))}
                    />
                    <FormFieldSwitch
                        name="is_visible"
                        label={<Translate content="application.form.visibility"/>}
                        onChange={handleToggleSwitch}
                    />
                    <FormField
                        name="login_link[type]"
                        required={true}
                        value={submitValues.login_link.type}
                        label={<Translate content="application.form.loginLink.type" />}
                    >
                        <Columns vertical={false}>
                            <Column>
                                <SegmentedControl
                                    buttons={loginLinkTypeOptions}
                                    selected={submitValues.login_link.type}
                                    onChange={setApplicationLoginLinkType}
                                />
                            </Column>
                            <Column fill={true}>
                                {submitValues.login_link.type === ApplicationLoginLinkType.URL && (
                                    <FormFieldText
                                        size="fill"
                                        data-cy={FORM_ACTIONS.CONTENT}
                                        name="login_link[content]"
                                        onChange={createOnLoginLinkPropertyChange('content')}
                                        vertical
                                    />
                                )}
                            </Column>
                        </Columns>
                    </FormField>
                    {renderLoginLinkContent()}
                    <FormField
                        name="user_data_template"
                        label={
                            <Label>
                                <Translate content="application.form.user_data_template" />
                                <SystemVariablesPopover variables={systemVariables} />
                            </Label>
                        }
                    >
                        <StyledCodeEditor height={400}>
                            <CodeEditor
                                mode="yaml"
                                onChange={createOnPropertyChange('user_data_template')}
                                options={{
                                    indentWithTabs: false,
                                    extraKeys: {
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        'Tab'(cm: any) {
                                            cm.replaceSelection('   ', 'end');
                                        },
                                    },
                                }}
                            >
                                {item.user_data_template}
                            </CodeEditor>
                        </StyledCodeEditor>
                    </FormField>
                    <FormField
                        name="json_schema"
                        label={
                            <Label>
                                <Translate content="application.form.json_schema" />
                                <PrefilledPropertiesPopover />
                            </Label>
                        }
                    >
                        <StyledCodeEditor height={400}>
                            <CodeEditor
                                mode="javascript"
                                onChange={createOnPropertyChange('json_schema')}
                            >
                                {/*
                                    There is a bug in CodeEditor from UI Library.
                                    If inner value is null, editor will blocks and
                                    don't allow to change.
                                    https://webpros.atlassian.net/browse/UILIB-856
                                 */}
                                {item.json_schema ? item.json_schema : ''}
                            </CodeEditor>
                        </StyledCodeEditor>
                    </FormField>
                </Section>
            </Form>
        </FormLayout>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps<{ id: string }, StaticContext, { sourceId: string }>) => {
    const id = ownProps.match.params?.id;
    const sourceId = ownProps.location.state?.sourceId;

    return {
        applicationId: Number.parseInt(id, 10) || Number.parseInt(sourceId, 10) || 0,
        isCloneMode: Number.isInteger(sourceId),
        application: state.application,
        loadingFlags: {
            isLoadingPlans: state.app.loadingFlags.has(LOADING_FLAGS.PLAN_LIST),
            isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.APPLICATION_ITEM),
            isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.APPLICATION_ITEM_SAVE),
        },
        errors: state.app.formErrors,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    applicationActions: bindActionCreators(applicationActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    planActions: bindActionCreators(planActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
