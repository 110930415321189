// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_PLAN_LIST = 'plan/SET_PLAN_LIST';
export const APPEND_PLANS = 'plan/APPEND_PLANS';
export const ADD_PLAN_ITEM = 'plan/ADD_PLAN_ITEM';
export const REMOVE_PLAN_ITEM = 'plan/REMOVE_PLAN_ITEM';
export const REMOVE_PLAN_ITEMS = 'plan/REMOVE_PLAN_ITEMS';
export const UPDATE_PLAN_ITEM = 'plan/UPDATE_PLAN_ITEM';
export const CLEAR_DEFAULT = 'plan/CLEAR_DEFAULT';
export const SET_PLAN_ITEM_IS_LOADING = 'plan/SET_PLAN_ITEM_IS_LOADING';
export const UNSET_PLAN_ITEM_IS_LOADING = 'plan/UNSET_PLAN_ITEM_IS_LOADING';
export const SET_PLAN_ITEM_IS_DELETING = 'plan/SET_PLAN_ITEM_IS_DELETING';
export const SET_PLAN_ITEM = 'plan/SET_PLAN_ITEM';
export const UNSET_PLAN_ITEM = 'plan/UNSET_PLAN_ITEM';
export const UPDATE_PLAN_ITEM_POSITION = 'plan/UPDATE_PLAN_ITEM_POSITION';

