// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import * as authActions from 'common/modules/auth/actions';
import * as projectMemberActions from 'client/project/actions/member';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    passwordRule,
    validate,
} from 'common/validator';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { SimpleRegisterContainer } from 'client/auth/containers/SimpleRegisterForm/Styles';
import { Loader } from 'common/components';
import { AlertMessage } from 'client/auth/components/AlertMessage/AlertMessage';
import {
    FieldWithInfo,
    StyledForm,
} from 'common/modules/auth/components/StyledForm';
import {
    Button,
    Form,
    Section,
    Translate,
} from '@plesk/ui-library';
import LogoBox from 'common/modules/auth/components/LogoBox/LogoBox';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import { IRegisterRequest } from 'common/api/resources/Auth';
import LanguageSelector from 'common/containers/LanguageSelector/LanguageSelector';
import { FormFieldEmail } from 'common/components/Form/FormFieldEmail/FormFieldEmail';
import { AuthContainer } from 'common/modules/auth/Styles';

interface IRouteParams {
    id: string;
    token: string;
}

export type SimpleRegisterFormProps =
    RouteComponentProps<IRouteParams> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const SimpleRegisterForm: React.FC<SimpleRegisterFormProps> = ({
    defaultLanguage,
    match: { params: { id, token } },
    responseError,
    authActions: { register },
    projectMemberActions: { joinProject },
    history,
    errors,
    loadingFlags: {
        isSigningUp,
        isJoining,
    },
    formErrorsActions: { setFormErrors },
    isAuth,
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        email: '',
        password: '',
        language_id: defaultLanguage?.id,
    });
    const [selectedLanguage, setSelectedLanguage] = React.useState(defaultLanguage);
    const [isRegistered, setIsRegistered] = React.useState(false);

    React.useEffect(() => {
        setSelectedLanguage(defaultLanguage);
        setSubmitValues(values => ({
            ...values,
            language_id: defaultLanguage?.id,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultLanguage]);

    const handleLanguageChange = (lang: number) => {
        setSubmitValues({
            ...submitValues,
            language_id: lang,
        });
    };

    const createHandleChange = (property: string) => (val: string) => {
        setSubmitValues(values => ({
            ...values,
            [property]: val,
        }));
    };

    React.useEffect(() => {
        const join = async () => {
            const result = await joinProject(parseFloat(id), token);

            if (isAuth) {
                return history.push('/');
            }

            setSubmitValues(values => ({
                ...values,
                email: result?.data.data.email,
            }));
            setIsRegistered(result?.data.data.is_registered);
        };

        join();

        return () => {
            setFormErrors({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegister = async (values: IRegisterRequest) => {
        const validationErrors = validate<IRegisterRequest>(values, {
            password: passwordRule(<Translate content="validate.badPassword" />),
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            values.token = token;

            await register(values);
            history.push('/projects');
        } catch (e) {
            throw e;
        }
    };

    const renderForm = () => {
        if (responseError.error) {
            return  (
                <AlertMessage
                    title={<Translate content="auth.registerSimple.failed" />}
                    message={responseError.error}
                />
            );
        }

        return (
            <StyledForm>
                <Form
                    onSubmit={handleRegister}
                    applyButton={false}
                    values={submitValues}
                    vertical={true}
                    errors={errors}
                    cancelButton={false}
                    hideRequiredLegend={true}
                    submitButton={false}
                >
                    <Section>
                        <FormFieldEmail
                            name="email"
                            disabled={true}
                            size="fill"
                            label={<Translate content="auth.login" />}
                            onChange={createHandleChange('email')}
                        />
                        <FieldWithInfo>
                            <FormFieldPassword
                                size="fill"
                                name="password"
                                label={<Translate content="auth.password" />}
                                description={<Translate content="account.settings.passwordDescription" />}
                                onChange={createHandleChange('password')}
                            />
                        </FieldWithInfo>
                        <LanguageSelector
                            selected={selectedLanguage}
                            onChange={handleLanguageChange}
                            minHeight="40px"
                        />
                        <Button
                            fill={true}
                            intent="primary"
                            type="submit"
                            state={isSigningUp ? 'loading' : undefined}
                        >
                            <Translate content="auth.registerBtn" />
                        </Button>
                    </Section>
                </Form>
            </StyledForm>
        );
    };

    if (isJoining) {
        return <Loader text={<Translate content="auth.registerSimple.joining" />} isLoading={true} />;
    }

    if (isRegistered) {
        return <Redirect to="/" />;
    }

    return (
        <AuthContainer>
            <LogoBox/>
            <SimpleRegisterContainer>
                {renderForm()}
            </SimpleRegisterContainer>
        </AuthContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    defaultLanguage: state.app.meta.defaultLanguage,
    responseError: state.app.responseError,
    isAuth: state.auth.isAuth,
    errors: state.app.formErrors,
    loadingFlags: {
        isSigningUp: state.app.loadingFlags.has(LOADING_FLAGS.REGISTER),
        isJoining: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_MEMBERS_JOIN),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
    projectMemberActions: bindActionCreators(projectMemberActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SimpleRegisterForm));
