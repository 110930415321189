// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    copyToClipboard,
    Form,
    FormFieldText,
    Paragraph,
    Section,
    Translate,
} from '@plesk/ui-library';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import isEmpty from 'validator/lib/isEmpty';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as apiTokenActions from 'common/modules/apiToken/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IApiTokenCreateRequest } from 'common/api/resources/ApiToken';
import { validate } from 'common/validator';
import CopyText from 'common/containers/CopyText/CopyText';
import { PopoverFormContent } from 'client/common/styles/PopoverForm';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';

interface IApiTokenFormProps {
    onCreated: () => void;
}

export type ApiTokenFormProps =
    IApiTokenFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues = {
    name: '',
};

export const ApiTokenForm: React.FC<ApiTokenFormProps> = ({
    onCreated,
    isSaving,
    formErrors,
    apiTokenActions: { createApiToken },
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const [submitValues] = React.useState({ ...defaultValues });
    const [token, setToken] = React.useState<string>();

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IApiTokenCreateRequest) => {
        const validationErrors = validate<IApiTokenCreateRequest>(values, {
            name: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            setToken(await createApiToken(values));
        } catch (e) {
            throw e;
        }
    };

    const handleCopyAndClose = () => {
        if (token) {
            copyToClipboard(token);
            onCreated();
        }
    };

    return (
        <PopoverFormContent>
            {!token && (
                <Section title={<Translate content="account.tokens.addNewToken" />}>
                    <Paragraph>
                        <Translate content="account.tokens.addNewTokenText" />
                    </Paragraph>
                    <Form
                        id="apiTokenForm"
                        footerClassName="hidden"
                        values={submitValues}
                        errors={formErrors}
                        onSubmit={handleSubmit}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        <FormFieldText
                            name="name"
                            placeholder="Name"
                            errors={formErrors}
                            autoFocus={true}
                            size="fill"
                            label={<Translate content="account.tokens.name" />}
                        />
                        <HeaderButton
                            type="submit"
                            isLoading={isSaving}
                            form="apiTokenForm"
                        >
                            <Translate content="account.tokens.add" />
                        </HeaderButton>
                    </Form>
                </Section>
            )}
            {token && (
                <Section title={<Translate content="account.tokens.tokenAdded" />}>
                    <Paragraph>
                        <Translate content="account.tokens.tokenAddedText" />
                    </Paragraph>
                    <CopyText width="100%">{token}</CopyText>
                    <HeaderButton onClick={handleCopyAndClose}>
                        <Translate content="account.tokens.copyAndClose" />
                    </HeaderButton>
                </Section>
            )}
        </PopoverFormContent>
    );
};

const mapStateToProps = (state: RootState) => ({
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_AUTH_API_TOKEN),
    formErrors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenForm);
