// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { IManagementNodeBackupResponse } from 'common/api/resources/ManagementNodeBackup/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';

export const managementNodeBackups = {
    list: (params?: IPaginatedWithSearch) =>
        api.get<IPaginateApiResponse<IManagementNodeBackupResponse[]>>('management_node_backups', {
            params: new QueryBuilder(params).build(),
        }),
    create: () => api.post<IApiResponse<IManagementNodeBackupResponse>>('management_node_backups'),
    restore: (id: number) => api.post(`management_node_backups/${id}/restore`),
    remove: (id: number) => api.delete(`management_node_backups/${id}`),
    removeBatch: (ids: number[]) => api.delete('management_node_backups', { data: { ids } }),
    getNextScheduledDate: () => api.get('management_node_backups/next_scheduled_date'),
};
