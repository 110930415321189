// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import UserData,
{ IUserDataProps } from 'common/components/ServerUserData/UserData';
import { connect } from 'react-redux';
import { RootState as AdminRootState } from 'admin/core/store';
import { RootState as ClientRootState } from 'client/core/store';
import { VirtualizationType } from 'common/api/resources/ComputeResource';

export interface IServerUserDataProps extends IUserDataProps {
    planId?: number;
    osImageVersionId?: number;
    disabledPlanIds?: number[];
    disabledOsImageVersionIds?: number[];
}

export type ServerUserDataProps =
    IServerUserDataProps &
    ReturnType<typeof mapStateToProps>;

export const ServerUserData: React.FC<ServerUserDataProps> = ({
    isSelected,
    isLinux,
    onSetUserData,
    userData,
    planId,
    osImageVersionId,
    plans,
    osImages,
    disabledPlanIds,
    disabledOsImageVersionIds,
}) => {
    const isUserDataDisabled = React.useCallback(() => {
        // Check that selected plan is VZ
        if (plans.some(plan => plan.id === planId && plan.virtualization_type === VirtualizationType.VZ)) {
            return true;
        }

        // Check that exist enabled KVM plan
        if (!plans.some(plan => plan.virtualization_type === VirtualizationType.KVM
            && !disabledPlanIds?.includes(plan.id)
        )) {
            return true;
        }

        // Check that selected OsImage version is VZ
        if (osImages.some(osImage => osImage.versions
            .some(version => version.id === osImageVersionId && version.virtualization_type === VirtualizationType.VZ))
        ) {
            return true;
        }

        // Check that exist enabled KVM OS image version
        return !osImages.some(osImage => osImage.versions
            .some(version =>
                version.virtualization_type === VirtualizationType.KVM
                && !disabledOsImageVersionIds?.includes(version.id)
            )
        );
    }, [osImages, plans, planId, osImageVersionId, disabledPlanIds, disabledOsImageVersionIds]);

    return (
        <UserData
            isSelected={isSelected}
            isLinux={isLinux}
            onSetUserData={onSetUserData}
            userData={userData}
            disabled={isUserDataDisabled()}
        />
    );
};

const mapStateToProps = (state: AdminRootState | ClientRootState) => ({
    plans: state.plan.list.data,
    osImages: state.osImage.list.data,
});

export default connect(mapStateToProps)(ServerUserData);
