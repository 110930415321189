// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RouteComponentProps } from 'react-router';
import {
    Action,
    Popover,
    Toolbar,
    ToolbarGroup,
    Translate,
} from '@plesk/ui-library';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import { IpListType } from 'common/api/resources/IpBlock';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { ICONS } from 'common/constants';
import List from 'common/components/List/List';
import { dataCySelector } from 'common/tests/selectors';
import VpcNetworkAddIpForm from 'common/modules/vpcNetwork/containers/VpcNetworkAddIpForm';
import { StyledActions } from 'common/components/Actions/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { IVpcNetworkIpResponse } from 'common/api/resources/VpcNetwork';
import { TABLE_ACTIONS } from 'common/modules/vpcNetwork/constants/tests';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import { StyledList } from 'client/common/styles/List';
import {
    HeaderContainer,
    HeaderContent,
    HeaderTitle,
} from 'client/common/styles/Header';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';

export type VpcNetworkIpsProps =
    RouteComponentProps<{ id: string }> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum VpcNetworkIpsTableColumns {
    IP = 'colIp',
    SERVER = 'colServer',
    ACTIONS = 'colActions',
}

const columns = [
    {
        key: VpcNetworkIpsTableColumns.IP,
        title: <Translate content="vpcNetwork.ip.list.ip" />,
        cellProps: {
            className: 'cell-bold',
        },
    },
    {
        key: VpcNetworkIpsTableColumns.SERVER,
        title: <Translate content="vpcNetwork.ip.list.server" />,
    },
    getActionColumnProps(),
];

const canBeRemoved = (item: IVpcNetworkIpResponse) => !item.server?.id;

export const VpcNetworkIps: React.FC<VpcNetworkIpsProps> = ({
    isUserLockedOrSuspended: userLockedOrSuspended,
    vpcNetwork: {
        ipList,
        item,
    },
    match: { params },
    loadingFlags: {
        isLoading,
        isLoadingList,
    },
    vpcNetworkActions: {
        getVpcNetwork,
        getVpcNetworkIps,
        removeIpFromVpcNetwork,
        removeIpsFromVpcNetwork,
    },
}) => {
    const vpcNetworkId = parseInt(params.id, 10);
    React.useEffect(() => {
        getVpcNetwork(vpcNetworkId);
        getVpcNetworkIps(vpcNetworkId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadPaginated = (page: number) => getVpcNetworkIps(vpcNetworkId, { page });
    const isFirstLoading = useIsFirstLoading(isLoading);

    const [isPopoverOpened, setPopoverOpened] = React.useState(false);
    const [selection, setSelection] = React.useState<string[]>([]);

    const remove = async (ids: number | number[]) => {
        Array.isArray(ids) ? await removeIpsFromVpcNetwork(item.id, ids) : await removeIpFromVpcNetwork(item.id, ids);
        setSelection([]);

        reloadListData(ipList, loadPaginated);
    };
    const handleItemRemove = (ip: IVpcNetworkIpResponse) => () => remove(ip.id);
    const handleRemoveSelected = () => remove(selection.map((str: string): number => parseInt(str, 10)));

    const data = ipList.data.map((vpcNetworkIp) => ({
        [VpcNetworkIpsTableColumns.IP]: vpcNetworkIp.ip,
        [VpcNetworkIpsTableColumns.SERVER]: vpcNetworkIp.server && (
            <Action
                component={Link}
                to={pathTo(`servers/${vpcNetworkIp.server.id}`)}
            >
                {vpcNetworkIp.server.name}
            </Action>
        ),
        [VpcNetworkIpsTableColumns.ACTIONS]: (
            <StyledActions>
                <ButtonWithConfirmation
                    disabled={!canBeRemoved(vpcNetworkIp) || userLockedOrSuspended}
                    isLoading={isLoading}
                    translations={{
                        title: <Translate content="vpcNetwork.ip.deleteConfirmation.title" />,
                        button: <Translate content="vpcNetwork.ip.deleteConfirmation.button" />,
                        tooltip: <Translate content="vpcNetwork.ip.deleteConfirmation.tooltip" />,
                        text: <Translate content="vpcNetwork.ip.deleteConfirmation.text" />,
                    }}
                    handleConfirm={handleItemRemove(vpcNetworkIp)}
                    data-cy={dataCySelector(vpcNetworkIp.id, 'remove')}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: vpcNetworkIp.id.toString(),
    }));

    return (
        <>
            <HeaderContainer>
                <HeaderContent>
                    <HeaderTitle>
                        <h1>
                            <Translate
                                content="vpcNetwork.ip.title"
                                params={{ vpcNetwork: item.name }}
                            />
                        </h1>
                    </HeaderTitle>
                </HeaderContent>
                {item.list_type === IpListType.SET && (
                    <Popover
                        onClose={() => setPopoverOpened(false)}
                        visible={isPopoverOpened}
                        target={(
                            <HeaderButton
                                onClick={() => setPopoverOpened(true)}
                                icon={ICONS.PLUS}
                                tooltip={userLockedOrSuspended}
                                disabled={userLockedOrSuspended}
                            >
                                <Translate content="vpcNetwork.ip.addBtn" />
                            </HeaderButton>
                        )}
                    >
                        <Loader isLoading={isLoading} center={false}>
                            <VpcNetworkAddIpForm
                                id={item.id}
                                onSubmit={() => {setPopoverOpened(false);}}
                            />
                        </Loader>
                    </Popover>
                )}
            </HeaderContainer>
            <Loader isLoading={isFirstLoading}>
                <StyledList>
                    <List
                        emptyView={
                            item.list_type === IpListType.SET ? (
                                <EmptyView
                                    title="vpcNetwork.ip.emptyView.set.title"
                                    description="vpcNetwork.ip.emptyView.set.description"
                                    buttonText="vpcNetwork.ip.emptyView.set.buttonText"
                                    onButtonClick={() => setPopoverOpened(true)}
                                    icon={ICONS.PLUS}
                                />
                            ) : (
                                <EmptyView
                                    title="vpcNetwork.ip.emptyView.range.title"
                                    description="vpcNetwork.ip.emptyView.range.description"
                                    icon={ICONS.NET}
                                />
                            )
                        }
                        toolbar={(
                            <Toolbar>
                                <ToolbarGroup title="actions">
                                    <ButtonWithConfirmation
                                        data-cy={TABLE_ACTIONS.IPS_BATCH_REMOVE}
                                        disabled={!selection.length}
                                        isLoading={false}
                                        confirmationButtonGhost={false}
                                        confirmationButtonText={<Translate content="vpcNetwork.ip.batchDeleteConfirmation.button" />}
                                        translations={{
                                            title: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.title" />,
                                            button: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.button" />,
                                            tooltip: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.tooltip" />,
                                            text: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.text" />,
                                        }}
                                        handleConfirm={handleRemoveSelected}
                                        icon={ICONS.RECYCLE}
                                    />
                                </ToolbarGroup>
                            </Toolbar>
                        )}
                        columns={columns}
                        data={data}
                        loadItems={loadPaginated}
                        meta={ipList.meta}
                        isLoading={isLoadingList}
                        isFirstLoading={isFirstLoading}
                        selection={selection}
                        onSelectionChange={(items: string[]) => setSelection(items)}
                    />
                </StyledList>
            </Loader>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    vpcNetwork: state.vpcNetwork,
    loadingFlags: {
        isLoading: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_ITEM) || state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_IP_LIST),
        isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_IP_LIST),
    },
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpcNetworkIps);
