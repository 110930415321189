// Copyright 2025. WebPros International GmbH. All rights reserved.

// should be synced with backend/api/v1/Permission/Models/PermissionList.php
export const PERMISSION_LIST = {
    MANAGE_COMPUTE_RESOURCES: 'manage compute resources',
    MANAGE_SERVERS: 'manage servers',
    MANAGE_STORAGE: 'manage storage',
    MANAGE_ROLES: 'manage roles',
    MANAGE_USERS: 'manage users',
    MANAGE_IP_BLOCKS: 'manage IP blocks',
    MANAGE_PLANS: 'manage plans',
    MANAGE_OS_IMAGES: 'manage os images',
    MANAGE_APPLICATIONS: 'manage applications',
    MANAGE_LOCATIONS: 'manage locations',
    MANAGE_APP_SETTINGS: 'manage app settings',
    MANAGE_SSH_KEYS: 'manage ssh keys',
    MANAGE_API_TOKENS: 'manage api tokens',
    MANAGE_LIMIT_GROUPS: 'manage limit groups',
    MANAGE_NOTIFICATIONS: 'manage notifications',
    MANAGE_LICENSES: 'manage licenses',
    MANAGE_BACKUP_NODES: 'manage backup nodes',
    MANAGE_BACKUPS: 'manage backups',
    MANAGE_MANAGEMENT_NODE_BACKUPS: 'manage management node backups',
    MANAGE_ICONS: 'manage icons',
    MANAGE_TASKS: 'manage tasks',
    MANAGE_SERVERS_MIGRATION: 'manage servers migration',
    MANAGE_ACTIVITY_LOGS: 'manage activity logs',
    MANAGE_CLUSTER_IMPORTS: 'manage cluster imports',
    MANAGE_EVENT_HANDLERS: 'manage event handlers',
    MANAGE_ALL_ISO_IMAGES: 'manage all ISO images',
    MANAGE_OWNED_ISO_IMAGES: 'manage owned ISO images',
    MANAGE_OFFERS: 'manage offers',
    MANAGE_TAGS: 'manage tags',
    GET_COMPUTE_RESOURCES: 'get compute resources',
    GET_SERVERS: 'get servers',
    GET_LOCATIONS: 'get locations',
    GET_OS_IMAGES: 'get os images',
    GET_APPLICATIONS: 'get applications',
    GET_DASHBOARD: 'get dashboard',
    GET_LICENSES: 'get licenses',
    GET_APP_SETTINGS: 'get app settings',
    GET_PLANS: 'get plans',
    GET_SSH_KEYS: 'get ssh keys',
    GET_USERS: 'get users',
    GET_IP_BLOCKS: 'get IP blocks',
    GET_ICONS: 'get icons',
    GET_TASKS: 'get tasks',
    GET_BACKUP_NODES: 'get backup nodes',
    GET_BACKUPS: 'get backups',
    GET_MANAGEMENT_NODE_BACKUPS: 'get management node backups',
    GET_ROLES: 'get roles',
    GET_LIMIT_GROUPS: 'get limit groups',
    GET_CLUSTER_IMPORTS: 'get cluster imports',
    GET_EVENT_HANDLERS: 'get event handlers',
    GET_ISO_IMAGES: 'get ISO images',
    GET_OFFERS: 'get offers',
    USAGE: 'usage statistic',
    VIEW_HELP: 'view help',
    VIEW_ADMIN_AREA: 'view admin area',
    ACCESS_BILLING_BYPASS: 'access billing bypass',
    RESIZE_SERVERS: 'resize servers',
    DELETE_SERVERS: 'delete servers',
    CREATE_SERVERS: 'create servers',
    CHANGE_SERVERS_HOSTNAME: 'change servers hostname',
    CHANGE_SERVERS_BOOT_MODE: 'change servers boot mode',
    ADD_ADDITIONAL_IP_ADDRESS: 'add additional IP address',
    DELETE_ADDITIONAL_IP_ADDRESS: 'delete additional IP address',
    CREATE_SERVERS_SNAPSHOT: 'create servers snapshot',
    REVERT_SERVERS_SNAPSHOT: 'revert servers snapshot',
    DELETE_SERVERS_SNAPSHOT: 'delete servers snapshot',
    BOOT_FROM_ISO_IMAGES: 'boot from ISO images',
    CREATE_PROJECTS: 'create projects',
    RESET_ROOT_PASSWORD: 'reset root password',
    START_SERVERS: 'start servers',
    STOP_SERVERS: 'stop servers',
    RESTART_SERVERS: 'restart servers',
    POWER_OFF_SERVERS: 'power off servers',
    OPEN_VNC_CONSOLE: 'open vnc console',
    ADD_ADDITIONAL_DISK: 'add additional disk',
    DELETE_ADDITIONAL_DISK: 'delete additional disk',
    RESIZE_ADDITIONAL_DISK: 'resize additional disk',
    MANAGE_ALL_VPC_NETWORKS: 'manage all VPC networks',
    MANAGE_OWNED_VPC_NETWORKS: 'manage owned VPC networks',
    GET_VPC_NETWORKS: 'get VPC networks',
    GET_COMPUTE_RESOURCE_NAME: 'get compute resource name',
    GET_TAGS: 'get tags',
    MANAGE_IMAGE_PRESETS: 'manage image presets',
    GET_IMAGE_PRESETS: 'get image presets',
    SYNC_IMAGE_PRESETS: 'sync image presets',
    MASS_MAIL_USERS: 'mass mail users',
    ACCESS_CR_IN_MAINTENANCE_MODE: 'access cr in maintenance mode',
};
