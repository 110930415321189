// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    Section,
    Translate,
} from '@plesk/ui-library';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import isEmpty from 'validator/lib/isEmpty';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as projectMemberActions from 'client/project/actions/member';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IProjectMemberRequest } from 'common/api/resources/Project';
import { validate } from 'common/validator';
import { StyledForm } from 'client/common/styles/Form';
import { PopoverFormContent } from 'client/common/styles/PopoverForm';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { FormFieldEmail } from 'common/components/Form/FormFieldEmail/FormFieldEmail';

interface IProjectMemberFormProps {
    projectId: number;
    onCreated: () => void;
}

export type ProjectMemberFormProps =
    IProjectMemberFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues = {
    email: '',
};

export const ProjectMemberForm: React.FC<ProjectMemberFormProps> = ({
    projectId,
    onCreated,
    isSaving,
    formErrors,
    projectMembersActions: { createMember },
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const [submitValues] = React.useState({ ...defaultValues });

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IProjectMemberRequest) => {
        const validationErrors = validate<IProjectMemberRequest>(values, {
            email: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });


        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            await createMember(projectId, values);
            onCreated();
        } catch (e) {
            throw e;
        }
    };

    return (
        <PopoverFormContent>
            <Section title={<Translate content="projects.members.inviteMember" />}>
                <StyledForm>
                    <Form
                        id="projectMemberForm"
                        values={submitValues}
                        errors={formErrors}
                        onSubmit={handleSubmit}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        <FormFieldEmail
                            name="email"
                            placeholder="E-mail"
                            errors={formErrors}
                            autoFocus={true}
                            size="fill"
                            label={null}
                        />
                        <HeaderButton
                            icon="mail"
                            type="submit"
                            isLoading={isSaving}
                            form="projectMemberForm"
                        >
                            <Translate content="projects.members.invite" />
                        </HeaderButton>
                    </Form>
                </StyledForm>
            </Section>
        </PopoverFormContent>
    );
};

const mapStateToProps = (state: RootState) => ({
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_MEMBERS_ITEM),
    formErrors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectMembersActions: bindActionCreators(projectMemberActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMemberForm);
