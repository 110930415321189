// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import * as roleActions from 'admin/role/actions';
import * as permissionActions from 'common/modules/permission/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Loader } from 'common/components';
import {
    List,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    TABLE_ACTIONS,
    ROLES,
} from 'admin/role/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { StyledActions } from 'common/components/Actions/Styles';
import { dataCySelector } from 'common/tests/selectors';
import { Dialog } from 'common/components/Dialog/Dialog';
import RoleForm from 'admin/role/containers/RoleForm/RoleForm';
import Action from 'common/components/Action/Action';
import { History } from 'history';
import { getActionColumnProps } from 'common/helpers/list';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

interface IRoleProps {
    history: History;
}

export type RoleProps =
    IRoleProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IRoleState {
    dialogOpened: boolean;
}

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="role.list.id" />,
}, {
    width: '45%',
    key: 'colName',
    title: <Translate content="role.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colUsers',
    title: <Translate content="role.list.users"/>,
}, getActionColumnProps(),
];

export class Role extends React.Component<RoleProps, IRoleState> {

    state: IRoleState = {
        dialogOpened: false,
    };

    async componentDidMount() {
        const { getRoles } = this.props.roleActions;
        const { getPermissions } = this.props.permissionActions;

        await Promise.all([
            getRoles(),
            // Load permissions for using it RoleForm.
            getPermissions(),
        ]);
    }

    handleRemove = (id: number) => async () => {
        const { removeRole: removeRoleAction } = this.props.roleActions;
        await removeRoleAction(id);
    };

    handlerCreate = () => {
        const { unsetRoleItem: unsetItem } = this.props;
        unsetItem();
        this.setState({ dialogOpened: true });
    };

    handleEdit = (id: number) => () => {
        const { getRole } = this.props.roleActions;
        getRole(id);
        this.setState({ dialogOpened: true });
    };

    closeDialog = () =>
        this.setState({ dialogOpened: false });

    getData() {
        const { data } = this.props.role.list;

        return data.map((role) => ({
            colId: role.id,
            colName: role.name,
            colUsers: (
                <Action
                    component={Link}
                    to={pathTo(`users/?role_id=${role.id}`)}
                    data-cy={dataCySelector(role.id, TABLE_ACTIONS.FILTER)}
                >
                    {role.users_count}
                </Action>
            ),
            colActions: (
                <StyledActions>
                    <Tooltip title={<Translate content="role.tooltip.edit"/>}>
                        <Action
                            disabled={role.is_default}
                            icon="pencil"
                            className="action-icon"
                            onClick={this.handleEdit(role.id)}
                            data-cy={dataCySelector(role.id, TABLE_ACTIONS.EDIT)}
                        />
                    </Tooltip>
                    <ButtonWithConfirmation
                        disabled={role.is_default || !!role.users_count}
                        isLoading={role.is_deleting}
                        translations={{
                            title: (
                                <Translate content="role.buttonWithConfirmation.title" />
                            ),
                            text: (
                                <Translate content="role.buttonWithConfirmation.confirmationText" />
                            ),
                            button: (
                                <Translate content="role.buttonWithConfirmation.button" />
                            ),
                            tooltip: (
                                <Translate content="role.buttonWithConfirmation.tooltip" />
                            ),
                        }}
                        handleConfirm={this.handleRemove(role.id)}
                        data-cy={dataCySelector(role.id, TABLE_ACTIONS.REMOVE)}
                        icon="recycle"
                    />
                </StyledActions>
            ),
            key: role.id.toString(),
        }));
    }

    render() {
        const {
            dialogOpened,
        } = this.state;
        const {
            loadingFlags,
            role: { list, item },
            roleActions: { loadRolesOnScroll },
        } = this.props;
        const data = this.getData();

        return (
            <>
                <PageHeader
                    isButtonShown={data.length > 0}
                    title={<Translate content="role.title"/>}
                    buttonText="role.addBtn"
                    buttonIcon="resource"
                    onButtonClick={this.handlerCreate}
                />
                <StyledTable>
                    <InfiniteScroll
                        loadMore={loadRolesOnScroll}
                        hasMore={!!list.links.next}
                    >
                        <Loader isLoading={loadingFlags.isLoadingList}>
                            <List
                                emptyView={
                                    <EmptyView
                                        title="role.emptyView.title"
                                        description="role.emptyView.description"
                                        buttonText="role.emptyView.buttonText"
                                        onButtonClick={this.handlerCreate}
                                        icon="resource"
                                    />
                                }
                                data-cy={ROLES.TABLE}
                                columns={columns}
                                data={data}
                            />
                        </Loader>
                    </InfiniteScroll>
                </StyledTable>
                <Dialog
                    heading={
                        <Translate
                            content={item.id ? 'role.form.titleEdit' : 'role.form.titleAdd'}
                            params={item.id ? { role: item.name } : {}}
                        />
                    }
                    closeHandler={this.closeDialog}
                    isOpen={dialogOpened}
                    size="xs"
                >
                    <Loader isLoading={loadingFlags.isLoadingItem} center={false}>
                        <RoleForm onSubmit={this.closeDialog} />
                    </Loader>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    role: state.role,
    loadingFlags: {
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.ROLE_ITEM),
        isLoadingList: !state.role.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.ROLE_LIST),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    roleActions: bindActionCreators(roleActions, dispatch),
    permissionActions: bindActionCreators(permissionActions, dispatch),
    unsetRoleItem: bindActionCreators(roleActions.unsetRoleItem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Role);
