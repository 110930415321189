// Copyright 2025. WebPros International GmbH. All rights reserved.

import { StorageType } from 'common/api/resources/StorageType';

export const EDIT_ACTION = 'edit';
export const REMOVE_ACTION = 'remove';
export const STORAGE_FORM_ID = 'storageForm';

export const TRANSLATED_TYPES = {
    [StorageType.NFS]: 'NFS',
};
