// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const MassMailingFormFooter = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  .pul-popover__target {
    margin-left: 16px;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  padding: 0 15px;
  color: ${COLORS.RED};
`;

export const SelectWithDescription = styled.div`
    margin-bottom: 8px;

    .pul-section-item {
        margin-bottom: 0;
    }
`;
