// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IUserResponse } from 'common/api/resources/User';
import { Collapse } from 'react-collapse';
import NavItem from 'admin/menu/components/Navigation/NavItem';
import NavGroup,
{ INavGroupProps } from 'admin/menu/components/Navigation/NavGroup';
import {
    IMenuItem,
    MenuState,
} from 'common/modules/app/menu/reducer';
import { hasPermissionInRoles } from 'common/modules/permission/selectors';

export interface INavigationProps extends React.HTMLProps<HTMLElement> {
    user: IUserResponse;
    items: MenuState;
    isOpened?: boolean;
    isNested?: boolean;
    onNavigate?: (href: string) => void;
    onNestedToggle?: (ids: number[], isOpened: boolean) => void;
}

export const Navigation: React.FC<INavigationProps> = ({
    user,
    items,
    isOpened,
    isNested,
    onNavigate,
    onNestedToggle,
}) => {
    const renderMenuItems = (): React.ReactNode => items
        .filter((item: IMenuItem): boolean => !item.permission || hasPermissionInRoles(user.roles, ...item.permission))
        .map((item: IMenuItem, key: number) => {
            if (item.subItems && item.subItems.length > 0) {
                const navGroupProps: INavGroupProps = {
                    user,
                    item,
                    isNested,
                    onNestedNavigate: onNavigate,
                };

                const id = item.id;

                if (onNestedToggle && id) {
                    navGroupProps.onClick = () => {
                        onNestedToggle([id], !item.isOpened);
                    };

                    navGroupProps.onChevronClick = (e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onNestedToggle([id], !item.isOpened);
                    };

                    navGroupProps.onNestedToggle = (ids: number[], isActive: boolean) => {
                        onNestedToggle([
                            key,
                            ...ids,
                        ], isActive);
                    };
                }

                return (
                    <NavGroup key={key} {...navGroupProps}/>
                );
            }

            return (
                <NavItem
                    key={key}
                    item={item}
                    isNested={isNested}
                />
            );
        });

    return (
        <Collapse style={{
            height: 'auto',
        }} isOpened={!!isOpened}>
            {renderMenuItems()}
        </Collapse>
    );
};

export default Navigation;
