// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as notificationActions from 'admin/notification/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { addToPaginated } from 'common/reducers/list';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import {
    INotificationResponse,
    NOTIFICATION_TYPE,
} from 'common/api/resources/Notification';

interface INotification {
    list: IPaginateApiResponse<INotificationResponse[]>;
}

export type NotificationAction = ActionType<typeof notificationActions>;
export type NotificationState = INotification;

export const initialNotificationItemState: INotificationResponse = {
    id: 0,
    data: {
        id: 0,
        name: '',
        date: '',
        version: '',
        status: COMPUTE_RESOURCE_STATUS.COMMISSIONING,
        compute_resource_vm: {
            id: 1,
            name: 'compute_resource_vm name',
        },
        backup_node_name: '',
        management_node_backup_count: 0,
    },
    type: NOTIFICATION_TYPE.COMPUTE_RESOURCE_STATUS_CHANGE,
};

export default combineReducers<NotificationState, NotificationAction>({
    list: (state = paginateInitialState, action: NotificationAction) => {
        switch (action.type) {
        case getType(notificationActions.setNotificationList):
            return action.payload;
        case getType(notificationActions.appendNotifications):
            return {
                ...state,
                data: [...state.data, ...action.payload.data],
                meta: {
                    ...state.meta,
                    to: state.meta.to + action.payload.data.length,
                },
            };
        case getType(notificationActions.clearNotificationList):
            return { ...paginateInitialState };
        case getType(notificationActions.addNotificationItem):
            return addToPaginated(
                {
                    ...state,
                    meta: {
                        ...state.meta,
                        total: ++state.meta.total,
                        to: ++state.meta.to,
                    },
                },
                action.payload
            );
        case getType(notificationActions.removeNotificationItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
                meta: {
                    ...state.meta,
                    total: --state.meta.total,
                    to: --state.meta.to,
                },
            };
        default:
            return state;
        }
    },
});
