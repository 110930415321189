// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { useInterval } from 'common/hooks/useInterval';
import { ElapsedTimeText } from 'common/modules/auth/components/PasswordLabel/Styles';
import moment from 'moment';

export interface ITimeoutLinkProps extends React.HTMLProps<HTMLDivElement>{
    text: JSX.Element | string;
    from: string;
}

export const RESET_INTERVAL = 60;

const TimeoutLink: React.FC<ITimeoutLinkProps> = ({
    children,
    style,
    text,
    from,
}) => {
    const [elapsed, setElapsed] = React.useState(0);

    React.useEffect(() => {
        if (!from) {
            return;
        }

        const diff = moment().utc().diff(moment.utc(from, 'YYYY-MM-DD HH:mm:ss'), 'seconds');

        if (diff < RESET_INTERVAL) {
            setElapsed(RESET_INTERVAL - diff);
        }
    }, [from]);

    useInterval(() => {
        setElapsed(t => t - 1);
    }, elapsed > 0 ? 1000 : undefined);

    const getFormattedTime = (total: number) => {
        const minutes = Math.floor(total / 60);
        const seconds = total - minutes * 60;

        const format = (value: number) => value < 10 ? `0${value}` : value;

        return `${format(minutes)}:${format(seconds)}`;
    };

    return (
        <div style={style}>
            {elapsed < 1 && children}
            {elapsed > 0 && (
                <>
                    {text}
                    <ElapsedTimeText>
                        {getFormattedTime(elapsed)}
                    </ElapsedTimeText>
                </>
            )}
        </div>
    );
};

export default TimeoutLink;
