// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IpBlockType } from 'common/api/resources/IpBlock';
import {
    ContentName,
    IpTypeItem,
} from 'common/components/IpTypeCard/Styles';

export type IIpTypeCardProps = {
    isSelected: boolean;
    onSelect: (ipBlockTypes: IpBlockType[]) => void;
    disabled?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const IpTypeCard: React.FC<IIpTypeCardProps> = ({
    isSelected,
    onSelect,
    disabled = false,
    children,
}) => (
    <IpTypeItem
        isSelected={isSelected}
        onClick={disabled ? undefined : onSelect}
        isDisabled={disabled}
    >
        <ContentName>
            {children}
        </ContentName>
    </IpTypeItem>
);
