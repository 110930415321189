// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { IAccountUpdateRequest } from 'common/api/resources/Account';
import { updateUserSettings } from 'common/modules/auth/actions';
import {
    Form,
    Section,
    Translate,
    FormFieldText,
    setIn,
    FormField,
} from '@plesk/ui-library';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import PasswordStrength from 'client/account/components/PasswordStrength/PasswordStrength';
import {
    FormContainer,
    InputContainer,
    PasswordContainer,
} from 'client/account/containers/Account/Settings/Styles';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import LanguageSelector from 'common/containers/LanguageSelector/LanguageSelector';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import {
    passwordRule,
    requiredRule,
    validate,
} from 'common/validator';
import { FORM } from 'client/account/constants/test';
import { SIZE } from 'common/constants';

export type SettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Settings: React.FunctionComponent<SettingsProps> = ({
    authUser: { language, email },
    languages,
    updateSettings,
    isSaving,
    formErrors,
    formErrorsActions: {
        clearFormErrors,
        setFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        password: '',
        current_password: '',
        confirm_password: '',
        language_id: language.id,
    });

    const isDisabled = (!submitValues.password || !submitValues.current_password || !submitValues.confirm_password)
        && (submitValues.language_id === language.id);

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: IAccountUpdateRequest) => {
        let rules = {};

        if (values.password) {
            rules['password'] = passwordRule(<Translate content="validate.badPassword" />);
            rules['current_password'] = requiredRule(<Translate content="validate.fieldRequired" />);
            rules['confirm_password'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }

        const validationErrors = validate<IAccountUpdateRequest>(values, rules);

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            const request: IAccountUpdateRequest = {};

            if (values.password) {
                request.password = values.password;
                request.confirm_password = values.confirm_password;
                request.current_password = values.current_password;
            }
            if (values.language_id !== language.id) {
                request.language_id = values.language_id;
            }

            await updateSettings(request);
            setSubmitValues({
                password: '',
                confirm_password: '',
                current_password: '',
                language_id: values.language_id ? values.language_id : language.id,
            });
            // eslint-disable-next-line no-empty
        } catch (e) { }
    };

    const handleFieldChange = (field: string, value: string) => {
        setSubmitValues(setIn(submitValues, field, value));
    };
    const handleLanguageChange = (lang: number) => setSubmitValues(values => ({ ...values, language_id: +lang }));

    return (
        <FormContainer>
            <Form
                id="accountSettingsForm"
                values={submitValues}
                errors={formErrors}
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <InputContainer>
                    <FormFieldText
                        disabled={true}
                        label={<Translate content="account.settings.emailLabel" />}
                        value={email}
                        size={SIZE.FILL}
                    />
                </InputContainer>
                <PasswordContainer>
                    <FormFieldPassword
                        name="current_password"
                        label={<Translate content="account.settings.currentPasswordLabel" />}
                        errors={formErrors}
                        size={SIZE.FILL}
                    />
                </PasswordContainer>
                <PasswordContainer>
                    <FormFieldPassword
                        name="password"
                        label={<Translate content="account.settings.passwordLabel" />}
                        errors={formErrors}
                        size={SIZE.FILL}
                    />
                    <PasswordStrength password={submitValues.password} />
                </PasswordContainer>
                <PasswordContainer>
                    <FormFieldPassword
                        name="confirm_password"
                        label={<Translate content="account.settings.confirmPasswordLabel" />}
                        errors={formErrors}
                        size={SIZE.FILL}
                    />
                </PasswordContainer>
                {languages.length > 1 && (
                    <Section
                        title={
                            <Translate content="account.settings.languageLabel" />
                        }
                        data-cy={FORM.LANGUAGE_DROPDOWN}
                    >
                        <FormField name="language_id">
                            {({ getId }) => (
                                <LanguageSelector
                                    inputId={getId()}
                                    languages={languages}
                                    selected={language}
                                    onChange={handleLanguageChange}
                                    minHeight="40px"
                                />
                            )}
                        </FormField>

                    </Section>
                )}
                <HeaderButton
                    disabled={isDisabled}
                    type="submit"
                    isLoading={isSaving}
                    form="accountSettingsForm"
                >
                    <Translate content="account.settings.submit" />
                </HeaderButton>
            </Form>
        </FormContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    authUser: state.auth.user,
    languages: state.language.list.data,
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.UPDATE_USER),
    formErrors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateSettings: bindActionCreators(updateUserSettings, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
