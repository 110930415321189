// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import { IVlanResponse } from 'common/api/resources/Vlan';

export enum IpBlockType {
    IPv4 = 'IPv4',
    IPv6 = 'IPv6',
}

export enum IpListType {
    RANGE = 'range',
    SET = 'set',
}

export const IpListTypeTranslations: Record<IpListType, string> = {
    [IpListType.RANGE]: 'ipListType.range',
    [IpListType.SET]: 'ipListType.set',
};

export interface IReverseDns {
    zone: string;
    enabled: boolean;
    template: string;
}

export interface IIpBlockCreateRequest {
    name: string;
    list_type: IpListType;
    type: IpBlockType;
    gateway: string;
    netmask?: string;
    ns_1: string;
    ns_2: string;
    compute_resources: string[];
    from: string;
    to: string;
    range: string;
    subnet: string;
    reverse_dns: IReverseDns;
    vlan_id?: number;
}

export interface IIpBlockResponse {
    id: number;
    name: string;
    type: IpBlockType;
    list_type: IpListType;
    gateway: string;
    netmask: string;
    ns_1: string;
    ns_2: string;
    compute_resources: IComputeResourceResponse[];
    from: string;
    to: string;
    is_deleting: boolean;
    range: string;
    subnet: string;
    reverse_dns: IReverseDns;
    reserved_ips_count: number;
    total_ips_count: string;
    vlan?: IVlanResponse;
}
