// Copyright 2025. WebPros International GmbH. All rights reserved.

import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { FIELDS } from 'common/components/plan/PlanForm';
import { IPlanResponse } from 'common/api/resources/Plan';
import { StorageType } from 'common/api/resources/StorageType';

export const filterPlanFields = (fields: FIELDS[], plan?: IPlanResponse) => {
    if (!plan) {
        return fields;
    }

    if (!plan.is_snapshot_available) {
        fields = fields.filter(field => ![
            FIELDS.IS_SNAPSHOTS_ENABLED,
            FIELDS.SNAPSHOT_LIMITS,
        ].some(item => item === field));
    }

    if (plan.storage_type !== StorageType.THIN_LVM) {
        fields = fields.filter(field => field !== FIELDS.INCREMENTAL_BACKUP);
    }

    // Hide fields which are not supported by KVM
    if (plan.virtualization_type === VirtualizationType.KVM) {
        fields = fields.filter(field => ![
            FIELDS.VCPU_UNITS,
            FIELDS.VCPU_LIMIT,
            FIELDS.IO_PRIORITY,
            FIELDS.SWAP,
            FIELDS.VZ_PARAMETERS,
        ].some(item => item === field));
    }

    // Hide fields which are not supported by VZ
    if (plan.virtualization_type === VirtualizationType.VZ) {
        fields = fields.filter(field => ![
            FIELDS.AVAILABLE_APPLICATIONS,
        ].some(item => item === field));
    }

    return fields;
};
