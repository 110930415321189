// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    validate,
    requiredRule,
} from 'common/validator';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as computeResourceActions from 'admin/computeResource/actions';
import {
    Form,
    Section,
    Translate,
    FormFieldText,
    FormField,
    StatusMessage,
} from '@plesk/ui-library';
import { ISelectOption } from 'common/components';
import {
    IComputeResourcePatchRequest,
    IComputeResourceResponse,
    NetworkType,
} from 'common/api/resources/ComputeResource';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    createOptionsLoader,
    createChangeHandler,
} from 'common/components/Select/helpers';
import {
    ILocationResponse,
    locations,
} from 'common/api/resources/Location';
import {
    IIpBlockResponse,
    ipBlocks,
    IpBlockType,
} from 'common/api/resources/IpBlock';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { FormFieldSwitch } from 'common/components/Form/FormFieldSwitch/FormFieldSwitch';
import { dataCySelector } from 'common/tests/selectors';
import { COMPUTE_RESOURCE } from 'admin/computeResource/constants/tests';

interface IComputeResourceEditFormProps {
    computeResource: IComputeResourceResponse;
    onClose: () => void;
}

export type ComputeResourceEditFormProps =
    IComputeResourceEditFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: IComputeResourcePatchRequest = Object.freeze({
    name: '',
    locations: [],
    ip_blocks: [],
    is_locked: false,
});

const locationToSelectOptions = (location: ILocationResponse) => ({
    label: location.name,
    value: location.id.toString(),
});

const ipBlockToSelectOptions = (ipBlock: IIpBlockResponse) => ({
    label: ipBlock.name,
    value: ipBlock.id.toString(),
    meta: { type: ipBlock.type },
});

export const ComputeResourceEditForm: React.FC<ComputeResourceEditFormProps> = ({
    computeResource,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    computeResourceActions: {
        updateComputeResource,
    },
    onClose,
    isLoading,
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });
    const [selectedLocations, setSelectedLocations] = React.useState<ISelectOption[]>([]);
    const [selectedIpBlocks, setSelectedIpBlocks] = React.useState<ISelectOption[]>([]);
    const [hasIpv6BlockSelected, setHasIpv6BlockSelected] = React.useState(false);

    React.useEffect(() => {
        setSubmitValues(prevState => ({
            ...prevState,
            name: computeResource.name,
            locations: computeResource.locations.map((location) => location.id),
            ip_blocks: computeResource.ip_blocks ? computeResource.ip_blocks!.map((ipBlock) => ipBlock.id) : [],
            is_locked: computeResource.is_locked,
        }));
        setSelectedLocations(computeResource.locations.map(locationToSelectOptions));
        setSelectedIpBlocks(computeResource.ip_blocks ? computeResource.ip_blocks.map(ipBlockToSelectOptions) : []);

        return () => {
            clearFormErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const result = selectedIpBlocks.find(item => (
            item.meta.type === IpBlockType.IPv6
        ));

        setHasIpv6BlockSelected(result !== undefined);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIpBlocks]);

    const handleLocationsChange = createChangeHandler(
        setSubmitValues,
        setSelectedLocations,
        'locations'
    );

    const handleIpBlocksChange = createChangeHandler(
        setSubmitValues,
        setSelectedIpBlocks,
        'ip_blocks'
    );

    const loadLocationOptions = createOptionsLoader(
        locations.list,
        locationToSelectOptions
    );

    const loadIpBlockOptions = createOptionsLoader(
        ipBlocks.list,
        ipBlockToSelectOptions
    );

    const handleEdit = async (values: IComputeResourcePatchRequest) => {
        const errors = validate<IComputeResourcePatchRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            await updateComputeResource(computeResource.id, values);
            onClose();
        } catch (e) {
            throw e;
        }
    };

    const handleNameChange = (value: string) => {
        setSubmitValues({ ...submitValues, name: value });
    };
    const handleToggleSwitch = () => {
        setSubmitValues({
            ...submitValues,
            is_locked: !submitValues.is_locked,
        });
    };

    const showIpv6Warning = computeResource.settings.network.type === NetworkType.ROUTED
        && hasIpv6BlockSelected
        && !computeResource.metrics.network.ipv6_available;

    return (
        <>
            <Form
                id="computeResourceForm"
                footerClassName="hidden"
                onSubmit={handleEdit}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        size="fill"
                        name="name"
                        label={<Translate content="computeResource.actionDialog.name" />}
                        required={true}
                        onChange={handleNameChange}
                    />
                    <FormField
                        name="locations"
                        value={selectedLocations}
                        label={<Translate content="computeResource.actionDialog.locations" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                menuPosition="fixed"
                                value={selectedLocations}
                                isMulti={true}
                                loadOptions={loadLocationOptions}
                                onChange={handleLocationsChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                inputId={getId()}
                            />
                        )}
                    </FormField>
                    <FormField
                        name="ip_blocks"
                        value={selectedIpBlocks}
                        label={<Translate content="computeResource.actionDialog.ipBlocks" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                menuPosition="fixed"
                                value={selectedIpBlocks}
                                isMulti={true}
                                loadOptions={loadIpBlockOptions}
                                onChange={handleIpBlocksChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                inputId={getId()}
                            />
                        )}
                    </FormField>
                    {showIpv6Warning &&
                        <StatusMessage intent="warning">
                            <Translate content="computeResource.actionDialog.ipv6NotAvailable" />
                        </StatusMessage>
                    }
                    <FormFieldSwitch
                        intent='danger'
                        onChange={handleToggleSwitch}
                        name="is_locked"
                        label={<Translate content="computeResource.actionDialog.isLocked"/>}
                        data-cy={dataCySelector(computeResource.id, COMPUTE_RESOURCE.IS_LOCKED)}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="computeResourceForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isLoading}
            >
                <Translate content={'computeResource.actionDialog.createBtn'} />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceEditForm);
