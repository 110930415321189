// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { initialState } from 'common/modules/settings/reducer';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    Logo,
    SectionDescription,
} from 'admin/settings/containers/Settings/Styles';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FormFieldColorPicker } from 'common/components/Form/FormFieldColorPicker/FormFieldColorPicker';
import { ColorResult } from 'react-color';
import { HTTP_CODES } from 'common/api/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import {
    FormField,
    FormFieldCheckbox,
    FormFieldSelect,
    FormFieldText,
    Icon,
    Section,
    Translate,
} from '@plesk/ui-library';
import {
    ILimitGroupResponse,
    limitGroups,
} from 'common/api/resources/LimitGroup';
import {
    IRoleResponse,
    roles,
    ROLE_CLIENT,
} from 'common/api/resources/Role';
import { fileStorage } from 'common/api/resources/FileStorage';
import * as settingsActions from 'common/modules/settings/actions';
import { initialItemState } from 'admin/limitGroup/reducer';
import { ISettingsResponse } from 'common/api/resources/Settings';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { IpBlockType } from 'common/api/resources/IpBlock';

export type UserAreaSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const UserAreaSettings: React.FC<UserAreaSettingsProps> = ({
    responseError,
    permissions: { canManageLimitGroups, canManageRoles },
    formErrorsActions: { setFormErrors },
    settingsActions: { setSettings, saveSettings },
    settings,
}) => {
    const selectedLimitGroup = settings.limit_group ? {
        label: settings.limit_group.name,
        value: settings.limit_group.id,
    } : null;

    const selectedRegistrationRole = {
        label: settings.registration.role,
        value: settings.registration.role,
    };

    const resetToDefault = (key: string) => () => {
        const { theme } = initialState;

        setSettings({
            ...settings,
            theme: {
                ...settings.theme,
                [key]: theme[key],
            },
        });
    };

    const handleRemoveLogo = () => setSettings({
        ...settings,
        theme: {
            ...settings.theme,
            logo: null,
        },
    });

    const handleRemoveFavicon = () => {
        setSettings({
            ...settings,
            theme: {
                ...settings.theme,
                favicon: null,
            },
        });

        const link = document.querySelector('link[rel~=\'icon\']');
        if (link && link instanceof HTMLLinkElement) {
            link.href = '/favicon.svg';
        }
    };

    const handleChangePrimaryColor = (color: ColorResult) => setSettings({
        ...settings,
        theme: {
            ...settings.theme,
            primary_color: color.hex,
        },
    });
    const handleChangeSecondaryColor = (color: ColorResult) => setSettings({
        ...settings,
        theme: {
            ...settings.theme,
            secondary_color: color.hex,
        },
    });

    const handleBrandNameChange = (value: string) => setSettings({
        ...settings,
        theme: {
            ...settings.theme,
            brand_name: value,
        },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeLimitGroup = (option: any) => {
        const updatedSettings = {
            ...settings,
            limit_group: option ? {
                ...initialItemState,
                id: option.value,
                name: option.label,
            } : null,
        };

        setSettings(updatedSettings);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeRegistrationRole = (option: any) => {
        const updatedSettings = {
            ...settings,
            registration: {
                role: option ? option.value : null,
            },
        };

        setSettings(updatedSettings);
    };

    const handleFileUpload = (target: string, callback: (url: string) => void) => async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        if (e.target.files.length) {
            const data = new FormData();

            data.append('file', e.target.files[0]);

            try {
                const response = await fileStorage.upload(data);

                callback(response.data);
            } catch (err) {
                if (err.response.status === HTTP_CODES.VALIDATION_ERROR) {
                    let errors = {};
                    errors[target] = err.response.data.errors.file;
                    setFormErrors(errors);
                }
            }
        }
    };

    const loadLimitOptions = createOptionsLoader(
        limitGroups.list,
        (item: ILimitGroupResponse) => ({
            label: item.name,
            value: item.id.toString(),
        }),
        canManageLimitGroups
    );

    const loadRoleOptions = createOptionsLoader(
        roles.list,
        (item: IRoleResponse) => ({
            label: item.name,
            value: item.name,
        }),
        canManageRoles
    );

    const renderLogoInput = () => {
        if (!settings.theme.logo) {
            const callback = (url: string) => setSettings({
                ...settings,
                theme: {
                    ...settings.theme,
                    logo: url,
                },
            });
            return (
                <>
                    <input type="file" onChange={handleFileUpload('logo', callback)} />
                    {responseError.error}
                </>
            );
        }

        return (
            <Logo>
                <img alt="logo" src={settings.theme.logo} />
                <span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={handleRemoveLogo}>
                        <Icon name="cross-mark-circle" />
                    </a>
                </span>
            </Logo>
        );
    };

    const renderFaviconInput = () => {
        if (!settings.theme.favicon) {
            const callback = (url: string) => {
                setSettings({
                    ...settings,
                    theme: {
                        ...settings.theme,
                        favicon: url,
                    },
                });

                const link = document.querySelector('link[rel~=\'icon\']');
                if (link && link instanceof HTMLLinkElement) {
                    link.href = url;
                }
            };
            return (
                <>
                    <input type="file" onChange={handleFileUpload('favicon', callback)} />
                    {responseError.error}
                </>
            );
        }

        return (
            <Logo>
                <img alt="logo" src={settings.theme.favicon} />
                <span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={handleRemoveFavicon}>
                        <Icon name="cross-mark-circle" />
                    </a>
                </span>
            </Logo>
        );
    };

    const handleSubmit = (values: ISettingsResponse) => {
        const formErrors = validate<ISettingsResponse>(values, {
            'theme.brand_name': requiredRule(<Translate content="validate.fieldRequired" />),
            'theme.primary_color': requiredRule(<Translate content="validate.fieldRequired" />),
            'theme.secondary_color': requiredRule(<Translate content="validate.fieldRequired" />),
            'server_creation.default_selected_ip_types': requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(formErrors).length) {
            setFormErrors(formErrors);
            return;
        }

        saveSettings({
            theme: values.theme,
            features: values.features,
            limit_group: values.limit_group ? values.limit_group.id : null,
            registration: values.registration,
            server_creation: values.server_creation,
        });
    };

    return (
        <SettingsWrapper title="settings.titles.userArea" onSubmit={handleSubmit}>
            <Section title={<Translate content="settings.features.authorization.title" />}>
                <SectionDescription>
                    <Translate content="settings.features.authorization.registration.description" />
                </SectionDescription>
                <FormFieldCheckbox
                    name="features[allow_registration]"
                    label={<Translate content="settings.features.authorization.registration.label" />}
                />
                <SectionDescription>
                    <Translate content="settings.features.authorization.passwordRecovery.description" />
                </SectionDescription>
                <FormFieldCheckbox
                    name="features[allow_password_recovery]"
                    label={<Translate content="settings.features.authorization.passwordRecovery.label" />}
                />
                <FormField
                    name="limit_group"
                    required={true}
                    label={<Translate content="settings.limitGroup.limitGroup" />}
                    style={{ maxWidth: '500px' }}
                >
                    <AsyncSelectInput
                        isClearable={true}
                        placeholder="Unlimited"
                        onChange={handleChangeLimitGroup}
                        loadOptions={loadLimitOptions}
                        value={selectedLimitGroup}
                        debounceTimeout={1000}
                        additional={{ page: 1 }}
                    />
                </FormField>
                <FormField
                    name="registration[role]"
                    required={true}
                    label={<Translate content="settings.registration.clientRole" />}
                    style={{ maxWidth: '500px' }}
                >
                    <AsyncSelectInput
                        isClearable={false}
                        placeholder={ROLE_CLIENT}
                        onChange={handleChangeRegistrationRole}
                        loadOptions={loadRoleOptions}
                        value={selectedRegistrationRole}
                        debounceTimeout={1000}
                        additional={{ page: 1 }}
                    />
                </FormField>
            </Section>
            <Section title={<Translate content="settings.branding.title" />}>
                <FormFieldText
                    name="theme[brand_name]"
                    size="xl"
                    label={<Translate content="settings.branding.brandName" />}
                    description={
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a onClick={resetToDefault('brand_name')}>
                            <Translate content="settings.branding.resetToDefaults" />
                        </a>
                    }
                    required={true}
                    onChange={handleBrandNameChange}
                />
                <FormFieldColorPicker
                    color={settings.theme.primary_color}
                    onChangeColor={handleChangePrimaryColor}
                    fieldName="theme[primary_color]"
                    label={<Translate content="settings.branding.primaryColor" />}
                    description={
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a onClick={resetToDefault('primary_color')}>
                            <Translate content="settings.branding.resetToDefaults" />
                        </a>
                    }
                />
                <FormFieldColorPicker
                    color={settings.theme.secondary_color}
                    onChangeColor={handleChangeSecondaryColor}
                    fieldName="theme[secondary_color]"
                    label={<Translate content="settings.branding.secondaryColor" />}
                    description={
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a onClick={resetToDefault('secondary_color')}>
                            <Translate content="settings.branding.resetToDefaults" />
                        </a>
                    }
                />
                <FormField
                    name="logo"
                    label={<Translate content="settings.branding.logo" />}
                    fullDescription={<Translate content="settings.branding.logoDescription" />}
                >
                    {renderLogoInput()}
                </FormField>
                <FormField
                    name="favicon"
                    label={<Translate content="settings.branding.favicon" />}
                    fullDescription={<Translate content="settings.branding.faviconDescription" />}
                >
                    {renderFaviconInput()}
                </FormField>
                <FormFieldText
                    name="theme[terms_and_conditions_url]"
                    size="xl"
                    label={<Translate content="settings.branding.termsAndConditions" />}
                />
                <FormFieldCheckbox
                    name="features[hide_api_documentation_link]"
                    label={<Translate content="settings.branding.hideApiDocumentationLink" />}
                />
            </Section>
            <Section title={<Translate content="settings.features.serverCreation.title" />}>
                <SectionDescription>
                    <Translate content="settings.features.serverCreation.description" />
                </SectionDescription>
                <FormFieldCheckbox
                    name="features[hide_plan_name]"
                    label={<Translate content="settings.features.serverCreation.hidePlanName" />}
                />
                <FormFieldCheckbox
                    name="features[hide_location_section]"
                    label={<Translate content="settings.features.serverCreation.hideLocationSection" />}
                />
                <FormFieldCheckbox
                    name="features[hide_plan_section]"
                    label={<Translate content="settings.features.serverCreation.hidePlanSection" />}
                />
                <FormFieldCheckbox
                    name="features[hide_user_data]"
                    label={<Translate content="settings.features.serverCreation.hideUserData" />}
                />
                <FormFieldCheckbox
                    name="server_creation[hide_ip_type_selection]"
                    label={<Translate content="settings.serverCreation.hideIpTypeSelection" />}
                />
                <FormFieldSelect
                    name="server_creation[default_selected_ip_types]"
                    label={<Translate content="settings.serverCreation.defaultSelectedIpTypes" />}
                    disabled={settings.server_creation.hide_ip_type_selection}
                >
                    {Object.values(IpBlockType).map((type, index) => (
                        <option key={index} value={type}>
                            {type}
                        </option>
                    ))}
                </FormFieldSelect>
            </Section>
        </SettingsWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    responseError: state.app.responseError,
    settings: state.settings,
    permissions: {
        canManageLimitGroups: hasPermission(state, PERMISSION_LIST.MANAGE_LIMIT_GROUPS),
        canManageRoles: hasPermission(state, PERMISSION_LIST.MANAGE_ROLES),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAreaSettings);
