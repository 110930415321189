// Copyright 2025. WebPros International GmbH. All rights reserved.

export const LIMIT_GROUPS = {
    TABLE: 'limitGroups-table',
};

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
    FILTER: 'filter',
};

export const FORM = {
    VMS: 'vms',
    RUNNING_VMS: 'running_vms',
    ADDITIONAL_IPV4: 'additional_ipv4',
    ADDITIONAL_IPV6: 'additional_ipv6',
    ISO_IMAGES: 'iso_images',
    ISO_IMAGES_SIZE: 'iso_images_size',
};
