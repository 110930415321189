// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import {
    FormField,
    FormFieldText,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { ISelectRequiredOption } from 'common/components';
import * as activityLogActions from 'admin/activityLog/actions';
import { ValueType } from 'react-select';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { IActivityLogFilters } from 'common/api/resources/ActivityLog';
import SelectInput from 'common/components/Select/SelectInput';
import {
    activityLogEventMap,
    ACTIVITY_LOG_EVENTS,
} from 'admin/activityLog/constants/activityLogEvents';
import Axios from 'axios';
import { ISelectOption } from 'admin/user/components/Filters';
import { SIZE } from 'common/constants';
import useQuery from 'common/hooks/useQuery';
import ActivityLogTable from 'admin/activityLog/containers/ActivityLogTable';

export type ActivityLogsProps =
    ReturnType<typeof mapDispatchToProps>;

const events: ISelectOption[] = Object.values(ACTIVITY_LOG_EVENTS).map(event => ({
    label: <Translate content={activityLogEventMap[event]} />,
    value: event,
}));

export const ActivityLogs: React.FC<ActivityLogsProps> = ({
    activityLogActions: {
        getActivityLogs,
    },
}) => {
    const [filters, setFilters] = useQuery<IActivityLogFilters>();

    React.useEffect(() => {
        const source = Axios.CancelToken.source();

        const fetchActivityLogList = async () => {
            try {
                await getActivityLogs({ filters }, source);
            } catch (e) {
                if (Axios.isCancel(e)) {
                    return;
                }

                throw e;
            }
        };

        fetchActivityLogList();

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const setFilter = React.useCallback((key: keyof IActivityLogFilters, value?: string) => {
        if (value === undefined) {
            setFilters(values => {
                const { [key]: _, ...rest } = values;
                return rest;
            });
            return;
        }

        setFilters(values => ({
            ...values,
            [key]: value,
        }));
    }, [setFilters]);


    const handleUserFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        const typedOption = option as ISelectOption;

        setFilter('user_id', typedOption?.value);
    };

    const handleEventFilterChange = async (option: ValueType<ISelectOption>) => {
        const typedOption = option as ISelectOption;

        setFilter('event', typedOption?.value);
    };

    const handleSearchFilterChange = async (value: string) => {
        setFilter('search', value.length ? value : undefined);
    };

    return (
        <>
            <PageHeader
                isButtonShown={false}
                title={<Translate content="activityLog.title"/>}
            />
            <ActivityLogTable
                toolbar={(
                    <FilterForm>
                        <FormField label={<Translate content="activityLog.list.event" />}>
                            <SelectInput<ISelectOption>
                                placeholder={<Translate content="filters.all"/>}
                                options={events}
                                isClearable={true}
                                onChange={handleEventFilterChange}
                            />
                        </FormField>
                        <UserFilter
                            label={<Translate content="activityLog.list.user" />}
                            onChange={handleUserFilterChange}
                            margin_left="0px"
                        />
                        <FormFieldText
                            label={<Translate content="activityLog.list.search" />}
                            name="search"
                            onChange={handleSearchFilterChange}
                            size={SIZE.FILL}
                        />
                    </FilterForm>
                )}
                filters={filters}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    activityLogActions: bindActionCreators(activityLogActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ActivityLogs);
