// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { ISelectOption } from 'common/components';
import useObjectPropertyState,
{ ObjectStorage } from 'common/hooks/useObjectPropertyState';

export type OptionLoader = (id: number) => Promise<ISelectOption>;

const useSelectFilter = (
    itemLoader: (id: number) => Promise<ISelectOption>,
    storage: ObjectStorage<number | undefined>
) => {
    const [storedValue, setStoredValue] = storage;
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState<ISelectOption>();

    React.useEffect(() => {
        if (storedValue && storedValue !== selectedOption?.value) {
            (async () => {
                try {
                    setIsLoading(true);
                    const option = await itemLoader(storedValue);
                    setSelectedOption(option);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [storedValue, setIsLoading, selectedOption, setSelectedOption, itemLoader]);

    const selectItem = React.useCallback((option?: ISelectOption) => {
        setStoredValue(option?.value ? option.value as number : undefined);
        setSelectedOption(option);
    }, [setStoredValue, setSelectedOption]);

    return {
        isLoading,
        selectedItem: selectedOption,
        selectItem,
    };
};

export const useObjectSelectFilter = <S>(
    key: keyof S,
    objectStorage: ObjectStorage<S>,
    itemLoader: OptionLoader
) => {
    const storage = useObjectPropertyState<S, number | undefined>(key, objectStorage);
    return useSelectFilter(itemLoader, storage);
};
