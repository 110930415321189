// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { COLORS } from 'admin/core/theme';
import { ICONS } from 'common/constants';
import {
    Item,
    Icon,
    Translate,
} from '@plesk/ui-library';
import {
    DashboardItemContainer,
    ItemAdditionalDescription,
    ItemDescription,
} from 'admin/dashboard/containers/items/Styles';
import {
    DataUnit,
    convertToDataUnit,
    usagePercentage,
} from 'common/helpers/units';

export type ClusterMemoryItemProps =
    ReturnType<typeof mapStateToProps>;

export const ClusterMemoryItem: React.FC<ClusterMemoryItemProps> = ({
    stats,
}) => {
    const usage = usagePercentage(stats.total, stats.used);
    const formattedTotal = convertToDataUnit(stats.total, DataUnit.GiB);
    const formattedUsed = convertToDataUnit(stats.used, DataUnit.GiB);

    return (
        <DashboardItemContainer>
            <Item
                icon={
                    <Icon
                        name={ICONS.CPU}
                        size={32}
                        style={{ color: COLORS.PRIMARY }}
                    />
                }
                description={(
                    <>
                        <ItemDescription data-cy="cluster-memory-usage">
                            {usage}%
                        </ItemDescription>
                        <ItemAdditionalDescription>
                            <Translate
                                content="dashboard.cards.usage"
                                params={{
                                    used: formattedUsed,
                                    total: formattedTotal,
                                    unit: DataUnit.GiB.toString(),
                                }}
                            />
                        </ItemAdditionalDescription>
                    </>
                )}
                view="card"
                title={
                    <Translate content="dashboard.cards.clusterMemory" />
                }
            />
        </DashboardItemContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    stats: state.dashboard.data.cluster_memory,
});

export default connect(mapStateToProps)(ClusterMemoryItem);
