// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Loader } from 'common/components';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import { RouteComponentProps } from 'react-router';
import { VncDialog as VncDialogComponent } from 'common/components/VncDialog/VncDialog';
import { Translate } from '@plesk/ui-library';

interface IVncDialogProps {
    isAuthenticated: boolean;
    vm: IVmResponse;
    isSettingVncUp: boolean;
    isLoadingVm: boolean;
    getComputeResourceVm: (id: number) => void;
    canOpenVncConsole: boolean;
    directConnection?: boolean;
}

export type VncDialogProps =
    RouteComponentProps<{ id: string }> &
    IVncDialogProps;

const VncDialog: React.FC<VncDialogProps> = ({
    match: { params },
    isAuthenticated,
    vm,
    isLoadingVm,
    isSettingVncUp,
    getComputeResourceVm,
    canOpenVncConsole,
    directConnection = false,
}) => {
    React.useLayoutEffect(() => {
        if (!vm.id) {
            getComputeResourceVm(parseInt(params.id, 10));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vm]);

    React.useEffect(() => {
        if (!isAuthenticated) {
            window.close();
        }
    }, [isAuthenticated]);

    if (!canOpenVncConsole) {
        return (<Translate content="vncDialog.accessDenied" />);
    }

    return (
        <Loader isLoading={isLoadingVm}>
            {vm.id && (
                <VncDialogComponent
                    vm={vm}
                    isSettingVncUp={isSettingVncUp}
                    directConnection={directConnection}
                />
            )}
        </Loader>
    );
};

export default VncDialog;
