// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import SelectInput from 'common/components/Select/SelectInput';
import { ILoadOptions } from 'common/components';
import { ValueType } from 'react-select';
import {
    FormField,
    Translate,
} from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { computeResources } from 'common/api/resources/ComputeResource';
import {
    TASK_ACTION,
    TASK_STATUS,
} from 'common/api/resources/Task';

export interface ISelectOption {
    label: string | undefined;
    value: string | undefined;
}

export interface IFilterOptions {
    status?: string;
    action?: string;
    compute_resource_id?: number;
    version?: string;
}

export interface IFilterProps {
    data: IFilterOptions;
    onStatusFilterChange: (option: ValueType<ISelectOption>) => void;
    onActionFilterChange: (option: ValueType<ISelectOption>) => void;
    onComputeResourceFilterChange: (option: ValueType<ISelectOption>) => void;
    selectedComputeResource?: ISelectOption;
}

export const Filters: React.FC<IFilterProps> = ({
    data,
    onStatusFilterChange,
    onActionFilterChange,
    onComputeResourceFilterChange,
    selectedComputeResource,
}) => {
    const statuses: ISelectOption[] = Object.values(TASK_STATUS).map(status => ({
        value: status,
        label: status,
    }));

    const actions: ISelectOption[] = Object.values(TASK_ACTION).map(action => ({
        value: action,
        label: action,
    }));

    const selectedAction = data.action ? {
        value: data.action,
        label: data.action,
    } : undefined;

    const loadComputeResources: ILoadOptions = async (search, loadedOptions, { page }) => {
        const response = await computeResources.list({
            page,
            filters: {
                search,
            },
        });

        return {
            options: response.data.data.map(computeResource => ({
                label: computeResource.host,
                value: computeResource.id.toString(),
            })),
            hasMore: !!response.data.links.next,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <FilterForm>
            <FormField label={<Translate content="task.filters.actions" />}>
                <SelectInput<ISelectOption>
                    placeholder={<Translate content="filters.all"/>}
                    options={actions}
                    isClearable={true}
                    onChange={onActionFilterChange}
                    value={selectedAction}
                />
            </FormField>
            <FormField label={<Translate content="task.filters.status" />}>
                <SelectInput<ISelectOption>
                    placeholder={<Translate content="filters.all"/>}
                    options={statuses}
                    isClearable={true}
                    onChange={onStatusFilterChange}
                />
            </FormField>
            <FormField label={<Translate content="task.filters.computeResource" />}>
                <AsyncSelectInput
                    isClearable={true}
                    placeholder={<Translate content="filters.all"/>}
                    loadOptions={loadComputeResources}
                    onChange={onComputeResourceFilterChange}
                    debounceTimeout={1000}
                    additional={{ page: 1 }}
                    value={selectedComputeResource}
                />
            </FormField>
        </FilterForm>
    );
};
