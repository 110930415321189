// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    ILimitGroupRequest,
    ILimitGroupResponse,
} from 'common/api/resources/LimitGroup/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const limitGroups = {
    item: (id: number) => api.get<IApiResponse<ILimitGroupResponse>>(`limit_groups/${id}`),
    update: (id: number, data: ILimitGroupRequest) => api.put<IApiResponse<ILimitGroupResponse>>(`limit_groups/${id}`, data),
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<ILimitGroupResponse[]>>('limit_groups', {
        params: new QueryBuilder(params).build(),
    }),
    remove: (id: number) => api.delete(`limit_groups/${id}`),
    create: (data: ILimitGroupRequest) => api.post<IApiResponse<ILimitGroupResponse>>('limit_groups', data),
};
