// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    IVmResponse,
    IVpcNetworkAttachSucceedEvent,
    IVpcNetworkAttachFailedEvent,
    IVpcNetworkDetachSucceedEvent,
    IVpcNetworkDetachFailedEvent,
} from 'common/api/resources/ComputeResourceVm';
import {
    CompactEmptyView,
    NetworkingHeader,
} from 'common/components/ServerTabs/NetworkingTab/Styles';
import {
    Action,
    Item,
    Translate,
} from '@plesk/ui-library';
import List from 'common/components/List/List';
import { getActionColumnProps } from 'common/helpers/list';
import { StyledTable } from 'common/components/styles/StyledTable';
import CopyText from 'common/containers/CopyText/CopyText';
import { Link } from 'react-router-dom';
import {
    isAdminSite,
    pathTo,
} from 'common/helpers/core';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { ICommonState } from 'common/store';
import { connect } from 'react-redux';
import { IComputeResourceVmVpcNetworkInterfaceResponse } from 'common/api/resources/VpcNetwork';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Button } from 'admin/common/components/Button/Button';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
} from 'common/constants';
import { Dialog } from 'common/components/Dialog/Dialog';
import AttachVpcInterfaceForm from 'common/components/ServerTabs/NetworkingTab/AttachVpcInterfaceForm';
import { initEchoConnection } from 'common/services/EchoService';
import { StyledActions } from 'common/components/Actions/Styles';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/components/ServerTabs/NetworkingTab/constants/test';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { canModifyNetwork } from 'common/services/ServerCapabilities';

enum VpcInterfacesTableColumns {
    IP = 'colIp',
    MAC = 'colMac',
    VPC_NETWORK = 'colVpcNetwork',
    ACTIONS = 'colActions',
}

const columns = [
    {
        width: '20%',
        key: VpcInterfacesTableColumns.IP,
        title: <Translate content="servers.tabs.networking.vpcInterfaces.list.ip" />,
    },
    {
        width: '20%',
        key: VpcInterfacesTableColumns.MAC,
        title: <Translate content="servers.tabs.networking.vpcInterfaces.list.mac" />,
    },
    {
        width: '20%',
        key: VpcInterfacesTableColumns.VPC_NETWORK,
        truncate: true,
        title: <Translate content="servers.tabs.networking.vpcInterfaces.list.vpcNetwork" />,
    },
    getActionColumnProps(),
];

export interface IVpcInterfacesProps {
    server: IVmResponse;
    vpcNetworkInterfaces: IPaginateApiResponse<IComputeResourceVmVpcNetworkInterfaceResponse[]>;
}

export type VpcInterfacesProps = IVpcInterfacesProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export const VpcInterfaces: React.FC<VpcInterfacesProps> = ({
    echoCredentials,
    isLoadingVpcInterfaces,
    isAttaching,
    server,
    vpcNetworkInterfaces,
    canGetVpcNetworks,
    canManageVpcNetworks,
    computeResourceVmActions: {
        getVpcNetworkInterfaces,
        commitVpcNetworkInterfaceAttachSuccess,
        commitVpcNetworkInterfaceAttachFail,
        commitVpcNetworkInterfaceDetachSuccess,
        commitVpcNetworkInterfaceDetachFail,
    },
    vpcNetworkActions: {
        detachServersFromVpcNetwork,
    },
}) => {
    const [dialogOpened, setDialogOpened] = React.useState(false);

    const handleOpenDialog = () => setDialogOpened(true);
    const handleCloseDialog = () => setDialogOpened(false);

    const loadPaginated = (page: number) => getVpcNetworkInterfaces(server.id, { page });

    const canModifyServerNetwork = canModifyNetwork(server);

    const vpcInterfaceColumns = React.useMemo(() => {
        if (canGetVpcNetworks) {
            return columns;
        }

        return columns.filter(c => c.key !== VpcInterfacesTableColumns.VPC_NETWORK);
    }, [canGetVpcNetworks]);

    React.useEffect(() => {
        const echo = initEchoConnection(echoCredentials);

        if (server.uuid) {
            const channel = echo.private(`${SOCKET_CHANNELS.VIRTUAL_SERVER}.${server.uuid}`);

            channel.listen(
                SOCKET_EVENTS.VPC_ATTACH_SUCCEED,
                (data: IVpcNetworkAttachSucceedEvent) => {
                    commitVpcNetworkInterfaceAttachSuccess(data.vpcNetworkInterface);
                }
            );
            channel.listen(
                SOCKET_EVENTS.VPC_ATTACH_FAILED,
                (data: IVpcNetworkAttachFailedEvent) => {
                    commitVpcNetworkInterfaceAttachFail(data.vpcNetworkInterfaceId);
                }
            );
            channel.listen(
                SOCKET_EVENTS.VPC_DETACH_SUCCEED,
                (data: IVpcNetworkDetachSucceedEvent) => {
                    setDetachingId(undefined);
                    commitVpcNetworkInterfaceDetachSuccess(data.vpcNetworkInterfaceId);
                }
            );
            channel.listen(
                SOCKET_EVENTS.VPC_DETACH_FAILED,
                (data: IVpcNetworkDetachFailedEvent) => {
                    setDetachingId(undefined);
                    commitVpcNetworkInterfaceDetachFail(data.vpcNetworkInterfaceId);
                }
            );
        }

        return () => {
            echo.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.uuid, echoCredentials]);

    const [detachingId, setDetachingId] = React.useState<number>();
    const handleDetach = (vpcNetworkId: number) => () => {
        setDetachingId(vpcNetworkId);
        detachServersFromVpcNetwork(vpcNetworkId, { server_ids: [server.id] });
    };

    const data = vpcNetworkInterfaces.data
        ?.map((vpcNetworkInterface: IComputeResourceVmVpcNetworkInterfaceResponse) => ({
            [VpcInterfacesTableColumns.IP]: (<CopyText>{vpcNetworkInterface.ip.ip}</CopyText>),
            [VpcInterfacesTableColumns.MAC]: (<CopyText>{vpcNetworkInterface.mac_address}</CopyText>),
            [VpcInterfacesTableColumns.VPC_NETWORK]: vpcNetworkInterface.ip.vpc_network
                ? (
                    isAdminSite()
                        ? (
                            <Action
                                component={Link}
                                to={pathTo(`vpc_networks?id=${vpcNetworkInterface.ip.vpc_network.id}`)}
                            >
                                {vpcNetworkInterface.ip.vpc_network.name}
                            </Action>
                        )
                        : vpcNetworkInterface.ip.vpc_network.name
                )
                : null,
            [VpcInterfacesTableColumns.ACTIONS]: canManageVpcNetworks ? (
                <StyledActions>
                    <ButtonWithConfirmation
                        data-cy={dataCySelector(vpcNetworkInterface.id, TABLE_ACTIONS.DETACH_VPC_INTERFACE)}
                        translations={{
                            title: (
                                <Translate content="servers.tabs.networking.vpcInterfaces.detachPopover.title" />
                            ),
                            button: (
                                <Translate content="servers.tabs.networking.vpcInterfaces.detachPopover.button" />
                            ),
                            tooltip: canModifyServerNetwork ? (
                                <Translate content="servers.tabs.networking.vpcInterfaces.detachPopover.tooltip" />
                            ) : (
                                <Translate content="servers.guestToolsMissing" />
                            ),
                        }}
                        handleConfirm={handleDetach(vpcNetworkInterface.ip.vpc_network!.id)}
                        isLoading={detachingId === vpcNetworkInterface.ip.vpc_network!.id}
                        disabled={server.is_processing || !canModifyServerNetwork}
                        icon={ICONS.RECYCLE}
                    />
                </StyledActions>
            ) : null,
            key: vpcNetworkInterface.id.toString(),
        }));

    const attachedVpcNetworkIds = React.useMemo(
        () => vpcNetworkInterfaces.data?.map((vpcNetworkInterface) => vpcNetworkInterface.ip.vpc_network?.id!),
        [vpcNetworkInterfaces.data]
    );

    return (
        <Item
            title={
                <NetworkingHeader>
                    <h3>
                        <Translate content="servers.tabs.networking.vpcInterfaces.title" />
                    </h3>
                    {canManageVpcNetworks && (
                        <Button
                            size={SIZE.LG}
                            intent={INTENT_TYPE.PRIMARY}
                            icon={ICONS.PLUS}
                            isLoading={isAttaching}
                            disabled={server.is_processing || !canModifyServerNetwork}
                            onClick={handleOpenDialog}
                            tooltip={!canModifyServerNetwork && (
                                <Translate content="servers.guestToolsMissing" />
                            )}
                        >
                            <Translate content="servers.tabs.networking.vpcInterfaces.button" />
                        </Button>
                    )}
                </NetworkingHeader>
            }
            view="card"
        >
            <StyledTable>
                <List
                    isLoading={isLoadingVpcInterfaces}
                    emptyView={(
                        <CompactEmptyView
                            hideIcon={true}
                            title="servers.tabs.networking.vpcInterfaces.emptyView.title"
                            description="servers.tabs.networking.vpcInterfaces.emptyView.description"
                        />
                    )}
                    columns={vpcInterfaceColumns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={vpcNetworkInterfaces.meta}
                />
                <Dialog
                    isOpen={dialogOpened}
                    heading={<Translate content="servers.tabs.networking.vpcInterfaces.attachDialog.header"/>}
                    size={SIZE.XS}
                    closeHandler={handleCloseDialog}
                >
                    <AttachVpcInterfaceForm
                        server={server}
                        attachedVpcNetworkIds={attachedVpcNetworkIds}
                        onSubmit={handleCloseDialog}
                    />
                </Dialog>
            </StyledTable>
        </Item>
    );
};


const mapStateToProps = (state: ICommonState) => ({
    isAttaching: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_SERVER_ATTACH),
    isLoadingVpcInterfaces : state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_VPC_NETWORK_INTERFACE_LIST),
    echoCredentials: `${state.auth.authData.token_type} ${state.auth.authData.access_token}`,
    canGetVpcNetworks: hasPermission(
        state,
        PERMISSION_LIST.GET_VPC_NETWORKS,
        PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS,
        PERMISSION_LIST.MANAGE_OWNED_VPC_NETWORKS
    ),
    canManageVpcNetworks: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS,
        PERMISSION_LIST.MANAGE_OWNED_VPC_NETWORKS
    ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpcInterfaces);