// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import { IIpBlockResponse } from 'common/api/resources/IpBlock/model';

export enum VlanMode {
    TRUNK = 'trunk',
    NATIVE_UNTAGGED = 'native-untagged',
    NATIVE_TAGGED = 'native-tagged',
    ACCESS = 'access',
    DOT1Q_TUNNEL = 'dot1q-tunnel'
}

export interface IVlanResponse {
    id: number;
    name: string;
    tag: number;
    mode: VlanMode;
    trunks?: number[];
    ip_blocks?: IIpBlockResponse[];
}

export interface IVlanListFilters {}

export interface IVlanRequest {
    name: string;
    tag: number;
    mode: VlanMode;
    trunks?: number[];
    ip_block_ids?: number[];
}

export interface IVlanPatchRequest {}

export interface IVlanListRequest extends IRequest<IVlanListFilters>, IPaginated {}