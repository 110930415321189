// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { PageSection } from 'common/components/PageHeader/Styles';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import * as React from 'react';
import { ACCOUNT_TABS } from 'admin/account/constants';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import PasswordForm from 'admin/account/containers/PasswordForm';
import TwoFactorAuthForm from 'common/modules/account/containers/TwoFactorAuthForm';
import { TabsExpander } from 'client/common/styles/Tabs';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';

export type AccountProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps>;

export const Account: React.FC<AccountProps> = ({
    user,
    history,
    location: {
        hash,
    },
}) => {
    if (!hash) {
        history.replace(ACCOUNT_TABS.PASSWORD);
        return null;
    }

    const tabs = Object.values(ACCOUNT_TABS);
    const handleTabSelect = (index: number) => history.replace(tabs[index]);

    return (
        <>
            <PageHeader
                title={(
                    <PageSection>
                        <Translate content="account.title" />
                    </PageSection>
                )}
            />
            <Tabs selectedIndex={tabs.findIndex(tab => tab === hash)} onSelect={handleTabSelect}>
                <TabList>
                    <Tab><Translate content="account.tabs.password"/></Tab>
                    <Tab>
                        <Icon name={ICONS.SHIELD} intent={user.is_two_factor_auth_enabled ? INTENT_TYPE.SUCCESS : INTENT_TYPE.DANGER}/>
                        &nbsp;
                        <Translate content="account.tabs.twoFactorAuth"/>
                    </Tab>
                    <TabsExpander />
                </TabList>
                <TabPanel><PasswordForm /></TabPanel>
                <TabPanel><TwoFactorAuthForm /></TabPanel>
            </Tabs>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(Account));
