// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IRoleResponse } from 'common/api/resources/Role';
import {
    RolesContainer,
    RolesDropdown,
} from 'admin/user/components/Roles/Styles';
import {
    Menu,
    MenuItem,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';

type RoleClick = (role: IRoleResponse) => void;

export interface IRolesProps {
    roles: IRoleResponse[];
    onClick?: RoleClick;
}

const createRoleClickHandler = (role: IRoleResponse, onClick?: RoleClick) => () => onClick && onClick(role);

const createMenu = (roles: IRoleResponse[], onClick?: RoleClick) => (
    <Menu>
        {roles.map((role) => (
            <MenuItem key={role.id} onClick={createRoleClickHandler(role, onClick)}>
                {role.name}
            </MenuItem>
        ))}
    </Menu>
);

const sortRoles = (roles: IRoleResponse[]) =>
    // Ensure that default roles come first.
    roles.sort((lval: IRoleResponse, rval: IRoleResponse) => {
        switch (true) {
        case lval.is_default && rval.is_default:
            return lval.name.localeCompare(rval.name);

        case lval.is_default:
            return -1;

        case rval.is_default:
            return 1;
        }

        return 0;
    });

export const MAX_SHOWN_ROLES = 2;

const Roles: React.FC<IRolesProps> = ({
    roles,
    onClick,
}) => {
    const sortedRoles = sortRoles(roles);

    const renderMoreButton = () => {
        if (sortedRoles.length <= MAX_SHOWN_ROLES) {
            return undefined;
        }

        return (
            <RolesDropdown
                menu={createMenu(sortedRoles.slice(MAX_SHOWN_ROLES), onClick)}
            >
                <Button ghost={true} intent={INTENT_TYPE.PRIMARY}>
                    +{sortedRoles.length - MAX_SHOWN_ROLES}
                </Button>
            </RolesDropdown>
        );
    };

    return (
        <RolesContainer>
            {sortedRoles.slice(0, MAX_SHOWN_ROLES).map((role) => (
                <Button
                    key={role.id}
                    onClick={createRoleClickHandler(role, onClick)}
                    ghost={true}
                >
                    {role.name}
                </Button>
            ))}
            {renderMoreButton()}
        </RolesContainer>
    );
};

export default Roles;
