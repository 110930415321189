// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import NotificationItem from 'admin/notification/containers/Items/NotificationItem';

export interface INewVersionAvailableItemProps {
    item: INotificationResponse;
}

export const NewVersionAvailableItem: React.FC<INewVersionAvailableItemProps> = ({
    item,
}) => (
    <NotificationItem
        item={item}
        icon={<Icon name="info-circle" size="16" intent="info" />}
        header={(
            <Translate
                content="notification.item.newVersionAvailable.title"
                params={{ version: item.data.version }}
            />
        )}
        description={(
            <Translate
                content="notification.item.newVersionAvailable.description"
            />
        )}
    />
);

export default NewVersionAvailableItem;
