// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    intMinRule,
    ipRule,
    requiredRule,
    validate,
} from 'common/validator';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Loader,
    Tooltip,
} from 'common/components';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as computeResourceActions from 'admin/computeResource/actions';
import {
    Column,
    Columns,
    Form,
    FormField,
    FormFieldSelect,
    FormFieldText,
    Section,
    SelectOption,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { BALANCE_STRATEGY } from 'common/api/resources/Storage';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Limit } from 'common/components/Limit/Limit';
import { dataCySelector } from 'common/tests/selectors';
import SelectInput from 'common/components/Select/SelectInput';
import { ISelectOption } from 'admin/user/components/Filters';
import {
    IComputeResourceResponse,
    IComputeResourceSettingNetwork,
    NetworkType,
    VIRTUALIZATION_TYPE_TRANSLATION_MAP,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { GiB } from 'common/helpers/units';
import { DiskCacheMode } from 'common/api/resources/ComputeResourceVm';
import { COMPUTE_RESOURCE_SETTINGS } from 'admin/computeResource/constants/tests';
import { ILimit } from 'common/api/resources/model';
import { humanizeNetworkType } from 'admin/computeResource/constants';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { IConcurrentBackups } from 'common/api/resources/BackupNode';

interface IComputeResourceSettingsFormProps {
    computeResource: IComputeResourceResponse;
    onClose: () => void;
}

export type ComputeResourceSettingsFormProps =
    IComputeResourceSettingsFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface ISettings {
    iso_path: string;
    cache_path: string;
    backup_tmp_path: string;
    vnc_proxy_port: number;
    balance_strategy: string;
    network: IComputeResourceSettingNetwork;
    virtualization_types: VirtualizationType[];
    vm: ILimit;
    hdd: ILimit;
    ram: ILimit;
    vcpu: ILimit;
    vs_disk_cache_mode: DiskCacheMode | null;
    concurrent_backups: IConcurrentBackups;
}

const defaultValues: ISettings = {
    iso_path: '',
    cache_path: '',
    backup_tmp_path: '',
    vnc_proxy_port: 7778,
    balance_strategy: '',
    network: {
        type: NetworkType.ROUTED,
        bridges: [],
    },
    virtualization_types: [],
    vm: {
        limit: 0,
        is_enabled: false,
    },
    hdd: {
        limit: 0,
        is_enabled: false,
    },
    ram: {
        limit: 0,
        is_enabled: false,
    },
    vcpu: {
        limit: 0,
        is_enabled: false,
    },
    vs_disk_cache_mode: null,
    concurrent_backups: {
        create: 1,
        restore: 1,
    },
};

const balanceStrategyOptions: ISelectOption[] = [
    {
        label: BALANCE_STRATEGY.RANDOM,
        value: BALANCE_STRATEGY.RANDOM,
    },
    {
        label: BALANCE_STRATEGY.ROUND_ROBIN,
        value: BALANCE_STRATEGY.ROUND_ROBIN,
    },
    {
        label: BALANCE_STRATEGY.MOST_STORAGE_AVAILABLE,
        value: BALANCE_STRATEGY.MOST_STORAGE_AVAILABLE,
    },
];

export const limitSections = [
    { key: 'vm', measure: 'units' },
    { key: 'hdd', measure: 'gib' },
    { key: 'ram', measure: 'gib' },
    { key: 'vcpu', measure: 'units' },
];

const diskCacheModeOptions = Object.values(DiskCacheMode).map((value) => (
    <option key={value} value={value}>
        {value}
    </option>
));

export const SettingsDialog: React.FC<ComputeResourceSettingsFormProps> = ({
    onClose,
    computeResource,
    formErrors,
    isItemCreating,
    isLoading,
    formErrorsActions: { setFormErrors, clearFormErrors },
    computeResourceActions: { updateSettings },
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });

    React.useEffect(() => {
        const { limits, ...other } = computeResource.settings;
        setSubmitValues({
            ...other,
            vm: {
                limit: limits.vm.total,
                is_enabled: !limits.vm.unlimited,
            },
            hdd: {
                limit: limits.hdd.total,
                is_enabled: !limits.hdd.unlimited,
            },
            ram: {
                limit: Math.round(limits.ram.total / GiB),
                is_enabled: !limits.ram.unlimited,
            },
            vcpu: {
                limit: limits.vcpu.total,
                is_enabled: !limits.vcpu.unlimited,
            },
        });

        return () => {
            clearFormErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreate = async (values: ISettings) => {
        const rules = {
            iso_path: requiredRule(<Translate content="validate.fieldRequired" />),
            cache_path: requiredRule(<Translate content="validate.fieldRequired" />),
            backup_tmp_path: requiredRule(<Translate content="validate.fieldRequired" />),
            vnc_proxy_port: requiredRule(<Translate content="validate.fieldRequired" />),
            vm: requiredRule(<Translate content="validate.fieldRequired" />),
            hdd: requiredRule(<Translate content="validate.fieldRequired" />),
            ram: requiredRule(<Translate content="validate.fieldRequired" />),
            vcpu: requiredRule(<Translate content="validate.fieldRequired" />),
            balance_strategy: requiredRule(<Translate content="validate.fieldRequired" />),
            virtualization_types: requiredRule(<Translate content="validate.fieldRequired" />),
            'concurrent_backups.create': intMinRule(<Translate content="validate.fieldRequired" params={{ min: 1 }}/>, 1),
            'concurrent_backups.restore': intMinRule(<Translate content="validate.fieldRequired" params={{ min: 1 }}/>, 1),
        };

        if (values.network.ip_for_vpc_network) {
            rules['network.ip_for_vpc_network'] = ipRule(<Translate content="validate.badIpAddress" />, 4);
        }

        const errors = validate<ISettings>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        clearFormErrors();

        await updateSettings(computeResource.id, {
            iso_path: values.iso_path,
            cache_path: values.cache_path,
            backup_tmp_path: values.backup_tmp_path,
            vnc_proxy_port: values.vnc_proxy_port,
            network: {
                type: values.network.type,
                bridges: values.network.bridges,
                ip_for_vpc_network: values.network.ip_for_vpc_network,
            },
            limits: {
                vm: { total: values.vm.limit, unlimited: !values.vm.is_enabled },
                hdd: { total: values.hdd.limit, unlimited: !values.hdd.is_enabled },
                ram: { total: values.ram.limit * GiB, unlimited: !values.ram.is_enabled },
                vcpu: { total: values.vcpu.limit, unlimited: !values.vcpu.is_enabled },
            },
            balance_strategy: values.balance_strategy,
            virtualization_types: values.virtualization_types,
            vs_disk_cache_mode: values.vs_disk_cache_mode || null,
            concurrent_backups: {
                create: values.concurrent_backups.create,
                restore: values.concurrent_backups.restore,
            },
        });
        onClose();
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const createLimitOnChange = (key: string) => () => {
        setSubmitValues((state) => ({
            ...state,
            [key]: {
                ...state[key],
                is_enabled: !state[key].is_enabled,
                limit: !state[key].is_enabled ? state[key].limit : 0,
            },
        }));
    };

    const renderLimitField = (key: string, measure: string) => (
        <Limit
            key={key}
            onChangeIsEnabled={createLimitOnChange(key)}
            limit={submitValues[key]}
            fieldName={`${key}[limit]`}
            fieldLabel={<Translate content={`computeResource.settings.form.limits.${measure}`}/>}
            label={<Translate content={`computeResource.settings.form.limits.${key}`}/>}
            required={true}
            data-cy={dataCySelector(computeResource.id, key)}
        />
    );

    const getBalanceStrategyValue = (value: string) => balanceStrategyOptions.find((option) => option.value === value);

    const canChangeNetworkType = (from: NetworkType, to: NetworkType) => {
        switch(from) {
        case NetworkType.BRIDGED:
        case NetworkType.ROUTED:
            return [NetworkType.BRIDGED, NetworkType.ROUTED].includes(to);
        case NetworkType.VZ_ROUTED:
            return to === NetworkType.VZ_ROUTED;
        }
        return false;
    };

    const networkTypeOptions = Object.values(NetworkType).map((type) => {
        const canChange = canChangeNetworkType(submitValues.network.type, type);
        let tooltip;
        if (canChange || computeResource.vms_count > 0) {
            tooltip = null;
        } else {
            tooltip = type === NetworkType.VZ_ROUTED
                ? (<Translate content="computeResource.settings.form.network.vzRoutedIsNotAllowed"/>)
                : (<Translate content="computeResource.settings.form.network.vzRoutedIsOnlyAllowed"/>);
        }

        return {
            value: type.toString(),
            title: humanizeNetworkType(type),
            disabled: !canChange || computeResource.vms_count > 0,
            tooltip,
        };
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleBalanceStrategyChange = (option: any) => {
        setSubmitValues({
            ...submitValues,
            balance_strategy: option.value.toString(),
        });
    };

    return (
        <Loader isLoading={isLoading} center={false}>
            <Form
                id="settingsForm"
                onSubmit={handleCreate}
                onFieldChange={handleFieldChange}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
                footerClassName="hidden"
            >
                <Section>
                    <FormFieldText
                        size="fill"
                        name="iso_path"
                        label={<Translate content="computeResource.settings.form.isoPath" />}
                        required={true}
                    />
                    <FormFieldText
                        size="fill"
                        name="cache_path"
                        label={<Translate content="computeResource.settings.form.cachePath" />}
                        required={true}
                    />
                    <FormFieldText
                        size="fill"
                        name="backup_tmp_path"
                        label={<Translate content="computeResource.settings.form.backupTmpPath" />}
                        required={true}
                    />
                    <FormField
                        label={<Translate content="computeResource.settings.form.concurrentBackups.title" />}
                        required={true}
                    >
                        <Columns gap={SIZE.MD} vertical={false}>
                            <Column width={219}>
                                <FormFieldNumber
                                    name="concurrent_backups[create]"
                                    label={<Translate content="computeResource.settings.form.concurrentBackups.create" />}
                                    size={SIZE.FILL}
                                    min={1}
                                    vertical
                                />
                            </Column>
                            <Column width={219}>
                                <FormFieldNumber
                                    name="concurrent_backups[restore]"
                                    label={<Translate content="computeResource.settings.form.concurrentBackups.restore" />}
                                    size={SIZE.FILL}
                                    min={1}
                                    vertical
                                />
                            </Column>
                        </Columns>
                    </FormField>
                    <FormFieldText
                        size="fill"
                        name="vnc_proxy_port"
                        label={<Translate content="computeResource.settings.form.vncProxyPort" />}
                        required={true}
                    />
                    <FormField
                        name="balance_strategy"
                        label={<Translate content="computeResource.settings.form.balanceStrategy" />}
                        required={true}
                        description={<Translate content="computeResource.settings.form.balanceStrategyDescription" />}
                    >
                        {({ getId }) => (
                            <SelectInput<ISelectOption>
                                inputId={getId()}
                                options={balanceStrategyOptions}
                                isClearable={false}
                                onChange={handleBalanceStrategyChange}
                                value={getBalanceStrategyValue(submitValues.balance_strategy)}
                            />
                        )}
                    </FormField>
                    <FormFieldSelect
                        required={true}
                        name="virtualization_types"
                        label={<Translate content="computeResource.settings.form.virtualizationTypes.title" />}
                        multiple
                        size={SIZE.FILL}
                        value={submitValues.virtualization_types}
                    >
                        {(computeResource.capabilities.kvm
                            || computeResource.settings.virtualization_types.includes(VirtualizationType.KVM)) && (
                            <SelectOption value={VirtualizationType.KVM}>
                                {VIRTUALIZATION_TYPE_TRANSLATION_MAP[VirtualizationType.KVM]}
                            </SelectOption>
                        )}
                        {(computeResource.capabilities.vz
                            || computeResource.settings.virtualization_types.includes(VirtualizationType.VZ)) && (
                            <SelectOption value={VirtualizationType.VZ}>
                                {VIRTUALIZATION_TYPE_TRANSLATION_MAP[VirtualizationType.VZ]}
                            </SelectOption>
                        )}
                    </FormFieldSelect>
                </Section>
                <Section vertical={true} title={<Translate content="computeResource.settings.form.network.title" />}>
                    <FormField
                        fullDescription={
                            <Translate
                                content="computeResource.settings.form.network.fullDescription"
                                params={{
                                    link: (
                                        <a
                                            href="https://docs.solusvm.com/v2/administrator-guide/Choosing%2Ba%2BCompute%2BResource%2BNetwork%2BType.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Translate content="computeResource.settings.form.network.fullDescriptionLink" />
                                        </a>
                                    ),
                                }}
                            />
                        }
                        name="network[type]"
                        required={true}
                        label={<Translate content="computeResource.settings.form.network.type" />}
                    >
                        {({ setValue }) => (
                            <Tooltip
                                shown={computeResource.vms_count > 0}
                                title={<Translate content="computeResource.settings.form.network.description"/>}>
                                <SegmentedControl
                                    buttons={networkTypeOptions}
                                    selected={submitValues.network.type}
                                    onChange={setValue}
                                />
                            </Tooltip>
                        )}
                    </FormField>
                    <FormFieldText
                        size={SIZE.FILL}
                        name="network[ip_for_vpc_network]"
                        label={<Translate content="computeResource.settings.form.network.ipForVpcNetwork" />}
                    />
                </Section>
                <Section vertical={true} title={<Translate content="computeResource.settings.form.limits.title" />}>
                    {limitSections.map(item => renderLimitField(item.key, item.measure))}
                </Section>
                <Section title={<Translate content="computeResource.settings.form.kvm.title" />}>
                    <FormFieldSelect
                        size="fill"
                        name="vs_disk_cache_mode"
                        data-cy={COMPUTE_RESOURCE_SETTINGS.VS_DISK_CACHE_MODE}
                        description={<Translate content="computeResource.settings.form.kvm.diskCacheModeDescription" />}
                        clearable={true}
                        label={<Translate content="computeResource.settings.form.kvm.diskCacheMode" />}
                    >
                        {diskCacheModeOptions}
                    </FormFieldSelect>
                </Section>
            </Form>
            <Button
                type="submit"
                form="settingsForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemCreating}
            >
                <Translate content="computeResource.settings.form.saveBtn" />
            </Button>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isItemCreating: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_COMPUTE_RESOURCE_SETTINGS),
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_SETTINGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialog);
