// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import { IApplicationResponse } from 'common/api/resources/Application';
import { ServerIcon } from 'common/components/ServerIcon/ServerIcon';
import { COLORS } from 'common/theme';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import {
    CutTitle,
    Tooltip,
} from 'common/components';
import { ICONS } from 'common/constants';
import {
    ApplicationItem,
    ItemContainer,
    ItemContent,
    ItemContentName,
    LogoContainer,
} from 'common/components/serverType/Styles';
import { dataCySelector } from 'common/tests/selectors';
import { useDisabledEntitiesManager } from 'common/helpers/ManagerOfDisabledEntities';

export interface IApplicationCardProps {
    application: IApplicationResponse;
    isSelected: boolean;
    onApplicationSelected: (id: number) => void;
}

export const ApplicationCard: React.FC<IApplicationCardProps> = ({
    application,
    onApplicationSelected,
    isSelected,
}) => {

    const manager = useDisabledEntitiesManager();

    const applicationStatus = React.useMemo(
        () => manager.getApplicationStatus(application),
        [manager, application]
    );

    const tooltips = applicationStatus?.isDisabled
        ? applicationStatus
            .conflictMessages
            .map((conflictMessage, ind) => (
                <div key={ind}>
                    <Translate content={conflictMessage.messageKey} params={conflictMessage.params}/>
                </div>
            ))
        : null;

    const isDisabled = !!applicationStatus?.isDisabled ?? false;

    const handleClick = () => !isDisabled && onApplicationSelected(application.id);

    return (
        <Tooltip shown={isDisabled} title={tooltips}>
            <ApplicationItem onClick={handleClick} isSelected={isSelected} isDisabled={isDisabled}>
                <ItemContainer data-cy={dataCySelector(application.id, PROJECT_SERVER_PAGE.APPLICATION_CARD)}>
                    <div>
                        <LogoContainer>
                            {isSelected ? <Icon name={ICONS.CHECK_MARK} /> : <ServerIcon width={25} icon={application.icon} />}
                        </LogoContainer>
                    </div>
                    <ItemContent>
                        <ItemContentName data-cy={PROJECT_SERVER_PAGE.APPLICATION_CARD}>
                            <CutTitle title={application.name} withTooltip={!isDisabled}/>
                        </ItemContentName>
                    </ItemContent>
                    {!application.is_visible && (
                        <Icon name="eye-closed" size="16" style={{ color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60 }}/>
                    )}
                </ItemContainer>
            </ApplicationItem>
        </Tooltip>
    );
};
