// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    BillingType,
    ISettingsResponse,
} from 'common/api/resources/Settings';
import {
    FormFieldSelect,
    FormFieldText,
    Section,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import * as settingsActions from 'common/modules/settings/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';

export type BillingIntegrationSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const BillingIntegrationSettings: React.FC<BillingIntegrationSettingsProps> = ({
    settings,
    settingsActions: { saveSettings },
}) => {
    const translate = useTranslate();

    const humanBillingTypesMap = {
        [BillingType.NULL]: translate('settings.billingIntegration.types.none'),
        [BillingType.WHMCS]: translate('settings.billingIntegration.types.whmcs'),
        [BillingType.CUSTOM]: translate('settings.billingIntegration.types.custom'),
    };

    const billingTypeOptions = Object.values(BillingType).map((value) => (
        <option key={value} value={value}>
            {humanBillingTypesMap[value]}
        </option>
    ));

    const getInputName = (name: string) => `billing_integration[drivers][${settings.billing_integration.type}][${name}]`;

    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            billing_integration: values.billing_integration,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.billingIntegration"
            onSubmit={handleSubmit}
        >
            <Section>
                <FormFieldSelect
                    size="xl"
                    name="billing_integration[type]"
                    required={true}
                    label={<Translate content="settings.billingIntegration.type" />}
                >
                    {billingTypeOptions}
                </FormFieldSelect>
                {[BillingType.WHMCS, BillingType.CUSTOM].includes(settings.billing_integration.type) && (
                    <>
                        <FormFieldText
                            size="xl"
                            name={getInputName('url')}
                            label={<Translate content="settings.billingIntegration.whmcs.url" />}
                            required={true}
                        />
                        <FormFieldText
                            size="xl"
                            name={getInputName('token')}
                            label={<Translate content="settings.billingIntegration.whmcs.token" />}
                        />
                    </>
                )}
            </Section>
        </SettingsWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingIntegrationSettings);
