// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Container } from 'admin/common/components/FilterForm/Styles';
import { Form } from '@plesk/ui-library';

export interface IFilterFormProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values?: Record<string, any>;
    children: React.ReactNode;
    alignRight?: boolean;
}

const FilterForm: React.FC<IFilterFormProps> = ({
    values,
    children,
    alignRight,
}) => (
    <Form
        values={values}
        hideRequiredLegend={true}
        submitButton={false}
        cancelButton={false}
        applyButton={false}
        vertical={true}
        footerClassName="hidden"
        style={{ flex: 1 }}
    >
        <Container alignRight={alignRight}>
            {children}
        </Container>
    </Form>
);

export default FilterForm;
