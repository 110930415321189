// Copyright 2025. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { IAppState } from 'admin/core/store';
import * as types from 'admin/application/constants/types';
import {
    applications,
    IApplicationCreateRequest,
    IApplicationPatchRequest,
    IApplicationResponse,
} from 'common/api/resources/Application';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    removeBatch,
    update,
} from 'common/actions/actionsWrapper';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';

export const setApplicationList = createCustomAction(
    types.SET_APPLICATION_LIST,
    (data: IPaginateApiResponse<IApplicationResponse[]>) => ({ payload: data })
);
export const setApplicationItem = createCustomAction(
    types.SET_APPLICATION_ITEM,
    (data: IApplicationResponse) => ({ payload: data })
);
export const unsetApplicationItem = createCustomAction(types.UNSET_APPLICATION_ITEM);
export const addApplicationItem = createCustomAction(
    types.ADD_APPLICATION_ITEM,
    (data: IApplicationResponse) => ({ payload: data })
);
export const updateApplicationItem = createCustomAction(
    types.UPDATE_APPLICATION_ITEM,
    (data: IApplicationResponse) => ({ payload: data })
);
export const updateApplicationItemPosition = createCustomAction(
    types.UPDATE_APPLICATION_ITEM_POSITION,
    (id: number, position: number) => ({ payload: { id, position } })
);
export const removeApplicationItem = createCustomAction(
    types.REMOVE_APPLICATION_ITEM,
    (id: number) => ({ payload: id })
);
export const appendApplications = createCustomAction(
    types.APPEND_APPLICATIONS,
    (data: IPaginateApiResponse<IApplicationResponse[]>) => ({ payload: data })
);
export const clearDefault = createCustomAction(types.CLEAR_DEFAULT);
export const setApplicationItemIsLoading = createCustomAction(
    types.SET_APPLICATION_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const unsetApplicationItemIsLoading = createCustomAction(
    types.UNSET_APPLICATION_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const removeApplicationItems = createCustomAction(
    types.REMOVE_APPLICATION_ITEMS,
    (ids: number[]) => ({ payload: ids })
);
export const setApplicationItemIsDeleting = createCustomAction(
    types.SET_APPLICATION_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getApplications = (params?: IPaginatedWithSearch) => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.APPLICATION_LIST,
    action: setApplicationList,
    apiCall: () => applications.list(params),
});

export const createApplication = (data: IApplicationCreateRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.APPLICATION_ITEM_SAVE,
    action: addApplicationItem,
    apiCall: applications.create,
    translations: {
        success: 'application.toasts.applicationSaved',
    },
});

export const getApplication = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: applications.item,
    action: setApplicationItem,
    loadingFlag: LOADING_FLAGS.APPLICATION_ITEM,
});

export const updateApplication = (id: number, data: IApplicationCreateRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: applications.update,
    action: updateApplicationItem,
    loadingFlag: LOADING_FLAGS.APPLICATION_ITEM_SAVE,
    translations: {
        success: 'application.toasts.applicationSaved',
    },
});

export const updateDefaultApplication = (id: number, data: IApplicationPatchRequest) => async(dispatch: Dispatch) => {
    dispatch(setApplicationItemIsLoading(id));

    try {
        const result = await applications.patch(id, data);

        dispatch(clearDefault());
        dispatch(updateApplicationItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetApplicationItemIsLoading(id));
    }
};

export const updateApplicationVisibility = (id: number, data: IApplicationPatchRequest) => async(dispatch: Dispatch) => {
    dispatch(setApplicationItemIsLoading(id));

    try {
        const result = await applications.patch(id, data);

        dispatch(updateApplicationItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetApplicationItemIsLoading(id));
    }
};

export const removeApplication = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: applications.remove,
    setLoadingAction: setApplicationItemIsDeleting,
    action: removeApplicationItem,
    loadingFlag: LOADING_FLAGS.APPLICATION_ITEM_REMOVE,
    translations: {
        success: 'application.toasts.applicationDeleted',
    },
});

export const loadApplicationsOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.application.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.APPLICATION_LIST_INFINITY_SCROLL);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendApplications,
        loadingFlag: LOADING_FLAGS.APPLICATION_LIST_INFINITY_SCROLL,
    });
};

export const removeApplications = (ids: number[]) => async (dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: applications.removeBatch,
    action: removeApplicationItems,
    loadingFlag: LOADING_FLAGS.APPLICATION_BATCH_REMOVE,
    translations: {
        success: 'application.toasts.applicationBatchDeleted',
    },
});

export const updateApplicationPosition = (source_id: number, data: IApplicationPatchRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.APPLICATION_ITEM_SAVE));

    if (data.position) {
        dispatch(updateApplicationItemPosition(source_id, data.position));
    }

    try {
        const result = await applications.patch(source_id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'application.toasts.applicationSaved')(dispatch);
        dispatch(updateApplicationItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.APPLICATION_ITEM_SAVE));
    }
};