// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    IVlanResponse,
    VlanMode,
} from 'common/api/resources/Vlan';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as vlanActions from 'admin/vlan/actions';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';

interface IVlanReducer {
    item: IVlanResponse;
    list: IPaginateApiResponse<IVlanResponse[]>;
}

export type VlanActions = ActionType<typeof vlanActions>;
export type VlanState = Readonly<IVlanReducer>;

export const initialVlanState: IVlanResponse = {
    id: 0,
    name: '',
    tag: 0,
    mode: VlanMode.NATIVE_UNTAGGED,
    trunks: [],
    ip_blocks: [],
};

export default combineReducers<VlanState, VlanActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(vlanActions.setVlanList):
            return action.payload;
        case getType(vlanActions.addVlanItem):
            return addToPaginated(state, action.payload);
        case getType(vlanActions.updateVlanItem):
            return updateInPaginated(state, action.payload);
        case getType(vlanActions.removeVlanItem):
            return deleteFromPaginated(state, action.payload);
        case getType(vlanActions.setVlanItemIsDeleting):
            return updateInPaginated(state, action.payload);
        case getType(vlanActions.setVlanItemIsLoading):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialVlanState, action) => {
        switch (action.type) {
        case getType(vlanActions.setVlanItem):
            return action.payload;
        case getType(vlanActions.unsetVlanItem):
            return initialVlanState;
        default:
            return state;
        }
    },
});