// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    Icon,
    Item,
    Translate,
} from '@plesk/ui-library';
import {
    DashboardItemContainer,
    ItemAdditionalDescription,
    ItemDescription,
} from 'admin/dashboard/containers/items/Styles';

export type Ipv4ItemProps =
    ReturnType<typeof mapStateToProps>;

export const Ipv4Item: React.FC<Ipv4ItemProps> = ({
    stats,
}) => (
    <DashboardItemContainer>
        <Item
            icon={<Icon name="ip-addresses" intent="info" />}
            description={(
                <>
                    <ItemDescription>{stats.used}</ItemDescription>
                    <ItemAdditionalDescription>
                        <Translate content="dashboard.of" />
                        &nbsp;
                        <span>{stats.total}</span>
                    </ItemAdditionalDescription>
                </>
            )}
            view="card"
            title={<Translate content="dashboard.cards.ipv4" />}
        />
    </DashboardItemContainer>
);

const mapStateToProps = (state: RootState) => ({
    stats: state.dashboard.data.ipv4,
});

export default connect(mapStateToProps)(Ipv4Item);
