// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import { IShortVmResponse } from 'common/api/resources/ComputeResourceVm';
import { IShortStorageTagResponse } from 'common/api/resources/StorageTag';
import { ILimit } from 'common/api/resources/model';
import {
    DataUnit,
    DiskBandwidthUnit,
    IOpsUnit,
} from 'common/helpers/units';
import { IShortLocationResponse } from 'common/api/resources/Location';
import { IShortPlanResponse } from 'common/api/resources/Plan';

export interface IOfferParametersPrimaryDisk {
    iops: ILimit<IOpsUnit>;
    bandwidth: ILimit<DiskBandwidthUnit>;
}

export interface IOfferParametersAdditionalDisk {
    max_size: ILimit<DataUnit>;
    iops: ILimit<IOpsUnit>;
    bandwidth: ILimit<DiskBandwidthUnit>;
}

export type IOfferParameters = IOfferParametersPrimaryDisk | IOfferParametersAdditionalDisk | null;

export interface IOfferRequest {
    name: string;
    description?: string;
    type: OfferType;
    price_mode: OfferPriceMode;
    per_hour: number;
    per_month: number;
    storage_tag_id?: number;
    is_visible: boolean;
    position?: number;
    available_locations?: number[];
    available_plans?: number[];
    parameters: IOfferParameters;
}

export interface IOfferPatchRequest {
    is_visible?: boolean;
    position?: number;
}

export enum OfferType {
    PRIMARY_DISK = 'primary_disk',
    ADDITIONAL_DISK = 'additional_disk',
}

export enum OfferPriceMode {
    PER_UNIT = 'per_unit',
    PER_GIB = 'per_gib',
    PERCENT = 'percent',
}

export interface IOfferResponse {
    id: number;
    name: string;
    description: string;
    type: OfferType;
    position: number;
    price_mode: OfferPriceMode;
    per_hour: number;
    per_month: number;
    storage_tag?: IShortStorageTagResponse;
    is_visible: boolean;
    compute_resource_vms?: IShortVmResponse[];
    available_locations?: IShortLocationResponse[];
    available_plans?: IShortPlanResponse[];
    parameters: IOfferParameters;

    is_deleting?: boolean;
}

export interface IShortOfferResponse {
    id: number;
    name: string;
    description: string;
    type: OfferType;
    price_mode: OfferPriceMode;
    per_hour: number;
    per_month: number;
    parameters: IOfferParameters;
}

export interface IOfferListFilters {
    search?: string;
}

export interface IOfferListRequest extends IRequest<IOfferListFilters>, IPaginated {}
