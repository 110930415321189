// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_LIMIT_GROUP_LIST = 'limit-groups/SET_LIMIT_GROUPS_LIST';
export const REMOVE_LIMIT_GROUP_ITEM = 'limit-groups/REMOVE_LIMIT_GROUP_ITEM';
export const UPDATE_LIMIT_GROUP_ITEM = 'limit-groups/UPDATE_LIMIT_GROUP_ITEM';
export const ADD_LIMIT_GROUP_ITEM = 'limit-groups/ADD_LIMIT_GROUP_ITEM';
export const SET_LIMIT_GROUP_ITEM = 'limit-groups/SET_LIMIT_GROUP_ITEM';
export const UNSET_LIMIT_GROUP_ITEM = 'limit-groups/UNSET_LIMIT_GROUP_ITEM';
export const APPEND_LIMIT_GROUPS = 'limit-groups/APPEND_LIMIT_GROUPS';
export const SET_LIMIT_GROUP_ITEM_IS_DELETING = 'limit-groups/SET_LIMIT_GROUP_ITEM_IS_DELETING';
