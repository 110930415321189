// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IIsoImageListRequest,
    IIsoImageRequest,
    IIsoImageResponse,
} from 'common/api/resources/IsoImage/model';

export const isoImage = {
    list: (params?: IIsoImageListRequest) => api.get<IPaginateApiResponse<IIsoImageResponse[]>>('iso_images', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IIsoImageResponse>>(`iso_images/${id}`),
    remove: (id: number) => api.delete(`iso_images/${id}`),
    create: (data: IIsoImageRequest) => api.post<IApiResponse<IIsoImageResponse>>('iso_images', data),
    update: (id: number, data: IIsoImageRequest) => api.put<IApiResponse<IIsoImageResponse>>(`iso_images/${id}`, data),
};
