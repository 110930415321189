// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import SwitchWithConfirmation,
{ IConfirmOn } from 'common/components/SwitchWithConfirmation';
import {
    Card,
    CardRow,
    CardRowColumn,
    CardsContainer,
    CardWithSwitchBody,
} from 'common/components/CardWithSwitch/Styles';

export interface ICardWithSwitchProps {
    title: React.ReactNode;
    isEnabled: boolean;
    isLoading: boolean;
    handleChange: (isEnabled: boolean) => void;
    confirmOnEnable?: IConfirmOn;
    confirmOnDisable?: IConfirmOn;
    isDisabled?: boolean;
    tooltip?: React.ReactNode;
    'switch-data-cy'?: string;
}

export const CardWithSwitch: React.FC<React.PropsWithChildren<ICardWithSwitchProps>> = ({
    children,
    title,
    isEnabled,
    isDisabled,
    tooltip,
    isLoading,
    handleChange,
    confirmOnEnable,
    confirmOnDisable,
    'switch-data-cy': switchDataCy,
}) => (
    <CardsContainer>
        <Card size="351px">
            <CardRow>
                <CardRowColumn size="35px">
                    <SwitchWithConfirmation
                        loading={isLoading}
                        checked={isEnabled}
                        onChange={handleChange}
                        confirmOnDisable={confirmOnDisable}
                        confirmOnEnable={confirmOnEnable}
                        disabled={!!isDisabled}
                        tooltip={tooltip}
                        data-cy={switchDataCy}
                    />
                </CardRowColumn>
                <CardRowColumn>
                    <h3>{title}</h3>
                    <CardWithSwitchBody>
                        {children}
                    </CardWithSwitchBody>
                </CardRowColumn>
            </CardRow>
        </Card>
    </CardsContainer>
);
