// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import Demo from 'client/account/containers/Demo/Demo';
import { StyledForm } from 'client/common/styles/Form';

import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { TabsExpander } from 'client/common/styles/Tabs';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import ViewSelect from 'common/components/ViewSelect';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { setViewType } from 'common/modules/app/viewType/actions';
import {
    ICONS,
    INTENT_TYPE,
    VIEW_TYPE,
} from 'common/constants';
import Settings from 'client/account/containers/Account/Settings/Settings';
import AccountHeader from 'client/account/containers/Account/AccountHeader/AccountHeader';
import { ACCOUNT_TABS } from 'client/account/constants';
import ApiTokenList from 'client/account/containers/Account/ApiTokens/ApiTokenList';
import SshKeyList from 'client/account/containers/Account/SshKeys/SshKeyList';
import TwoFactorAuthForm from 'common/modules/account/containers/TwoFactorAuthForm';

export type AccountProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Account: React.FC<AccountProps> = ({
    user,
    history,
    location: { hash },
    selectedView,
    setSelectedView,
}) => {
    if (!user.has_verified_email) {
        return <Demo />;
    }

    if (!hash) {
        history.replace(ACCOUNT_TABS.SETTINGS);
        return null;
    }

    const tabs = Object.values(ACCOUNT_TABS);
    const handleTabSelect = (index: number) => history.replace(tabs[index]);
    const handleViewChange = (value: string) => setSelectedView(VIEW_TYPE[value]);

    return (
        <>
            <AccountHeader />
            <Tabs selectedIndex={tabs.findIndex(tab => tab === hash)} onSelect={handleTabSelect}>
                <TabList>
                    <Tab><Translate content="account.tabs.settings" /></Tab>
                    <Tab><Translate content="account.tabs.apiTokens" /></Tab>
                    <Tab><Translate content="account.tabs.sshKeys" /></Tab>
                    <Tab>
                        <Icon name={ICONS.SHIELD} intent={user.is_two_factor_auth_enabled ? INTENT_TYPE.SUCCESS : INTENT_TYPE.DANGER}/>
                        &nbsp;
                        <Translate content="account.tabs.twoFactorAuth" />
                    </Tab>
                    <TabsExpander />
                    {[ACCOUNT_TABS.API_TOKENS.toString(), ACCOUNT_TABS.SSH_KEYS.toString()].includes(hash) && (
                        <ViewSelect
                            selected={selectedView}
                            onChange={handleViewChange}
                        />
                    )}
                </TabList>
                <TabPanel>
                    <StyledForm>
                        <Settings />
                    </StyledForm>
                </TabPanel>
                <TabPanel><ApiTokenList /></TabPanel>
                <TabPanel><SshKeyList /></TabPanel>
                <TabPanel>
                    <StyledForm>
                        <TwoFactorAuthForm />
                    </StyledForm>
                </TabPanel>
            </Tabs>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    selectedView: state.app.viewType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSelectedView: bindActionCreators(setViewType, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
