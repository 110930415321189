// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as externalIntegrationActions from 'admin/externalIntegration/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    ExternalIntegrationType,
    IExternalIntegrationResponse,
} from 'common/api/resources/ExternalIntegration';

interface IExternalIntegration {
    list: IPaginateApiResponse<IExternalIntegrationResponse[]>;
    item: IExternalIntegrationResponse;
}

export type ExternalIntegrationAction = ActionType<typeof externalIntegrationActions>;
export type ExternalIntegrationState = Readonly<IExternalIntegration>;

export const initialExternalIntegrationState: IExternalIntegrationResponse = {
    id: 0,
    name: '',
    type: ExternalIntegrationType.CPANEL,
    applications_count: 0,
    servers_count: 0,
    credentials: {
        user: '',
        password: '',
        package_ids: [],
        group_id: '',
    },
    is_deleting: false,
};

export default combineReducers<ExternalIntegrationState, ExternalIntegrationAction>({
    list: (state = paginateInitialState, action: ExternalIntegrationAction) => {
        switch (action.type) {
        case getType(externalIntegrationActions.setExternalIntegrationList):
            return action.payload;
        case getType(externalIntegrationActions.addExternalIntegrationItem):
            return addToPaginated(state, action.payload);
        case getType(externalIntegrationActions.updateExternalIntegrationItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }

                    return item;
                }),
            };
        case getType(externalIntegrationActions.setExternalIntegrationIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialExternalIntegrationState, action) => {
        switch (action.type) {
        case getType(externalIntegrationActions.setExternalIntegrationItem):
            return action.payload;
        case getType(externalIntegrationActions.unsetExternalIntegrationItem):
            return { ...initialExternalIntegrationState };
        default:
            return state;
        }
    },
});
