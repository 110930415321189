// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    Icon,
    Item,
    Status,
    Translate,
} from '@plesk/ui-library';
import {
    DashboardItemContainer,
    ItemDescription,
    StatusContainer,
    StatusCount,
    StatusLinks,
} from 'admin/dashboard/containers/items/Styles';

export type ComputeResourceItemProps =
    ReturnType<typeof mapStateToProps>;

export const ComputeResourceItem: React.FC<ComputeResourceItemProps> = ({
    stats,
}) => (
    <DashboardItemContainer>
        <Item
            style={{ minHeight: '175px' }}
            icon={<Icon name="resource" intent="info" />}
            description={<ItemDescription>{stats.total}</ItemDescription>}
            view="card"
            title={(
                <Translate content="dashboard.cards.computeResources" />
            )}
        >
            <StatusContainer>
                <StatusLinks>
                    <Status intent="success" compact={true}>
                        <Translate content="computeResource.statuses.active" />
                    </Status>
                    <Status intent="danger" compact={true}>
                        <Translate content="computeResource.statuses.failed" />
                    </Status>
                    <Status intent="inactive" compact={true}>
                        <Translate content="computeResource.statuses.unavailable" />
                    </Status>
                </StatusLinks>
                <div>
                    <StatusCount>{stats.status.active}</StatusCount>
                    <StatusCount>{stats.status.failed}</StatusCount>
                    <StatusCount>{stats.status.unavailable}</StatusCount>
                </div>
            </StatusContainer>
        </Item>
    </DashboardItemContainer>
);

const mapStateToProps = (state: RootState) => ({
    stats: state.dashboard.data.compute_resources,
});

export default connect(mapStateToProps)(ComputeResourceItem);
