// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    FormField,
    Input,
    Translate,
} from '@plesk/ui-library';
import styled from 'styled-components';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    ChangeEvent,
    ReactNode,
} from 'react';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import * as React from 'react';

const Row = styled.div`
    display: flex;
`;

export interface ITagValueInputProps {
    label: ReactNode;
    name: string;
    tagValue: {
        id?: number;
        value: string;
    };
    isDeletionDisabled: boolean;
    onChange: (value: string) => void;
    onDelete: () => void;
}

export const TagValueInput: React.FC<ITagValueInputProps> = ({
    label,
    name,
    tagValue,
    isDeletionDisabled,
    onChange,
    onDelete,
}) => (
    <FormField
        label={label}
        name={name}
        vertical
    >
        {({ getId }) => (
            <Row>
                <Input
                    id={getId()}
                    size={SIZE.FILL}
                    value={tagValue.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                />
                <ButtonWithConfirmation
                    translations={{
                        title: <Translate content="tag.valueDeleteConfirmation.title" />,
                        button: <Translate content="tag.valueDeleteConfirmation.button" />,
                        text: <Translate content="tag.valueDeleteConfirmation.text" />,
                        tooltip: <Translate content="tag.valueDeleteConfirmation.tooltip" />,
                    }}
                    handleConfirm={onDelete}
                    icon={ICONS.CROSS_MARK}
                    withConfirmation={tagValue.id !== undefined}
                    disabled={isDeletionDisabled}
                />
            </Row>
        )}
    </FormField>
);
