// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const Header = styled.div`
    top: 0;
    background: #fff;
    padding: 50px 0 0;
    z-index: 3000;
    
    @media (max-width: 560px) {
        top: 48px;
        padding: 20px 0 5px;
    }
`;

export const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 16px;
    
    @media (max-width: 560px) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 6px;
    }
`;

