// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    Icon,
    Item,
    Translate,
} from '@plesk/ui-library';
import {
    DashboardItemContainer,
    ItemAdditionalDescription,
    ItemDescription,
} from 'admin/dashboard/containers/items/Styles';
import {
    DataUnit,
    convertToDataUnit,
    usagePercentage,
} from 'common/helpers/units';

export type ClusterDiskItemProps =
    ReturnType<typeof mapStateToProps>;

export const ClusterDiskItem: React.FC<ClusterDiskItemProps> = ({
    stats,
}) => {
    const usage = usagePercentage(stats.total, stats.used);
    const formattedTotal = convertToDataUnit(stats.total, DataUnit.GiB);
    const formattedUsed = convertToDataUnit(stats.used, DataUnit.GiB);

    return (
        <DashboardItemContainer>
            <Item
                icon={<Icon name="hard-drive" intent="info" />}
                description={(
                    <>
                        <ItemDescription data-cy="cluster-disk-usage">
                            {usage}%
                        </ItemDescription>
                        <ItemAdditionalDescription>
                            <Translate
                                content="dashboard.cards.usage"
                                params={{
                                    used: formattedUsed,
                                    total: formattedTotal,
                                    unit: 'GiB',
                                }}
                            />
                        </ItemAdditionalDescription>
                    </>
                )}
                view="card"
                title={<Translate content="dashboard.cards.clusterDisk" />}
            />
        </DashboardItemContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    stats: state.dashboard.data.cluster_disk,
});

export default connect(mapStateToProps)(ClusterDiskItem);
