// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_OS_IMAGE_LIST = 'osImage/SET_OS_IMAGE_LIST';
export const APPEND_OS_IMAGES = 'osImage/APPEND_OS_IMAGES';
export const ADD_OS_IMAGE_ITEM = 'osImage/ADD_OS_IMAGE_ITEM';
export const SET_OS_IMAGE_ITEM = 'osImage/SET_OS_IMAGE_ITEM';
export const UNSET_OS_IMAGE_ITEM = 'osImage/UNSET_OS_IMAGE_ITEM';
export const REMOVE_OS_IMAGE_ITEM = 'osImage/REMOVE_OS_IMAGE_ITEM';
export const REMOVE_OS_IMAGE_ITEMS = 'osImage/REMOVE_OS_IMAGE_ITEMS';
export const UPDATE_OS_IMAGE_ITEM = 'osImage/UPDATE_OS_IMAGE_ITEM';
export const UPDATE_OS_IMAGE_ITEM_POSITION = 'osImage/UPDATE_OS_IMAGE_ITEM_POSITION';
export const CLEAR_DEFAULT = 'osImage/CLEAR_DEFAULT';
export const SET_OS_IMAGE_ITEM_IS_LOADING = 'osImage/SET_OS_IMAGE_ITEM_IS_LOADING';
export const UNSET_OS_IMAGE_ITEM_IS_LOADING = 'osImage/UNSET_OS_IMAGE_ITEM_IS_LOADING';
export const SET_OS_IMAGE_ITEM_IS_DELETING = 'osImage/SET_OS_IMAGE_ITEM_IS_DELETING';
export const SET_OS_IMAGE_VERSION_ITEM = 'osImage/SET_OS_IMAGE_VERSION_ITEM';
export const UNSET_OS_IMAGE_VERSION_ITEM = 'osImage/UNSET_OS_IMAGE_VERSION_ITEM';
export const UPDATE_OS_IMAGE_VERSION_ITEM = 'osImage/UPDATE_OS_IMAGE_VERSION_ITEM';
export const REMOVE_OS_IMAGE_VERSION_ITEM = 'osImage/REMOVE_OS_IMAGE_VERSION_ITEM';
export const ADD_OS_IMAGE_VERSION = 'osImage/ADD_OS_IMAGE_VERSION';
export const UPDATE_OS_IMAGE_VERSION_ITEM_POSITION = 'osImage/UPDATE_OS_IMAGE_VERSION_ITEM_POSITION';
export const SET_OS_IMAGE_VERSION_ITEM_IS_LOADING = 'osImage/SET_OS_IMAGE_VERSION_ITEM_IS_LOADING';
export const UNSET_OS_IMAGE_VERSION_ITEM_IS_LOADING = 'osImage/UNSET_OS_IMAGE_VERSION_ITEM_IS_LOADING';
