// Copyright 2025. WebPros International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import * as React from 'react';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import { Translate } from '@plesk/ui-library';
import { createOptionsLoader } from 'common/components/Select/helpers';

export interface IUserFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
    margin_left?: string;
    value?: ValueType<ISelectRequiredOption>;
    isLoading?: boolean;
}

export const UserFilter: React.FC<IUserFilterProps & React.ComponentProps<typeof FixedFormField>> = ({
    onChange,
    margin_left,
    value,
    isLoading,
    ...props
}) => {
    const loadUsers = createOptionsLoader(
        users.list,
        (item: IUserResponse) => ({
            label: item.email,
            value: item.id,
        })
    );

    return (
        <FixedFormField margin_left={margin_left} data-cy={FILTERS.USER} {...props}>
            <AsyncSelectInput
                inputId="user-filter"
                isClearable={true}
                placeholder={<Translate content="filters.user"/>}
                loadOptions={loadUsers}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
                value={value}
                isLoading={isLoading}
                menuPosition="absolute"
                menuPortalTarget={document.body}
            />
        </FixedFormField>
    );
};
