// Copyright 2025. WebPros International GmbH. All rights reserved.

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
    FILTER: 'filter',
};

export const FORM = {
    LANGUAGE_DROPDOWN: 'language_dropdown',
};
