// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const StatusIconContainer = styled.div`
    width: 20px;
    height: 100%;
    margin-right: 6px;

    .pul-icon--success {
        color: ${COLORS.GREEN}!important;
    }

    .pul-icon--info {
        color: ${COLORS.PRIMARY}!important;
    }

    .pul-icon--danger {
        color: ${COLORS.RED}!important;
    }
`;

export const StatusText = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;

export const ErrorText = styled.div`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;

    color: ${COLORS.GREY_80};
`;

ErrorText.displayName = 'ErrorText';

export const InstallState = styled.div`
    display: flex;
    padding-left: 12px;
    width: 250px;
    flex-basis: 250px;
`;

export const StatusSubtext = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: ${COLORS.GREY_80};
    display: flex;
    white-space: nowrap;
`;

export const ProgressContainer = styled.div`
    margin-left: -19px;
    margin-top: 16px;
    margin-bottom: 8px;

    .pul-progress-step {
        width: 250px;
    }

    .pul-progress-step:not(:last-child) {
        margin-bottom: 8px;
    }
`;

export const UpdateErrorsContainer = styled.div`
    margin-top: 16px;
    margin-left: -12px;
`;

export const InstallErrorContainer = styled.div`
    margin-top: 16px;
`;
