// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as serversMigrationActions from 'admin/serversMigration/actions';
import { combineReducers } from 'redux';
import { initialComputeResourceState } from 'admin/computeResource/reducers';
import {
    IPreCheckResponse,
    IServersMigrationResponse,
} from 'common/api/resources/ServersMigration';
import { initialTaskState } from 'admin/task/reducer';
import { updateInPaginated } from 'common/reducers/list';

export interface IServersMigrationReducer {
    list: IPaginateApiResponse<IServersMigrationResponse[]>;
    preCheck: IPreCheckResponse;
}

export type ServersMigrationActions = ActionType<typeof serversMigrationActions>;
export type ServersMigrationState = Readonly<IServersMigrationReducer>;

export const initialServersMigrationState: IServersMigrationResponse = {
    id: 0,
    destination_compute_resource: initialComputeResourceState,
    task: initialTaskState,
    children: [
        initialTaskState,
    ],
    is_deleting: false,
};

export const initialServersMigrationPreCheckState: IPreCheckResponse = {
    are_servers_with_linux_bridge_vpc_network_selected: false,
};

export default combineReducers<ServersMigrationState, ServersMigrationActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {

        case getType(serversMigrationActions.setServersMigrations):
            return action.payload;

        case getType(serversMigrationActions.updateServersMigrationTask):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.task?.id === action.payload.id) {
                        return {
                            ...item,
                            task: {
                                ...item.task!,
                                ...action.payload,
                            },
                        };
                    }

                    return {
                        ...item,
                        children: item.children?.map(task => {
                            if (task.id === action.payload.id) {
                                return {
                                    ...task,
                                    ...action.payload,
                                };
                            }
                            return task;
                        }),
                    };
                }),
            };

        case getType(serversMigrationActions.setServersMigrationIsDeleting):
            return updateInPaginated(state, action.payload);

        default:
            return state;
        }
    },
    preCheck: (state = initialServersMigrationPreCheckState, action) => {
        switch (action.type) {
        case getType(serversMigrationActions.setPreCheck):
            return action.payload;
        default:
            return state;
        }
    },
});
