// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const UserDataHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    .pul-action {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-left: 8px;
    }

    .pul-icon {
        color: ${props => props.theme.primary_color};
        width: 12px;
        height: 12px;
    }

    span:first-child {
        flex: 1;
    }
`;
