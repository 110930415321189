// Copyright 2025. WebPros International GmbH. All rights reserved.

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
};

export const LIST = {
    ADD_ADDITIONAL_DISK: 'add_additional_disk',
};

export const REMOVE_DISK = 'remove_disk';

export const FORM = {
    MAX_SIZE: 'max_size',
    BANDWIDTH: 'bandwidth',
    IOPS: 'iops',
};