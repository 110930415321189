// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as taskActions from 'admin/task/actions';
import TaskTable from 'admin/task/containers/TaskTable';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { ICONS } from 'common/constants';

interface IVmTasksTabProps {
    id: number;
}

export type VmTasksTabProps =
    IVmTasksTabProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const VmTasksTab: React.FC<VmTasksTabProps> = ({
    id,
    list,
    isLoadingList,
    taskActions: {
        getTaskList,
    },
}) => {
    const filters = {
        compute_resource_vm_id: id,
    };

    const isFirstLoading = useRequestCancellationEffect(
        token => getTaskList({ filters }, token),
        []
    );

    // We have to do it because we use filter (by server id) that is hidden from users,
    // and empty view for filtered result is different from general result.
    if (list.data.length === 0 && !isLoadingList) {
        return (
            <EmptyView
                title="task.emptyView.title"
                icon={ICONS.TASKS}
            />
        );
    }

    return (
        <TaskTable
            isFirstLoading={isFirstLoading}
            borderless={true}
            filters={filters}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.task.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_TASK_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VmTasksTab);
