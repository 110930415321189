// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as FormErrorsActions from 'common/modules/app/formErrors/actions';

export type IFormErrors = { [key: string]: string[] } | Record<string, string[]>;

export type FormErrorsAction = ActionType<typeof FormErrorsActions>;
export type FormErrorsState = Readonly<IFormErrors>;

export default (state: FormErrorsState = {}, action: FormErrorsAction) => {
    switch (action.type) {
    case getType(FormErrorsActions.setFormErrors):
        return {
            ...action.payload,
        };
    case getType(FormErrorsActions.clearFormErrors):
        if (Object.keys(state).length === 0) {
            return state;
        }

        return {};
    default:
        return state;
    }
};
