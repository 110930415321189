// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 380px;
`;

export const InputContainer = styled.div`
    div.pul-section-item__title span:first-child {
        font-size: 20px;
        font-weight: 600;
        color: #222;
    }
    
    margin-bottom: 20px;
`;

export const PasswordContainer = styled(InputContainer)`
    .pul-input__input {
        width: 100%;
    }
    
    .pul-input--size-fill {
      width: 100%;
    }
`;