// Copyright 2025. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/serversMigration/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { HTTP_CODES } from 'common/api/constants';
import {
    IPreCheckResponse,
    IServerMigrationRequest,
    IServersMigrationResponse,
    serversMigrations,
} from 'common/api/resources/ServersMigration';
import {
    remove,
    removeBatch,
} from 'common/actions/actionsWrapper';
import { ITaskResponse } from 'common/api/resources/Task';
import {
    clearFormErrors,
    setFormErrors,
} from 'common/modules/app/formErrors/actions';
import { transformErrors } from 'common/validator';
import { CancelTokenSource } from 'axios';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const setServersMigrations = createCustomAction(
    types.SET_SERVERS_MIGRATION_LIST,
    (data: IPaginateApiResponse<IServersMigrationResponse[]>) => ({ payload: data })
);
export const removeServersMigrationItem = createCustomAction(
    types.REMOVE_SERVERS_MIGRATION_ITEM,
    (id: number) => ({ payload: id })
);
export const removeServersMigrationItems = createCustomAction(
    types.REMOVE_SERVERS_MIGRATION_ITEMS,
    (ids: number[]) => ({ payload: ids })
);
export const updateServersMigrationTask = createCustomAction(
    types.UPDATE_SERVERS_MIGRATION_TASK,
    (data: Partial<ITaskResponse>) => ({ payload: data })
);
export const addServersMigrationItem = createCustomAction(
    types.SERVERS_MIGRATION_ADD_ITEM,
    (data: IServersMigrationResponse) => ({ payload: data })
);

export const setServersMigrationIsDeleting = createCustomAction(
    types.SET_SERVERS_MIGRATION_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const setPreCheck = createCustomAction(
    types.SET_SERVERS_MIGRATION_PRE_CHECK,
    (data: IPreCheckResponse) => ({ payload: data })
);

export const getServersMigrations = (request?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SERVERS_MIGRATION_LIST));

    try {
        const result = await serversMigrations.list(request, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setServersMigrations(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SERVERS_MIGRATION_LIST));
    }
};

export const createServersMigration = (data: IServerMigrationRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SERVERS_MIGRATION_CREATE));
    try {
        const result = await serversMigrations.create(data);

        dispatch(clearFormErrors());
        dispatch(addServersMigrationItem(result.data.data));

        return result;
    } catch (e) {
        if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
            dispatch(setFormErrors(transformErrors(e.response.data.errors)));
        } else if (e.response.status === HTTP_CODES.BAD_REQUEST) {
            dispatch(setFormErrors({
                notAccessible: e.response.data.errors,
            }));
        }

        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SERVERS_MIGRATION_CREATE));
    }
};

export const removeServersMigration = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: serversMigrations.remove,
    setLoadingAction: setServersMigrationIsDeleting,
    action: removeServersMigrationItem,
    loadingFlag: LOADING_FLAGS.SERVERS_MIGRATION_REMOVE,
    translations: {
        success: 'serversMigration.toasts.deleted',
    },
});

export const removeServersMigrations = (ids: number[]) => async (dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: serversMigrations.removeBatch,
    action: removeServersMigrationItems,
    loadingFlag: LOADING_FLAGS.SERVERS_MIGRATION_BATCH_REMOVE,
    translations: {
        success: 'serversMigration.toasts.batchDelete',
    },
});

export const preCheckMigration = (ids: number[]) => async (dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SERVERS_MIGRATION_PRE_CHECK));
    try {
        const result = await serversMigrations.preCheck(ids);
        dispatch(setPreCheck(result.data.data));
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SERVERS_MIGRATION_PRE_CHECK));
    }
};
