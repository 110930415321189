// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as updateActions from 'admin/update/actions';
import { Header } from 'admin/common/components/PageHeader/Styles';
import {
    Translate,
    Label,
    Button,
    Icon,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Title } from 'admin/update/containers/Styles';
import { Loader } from 'common/components';
import VersionCard from 'admin/update/containers/VersionCard/VersionCard';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';

export type UpdateProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Update: React.FC<UpdateProps> = ({
    data,
    isLoadingList,
    push,
    updateActions: {
        getUpdateData,
    },
}) => {
    React.useEffect(() => {
        getUpdateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => push('/admin/settings/system_updates');

    const items = data.versions.map((item, index) => (
        <VersionCard
            key={index}
            item={item}
            isCurrentVersion={item.version === data.current_version}
            isInstallable={item.version === data.version}
        />
    ));

    return (
        <Loader isLoading={isLoadingList}>
            <Header>
                <Title>
                    <Translate content="update.header" />
                    <Label intent={INTENT_TYPE.INACTIVE}>
                        <Translate content="update.version" />
                        &nbsp;
                        {data.current_version}
                    </Label>
                </Title>
                <Button
                    style={{ marginLeft: 'auto' }}
                    icon={<Icon name="gear" size="24"/>}
                    ghost={true}
                    onClick={handleClick}
                    tooltip={<Translate content="update.changeSettings" />}
                />
            </Header>
            <div>
                {items}
            </div>
        </Loader>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
    data: state.update,
    push: ownProps.history.push,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.UPDATE_DATA),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateActions: bindActionCreators(updateActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Update));
