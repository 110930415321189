// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ILanguageResponse } from 'common/api/resources/Language';
import * as languageActions from 'common/modules/language/actions';
import { LanguageName } from 'common/components/LanguageName';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { StyledSelector } from 'common/containers/LanguageSelector/Styles';

interface ILanguageSelectorProps {
    inputId?: string;
    languages?: ILanguageResponse[];
    selected: ILanguageResponse | null;
    onChange: (locale: number) => void;
    minHeight?: string;
    checked?: Record<string, boolean>;
}

export type LanguageSelectorProps =
    ILanguageSelectorProps &
    ReturnType<typeof mapDispatchToProps>;

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
    selected,
    onChange,
    inputId,
    minHeight,
    checked,
    languages,
    languageActions: {
        getLanguages,
    },
}) => {
    const languageToSelectOption = React.useCallback((lang: ILanguageResponse) => ({
        label: (
            <LanguageName
                language={lang}
                checked={checked ? checked[lang.locale]!! : false}
            />
        ),
        value: lang.id.toString(),
    }), [checked]);

    const [selectedLanguage, setSelectedLanguage] = React.useState(selected ? languageToSelectOption(selected) : null);

    React.useEffect(() => {
        // Assume that languages will not changes every time, so we can cache
        // them.
        if (languages?.length === 0) {
            getLanguages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (selected) {
            setSelectedLanguage(languageToSelectOption(selected));
        }
    }, [selected, languageToSelectOption]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (option: any) => {
        onChange(parseInt(option.value, 10));
        setSelectedLanguage(option);
    };

    const options = React.useMemo(() => languages?.map(languageToSelectOption), [languages, languageToSelectOption]);

    return (
        <StyledSelector
            inputId={inputId}
            onChange={handleChange}
            value={selectedLanguage}
            options={options}
            menuPosition="fixed"
            minHeight={minHeight}
            data-cy="selector"
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    languageActions: bindActionCreators(languageActions, dispatch),
});

export default connect(null, mapDispatchToProps)(LanguageSelector);
