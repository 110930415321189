// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as tagActions from 'admin/tag/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Translate } from '@plesk/ui-library';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import TagForm from 'admin/tag/containers/TagForm';
import { TagActions } from 'admin/tag/components/TagActions';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export type TagsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum TagTableColumns {
    ID = 'colId',
    KEY = 'colKey',
    VALUES = 'colValues',
    ACTIONS = 'colActions',
}

const columns = [{
    width: '1%',
    key: TagTableColumns.ID,
    title: <Translate content="tag.list.id" />,
}, {
    width: '15%',
    key: TagTableColumns.KEY,
    title: <Translate content="tag.list.key" />,
}, {
    width: '15%',
    key: TagTableColumns.VALUES,
    title: <Translate content="tag.list.values" />,
}, getActionColumnProps(),
];

export const Tags: React.FC<TagsProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    canManage,
    tagActions: {
        getTags,
        getTag,
        removeTag,
    },
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        getTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPaginated = (page: number) => getTags({ page });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const handleRemove = (id: number) => async () => {
        await removeTag(id);
        reloadListData(list, loadPaginated);
    };

    const handleEdit = (id: number) => () => {
        getTag(id);
        setDialogOpen(true);
    };

    const data = list.data.map((tag) => ({
        [TagTableColumns.ID]: tag.id,
        [TagTableColumns.KEY]: tag.key,
        [TagTableColumns.VALUES]: tag.values.map(v => v.value).join(', '),
        [TagTableColumns.ACTIONS]: canManage ? (
            <TagActions
                item={tag}
                handleEdit={handleEdit(tag.id)}
                handleRemove={handleRemove(tag.id)}
            />
        ) : undefined,
        key: tag.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0 && canManage}
                title={<Translate content="tag.title"/>}
                buttonText="tag.addButton"
                buttonIcon={ICONS.PLUS}
                onButtonClick={() => setDialogOpen(true)}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="tag.emptyView.title"
                            description="tag.emptyView.description"
                            buttonText="tag.emptyView.buttonText"
                            onButtonClick={() => setDialogOpen(true)}
                            icon={ICONS.LABEL}
                        />
                    }
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                />
            </Loader>
            <Dialog
                heading={<Translate content={item.id ? 'tag.dialog.title.edit' : 'tag.dialog.title.create'} />}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <TagForm onSubmit={() => setDialogOpen(false)}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.tag.item,
    list: state.tag.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.TAG_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.TAG_ITEM),
    canManage: hasPermission(state, PERMISSION_LIST.MANAGE_TAGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    tagActions: bindActionCreators(tagActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
