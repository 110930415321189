// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormField,
    FormFieldProps,
    InputNumber,
    InputNumberProps,
} from '@plesk/ui-library';

export const FormFieldNumber: React.FC<FormFieldProps & InputNumberProps> = ({
    name,
    min,
    max,
    step,
    defaultValue,
    disabled,
    size = 'sm',
    value,
    ...props
}) => (
    <FormField name={name} {...props}>
        {({ setValue, getValue, getName, getId }) => (
            <InputNumber
                size={size}
                id={getId()}
                name={getName()}
                min={min}
                max={max}
                step={step}
                defaultValue={defaultValue}
                onChange={setValue}
                value={value !== undefined ? value : getValue()}
                disabled={disabled}
            />
        )}
    </FormField>
);