// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_CLUSTER_IMPORT_LIST = 'cluster-import/SET_CLUSTER_IMPORT_LIST';
export const SET_CLUSTER_IMPORT_ITEM = 'cluster-import/SET_CLUSTER_IMPORT_ITEM';
export const UNSET_CLUSTER_IMPORT_ITEM = 'cluster-import/UNSET_CLUSTER_IMPORT_ITEM';
export const ADD_CLUSTER_IMPORT_ITEM = 'cluster-import/ADD_CLUSTER_IMPORT_ITEM';
export const UPDATE_CLUSTER_IMPORT_ITEM = 'cluster-import/UPDATE_CLUSTER_IMPORT_ITEM';
export const SET_CLUSTER_IMPORT_STATUS = 'cluster-import/SET_CLUSTER_IMPORT_STATUS';
export const SET_CLUSTER_IMPORT_IS_DELETING = 'cluster-import/SET_CLUSTER_IMPORT_IS_DELETING';
export const SET_CLUSTER_IMPORT_IS_LOADING = 'cluster-import/SET_CLUSTER_IMPORT_IS_LOADING';
export const REMOVE_CLUSTER_IMPORT_ITEM = 'cluster-import/REMOVE_CLUSTER_IMPORT_ITEM';
export const SET_CLUSTER_IMPORT_COMPUTE_RESOURCES = 'cluster-import/SET_CLUSTER_IMPORT_COMPUTE_RESOURCES';
