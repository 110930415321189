// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IPaginateApiResponse } from 'common/api/resources/Response';

export interface IListReorderedItem {
    oldIndex: number;
    newIndex: number;
}

export const addToList = <I>(item: I, list: I[]): I[] => [
    item,
    ...list,
];

export const addItemsToList = <I>(items: I[], list: I[]): I[] => [
    ...items,
    ...list,
];

export const addToPaginated = <I>(
    curr: IPaginateApiResponse<I[]>,
    item: I
) => ({
        ...curr,
        data: addToList(item, curr.data),
    });

export const addItemsToPaginated = <I>(
    curr: IPaginateApiResponse<I[]>,
    items: I[]
) => ({
        ...curr,
        data: addItemsToList(items, curr.data),
    });

export const appendToPaginated = <I>(
    prev: IPaginateApiResponse<I[]>,
    next: IPaginateApiResponse<I[]>
): IPaginateApiResponse<I[]> => ({
        links: next.links,
        data: [...prev.data, ...next.data],
        meta: next.meta,
    });

export const deleteFromPaginated = <I>(
    curr: IPaginateApiResponse<I[]>,
    value: string | number,
    property: string = 'id'
) => ({
        ...curr,
        data: curr.data.filter(item => item[property] !== value),
    });

export const updateInPaginated = <I>(
    curr: IPaginateApiResponse<I[]>,
    data: Partial<I>,
    property: string = 'id'
) => updateInPaginatedBy(curr, data[property], data, property);

export const updateInPaginatedBy = <I>(
    curr: IPaginateApiResponse<I[]>,
    value: number | string,
    data: Partial<I>,
    property: string = 'id'
) => ({
        ...curr,
        data: curr.data.map(item => {
            if (item[property] === value) {
                return {
                    ...item,
                    ...data,
                };
            }

            return item;
        }),
    });

export const deleteItemsFromPaginated = <I>(
    curr: IPaginateApiResponse<I[]>,
    ids: number[],
    property: string = 'id'
) => ({
        ...curr,
        data: curr.data.reduce((prev: I[], current: I) => {
            if (ids.includes(current[property])) {
                return prev;
            }

            return [...prev, current];
        }, []),
    });

export const updateAllInPaginated = <I>(
    curr: IPaginateApiResponse<I[]>,
    updatedItem: Partial<I>
) => ({
        ...curr,
        data: curr.data.map(item => ({
            ...item,
            ...updatedItem,
        })),
    });
