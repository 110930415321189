// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { colorBetween } from 'common/helpers/color';
import {
    UsageContainer,
    UsageLine,
    UsageLineBar,
} from 'admin/common/components/Usage/Styles';

const USAGE_START_COLOR = '#5C7C1D';
const USAGE_END_COLOR = '#ff0000';

export interface IUsageProps {
    progress: number;
    title: string | React.ReactNode;
    startColor?: string;
    endColor?: string;
}

const Usage: React.FC<IUsageProps> = ({
    progress,
    title,
    startColor = USAGE_START_COLOR,
    endColor = USAGE_END_COLOR,
}) => {
    const percentage = progress < 100 ? progress : 100;

    return (
        <UsageContainer>
            {title}
            <UsageLineBar>
                <UsageLine progress={percentage} color={colorBetween(startColor, endColor, percentage)}/>
            </UsageLineBar>
        </UsageContainer>
    );
};

export default Usage;
