// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_BACKUP_LIST = 'backup/SET_BACKUP_LIST';
export const ADD_BACKUP_ITEM = 'backup/ADD_BACKUP_ITEM';
export const UNSET_BACKUPS = 'backup/UNSET_BACKUPS';
export const APPEND_BACKUPS = 'backup/APPEND_BACKUPS';
export const UPDATE_BACKUP = 'backup/UPDATE_BACKUP';
export const UPDATE_BACKUP_PROGRESS = 'backup/UPDATE_BACKUP_PROGRESS';
export const REMOVE_BACKUP_ITEMS = 'backup/REMOVE_BACKUP_ITEMS';
export const SET_BACKUP_ITEM_IS_DELETING = 'backup/SET_BACKUP_ITEM_IS_DELETING';
export const SET_NEXT_SCHEDULED_BACKUP_DATE = 'backup/SET_NEXT_SCHEDULED_BACKUP_DATE';
