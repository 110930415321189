// Copyright 2025. WebPros International GmbH. All rights reserved.
import styled from 'styled-components';

export const ActionsContainer = styled.div`
    .pul-dropdown {
        display: none;
    }

    @media (max-width: 671px) {
        .pul-dropdown {
            display: block;
        }
    }
`;

export const InlineActions = styled.div`
  display: flex;

  @media (max-width: 671px) {
    display: none;
    }
`;
