// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as snapshotActions from 'common/modules/snapshot/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { SnapshotForm } from 'common/components/ServerTabs/SnapshotTab/SnapshotForm';

const mapStateToProps = (state: RootState) => ({
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SNAPSHOTS_ITEM),
    formErrors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    snapshotActions: bindActionCreators(snapshotActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotForm);