// Copyright 2025. WebPros International GmbH. All rights reserved.

import { FC } from 'react';
import {
    IVmResponse,
    VirtualServerSettingsRequest,
    VZNetfilterStatus,
} from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';
import { Grid } from 'common/components/ServerTabs/SettingsTab/Styles';
import VZNetfilterStatusControl from 'common/components/ServerTabs/components/Controls/VZNetfilterStatusControl';
import VZTUNTAPControl from 'common/components/ServerTabs/components/Controls/VZTUNTAPControl';
import VZPPPControl from 'common/components/ServerTabs/components/Controls/VZPPPControl';
import SettingsPropertyCard from 'common/components/ServerTabs/SettingsTab/SettingsPropertyCard';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import SuspendResumeControl from 'common/components/ServerTabs/components/Controls/SuspendResumeControl';

export interface IVZSettingsTabProps {
    server: IVmResponse;
    isUpdating: boolean;
    updateSettings: (settings: VirtualServerSettingsRequest) => void;
    isAdminSettings: boolean;
    isSuspendToggling: boolean;
    toggleSuspendStatus: (isSuspended: boolean) => void;
    canManageServers: boolean;
}

export const VZSettingsTab: FC<IVZSettingsTabProps> = ({
    server,
    isUpdating,
    updateSettings,
    isAdminSettings,
    toggleSuspendStatus,
    isSuspendToggling,
    canManageServers,
}) => {
    const enableVncConsole = () => {
        updateSettings({ vnc_enabled: true });
    };

    const netfilterChange = (value: VZNetfilterStatus) => {
        updateSettings({ netfilter: value });
    };

    const tunTAPChange = (value: boolean) => {
        updateSettings({ tun_tap: value });
    };

    const pppChange = (value: boolean) => {
        updateSettings({ ppp: value });
    };

    const isEditable = (key: string) => canManageServers || (server.settings[key]?.is_editable ?? false);

    return (
        <>
            {isAdminSettings && (
                <Grid gap="md">
                    <SettingsPropertyCard xs={12} sm={12} md={12} xl={12} lg={12}
                        title={<Translate content="servers.tabs.settings.vncConsole.title" />}
                    >
                        <ButtonWithConfirmation
                            isLoading={isUpdating}
                            disabled={server.settings.vnc_enabled || !canManageServers}
                            buttonSize={SIZE.LG}
                            buttonIntent={INTENT_TYPE.PRIMARY}
                            confirmationButtonGhost={false}
                            translations={{
                                title: <Translate content="servers.tabs.settings.vncConsole.confirmationDialog.title" />,
                                text: <Translate content="servers.tabs.settings.vncConsole.confirmationDialog.content" />,
                                button: <Translate content="servers.tabs.settings.vncConsole.confirmationDialog.button" />,
                            }}
                            confirmationButtonText={<Translate content='servers.tabs.settings.vncConsole.button' />}
                            handleConfirm={enableVncConsole}
                        />
                    </SettingsPropertyCard>
                </Grid>
            )}

            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={12} lg={12}
                    title={<Translate content="servers.tabs.settings.suspendResume.title" />}
                    text={<Translate content="servers.tabs.settings.suspendResume.text" />}
                >
                    <SuspendResumeControl
                        value={server.is_suspended}
                        disabled={isSuspendToggling || !canManageServers}
                        loading={isSuspendToggling}
                        onChange={toggleSuspendStatus}
                    />
                </SettingsPropertyCard>
            </Grid>

            <Grid gap="md" xs={1} sm={1} md={2} lg={2} xl={2}>
                <SettingsPropertyCard
                    title="Netfilter"
                    titleId="vz-netfilter-status-label"
                    text={<Translate content="servers.tabs.settings.netfilter.text" />}
                >
                    <VZNetfilterStatusControl
                        inputId="vz-netfilter-status-input"
                        aria-labelledby="vz-netfilter-status-label"
                        value={server.settings.netfilter?.value ?? VZNetfilterStatus.STATELESS}
                        disabled={isUpdating || !isEditable('netfilter')}
                        onChange={netfilterChange}
                    />
                </SettingsPropertyCard>
                <SettingsPropertyCard
                    title="TUN/TAP"
                    text={<Translate content="servers.tabs.settings.tunTAP.text" />}
                >
                    <VZTUNTAPControl
                        label="TUN/TAP"
                        value={server.settings.tun_tap?.value ?? false}
                        disabled={isUpdating || !isEditable('tun_tap')}
                        onChange={tunTAPChange}
                    />
                </SettingsPropertyCard>
                <SettingsPropertyCard
                    title="PPP"
                    text={<Translate content="servers.tabs.settings.ppp.text" />}
                >
                    <VZPPPControl
                        label="PPP"
                        value={server.settings.ppp?.value ?? false}
                        disabled={isUpdating || !isEditable('ppp')}
                        onChange={pppChange}
                    />
                </SettingsPropertyCard>
            </Grid>
        </>
    );
};

export default VZSettingsTab;
