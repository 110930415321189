// Copyright 2025. WebPros International GmbH. All rights reserved.

export const SET_PROJECT_LIST = 'project/SET_PROJECT_LIST';
export const SET_PROJECT_ITEM = 'project/SET_PROJECT_ITEM';
export const ADD_PROJECT = 'project/ADD_PROJECT';
export const ADD_MEMBER = 'project/ADD_MEMBER';
export const REMOVE_PROJECT_ITEM = 'project/REMOVE_PROJECT_ITEM';
export const UPDATE_PROJECT_ITEM = 'project/UPDATE_PROJECT_ITEM';
export const UPDATE_MEMBER_ITEM = 'project/UPDATE_MEMBER_ITEM';
export const SET_IS_LOADING = 'project/SET_IS_LOADING';
export const UNSET_IS_LOADING = 'project/UNSET_IS_LOADING';
export const SET_PROJECT_SERVER_LIST = 'project/SET_PROJECT_SERVER_LIST';
export const SET_SNAPSHOTS_LIST = 'project/SET_SNAPSHOTS_LIST';
export const ADD_SNAPSHOT = 'project/ADD_SNAPSHOT';
export const UPDATE_SNAPSHOT_ITEM_BY_UUID = 'project/UPDATE_SNAPSHOT_ITEM_BY_UUID';
export const REMOVE_SNAPSHOT_ITEM = 'project/REMOVE_SNAPSHOT_ITEM';
export const SET_PROJECT_SSH_KEYS_LIST = 'project/SET_PROJECT_SSH_KEYS_LIST';
export const APPEND_PROJECT_SSH_KEY_ITEM = 'ssh-keys/APPEND_PROJECT_SSH_KEY_ITEM';
export const SET_PROJECT_TOKEN_PRICING = 'project/SET_PROJECT_TOKEN_PRICING';
export const UPDATE_PROJECT_SERVER = 'project/UPDATE_PROJECT_SERVER';
export const SET_PROJECT_SERVER = 'project/SET_PROJECT_SERVER';
export const UNSET_PROJECT_SERVER = 'project/UNSET_PROJECT_SERVER';
export const REMOVE_PROJECT_VM = 'project/REMOVE_PROJECT_VM';
export const SET_SERVER_IS_LOADING = 'project/SET_SERVER_IS_LOADING';
export const UNSET_SERVER_IS_LOADING = 'project/UNSET_SERVER_IS_LOADING';
export const SET_IS_CONFIRMATION_SHOWN = 'project/SET_IS_CONFIRMATION_SHOWN';
export const SET_PROJECT_MEMBERS_LIST = 'project/SET_PROJECT_MEMBERS_LIST';
export const REMOVE_MEMBER_ITEM = 'project/REMOVE_MEMBER_ITEM';
export const SET_FORM_ERRORS = 'project/SET_FORM_ERRORS';
export const CLEAR_FORM_ERRORS = 'project/CLEAR_FORM_ERRORS';
export const SET_SNAPSHOT_ITEM_IS_PROCESSING = 'project/SET_SNAPSHOT_ITEM_IS_PROCESSING';
export const UNSET_SNAPSHOT_ITEM_IS_PROCESSING = 'project/UNSET_SNAPSHOT_ITEM_IS_PROCESSING';
export const SET_CPU_CHART_DATA = 'project/SET_CPU_CHART_DATA';
export const SET_MEMORY_CHART_DATA = 'project/SET_MEMORY_CHART_DATA';
export const UPDATE_CHART = 'project/UPDATE_CHART';
export const SET_NETWORK_CHART_DATA = 'project/SET_NETWORK_CHART_DATA';
export const SET_DISKS_CHART_DATA = 'project/SET_DISKS_CHART_DATA';
