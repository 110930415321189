// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as storageTagActions from 'admin/storageTag/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Form,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    IStorageTagRequest,
    IStorageTagResponse,
} from 'common/api/resources/StorageTag/model';

interface IStorageTagFormProps {
    onSubmit: () => void;
}

export type StorageTagFormProps =
    IStorageTagFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const responseToFormValues = ({ tier, ...response }: IStorageTagResponse): IStorageTagRequest => ({
    ...response,
});

export const StorageTagForm: React.FC<StorageTagFormProps> = ({
    item,
    isItemSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    storageTagActions: {
        unsetStorageTagItem,
        createStorageTag,
        updateStorageTag,
    },
    onSubmit,
}) => {
    const [submitValues, setSubmitValues] = React.useState(responseToFormValues(item));

    React.useEffect(() => () => {
        clearFormErrors();
        unsetStorageTagItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: IStorageTagRequest) => {
        const errors = validate<IStorageTagRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        if (!values.description) {
            values.description = undefined;
        }

        item.id
            ? await updateStorageTag(item.id, values)
            : await createStorageTag(values);
        onSubmit();
    };

    return (
        <>
            <Form
                id="storageTagForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size={SIZE.FILL}
                        label={<Translate content="storageTag.form.name" />}
                        required={true}
                    />
                    <FormFieldText
                        name="description"
                        multiline={true}
                        size={SIZE.FILL}
                        label={<Translate content="storageTag.form.description" />}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="storageTagForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="storageTag.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.storageTag.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_TAG_ITEM_SAVE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    storageTagActions: bindActionCreators(storageTagActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageTagForm);
