// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ILanguageResponse } from 'common/api/resources/Language';
import {
    Button,
    Menu,
    MenuItem,
} from '@plesk/ui-library';
import { LanguageName } from 'common/components/LanguageName';
import { Dropdown } from 'common/containers/LanguageDropdown/Styles';

export interface ILanguageDropdownProps {
    selected: ILanguageResponse;
    onChange: (locale: ILanguageResponse) => void;
    languages: ILanguageResponse[];
}

export const LanguageDropdown: React.FC<ILanguageDropdownProps> = ({
    selected,
    onChange,
    languages,
    ...props
}) => {
    const [selectedLanguage, setSelectedLanguage] = React.useState<ILanguageResponse>(selected);

    React.useEffect(() => {
        setSelectedLanguage(selected);
    }, [selected]);

    const handleMenuItemClick = (language: ILanguageResponse) => () => {
        onChange(language);
        setSelectedLanguage(language);
    };

    const menu = React.useMemo(() => (
        <Menu className="dropdown-language-menu">
            {languages.map((language) => (
                <MenuItem key={language.id} onClick={handleMenuItemClick(language)}>
                    <LanguageName language={language} />
                </MenuItem>
            ))}
        </Menu>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [languages]);

    const dropdownTitle = React.useMemo(() => {
        if (!selectedLanguage) {
            return undefined;
        }

        if (selectedLanguage.icon) {
            return (
                <img
                    width={16}
                    src={selectedLanguage.icon.url}
                    alt={selectedLanguage.icon.name}
                />
            );
        }

        return selectedLanguage.name;
    }, [selectedLanguage]);

    return (
        <Dropdown menu={menu} menuPlacement="bottom-start" {...props}>
            <Button
                ghost={true}
                icon="chevron-down"
            >
                {dropdownTitle}
            </Button>
        </Dropdown>
    );
};

export default LanguageDropdown;
