// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { PopoverFormContent } from 'client/common/styles/PopoverForm';
import { IpContainer } from 'common/components/ServerTabs/NetworkingTab/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { connect } from 'react-redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { RootState } from 'client/core/store';
import { IpBlockType } from 'common/api/resources/IpBlock';
import {
    Button,
    Form,
    FormField,
    FormFieldText,
    Popover,
    Section,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    IReverseDnsPatchRequest,
    IReverseDnsResponse,
} from 'common/api/resources/ReverseDns';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    domainRule,
    ipRule,
    requiredRule,
    validate,
} from 'common/validator';

export interface IRescueTabProps {
    reverseDns: IReverseDnsResponse;
    ipId?: number;
    target: React.ReactElement;
    isOpened: boolean;
    onClose: () => void;
}

export type PopoverFormProps =
    IRescueTabProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const PopoverForm: React.FC<PopoverFormProps> = ({
    onClose,
    isOpened,
    reverseDns,
    formErrors,
    target,
    ipId,
    computeResourceVmActions: {
        updateReverseDns,
        createIpV6ReverseDns,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    React.useEffect(() => () => {
        clearFormErrors();
        setSubmitValues(reverseDns);
    }, [reverseDns, clearFormErrors, isOpened]);

    const [isSaving, setIsSaving] = React.useState(false);
    const [submitValues, setSubmitValues] = React.useState(reverseDns);

    const handleSubmit = async (values: IReverseDnsPatchRequest) => {
        setSubmitValues({ ...submitValues, ...values });

        const rules = {
            domain: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                domainRule(<Translate content="validate.badDomain" />),
            ],
        };

        if (!reverseDns.is_primary) {
            rules['ip'] = [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, 6),
            ];
        }

        const errors = validate(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            clearFormErrors();
            setIsSaving(true);

            const request = { ...values };

            if (reverseDns.is_primary) {
                delete request.ip;
            }

            reverseDns.id > 0 ? await updateReverseDns(reverseDns.id, request) : await createIpV6ReverseDns({
                ...request,
                type: IpBlockType.IPv6,
                ip_id: ipId || 0,
            });
            onClose();
        } catch (e) {
            throw e;
        } finally {
            setIsSaving(false);
        }
    };

    const renderIpField = () => {
        if (reverseDns.is_primary) {
            return (
                <FormField>
                    <IpContainer>
                        <CopyText>
                            {reverseDns.ip}
                        </CopyText>
                    </IpContainer>
                </FormField>
            );
        }

        return (
            <FormFieldText
                disabled={isSaving}
                name="ip"
                placeholder="2001:db8:8:4::1"
                autoFocus={true}
                size={SIZE.FILL}
                label={
                    <Translate content="servers.tabs.networking.reverseDns.popover.ipv6" />
                }
            />
        );
    };

    return (
        <Popover
            canCloseOnOutsideClick={false}
            onClose={onClose}
            visible={isOpened}
            target={target}
            placement="left"
        >
            <PopoverFormContent>
                <Section
                    title={
                        reverseDns.id > 0 ?
                            <Translate content="servers.tabs.networking.reverseDns.popover.editTitle" /> :
                            <Translate content="servers.tabs.networking.reverseDns.popover.createTitle" />
                    }
                >
                    <Form
                        id="form"
                        onSubmit={handleSubmit}
                        errors={formErrors}
                        values={submitValues}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                        footerClassName="hidden"
                    >
                        {renderIpField()}
                        <FormFieldText
                            disabled={isSaving}
                            placeholder="example.com"
                            name="domain"
                            autoFocus={true}
                            size={SIZE.FILL}
                            label={
                                <Translate content="servers.tabs.networking.reverseDns.popover.domain" />
                            }
                        />
                        <Button
                            state={isSaving ? 'loading' : undefined}
                            size={SIZE.LG}
                            intent={INTENT_TYPE.PRIMARY}
                            type="submit"
                            form="form"
                        >
                            <Translate content="servers.tabs.networking.reverseDns.popover.save" />
                        </Button>
                    </Form>
                </Section>
            </PopoverFormContent>
        </Popover>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

const mapStateToProps = (state: RootState) => ({
    formErrors: state.app.formErrors,
});

export default connect(mapStateToProps, mapDispatchToProps)(PopoverForm);
