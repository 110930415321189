// Copyright 2025. WebPros International GmbH. All rights reserved.
import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    ISearchRequest,
    SearchItemResponse,
} from 'common/api/resources/Search/model';
import { CancelTokenSource } from 'axios';

export const search = {
    search: (params?: ISearchRequest, cancelToken?: CancelTokenSource) => api.get<IApiResponse<SearchItemResponse[]>>('search', {
        params,
        cancelToken: cancelToken?.token,
    }),
};