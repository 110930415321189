// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Popover } from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import {
    Container,
    PopoverContainer,
} from 'common/components/PopoverList/Styles';

export interface IPopoverListProps<T> {
    items: T[];
    render: (item: T) => React.ReactNode | string;
    keyExtractor: (item: T) => string;
}

export const PopoverList = <T,>({
    items,
    render,
    keyExtractor,
}: IPopoverListProps<T>) => {
    if (items.length === 0) {
        return null;
    }

    return (
        <Container>
            {render(items[0])}
            {items.length > 1 &&
                (
                    <Popover
                        placement="bottom-left"
                        target={
                            <Button ghost={true}>
                                +{items.length - 1}
                            </Button>
                        }
                    >
                        <PopoverContainer>
                            {items.slice(1).map((item) => (
                                <div key={keyExtractor(item)}>{render(item)}</div>
                            ))}
                        </PopoverContainer>
                    </Popover>
                )
            }
        </Container>
    );
};

export default PopoverList;
