// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import * as authActions from 'common/modules/auth/actions';
import * as languageActions from 'common/modules/language/actions';
import * as metaActions from 'common/modules/app/meta/actions';
import * as settingsActions from 'common/modules/settings/actions';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import { Loader } from 'common/components';

export type StartupProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Startup: React.FC<React.PropsWithChildren<StartupProps>> = ({
    isAuthenticated,
    getUser,
    getLanguages,
    getSettings,
    getMeta,
    children,
    favicon,
}) => {
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const fetch = async () => {
            let promises = [
                getMeta(),
                getSettings(),
                // We should update languages every time when login or logout 'cause we
                // may get wrong result if user with manage settings settings will logout.
                getLanguages(),
            ];

            if (isAuthenticated) {
                promises.push(getUser());
            }

            try {
                setIsLoading(true);
                await Promise.all(promises);
            } finally {
                setIsLoading(false);
            }
        };

        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    React.useEffect(() => {
        const link = document.querySelector('link[rel~=\'icon\']');
        if (link && link instanceof HTMLLinkElement) {
            if (favicon) {
                link.href = favicon;
            } else if (favicon === null) {
                link.href = '/favicon.ico';
            }
        }

    }, [favicon]);

    return (
        <Loader isLoading={isLoading}>
            {children}
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuth,
    favicon: state.settings.theme.favicon,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMeta: bindActionCreators(metaActions.getMeta, dispatch),
    getUser: bindActionCreators(authActions.getUser, dispatch),
    getLanguages: bindActionCreators(languageActions.getLanguages, dispatch),
    getSettings: bindActionCreators(settingsActions.getSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Startup);
