// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import ProjectServerCard from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServerCard/ProjectServerCard';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    CutTitle,
    Loader,
} from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    INTENT_TYPE,
    VIEW_TYPE,
} from 'common/constants';
import { StyledList } from 'client/common/styles/List';
import {
    Action,
    Label,
    Translate,
} from '@plesk/ui-library';
import { VmStatus } from 'common/components/VmStatus';
import {
    HostnameActionContainer,
    HostnameCell,
} from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServersList/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import LocationCell from 'common/components/IconCell/LocationCell';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ProjectServerActions from 'client/project/containers/projectServer/ProjectServerItem/ProjectServerHeader/ProjectServerActions';
import { primaryIps } from 'common/api/resources/Ip';
import { getActionColumnProps } from 'common/helpers/list';
import List from 'common/components/List/List';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { PaginatedCardList } from 'client/common/components/Card';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';

export type ProjectServersListProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

enum ServersTableColumns {
    NAME = 'colName',
    DESCRIPTION = 'colDescription',
    STATUS = 'colStatus',
    IP = 'colIp',
    LOCATION = 'colLocation',
    COMPUTE_RESOURCE = 'colComputeResource',
}

const columns = [{
    key: ServersTableColumns.NAME,
    width: '15%',
    title: <Translate content="projects.server.list.colName" />,
    cellProps: {
        className: 'cell-os',
    },
}, {
    key: ServersTableColumns.DESCRIPTION,
    className: 'header-default',
    title: <Translate content="projects.server.list.colDescription" />,
    cellProps: {
        className: 'cell-description',
    },
}, {
    key: ServersTableColumns.STATUS,
    width: '15%',
    title: <Translate content="projects.server.list.colStatus" />,
    className: 'header-status',
    cellProps: {
        className: 'cell-status',
    },
}, {
    key: ServersTableColumns.IP,
    width: '15%',
    className: 'header-default',
    title: <Translate content="projects.server.list.colIp" />,
}, {
    key: ServersTableColumns.LOCATION,
    width: '15%',
    title: <Translate content="projects.server.list.colLocation" />,
    className: 'header-location',
}, {
    key: ServersTableColumns.COMPUTE_RESOURCE,
    width: '15%',
    title: <Translate content="projects.server.list.colComputeResource" />,
}, getActionColumnProps(),
];

export const ProjectServersList: React.FC<ProjectServersListProps> = ({
    view,
    isLoading,
    history,
    location: { pathname },
    servers,
    project,
    canGetComputeResourceName,
    computeResourceVmActions: { getProjectVms },
}) => {
    React.useEffect(() => {
        getProjectVms(project.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFirstLoading = useIsFirstLoading(isLoading);

    const loadPaginated = (page: number) => getProjectVms(project.id, { page });

    const serverColumns = React.useMemo(() => {
        if (canGetComputeResourceName) {
            return columns;
        }

        return columns.filter(c => c.key !== ServersTableColumns.COMPUTE_RESOURCE);
    }, [canGetComputeResourceName]);

    const data = servers.data.map((item) => ({
        colName: (
            <HostnameCell>
                {item.settings.os_image.icon && (
                    <img src={item.settings.os_image.icon} alt={item.settings.os_image.name} />
                )}
                <HostnameActionContainer>
                    <Action
                        component={Link}
                        to={pathTo(`projects/${project.id}/servers/${item.id}#overview`)}
                    >
                        {item.name}
                    </Action>
                    {item.is_suspended && (
                        <>
                                &nbsp;
                            <Label intent={INTENT_TYPE.WARNING}><Translate content="servers.status.suspended" /></Label>
                        </>
                    )}
                </HostnameActionContainer>
            </HostnameCell >
        ),
        colDescription: <CutTitle style={{ color: '#737373' }} title={item.description} />,
        colStatus: (
            <VmStatus
                status={item.status}
                isProcessing={item.is_processing}
                progress={item.progress}
                compact={true}
            />
        ),
        colIp: primaryIps(item.ips).map(ip => (
            <CopyText key={ip.id}>{ip.ip}</CopyText>
        )),
        colLocation: item.location && (
            <LocationCell location={item.location} />
        ),
        colComputeResource: item.compute_resource_name,
        colActions: <ProjectServerActions server={item} iconSize="16" />,
        key: item.id.toString(),
    }));

    return (
        <Loader isLoading={isFirstLoading}>
            {view === VIEW_TYPE.CARD && (
                <PaginatedCardList
                    items={servers.data}
                    meta={servers.meta}
                    loadPaginated={loadPaginated}
                    isLoading={isLoading}
                    component={server => <ProjectServerCard key={server.id} isDisabled={isLoading} vm={server} />}
                />
            )}
            {view === VIEW_TYPE.LIST && (
                <StyledList>
                    <List
                        isLoading={isLoading}
                        meta={servers.meta}
                        loadItems={loadPaginated}
                        columns={serverColumns}
                        data={data}
                    />
                </StyledList>
            )}
            {!data.length && (
                <EmptyView
                    title="projects.server.emptyView.title"
                    icon="card-row"
                />
            )}
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    view: state.app.viewType,
    servers: state.project.servers.list,
    project: state.project.projects.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_VM_LIST),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_VM_ITEM),
    canGetComputeResourceName: hasPermission(state, PERMISSION_LIST.GET_COMPUTE_RESOURCE_NAME),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectServersList));
