// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { IApiTokenResponse } from 'common/api/resources/ApiToken';
import * as apiTokenActions from 'common/modules/apiToken/actions';
import { CardItem } from 'client/common/components/Card';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'client/account/constants/test';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    Toolbar,
    ToolbarExpander,
    Translate,
    Icon,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { COLORS } from 'client/core/theme';

interface IApiTokenCardProps {
    apiToken: IApiTokenResponse;
}

export type ApiTokenCardProps =
    IApiTokenCardProps &
    ReturnType<typeof mapDispatchToProps>;

export const ApiTokenCard: React.FC<ApiTokenCardProps> = ({
    apiToken,
    apiTokenActions: { removeApiToken },
}) => {
    const handleRemove = () => removeApiToken(apiToken);

    return (
        <CardItem
            isLoading={apiToken.isLoading}
            icon={
                <Icon
                    name={ICONS.HEXAGONS}
                    size={32}
                    style={{ color: COLORS.PRIMARY }}
                />
            }
            cardTitle={apiToken.name}
            item={
                <Toolbar />
            }
            footer={
                <Toolbar>
                    <ToolbarExpander />
                    <ButtonWithConfirmation
                        isLoading={apiToken.isLoading}
                        translations={{
                            text: (
                                <Translate content="account.tokens.confirmationRemove.text" />
                            ),
                            button: (
                                <Translate content="account.tokens.confirmationRemove.button" />
                            ),
                            tooltip: (
                                <Translate content="account.tokens.confirmationRemove.button" />
                            ),
                        }}
                        handleConfirm={handleRemove}
                        data-cy={dataCySelector(1, TABLE_ACTIONS.REMOVE)}
                        icon="recycle"
                    />
                </Toolbar>
            }
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ApiTokenCard);
