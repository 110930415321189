// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ILocationResponse } from 'common/api/resources/Location';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import { COLORS } from 'common/theme';
import { ServerIcon } from 'common/components/ServerIcon/ServerIcon';
import { CutTitle } from 'common/components';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    ContentName,
    DescriptionText,
    ItemContent,
    LocationItem,
    LogoContainer,
} from 'common/components/location/Styles';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { useDisabledEntitiesManager } from 'common/helpers/ManagerOfDisabledEntities';

type IProjectServerCardProps = {
    location: ILocationResponse;
    isSelected: boolean;
    onItemClicked: (id: number) => void;
    disableLocationWithoutComputeResources: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const LocationCard: React.FC<IProjectServerCardProps> = ({
    location,
    isSelected,
    onItemClicked,
    disableLocationWithoutComputeResources,
}) => {

    const manager = useDisabledEntitiesManager();

    const locationStatus = React.useMemo(
        () => manager.getLocationStatus(location),
        [manager, location]
    );

    const tooltips = [];

    let isDisabled = false;

    if (locationStatus?.isDisabled) {
        isDisabled = true;

        locationStatus
            .conflictMessages
            .map((conflictMessage, ind) => (
                <div key={ind}>
                    <Translate content={conflictMessage.messageKey} params={conflictMessage.params}/>
                </div>
            ))
            .forEach(el => tooltips.push(el));
    }

    if (disableLocationWithoutComputeResources && !location.compute_resources.length) {
        isDisabled = true;

        tooltips.push(
            <div key="no_compute_resources">
                <Translate content="servers.create.locationConflicts.noComputeResources" />
            </div>
        );
    }

    const showInternalTooltips = !tooltips.length;

    const renderIcon = () => {
        if (isSelected) {
            return <Icon name={ICONS.LOCATION} />;
        }

        if (!location.icon) {
            return (
                <Icon
                    intent={INTENT_TYPE.INACTIVE}
                    name={ICONS.GLOBE}
                />
            );
        }

        return <ServerIcon icon={location.icon} />;
    };

    const handleClick = (id: number) => () => !isDisabled && onItemClicked(id);

    return (
        <Tooltip title={tooltips} shown={!!tooltips.length}>
            <LocationItem isSelected={isSelected} onClick={handleClick(location.id)} isDisabled={isDisabled}>
                <div>
                    <LogoContainer>
                        {renderIcon()}
                    </LogoContainer>
                </div>
                <ItemContent>
                    <ContentName data-cy={PROJECT_SERVER_PAGE.LOCATION_CARD}>
                        <CutTitle title={location.name} withTooltip={showInternalTooltips} />
                    </ContentName>
                    <DescriptionText className="location-description">
                        <CutTitle title={location.description} withTooltip={showInternalTooltips} />
                    </DescriptionText>
                </ItemContent>
                {!location.is_visible && (
                    <Icon name="eye-closed" size="16" style={{ color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60 }}/>
                )}
            </LocationItem>
        </Tooltip>
    );
};
