// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    Button,
    Translate,
} from '@plesk/ui-library';
import { TASK_STATUS_INTENT_MAP } from 'admin/task/constants';
import { TASKS_TABLE } from 'admin/task/constants/test';
import {
    ITaskResponse,
    TASK_STATUS,
} from 'common/api/resources/Task';
import { Status } from 'common/components/styles/Status';
import { dataCySelector } from 'common/tests/selectors';

export interface ITaskStatusProps {
    item: ITaskResponse;
    onOpen: () => void;
}

export const TASK_STATUS_MAP = {
    [TASK_STATUS.NOT_STARTED]: <Translate content="task.status.notStarted" />,
    [TASK_STATUS.PENDING]: <Translate content="task.status.pending" />,
    [TASK_STATUS.STATUS_WAITING_FOR_PREVIOUS_TASKS]: <Translate content="task.status.waitingForPreviousTasks" />,
    [TASK_STATUS.QUEUED]: <Translate content="task.status.queued" />,
    [TASK_STATUS.RUNNING]: <Translate content="task.status.running" />,
    [TASK_STATUS.CANCELED]: <Translate content="task.status.canceled" />,
    [TASK_STATUS.FAILED]: <Translate content="task.status.failed" />,
    [TASK_STATUS.DONE]: <Translate content="task.status.done" />,
    [TASK_STATUS.DONE_WITH_ERRORS]: <Translate content="task.status.failed" />,
};

const STATUS_WITH_BUTTONS = [
    TASK_STATUS.FAILED,
    TASK_STATUS.DONE,
    TASK_STATUS.DONE_WITH_ERRORS,
];

export const TaskStatus: React.FC<ITaskStatusProps> = ({
    item,
    onOpen,
}) => {
    const buttonEl = (
        <Button
            data-cy={dataCySelector(item.id, TASKS_TABLE.MODAL_LINK)}
            onClick={onOpen}
            ghost={true}
            icon="box-diagonal-top-in"
        />
    );

    const showButton = STATUS_WITH_BUTTONS.includes(item.status as TASK_STATUS) && item?.output?.length > 0;

    if (item.status === TASK_STATUS.RUNNING) {
        return (
            <>
                <Action progress={true}>{TASK_STATUS_MAP[item.status]} {item.progress}%</Action>
                {buttonEl}
            </>
        );
    }

    return (
        <>
            <Status intent={TASK_STATUS_INTENT_MAP[item.status]} compact={true}>
                {TASK_STATUS_MAP[item.status]}
            </Status>
            &nbsp;
            {showButton && buttonEl}
        </>
    );
};
