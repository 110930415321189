// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { StyledTable } from 'common/components/styles/StyledTable';
import CopyText from 'common/containers/CopyText/CopyText';
import { IReverseDnsResponse } from 'common/api/resources/ReverseDns';
import { EditReverseDns } from 'common/components/ServerTabs/NetworkingTab/EditReverseDns';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/components/ServerTabs/NetworkingTab/constants/test';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { StyledActions } from 'common/components/Actions/Styles';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { RootState } from 'client/core/store';
import { IpIssuedFor } from 'common/api/resources/Ip';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Button } from 'admin/common/components/Button/Button';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    IAdditionalIpAddFailedEvent,
    IAdditionalIpAddSuccessedEvent,
    IAdditionalIpDeletedEvent,
    IIpV4,
    IIpV6Range,
    IPrimaryIpChangedSuccessEvent,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
} from 'common/constants';
import { NetworkingHeader } from 'common/components/ServerTabs/NetworkingTab/Styles';
import {
    Item,
    List,
    Translate,
} from '@plesk/ui-library';
import { getActionColumnProps } from 'common/helpers/list';
import { Dialog } from 'common/components/Dialog/Dialog';
import AdditionalIPForm from 'common/components/ServerTabs/NetworkingTab/AdditionalIPForm';
import { RadioWithConfirmation } from 'common/components/RadioWithConfirmation/RadioWithConfirmation';
import { IpBlockType } from 'common/api/resources/IpBlock';
import { initEchoConnection } from 'common/services/EchoService';
import { canModifyNetwork } from 'common/services/ServerCapabilities';
import { SystemVariablesPopover } from 'admin/common/components/SystemVariablesPopover/SystemVariablesPopover';
import { ListColumn } from '@plesk/ui-library/types/src/components/List';

const initialItem: IReverseDnsResponse = Object.freeze({
    id: 0,
    is_primary: false,
    ip_id: 0,
    ip: '',
    domain: '',
});

interface ITableItem {
    colIp: string | React.ReactNode;
    colDomain: string | React.ReactNode;
    colActions: React.ReactNode;
    key: string;
}

export interface IServerIpAddressesProps {
    server: IVmResponse;
}

export type ServerIpAddressesProps = IServerIpAddressesProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export const ServerIpAddresses: React.FC<ServerIpAddressesProps> = ({
    echoCredentials,
    server,
    deleteReverseDns,
    deleteAdditionalIp,
    isAdditionalIpAdding,
    isPrimaryIpChanging,
    changePrimaryIp,
    commitAdditionalIpCreatingSuccess,
    commitAdditionalIpCreatingFail,
    commitAdditionalIpDeletingSuccess,
    commitAdditionalIpDeletingFail,
    commitPrimaryIpChangingSuccess,
    commitPrimaryIpChangingFail,
    canAddAdditionalIps,
    canDeleteAdditionalIps,
}) => {
    const [openedPopover, setOpenedPopover] = React.useState<number | null>();
    const [dialogOpened, setDialogOpened] = React.useState(false);
    const [reverseDnsIdForDeleting, setReverseDnsIdForDeleting] = React.useState(0);
    const [additionalIpIdForDeleting, setAdditionalIpIdForDeleting] = React.useState(0);
    const [newPrimaryIpId, setNewPrimaryIpId] = React.useState(0);

    const isActionDisabled = React.useMemo(
        () => !canModifyNetwork(server),
        [server]
    );

    React.useEffect(() => {
        const echo = initEchoConnection(echoCredentials);

        if (server.uuid) {
            const channel = echo.private(`${SOCKET_CHANNELS.VIRTUAL_SERVER}.${server.uuid}`);

            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_ADD_SUCCESSED,
                (data: IAdditionalIpAddSuccessedEvent) => {
                    commitAdditionalIpCreatingSuccess(data.ip);
                }
            );
            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_ADD_FAILED,
                (data: IAdditionalIpAddFailedEvent) => {
                    commitAdditionalIpCreatingFail(data.ipId);
                }
            );
            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_DELETE_SUCCESSED,
                (data: IAdditionalIpDeletedEvent) => {
                    commitAdditionalIpDeletingSuccess(data.deletedIpId);
                    setAdditionalIpIdForDeleting(0);
                }
            );
            channel.listen(
                SOCKET_EVENTS.ADDITIONAL_IP_DELETE_FAILED,
                () => {
                    commitAdditionalIpDeletingFail();
                    setAdditionalIpIdForDeleting(0);
                }
            );
            channel.listen(
                SOCKET_EVENTS.PRIMARY_IP_CHANGE_SUCCESSED,
                (data: IPrimaryIpChangedSuccessEvent) => {
                    commitPrimaryIpChangingSuccess(data.newPrimaryIpId);
                    setNewPrimaryIpId(0);
                }
            );
            channel.listen(
                SOCKET_EVENTS.PRIMARY_IP_CHANGE_FAILED,
                () => {
                    commitPrimaryIpChangingFail();
                    setNewPrimaryIpId(0);
                }
            );
        }

        return () => echo.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.uuid]);

    const handleDeleteReverseDns = (id: number) => async () => {
        try {
            setReverseDnsIdForDeleting(id);
            await deleteReverseDns(id);
        } finally {
            setReverseDnsIdForDeleting(0);
        }
    };
    const handleDeleteAdditionalIp = (id: number) => () => {
        setAdditionalIpIdForDeleting(id);
        deleteAdditionalIp(server.id, server.status, id);
    };

    const handleOpenPopover = (id: number) => setOpenedPopover(id);
    const handleClosePopover = () => setOpenedPopover(null);

    const handleOpenDialog = () => setDialogOpened(true);
    const handleCloseDialog = () => setDialogOpened(false);

    const columns = (version: IpBlockType) => {
        const cols: Array<ListColumn<ITableItem>> = [
            {
                width: '20%',
                key: 'colIp',
                title: <Translate content="servers.tabs.networking.reverseDns.list.ip" />,
            },
        ];

        const isDnsIntegrationEnabled = server.ip_addresses.ipv6.some(item => item.is_reverse_dns_enabled) ||
            server.ip_addresses.ipv4.some(item => item.is_reverse_dns_enabled);

        if (isDnsIntegrationEnabled) {
            cols.push({
                key: 'colDomain',
                title: <Translate content="servers.tabs.networking.reverseDns.list.domain" />,
            });
        }

        cols.push({
            key: 'colInfo',
            title: <Translate content="servers.tabs.networking.reverseDns.list.info" />,
        });

        if (version === IpBlockType.IPv4) {
            cols.push({
                key: 'colPrimary',
                title: <Translate content="servers.tabs.networking.reverseDns.list.primary" />,
            });
        }

        cols.push(getActionColumnProps());

        return cols;
    };

    const handlePrimaryIpChange = (ip: IIpV4 | IIpV6Range) => async () => {
        setNewPrimaryIpId(ip.id);
        await changePrimaryIp(server.id, server.status, ip.id);
    };

    const renderIpv4Rows = (ip: IIpV4) => ({
        colIp: (<CopyText>{ip.ip}</CopyText>),
        colInfo: (
            <SystemVariablesPopover
                button={<Translate content="servers.tabs.networking.reverseDns.popover.information.button" />}
                header={<Translate content="servers.tabs.networking.reverseDns.popover.information.header" />}
                isInBrackets={false}
                variables={[
                    {
                        variable: ip.gateway,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.gateway" />,
                    },
                    {
                        variable: ip.netmask,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.netmask" />,
                    },
                    {
                        variable: ip.cidr,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.cidr" />,
                    },
                    {
                        variable: ip.ns_1,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.ns_1" />,
                    },
                    {
                        variable: ip.ns_2,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.ns_2" />,
                    },
                ]}
            />
        ),
        colPrimary: (
            <RadioWithConfirmation
                id={`change-ip-radio-${ip.id}`}
                checked={ip.is_primary}
                name="primary"
                isLoading={(isPrimaryIpChanging && newPrimaryIpId === ip.id)}
                disabled={server.is_processing || isActionDisabled}
                onConfirm={handlePrimaryIpChange(ip)}
                translations={{
                    button: <Translate content="servers.tabs.networking.additionalIps.changePrimaryIpConfirmationPopover.button"/>,
                    title: <Translate content="servers.tabs.networking.additionalIps.changePrimaryIpConfirmationPopover.title"/>,
                    tooltip: <Translate content="servers.guestToolsMissing" />,
                }}
                showTooltip={isActionDisabled}
                data-cy={dataCySelector(ip.id, TABLE_ACTIONS.CHANGE_PRIMARY_IP)}
            />
        ),
        colDomain: ip.reverse_dns.domain,
        colActions: (
            <StyledActions>
                <EditReverseDns
                    disabled={!ip.is_reverse_dns_enabled || server.is_processing}
                    isPopoverOpened={openedPopover === ip.id}
                    ipId={ip.id}
                    reverseDns={ip.reverse_dns}
                    data-cy={dataCySelector(ip.id, TABLE_ACTIONS.EDIT)}
                    handleOpenPopover={handleOpenPopover}
                    onPopoverClose={handleClosePopover}
                />
                {canDeleteAdditionalIps && (
                    <ButtonWithConfirmation
                        data-cy={dataCySelector(ip.id, TABLE_ACTIONS.REMOVE_ADDITIONAL_IP)}
                        isLoading={additionalIpIdForDeleting === ip.id}
                        disabled={ip.is_primary || server.is_processing || isActionDisabled}
                        translations={{
                            title: (
                                <Translate content="servers.tabs.networking.additionalIps.removePopover.title" />
                            ),
                            button: (
                                <Translate content="servers.tabs.networking.additionalIps.removePopover.button" />
                            ),
                            tooltip: (
                                isActionDisabled
                                    ? <Translate content="servers.guestToolsMissing" />
                                    : ip.is_primary
                                        ? <Translate content="servers.tabs.networking.additionalIps.removePopover.tooltipForbiddenPrimary" />
                                        : <Translate content="servers.tabs.networking.additionalIps.removePopover.tooltip" />
                            ),
                            text: (
                                <Translate content="servers.tabs.networking.additionalIps.removePopover.text" />
                            ),
                        }}
                        handleConfirm={handleDeleteAdditionalIp(ip.id)}
                        icon={ICONS.RECYCLE}
                    />
                )}
            </StyledActions>
        ),
        key: ip.id.toString(),
    });

    const renderIpv6Rows = (ip: IIpV6Range) => ({
        colIp: (<CopyText>{ip.range}</CopyText>),
        colInfo: (
            <SystemVariablesPopover
                button={<Translate content="servers.tabs.networking.reverseDns.popover.information.button" />}
                header={<Translate content="servers.tabs.networking.reverseDns.popover.information.header" />}
                isInBrackets={false}
                variables={[
                    {
                        variable: ip.gateway,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.gateway" />,
                    },
                    {
                        variable: ip.ns_1,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.ns_1" />,
                    },
                    {
                        variable: ip.ns_2,
                        description: <Translate content="servers.tabs.networking.reverseDns.list.ns_2" />,
                    },
                ]}
            />
        ),
        colDomain: (
            <Translate
                content="servers.tabs.networking.reverseDns.list.entries"
                params={{ count: ip.reverse_dns.length }}
                data-cy={dataCySelector(ip.id, TABLE_ACTIONS.LIST_ENTRIES_REVERS_DNS)}
            />
        ),
        colPrimary: (
            <RadioWithConfirmation
                id={`change-radio-${ip.id}`}
                checked={ip.is_primary}
                name="primary_ipv6"
                isLoading={(isPrimaryIpChanging && newPrimaryIpId === ip.id)}
                disabled={server.is_processing || isActionDisabled}
                onConfirm={handlePrimaryIpChange(ip)}
                translations={{
                    button: <Translate content="servers.tabs.networking.additionalIps.changePrimaryIpConfirmationPopover.button"/>,
                    title: <Translate content="servers.tabs.networking.additionalIps.changePrimaryIpConfirmationPopover.title"/>,
                    tooltip: <Translate content="servers.guestToolsMissing" />,
                }}
                showTooltip={isActionDisabled}
                data-cy={dataCySelector(ip.id, TABLE_ACTIONS.CHANGE_PRIMARY_IP)}
            />
        ),
        colActions: (
            <StyledActions>
                <EditReverseDns
                    disabled={!ip.is_reverse_dns_enabled || isPrimaryIpChanging || server.is_processing}
                    isPopoverOpened={openedPopover === ip.id}
                    ipId={ip.id}
                    reverseDns={initialItem}
                    data-cy={dataCySelector(ip.id, TABLE_ACTIONS.EDIT)}
                    handleOpenPopover={handleOpenPopover}
                    onPopoverClose={handleClosePopover}
                    icon={ICONS.PLUS}
                />
                <ButtonWithConfirmation
                    data-cy={dataCySelector(ip.id, TABLE_ACTIONS.REMOVE_ADDITIONAL_IP)}
                    isLoading={additionalIpIdForDeleting === ip.id}
                    disabled={ip.is_primary || server.is_processing || isActionDisabled }
                    translations={{
                        title: (
                            <Translate content="servers.tabs.networking.additionalIps.removePopover.title" />
                        ),
                        button: (
                            <Translate content="servers.tabs.networking.additionalIps.removePopover.button" />
                        ),
                        tooltip: (
                            isActionDisabled
                                ? <Translate content="servers.guestToolsMissing" />
                                : ip.is_primary
                                    ? <Translate content="servers.tabs.networking.additionalIps.removePopover.tooltipForbiddenPrimary" />
                                    : <Translate content="servers.tabs.networking.additionalIps.removePopover.tooltip" />
                        ),
                        text: (
                            <Translate content="servers.tabs.networking.additionalIps.removePopover.text" />
                        ),
                    }}
                    handleConfirm={handleDeleteAdditionalIp(ip.id)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: ip.id.toString(),
    });

    const renderReversDnsRows = (id: number, item: IReverseDnsResponse, isReverseDnsEnabled: boolean) => ({
        colIp: (<CopyText>{item.ip}</CopyText>),
        colDomain: item.domain,
        colActions: (
            <StyledActions>
                <EditReverseDns
                    disabled={!isReverseDnsEnabled || isPrimaryIpChanging}
                    isPopoverOpened={openedPopover === item.ip_id}
                    ipId={item.ip_id}
                    reverseDns={item}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
                    handleOpenPopover={handleOpenPopover}
                    onPopoverClose={handleClosePopover}
                />
                <ButtonWithConfirmation
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE_REVERSE_DNS)}
                    isLoading={reverseDnsIdForDeleting === item.id}
                    disabled={item.is_primary || !isReverseDnsEnabled}
                    translations={{
                        title: (
                            <Translate content="servers.tabs.networking.reverseDns.removePopover.title" />
                        ),
                        button: (
                            <Translate content="servers.tabs.networking.reverseDns.removePopover.button" />
                        ),
                        tooltip: (
                            <Translate content="servers.tabs.networking.reverseDns.removePopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handleDeleteReverseDns(item.id)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: id.toString(),
    });

    const renderRowBody = () => (
        <List
            columns={columns(IpBlockType.IPv6)}
            data={
                server.ip_addresses?.ipv6.flatMap(
                    (ipv6) => ipv6.reverse_dns.map(item => renderReversDnsRows(
                        ipv6.id,
                        item,
                        ipv6.is_reverse_dns_enabled
                    ))
                )
            }
        />
    );

    const getListData = () => {
        const listDataIpv4: ITableItem[] = server.ip_addresses.ipv4
            .filter(item => item.issued_for === IpIssuedFor.VM)
            .map(item => renderIpv4Rows(item));

        const listDataIpv6: ITableItem[] = server.ip_addresses.ipv6
            .map(item => renderIpv6Rows(item));

        return [...listDataIpv4, ...listDataIpv6];
    };

    const showAddButton = canAddAdditionalIps && server.plan.is_additional_ips_available;

    return (
        <Item
            title={
                <NetworkingHeader>
                    <h3>
                        <Translate content="servers.tabs.networking.reverseDns.title" />
                    </h3>
                    {showAddButton && (
                        <Button
                            size={SIZE.LG}
                            intent={INTENT_TYPE.PRIMARY}
                            icon={ICONS.PLUS}
                            onClick={handleOpenDialog}
                            isLoading={isAdditionalIpAdding}
                            disabled={server.is_processing || isActionDisabled}
                            tooltip={isActionDisabled && (<Translate content="servers.guestToolsMissing" />)}
                        >
                            <Translate content="servers.tabs.networking.additionalIps.button" />
                        </Button>
                    )}
                </NetworkingHeader>
            }
            view="card"
        >
            <StyledTable>
                <List
                    renderRowBody={server.ip_addresses.ipv6.some((ipv6) => ipv6.is_reverse_dns_enabled) ? renderRowBody : undefined}
                    columns={columns(IpBlockType.IPv4)}
                    data={getListData()}
                    notExpandableRows={server.ip_addresses.ipv4.map(item => item.id.toString())}
                />
                {showAddButton && (
                    <Dialog
                        isOpen={dialogOpened}
                        heading={<Translate content="servers.tabs.networking.additionalIps.createDialog.header"/>}
                        size={SIZE.XS}
                        closeHandler={handleCloseDialog}
                    >
                        <AdditionalIPForm
                            server={server}
                            onSubmit={handleCloseDialog}
                        />
                    </Dialog>
                )}
            </StyledTable>
        </Item>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAdditionalIpAdding: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING),
    isPrimaryIpChanging: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP),
    echoCredentials: `${state.auth.authData.token_type} ${state.auth.authData.access_token}`,
    canAddAdditionalIps: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_SERVERS,
        PERMISSION_LIST.ADD_ADDITIONAL_IP_ADDRESS
    ),
    canDeleteAdditionalIps: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_SERVERS,
        PERMISSION_LIST.DELETE_ADDITIONAL_IP_ADDRESS
    ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteReverseDns: bindActionCreators(computeResourceVmActions.deleteIpV6ReverseDns, dispatch),
    deleteAdditionalIp: bindActionCreators(computeResourceVmActions.deleteAdditionalIp, dispatch),
    changePrimaryIp: bindActionCreators(computeResourceVmActions.changePrimaryIp, dispatch),
    commitPrimaryIpChangingSuccess: bindActionCreators(computeResourceVmActions.commitPrimaryIpChangingSuccess, dispatch),
    commitPrimaryIpChangingFail: bindActionCreators(computeResourceVmActions.commitPrimaryIpChangingFail, dispatch),
    commitAdditionalIpCreatingSuccess: bindActionCreators(computeResourceVmActions.commitAdditionalIpCreatingSuccess, dispatch),
    commitAdditionalIpCreatingFail: bindActionCreators(computeResourceVmActions.commitAdditionalIpCreatingFail, dispatch),
    commitAdditionalIpDeletingSuccess: bindActionCreators(computeResourceVmActions.commitAdditionalIpDeletingSuccess, dispatch),
    commitAdditionalIpDeletingFail: bindActionCreators(computeResourceVmActions.commitAdditionalIpDeletingFail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerIpAddresses);