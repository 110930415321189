// Copyright 2025. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import {
    SET_IS_LOADING,
    UNSET_IS_LOADING,
    LOADING_FLAGS,
} from 'common/modules/app/loadingFlags/constants';

export const setIsLoading = createCustomAction(
    SET_IS_LOADING,
    (flag: LOADING_FLAGS) => ({ payload: flag })
);
export const unsetIsLoading = createCustomAction(
    UNSET_IS_LOADING,
    (flag: LOADING_FLAGS) => ({ payload: flag })
);
