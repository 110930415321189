// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import * as settingsActions from 'common/modules/settings/actions';
import SolusIOLicense from 'admin/license/solusIO/containers/SolusIOLicense/SolusIOLicense';
import { connect } from 'react-redux';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    LicenseModel,
    LICENSE_MODEL_TRANSLATION_MAP,
} from 'common/api/resources/Settings';
import { Loader } from 'common/components';
import SolusVMLicense from 'admin/license/solusVM/containers/SolusVMLicense';
import {
    FormFieldSelect,
    SelectOption,
    Translate,
} from '@plesk/ui-library';
import * as metaActions from 'common/modules/app/meta/actions';
import { FormLabel } from 'admin/license/Styles';

export type LicenseProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const License: React.FC<LicenseProps> = ({
    licenseModel,
    loadingFlags: { isLoaded, isSaving },
    settingsActions: { getSettings, saveSettings },
    metaActions: { getMeta },
}) => {
    React.useEffect(() => {
        getSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = async (value: string) => {
        await saveSettings({
            license: {
                model: value as LicenseModel,
            },
        });
        await getMeta();
    };

    return (
        <Loader isLoading={!isLoaded}>
            <PageHeader
                isButtonShown={false}
                title={<Translate content="license.model" />}
            />
            <FormFieldSelect
                label={<FormLabel><Translate content="license.model" /></FormLabel>}
                value={licenseModel}
                name="license_model"
                onChange={handleOnChange}
                disabled={isSaving}
            >
                {Object.entries(LICENSE_MODEL_TRANSLATION_MAP).map(([key, value]) => (
                    <SelectOption key={key} value={key}>{value}</SelectOption>
                ))}
            </FormFieldSelect>
            <Loader isLoading={isSaving}>
                {licenseModel === LicenseModel.SOLUS_IO && <SolusIOLicense />}
                {licenseModel === LicenseModel.SOLUS_VM_V1 && <SolusVMLicense />}
            </Loader>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    licenseModel: state.settings.license.model,
    loadingFlags: {
        isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_APP_SETTINGS),
        isLoaded: state.app.loadingFlags.has(LOADING_FLAGS.APP_SETTINGS_LOADED),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
    metaActions: bindActionCreators(metaActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(License);
