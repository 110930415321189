// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'client/core/theme';
import { rgba } from 'polished';

export const StyledForm = styled.div`
  width: 100%;

  form {
      margin: auto;

      label span {
        font-size: 12px;
        color: ${COLORS.GREY_100};
      }
      
      input {
        height: 40px;
        background: ${COLORS.WHITE_0};
        border: 1px solid ${COLORS.GREY_50};
        box-sizing: border-box;
        box-shadow: inset 0 3px 2px ${rgba(COLORS.GREY_100, 0.05)};
        border-radius: 2px;
        
        &:focus {
            background: linear-gradient(0deg, ${rgba(COLORS.PRIMARY, 0.04)}, ${rgba(COLORS.PRIMARY, 0.04)}), ${COLORS.WHITE_0};
            border: 1px solid #6FBEDE;
            box-sizing: border-box;
            box-shadow: inset 0 3px 1px ${rgba(COLORS.PRIMARY, 0.09)};
            border-radius: 2px;
        }
      }
      
      div.pul-section {
        margin-bottom: 0;
      }
      
      div.pul-section-item__title  {
        margin-bottom: 0;
      }

      div.pul-section-item {
        padding: 0;
      }
      
      div.pul-form-field-text {
        margin-bottom: 20px;
      }
      
      span.pul-form-field-password__input {
        width: 100%;
      }
      
      div.pul-form-field--error {
        background: none;
        box-shadow: none;
        input {
            background: linear-gradient(0deg, ${rgba(COLORS.RED_0, 0.04)}, ${rgba(COLORS.RED_0, 0.04)}), ${COLORS.WHITE_0};
            border: 1px solid ${COLORS.RED_0};
            box-sizing: border-box;
            box-shadow: inset 0 3px 1px ${rgba(COLORS.RED_0, 0.07)};
        }
      }
      
      span.pul-form-field__error {
         margin: 8px 0 0;
      }
      
      button.pul-form-field-password__button--show {
         margin-left: -30px;
         background: none;
         color: ${COLORS.GREY_80};
         
         &:hover {
            color: ${props => props.theme.primary_color};
         }
      }   
      
      .pul-button--primary, .pul-button--primary.pul-button--disabled {
        margin-top: 40px;
        margin-bottom: 16px;
        height: 40px;
        background: ${props => props.theme.primary_color} !important;
        box-shadow: 0 1px 1px ${props => props.theme.primary_color && rgba(props.theme.primary_color, 0.5)};
        border-radius: 2px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      
      .pul-button--primary.pul-button--disabled {
          padding-top: 10px;
      }
      
      button.pul-button--loading {
        background: ${COLORS.GREY_20};
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.GREY_70};
        box-shadow: none;
      }
      
      .pul-form__footer {
        display: none;
      }
  }
`;

export const Text = styled.div`
    color: #c80000;
`;

export const LinkText = styled.div`
    color: ${COLORS.GREY_80};
`;

export const InfoBlock = styled.div`
  word-wrap: break-word;
  font-size: 12px;
  line-height: 16px;
`;

export const FieldWithInfo = styled.div`
  display: inline-block;
  max-height: 101px;
  width: 100%;
`;

export const FieldWithLoader = styled.div`
  .loader {
    position: absolute;
    top: 39px;
    right: 10px;
    z-index: 9999;
  }
`;
