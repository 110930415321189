// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled,
{ css } from 'styled-components';
import {
    ButtonGroup,
    SegmentedControl as UiLibSegmentedControl,
} from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';
import { SIZE } from 'common/constants';

const commonStyles = css`
    .pul-button--ghost.pul-button--active, .pul-button--ghost.pul-button--selected, .pul-button--ghost:active {
        color: ${COLORS.GREY_100};
    }

    .pul-button--disabled.pul-button--selected:hover {
        background-color: ${COLORS.GREY_30} !important
    }
`;

const commonPulButtonStyles = css`
    border-radius: 14px;

    &:active, &:focus {
        color: ${COLORS.GREY_100} !important;
        box-shadow: none !important;
    }

    &:hover:not([disabled]) {
        color: ${COLORS.GREY_100} !important;
    }
`;

// @ts-expect-error
export const StyledSegmentedControl = styled(UiLibSegmentedControl)<{
    disabled?: boolean;
    $size?: SIZE;
}>`
    ${({ $size }) => $size === SIZE.MD && css`
        width: 200px;
    `};
    
    .pul-button {
        ${commonPulButtonStyles}
        ${({ disabled }) => disabled && css`
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        `}
    }

    ${commonStyles}
`;

export const StyledSegmentedControlBlock = styled.div`
    .pul-button {
        ${commonPulButtonStyles}
    }

    ${commonStyles}
`;

export const SegmentedControlWithConfirmationContainer = styled(ButtonGroup)`
    .pul-button {
        padding: 0 12px;

        ${commonPulButtonStyles}

        &--selected {
            background-color: rgba(34, 34, 34, .09);
            box-shadow: none;
        }
    }

    ${commonStyles}
`;
