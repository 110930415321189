// Copyright 2025. WebPros International GmbH. All rights reserved.
import {
    IOnboarding,
    onboarding,
    OnboardingStep,
} from 'common/api/resources/Onboarding';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/onboarding/constants';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export const setOnboardingClusterSetup = createCustomAction(
    types.SET_ONBOARDING_CLUSTER_SETUP,
    (data: IOnboarding) => ({ payload: data })
);

export const getOnboardingClusterSetup = () => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.ONBOARDING_CLUSTER_SETUP));
    try {
        const result = await onboarding.get();
        dispatch(setOnboardingClusterSetup(result.data));
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.ONBOARDING_CLUSTER_SETUP));
    }
};

export function updateOnboardingClusterSetupStep(step: OnboardingStep, compute_resource_id?: number) {
    return async (dispatch: Dispatch) => {
        dispatch(setIsLoading(LOADING_FLAGS.ONBOARDING_CLUSTER_SETUP));
        try {
            const result = await onboarding.update(step, compute_resource_id);
            dispatch(setOnboardingClusterSetup(result.data));
            return result;
        } finally {
            dispatch(unsetIsLoading(LOADING_FLAGS.ONBOARDING_CLUSTER_SETUP));
        }
    };
}
