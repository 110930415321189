// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Translate,
    Action,
    Icon,
} from '@plesk/ui-library';
import { UserDataHeaderContainer } from 'common/components/ServerUserData/UserDataHeader/Styles';

interface IUserDataHeader {
    isLinux: boolean;
}

export const UserDataHeader: React.FC<IUserDataHeader> = ({
    isLinux,
}) => {
    const sampleLink = isLinux
        ? 'https://cloudinit.readthedocs.io/en/latest/topics/examples.html'
        : 'https://cloudbase-init.readthedocs.io/en/latest/userdata.html#cloud-config';

    return (
        <UserDataHeaderContainer>
            <Translate content="servers.create.userData.sectionTitle" />
            <div>
                <Icon name="arrow-diagonal-out" />
                <Action target="_blank" href={sampleLink}>
                    <Translate content="servers.create.userData.sampleBtn" />
                </Action>
            </div>
        </UserDataHeaderContainer>
    );
};

export default UserDataHeader;
