// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { PageSubHeader } from 'client/common/components';
import {
    Popover,
    Translate,
    Icon,
    Action,
    Grid,
    GridCol,
    Tooltip,
} from '@plesk/ui-library';
import { RootState } from 'client/core/store';
import {
    ISshKeyRequest,
    ISshKeyResponse,
} from 'common/api/resources/SshKey';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    ActionItem,
    SSHKeyItem,
    SSHKeyItemContent,
    HeaderContainer,
} from 'common/components/ServerSSHKeys/Styles';
import SshKeyForm from 'common/components/SshKeyForm/SshKeyForm';
import { connect } from 'react-redux';
import { ServerSSHKeysDrawer } from 'common/components/ServerSSHKeys/ServerSSHKeysDrawer';
import { IAxiosApiResponse } from 'common/api/resources/Response';

interface IServerSSHKeysProps {
    selected: number[];
    onSelected: (ids: number[]) => void;
    sshKeys: ISshKeyResponse[];
    create: (values: ISshKeyRequest) => IAxiosApiResponse<ISshKeyResponse>;
}

export type ServerSSHKeysProps =
    IServerSSHKeysProps &
    ReturnType<typeof mapStateToProps>;

export const ServerSSHKeys: React.FC<ServerSSHKeysProps> = ({
    userEmail,
    isItemCreating,
    sshKeys,
    onSelected,
    selected,
    create,
}) => {
    const [isDrawerOpened, setDrawerOpen] = React.useState(false);
    const [isPopoverOpened, setPopoverOpen] = React.useState(false);

    const handleSelect = (id: number ) => () => {
        const keys = selected.includes(id) ? selected.filter(key => key !== id) : [...selected, id];

        onSelected(keys);
    };

    const toggleDrawer = () => setDrawerOpen(!isDrawerOpened);
    const togglePopover = () => setPopoverOpen(!isPopoverOpened);

    const handleOnCreated = (id: number) => {
        handleSelect(id)();
        togglePopover();
    };

    const handleKeysAdd = (ids: number[]) => {
        onSelected(ids);
        setDrawerOpen(false);
    };

    const items = sshKeys.map((sshKey, key) => (
        <GridCol key={key}>
            <SSHKeyItem isSelected={selected.includes(sshKey.id)} onClick={handleSelect(sshKey.id)}>
                <SSHKeyItemContent>
                    <Tooltip title={sshKey.name}>
                        <span>{sshKey.name}</span>
                    </Tooltip>
                </SSHKeyItemContent>
            </SSHKeyItem>
        </GridCol>
    ));

    return (
        <>
            <HeaderContainer>
                <PageSubHeader
                    title="servers.create.ssh.sectionTitle"
                    action={(
                        <Action onClick={toggleDrawer}>
                            <Translate content="servers.create.ssh.showAll" />
                        </Action>
                    )}
                />
            </HeaderContainer>
            <Grid minColumnWidth={190} gap="lg">
                {items}
                <Popover
                    visible={isPopoverOpened}
                    onClose={togglePopover}
                    canCloseOnOutsideClick={false}
                    wide={true}
                    target={(
                        <GridCol>
                            <ActionItem onClick={togglePopover}>
                                <Icon size="24" name="key" />
                                <Translate content="servers.create.ssh.addBtn" />
                            </ActionItem>
                        </GridCol>
                    )}>
                    <SshKeyForm
                        userEmail={userEmail}
                        isItemCreating={isItemCreating}
                        create={create}
                        onCreated={handleOnCreated}
                    />
                </Popover>
            </Grid>
            <ServerSSHKeysDrawer
                isOpen={isDrawerOpened}
                sshKeys={sshKeys}
                selected={selected}
                onClose={toggleDrawer}
                onKeysAdd={handleKeysAdd}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    userEmail: state.auth.user.email,
    isItemCreating: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_SSH_KEY_ITEM),
});

export default connect(mapStateToProps)(ServerSSHKeys);
