// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Dispatch } from 'redux';
import * as localeActions from 'common/modules/app/language/actions';
import { ICommonState } from 'common/store';
import { connect } from 'react-redux';
import { ILanguageResponse } from 'common/api/resources/Language';
import LanguageDropdown from 'common/containers/LanguageDropdown/LanguageDropdown';

interface IAuthLanguageDropdown {
    onChange?: (lang: ILanguageResponse) => void;
}

export type AuthLanguageDropdownProps =
    IAuthLanguageDropdown &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

/**
 * Common language dropdown component for admin and client login form.
 * Allows to select application language for that forms.
 */
export const AuthLanguageDropdown: React.FC<AuthLanguageDropdownProps> = ({
    onChange,
    languages,
    appLanguage,
    setAppLanguage,
}) => {
    if (languages.length <= 1) {
        return null;
    }

    const handleChange = (language: ILanguageResponse) => {
        setAppLanguage(language);
        onChange?.(language);
    };

    return (
        <LanguageDropdown
            languages={languages}
            selected={appLanguage}
            onChange={handleChange}
        />
    );
};

const mapStateToProps = (state: ICommonState) => ({
    languages: state.language.list.data,
    appLanguage: state.app.language,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAppLanguage: (locale: ILanguageResponse) => dispatch(localeActions.setLanguage(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLanguageDropdown);
