// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IPlanResponse } from 'common/api/resources/Plan';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { ILocationResponse } from 'common/api/resources/Location';
import { IApplicationResponse } from 'common/api/resources/Application';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { IOfferResponse } from 'common/api/resources/Offer';
import { IVpcNetworkResponse } from 'common/api/resources/VpcNetwork';
import { IOsImageVersionResponse } from 'common/api/resources/OsImageVersion';
import {
    ComputeResourceVmCustomPlanRequest,
    ComputeResourceVmCustomPlanResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    createContext,
    useContext,
} from 'react';

export type ManagedResponseEntity = IPlanResponse
    | IOsImageVersionResponse
    | IApplicationResponse
    | ILocationResponse
    | IOfferResponse
    | IVpcNetworkResponse;

export interface IManagerOfDisabledEntitiesRecords {
    plans?: IPlanResponse[];
    osImages?: IOsImageResponse[];
    applications?: IApplicationResponse[];
    locations?: ILocationResponse[];
    offers?: IOfferResponse[];
    vpcNetworks?: IVpcNetworkResponse[];
}

export interface IManagerOfDisabledEntitiesSelection<T extends keyof ManagedResponseEntity | undefined = undefined> {
    plan?: (T extends keyof IPlanResponse ? IPlanResponse[T] : IPlanResponse) | ComputeResourceVmCustomPlanRequest | ComputeResourceVmCustomPlanResponse;
    osImageVersion?: T extends keyof IOsImageVersionResponse ? IOsImageVersionResponse[T] : IOsImageVersionResponse;
    application?: T extends keyof IApplicationResponse ? IApplicationResponse[T] : IApplicationResponse;
    location?: T extends keyof ILocationResponse ? ILocationResponse[T] : ILocationResponse;
    offers?: T extends keyof IOfferResponse ? Array<IOfferResponse[T]> : IOfferResponse[];
    vpcNetworks?: T extends keyof IVpcNetworkResponse ? Array<IVpcNetworkResponse[T]> : IVpcNetworkResponse[];
}

export interface IConflictMessage {
    messageKey: string;
    params?: Record<string, string>;
}

export type EntityStatus = {
    isDisabled: true;
    conflictMessages: IConflictMessage[];
} | {
    isDisabled: false;
};

export interface IManagerOfDisabledEntities {
    getDisabledPlanIds(): Array<IPlanResponse['id']>;

    getDisabledOsImageVersionIds(): Array<IOsImageVersionResponse['id']>;

    getDisabledApplicationIds(): Array<IApplicationResponse['id']>;

    getDisabledLocationIds(): Array<ILocationResponse['id']>;

    getDisabledOfferIds(): Array<IOfferResponse['id']>;

    getDisabledVpcNetworkIds(): Array<IVpcNetworkResponse['id']>;

    getPlanStatus(plan: IPlanResponse): EntityStatus | undefined;

    getOsImageVersionStatus(osImageVersion: IOsImageVersionResponse): EntityStatus | undefined;

    getApplicationStatus(application: IApplicationResponse): EntityStatus | undefined;

    getLocationStatus(location: ILocationResponse): EntityStatus | undefined;

    getOfferStatus(offer: IOfferResponse): EntityStatus | undefined;

    getVpcNetworkStatus(vpcNetwork: IVpcNetworkResponse): EntityStatus | undefined;
}

export type Entity = 'locations' | 'osImageVersions' | 'applications' | 'plans' | 'offers' | 'vpcNetworks';

export default class ManagerOfDisabledEntities implements IManagerOfDisabledEntities {
    protected records: IManagerOfDisabledEntitiesRecords;
    protected selectedIds: IManagerOfDisabledEntitiesSelection<'id'>;
    protected selectedEntities: IManagerOfDisabledEntitiesSelection;
    private entitiesStatuses: Record<Entity, Record<number, EntityStatus | undefined>>;

    constructor(records: IManagerOfDisabledEntitiesRecords, selection: IManagerOfDisabledEntitiesSelection<'id'>) {
        this.records = records;
        this.selectedIds = selection;
        this.selectedEntities = this.fillSelectedEntities();

        this.entitiesStatuses = {
            locations: {},
            osImageVersions: {},
            applications: {},
            plans: {},
            offers: {},
            vpcNetworks: {},
        };

        this.fillEntitiesStatus();
    }

    getPlanStatus(plan: IPlanResponse): EntityStatus | undefined {
        return this.entitiesStatuses.plans[plan.id];
    }

    getOsImageVersionStatus(osImageVersion: IOsImageVersionResponse): EntityStatus | undefined {
        return this.entitiesStatuses.osImageVersions[osImageVersion.id];
    }

    getApplicationStatus(application: IApplicationResponse): EntityStatus | undefined {
        return this.entitiesStatuses.applications[application.id];
    }

    getLocationStatus(location: ILocationResponse): EntityStatus | undefined {
        return this.entitiesStatuses.locations[location.id];
    }

    getOfferStatus(offer: IOfferResponse): EntityStatus | undefined {
        return this.entitiesStatuses.offers[offer.id];
    }

    getVpcNetworkStatus(vpcNetwork: IVpcNetworkResponse): EntityStatus | undefined {
        return this.entitiesStatuses.vpcNetworks[vpcNetwork.id];
    }

    getDisabledPlanIds() {
        return Object
            .keys(this.entitiesStatuses.plans)
            .filter(planId => this.entitiesStatuses.plans[planId].isDisabled)
            .map(planId => Number(planId));
    }

    getDisabledOsImageVersionIds() {
        return Object
            .keys(this.entitiesStatuses.osImageVersions)
            .filter(osImageVersionId => this.entitiesStatuses.osImageVersions[osImageVersionId].isDisabled)
            .map(osImageVersionId => Number(osImageVersionId));
    }

    getDisabledApplicationIds() {
        return Object
            .keys(this.entitiesStatuses.applications)
            .filter(applicationId => this.entitiesStatuses.applications[applicationId].isDisabled)
            .map(applicationId => Number(applicationId));
    }

    getDisabledLocationIds() {
        return Object
            .keys(this.entitiesStatuses.locations)
            .filter(locationId => this.entitiesStatuses.locations[locationId].isDisabled)
            .map(locationId => Number(locationId));
    }

    getDisabledOfferIds() {
        return Object
            .keys(this.entitiesStatuses.offers)
            .filter(offerId => this.entitiesStatuses.offers[offerId].isDisabled)
            .map(offerId => Number(offerId));
    }

    getDisabledVpcNetworkIds() {
        return Object
            .keys(this.entitiesStatuses.vpcNetworks)
            .filter(vpcNetworkId => this.entitiesStatuses.vpcNetworks[vpcNetworkId].isDisabled)
            .map(vpcNetworkId => Number(vpcNetworkId));
    }

    isPlanDisabled(plan: IPlanResponse | number) {
        const planEntity = typeof plan === 'number'
            ? this.records.plans?.find(item => item.id === plan)
            : plan;

        if (!planEntity) {
            return false;
        }

        return this.getDisabledPlanIds().includes(planEntity.id);
    }

    isOsImageVersionDisabled(osImageVersion: IOsImageVersionResponse | number) {
        const osImageVersionEntity = typeof osImageVersion === 'number'
            ? this.records.osImages
                ?.map(item => item.versions)
                .flat(1)
                .find(item => item.id === osImageVersion)
            : osImageVersion;

        if (!osImageVersionEntity) {
            return false;
        }

        return this.getDisabledOsImageVersionIds().includes(osImageVersionEntity.id);
    }

    isApplicationDisabled(application: IApplicationResponse | number) {
        const applicationEntity = typeof application === 'number'
            ? this.records.applications?.find(item => item.id === application)
            : application;

        if (!applicationEntity) {
            return false;
        }

        return this.getDisabledApplicationIds().includes(applicationEntity.id);
    }

    isLocationDisabled(location: ILocationResponse | number) {
        const locationEntity = typeof location === 'number'
            ? this.records.locations?.find(item => item.id === location)
            : location;

        if (!locationEntity) {
            return false;
        }

        return this.getDisabledLocationIds().includes(locationEntity.id);
    }

    isOfferDisabled(offer: IOfferResponse | number) {
        const offerEntity = typeof offer === 'number'
            ? this.records.offers?.find(item => item.id === offer)
            : offer;

        if (!offerEntity) {
            return false;
        }

        return this.getDisabledOfferIds().includes(offerEntity.id);
    }

    isVpcNetworkDisabled(vpcNetwork: IVpcNetworkResponse | number) {
        const vpcNetworkEntity = typeof vpcNetwork === 'number'
            ? this.records.vpcNetworks?.find(item => item.id === vpcNetwork)
            : vpcNetwork;

        if (!vpcNetworkEntity) {
            return false;
        }

        return this.getDisabledVpcNetworkIds().includes(vpcNetworkEntity.id);
    }

    private getPlanEntityStatus(plan: IPlanResponse): EntityStatus {
        const conflicts: IConflictMessage[] = [];

        const osImageVersionIdsFromPresets = this.records.osImages
            ?.map(item => item.versions)
            .flat(1)
            .filter(item =>
                plan.image_presets?.some(preset =>
                    item.image_presets?.some(imagePreset => imagePreset.id === preset.id)
                )
            )
            .map(item => item.id) ?? [];
        const allAvailableOsImageVersionIds = [
            ...plan.available_os_image_versions?.map(item => item.id) ?? [],
            ...osImageVersionIdsFromPresets,
        ];

        // Check if plan is disabled by selected OS image version
        if (this.selectedIds.osImageVersion && !allAvailableOsImageVersionIds.includes(this.selectedIds.osImageVersion)) {
            conflicts.push({
                messageKey: 'servers.create.planConflicts.osImageVersion',
                params: {
                    osImageVersion: this.getOsImageVersionFullName(this.selectedEntities.osImageVersion!),
                },
            });
        }

        const applicationIdsFromPresets = this.records.applications
            ?.filter(item =>
                plan.image_presets?.some(preset =>
                    item.image_presets?.some(imagePreset => imagePreset.id === preset.id)
                )
            )
            .map(item => item.id) ?? [];
        const allAvailableApplicationIds = [
            ...plan.available_applications?.map(item => item.id) ?? [],
            ...applicationIdsFromPresets,
        ];

        // Check if plan is disabled by selected application
        if (this.selectedIds.application && !allAvailableApplicationIds.includes(this.selectedIds.application)) {
            conflicts.push({
                messageKey: 'servers.create.planConflicts.application',
                params: {
                    application: this.selectedEntities.application!.name,
                },
            });
        }

        // Check if plan is disabled by selected location
        if (this.selectedIds.location && !plan.available_locations?.some(item => item.id === this.selectedIds.location)) {
            conflicts.push({
                messageKey: 'servers.create.planConflicts.location',
                params: {
                    location: this.selectedEntities.location!.name,
                },
            });
        }

        // Check if plan is disabled by selected offers
        if (this.selectedEntities.offers && this.selectedEntities.offers.length > 0) {
            for (const offer of this.selectedEntities.offers) {
                if (!offer.available_plans?.some(item => item.id === plan.id)) {
                    conflicts.push({
                        messageKey: 'servers.create.planConflicts.offer',
                        params: {
                            offer: offer.name,
                        },
                    });
                }
            }
        }

        return this.generateEntityStatusFromConflicts(conflicts);
    }

    private getOsImageVersionEntityStatus(osImageVersion: IOsImageVersionResponse) {
        const conflicts: IConflictMessage[] = [];

        if (this.selectedEntities.plan && this.selectedEntities.plan.virtualization_type !== osImageVersion.virtualization_type) {
            conflicts.push({ messageKey: 'servers.create.osImageVersionConflicts.planVirtualizationType' });
        }

        // Check if OS image version is disabled by plan
        if (this.selectedEntities.plan) {

            const osImageVersionImagePresetIds = osImageVersion.image_presets?.map(item => item.id).sort();
            const planImagePresetIds = 'image_presets' in this.selectedEntities.plan
                ? this.selectedEntities.plan.image_presets.map(item => item.id).sort()
                : this.selectedEntities.plan.image_preset_ids?.sort();
            const planOsImageVersionIds = this.selectedEntities.plan.available_os_image_versions
                ?.map(item => typeof item === 'object' ? item.id : item)
                .sort();

            if (this.selectedEntities.plan.is_custom
                && (planOsImageVersionIds || []).length === 0
                && (planImagePresetIds || []).length === 0
            ) {
                return this.generateEntityStatusFromConflicts(conflicts);
            }

            // OS Image Version must have at least one image preset in common with the plan
            // or direct available_plans must contain selected plan
            if (!osImageVersionImagePresetIds?.some(item => planImagePresetIds?.includes(item))
                && !planOsImageVersionIds?.some(item => item === osImageVersion.id)
            ) {
                if (this.selectedEntities.plan.is_custom) {
                    conflicts.push({ messageKey: 'servers.create.osImageVersionConflicts.customPlan' });
                } else {
                    conflicts.push({
                        messageKey: 'servers.create.osImageVersionConflicts.plan',
                        params: {
                            plan: this.selectedEntities.plan.name,
                        },
                    });
                }
            }
        }

        // Check if OS image version is disabled by location
        // If OS image version `available_locations` does not contain selected `locationId`, disable it
        if (this.selectedEntities.location && this.selectedEntities.location.available_plans?.every(item => !osImageVersion.available_plans?.some(plan => plan.id === item.id))) {
            conflicts.push({
                messageKey: 'servers.create.osImageVersionConflicts.location',
                params: {
                    location: this.selectedEntities.location.name,
                },
            });
        }

        return this.generateEntityStatusFromConflicts(conflicts);
    }

    private getApplicationEntityStatus(application: IApplicationResponse): EntityStatus {
        const conflicts: IConflictMessage[] = [];

        // Check if application is disabled by plant
        if (this.selectedEntities.plan) {
            // If plan virtualization type is VZ, disable all applications
            if (this.selectedEntities.plan.virtualization_type === VirtualizationType.VZ) {
                conflicts.push({ messageKey: 'servers.create.applicationConflicts.planVzVirtualizationType' });
            } else {
                const planImagePresetIds = 'image_presets' in this.selectedEntities.plan
                    ? this.selectedEntities.plan.image_presets.map(item => item.id).sort()
                    : this.selectedEntities.plan.image_preset_ids?.sort();

                const planApplicationIds = this.selectedEntities.plan.available_applications
                    ?.map(item => typeof item === 'object' ? item.id : item)
                    .sort();

                if (
                    this.selectedEntities.plan.is_custom
                    && (planApplicationIds || []).length === 0
                    && (planImagePresetIds || []).length === 0
                ) {
                    conflicts.push({ messageKey: 'servers.create.applicationConflicts.customPlan' });
                } else {
                    const applicationImagePresetIds = application.image_presets
                        ?.map(item => item.id)
                        .sort();

                    // Application must have at least one image preset in common with the plan
                    // or direct available_plans must contain selected plan
                    if (!applicationImagePresetIds?.some(item => planImagePresetIds?.includes(item))
                        && !planApplicationIds?.some(item => item === application.id)
                    ) {
                        if (this.selectedEntities.plan.is_custom) {
                            conflicts.push({ messageKey: 'servers.create.applicationConflicts.customPlan' });
                        } else {
                            conflicts.push({
                                messageKey: 'servers.create.applicationConflicts.plan',
                                params: {
                                    plan: this.selectedEntities.plan.name,
                                },
                            });
                        }
                    }
                }
            }
        }

        // Check if application is disabled by location
        // If application `available_locations` does not contain selected `locationId`, disable it
        if (this.selectedEntities.location &&
            this.selectedEntities.location.available_plans?.every(item => !application.available_plans?.some(plan => plan.id === item.id))) {
            conflicts.push({
                messageKey: 'servers.create.applicationConflicts.location',
                params: {
                    location: this.selectedEntities.location.name,
                },
            });
        }

        return this.generateEntityStatusFromConflicts(conflicts);
    }

    private getLocationEntityStatus(location: ILocationResponse): EntityStatus {
        const conflicts: IConflictMessage[] = [];

        // --- 1. Check if location is disabled by plan
        // If location `available_plans` does not contain the selected plan, conflict
        if (
            this.selectedEntities.plan &&
            !this.selectedEntities.plan.is_custom &&
            !location.available_plans?.some(item => item.id === this.selectedIds.plan)
        ) {
            conflicts.push({
                messageKey: 'servers.create.locationConflicts.plan',
                params: {
                    plan: this.selectedEntities.plan.name,
                },
            });
        }

        // --- 2. Check if location is disabled by offers
        if (this.selectedEntities.offers && this.selectedEntities.offers.length > 0) {
            for (const offer of this.selectedEntities.offers) {
                if (!offer.available_locations?.some(item => item.id === location.id)) {
                    conflicts.push({
                        messageKey: 'servers.create.locationConflicts.offer',
                        params: {
                            offer: offer.name,
                        },
                    });
                }
            }
        }

        // --- 3. Check if location is disabled by VPC networks
        if (this.selectedEntities.vpcNetworks && this.selectedEntities.vpcNetworks.length > 0) {
            for (const vpcNetwork of this.selectedEntities.vpcNetworks) {
                if (vpcNetwork.location.id !== location.id) {
                    conflicts.push({
                        messageKey: 'servers.create.locationConflicts.vpcNetwork',
                        params: {
                            vpcNetwork: vpcNetwork.name,
                        },
                    });
                }
            }
        }

        // --- 4. Unified OS Image Version check
        // We want to preserve BOTH:
        //  (a) The direct plan check
        //  (b) The image preset logic
        // => Only if there's NO direct plan coverage AND NO image preset coverage => conflict

        const osImageVersion = this.selectedEntities.osImageVersion;
        if (osImageVersion) {
            // Step a: Check if location has a directly compatible plan with the OS image version
            // (the first snippet's approach: location must have a plan whose available_os_image_versions includes our OS image version)
            let hasDirectCompatibility = false;
            if (location.available_plans?.length) {
                for (const availablePlan of location.available_plans) {
                    const realPlan = this.records.plans?.find(plan => plan.id === availablePlan.id);
                    if (!realPlan) {
                        continue;
                    }

                    // direct check
                    const compatiblePlanWithinLocation = realPlan.available_os_image_versions?.find(item => item.id === osImageVersion.id);
                    if (compatiblePlanWithinLocation) {
                        hasDirectCompatibility = true;
                        break;
                    }
                }
            }

            // Step b: Check image preset logic
            let hasPresetCompatibility = false;
            const osImageVersionImagePresetIds = osImageVersion.image_presets?.map(item => item.id).sort();
            if (location.available_plans?.length && osImageVersionImagePresetIds?.length) {
                const locationPlanIds = location.available_plans.map(item => item.id);
                const locationPlans = this.records.plans?.filter(plan => locationPlanIds.includes(plan.id));
                if (locationPlans?.length) {
                    const plansWithImagePreset = locationPlans.filter(plan => {
                        const planImagePresetIds = 'image_presets' in plan
                            ? plan.image_presets.map(item => item.id).sort()
                            // @ts-ignore
                            : plan.image_preset_ids?.sort();
                        // The plan is compatible if there is some overlap of preset IDs
                        return osImageVersionImagePresetIds?.some(presetId => planImagePresetIds?.includes(presetId));
                    });
                    hasPresetCompatibility = !!plansWithImagePreset?.length;
                }
            }

            // Combine direct + preset logic:
            // Conflict ONLY IF there's NO direct AND NO preset support
            if (!hasDirectCompatibility && !hasPresetCompatibility) {
                conflicts.push({
                    messageKey: 'servers.create.locationConflicts.osImageVersion',
                    params: {
                        osImageVersion: this.getOsImageVersionFullName(osImageVersion),
                    },
                });
            }
        }

        // --- 5. Unified Application check
        // Exactly the same approach: if there's no direct coverage AND no image preset coverage => conflict
        const application = this.selectedEntities.application;
        if (application) {
            // Step a: Direct coverage
            let hasDirectCompatibility = false;
            if (location.available_plans?.length) {
                for (const availablePlan of location.available_plans) {
                    const realPlan = this.records.plans?.find(plan => plan.id === availablePlan.id);
                    if (!realPlan) {
                        continue;
                    }

                    // direct check
                    const compatiblePlanWithinLocation = realPlan.available_applications?.find(item => item.id === application.id);
                    if (compatiblePlanWithinLocation) {
                        hasDirectCompatibility = true;
                        break;
                    }
                }
            }

            // Step b: Image preset logic
            let hasPresetCompatibility = false;
            const applicationImagePresetIds = application.image_presets?.map(item => item.id).sort();
            if (location.available_plans?.length && applicationImagePresetIds?.length) {
                const locationPlanIds = location.available_plans.map(item => item.id);
                const locationPlans = this.records.plans?.filter(plan => locationPlanIds.includes(plan.id));
                if (locationPlans?.length) {
                    const plansWithImagePreset = locationPlans.filter(plan => {
                        const planImagePresetIds = 'image_presets' in plan
                            ? plan.image_presets.map(item => item.id).sort()
                            // @ts-ignore
                            : plan.image_preset_ids?.sort();
                        // The plan is compatible if there is some overlap of preset IDs
                        return applicationImagePresetIds?.some(presetId => planImagePresetIds?.includes(presetId));
                    });
                    hasPresetCompatibility = !!plansWithImagePreset?.length;
                }
            }

            if (!hasDirectCompatibility && !hasPresetCompatibility) {
                conflicts.push({
                    messageKey: 'servers.create.locationConflicts.application',
                    params: {
                        application: application.name,
                    },
                });
            }
        }

        return this.generateEntityStatusFromConflicts(conflicts);
    }

    private getOfferEntityStatus(offer: IOfferResponse): EntityStatus {
        const conflicts: IConflictMessage[] = [];

        // Check if offer is disabled by plan
        // If offer `available_plans` does not contain selected `planId`, disable it
        if (!this.selectedEntities.plan?.is_custom &&
            this.selectedIds.plan &&
            !offer.available_plans?.some(item => item.id === this.selectedIds.plan)) {
            conflicts.push({
                messageKey: 'servers.create.offerConflicts.plan',
                params: {
                    plan: this.selectedEntities.plan!.name,
                },
            });
        }

        // Check if offer is disabled by location
        // If offer `available_locations` does not contain selected `locationId`, disable it
        if (this.selectedIds.location && !offer.available_locations?.some(item => item.id === this.selectedIds.location)) {
            conflicts.push({
                messageKey: 'servers.create.offerConflicts.location',
                params: {
                    location: this.selectedEntities.location!.name,
                },
            });
        }

        return this.generateEntityStatusFromConflicts(conflicts);
    }

    private getVpcNetworkEntityStatus(vpcNetwork: IVpcNetworkResponse): EntityStatus {
        const conflicts: IConflictMessage[] = [];

        // Check if VPC network is disabled by location
        // If VPC network `location` does not match selected `locationId`, disable it
        if (this.selectedIds.location && vpcNetwork.location.id !== this.selectedIds.location) {
            conflicts.push({
                messageKey: 'servers.create.vpcNetworkConflicts.location',
                params: {
                    location: this.selectedEntities.location!.name,
                },
            });
        }

        return this.generateEntityStatusFromConflicts(conflicts);
    }

    private generateEntityStatusFromConflicts(conflictMessages: IConflictMessage[]): EntityStatus {
        if (conflictMessages.length) {
            return {
                isDisabled: true,
                conflictMessages,
            };
        }

        return { isDisabled: false };
    }

    private getOsImageVersionFullName(osImageVersion: IOsImageVersionResponse): string {
        const osImage = this.records.osImages?.find(item =>
            !!item.versions.find(version => version.id === osImageVersion.id)
        )!;

        return `${osImage.name} ${osImageVersion.version}`;
    }

    private fillEntitiesStatus(): void {
        this.records.locations?.forEach(location => {
            this.entitiesStatuses.locations[location.id] = this.getLocationEntityStatus(location);
        });

        this.records.osImages?.forEach(osImage => {
            osImage.versions.forEach(osImageVersion => {
                this.entitiesStatuses.osImageVersions[osImageVersion.id] = this.getOsImageVersionEntityStatus(
                    osImageVersion
                );
            });
        });

        this.records.applications?.forEach(application => {
            this.entitiesStatuses.applications[application.id] = this.getApplicationEntityStatus(application);
        });

        this.records.plans?.forEach(plan => {
            this.entitiesStatuses.plans[plan.id] = this.getPlanEntityStatus(plan);
        });

        this.records.offers?.forEach(offer => {
            this.entitiesStatuses.offers[offer.id] = this.getOfferEntityStatus(offer);
        });

        this.records.vpcNetworks?.forEach(vpcNetwork => {
            this.entitiesStatuses.vpcNetworks[vpcNetwork.id] = this.getVpcNetworkEntityStatus(vpcNetwork);
        });
    }

    private fillSelectedEntities(): IManagerOfDisabledEntitiesSelection {
        return {
            plan: (typeof this.selectedIds.plan === 'object')
                ? this.selectedIds.plan
                : this.records.plans?.find(plan => plan.id === this.selectedIds.plan),
            osImageVersion: this.records.osImages
                ?.map(item => item.versions)
                .flat(1)
                .find(osImageVersion => osImageVersion.id === this.selectedIds.osImageVersion),
            application: this.records.applications?.find(app => app.id === this.selectedIds.application),
            location: this.records.locations?.find(loc => loc.id === this.selectedIds.location),
            offers: (this.records.offers ?? []).filter(offer => this.selectedIds.offers?.includes(offer.id)),
            vpcNetworks: (this.records.vpcNetworks ?? []).filter(vpc => this.selectedIds.vpcNetworks?.includes(vpc.id)),
        };
    }
}

export const DisabledEntitiesManagerContext = createContext<IManagerOfDisabledEntities | undefined>(undefined);
DisabledEntitiesManagerContext.displayName = 'DisabledEntitiesManagerContext';

export const useDisabledEntitiesManager = () => {
    const manager = useContext(DisabledEntitiesManagerContext);
    if (!manager) {
        throw new Error('DisabledEntitiesManagerContext is not provided');
    }

    return manager;
};
