// Copyright 2025. WebPros International GmbH. All rights reserved.

import { BackupNodeType } from 'common/api/resources/BackupNode';

const humanTypeMap = {
    [BackupNodeType.SSH_RSYNC]: 'SSH+rsync',
    [BackupNodeType.HETZNER_STORAGE_BOX]: 'Hetzner Storage Box',
    [BackupNodeType.S3]: 'S3',
};

export const humanizeType = (type: BackupNodeType): string => humanTypeMap[type];
