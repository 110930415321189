// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Status,
    Translate,
} from '@plesk/ui-library';
import {
    PasswordStrengthContainer,
    PasswordStrengthRow,
} from 'client/account/components/PasswordStrength/Styles';

export interface IPasswordStrengthProps {
    password: string;
}

export const PasswordStrength: React.FC<IPasswordStrengthProps> = ({
    password,
}) => {
    const [passesRules, setPassesRules] = React.useState({
        length: false,
        UCLetters: false,
        numbers: false,
    });
    React.useEffect(() => {
        setPassesRules({
            length: !!password.match(/^(?=[\S\s]{8,255}$)[\S\s]*/),
            UCLetters: !!password.match(/[A-Z]/),
            numbers: !!password.match(/[0-9]/),
        });
    }, [password]);

    const { length, numbers, UCLetters } = passesRules;

    return (
        <PasswordStrengthContainer>
            <PasswordStrengthRow>
                <Status intent={length ? 'info' : 'inactive'} compact={true}>
                    <Translate content="account.settings.passwordRules.length" />
                </Status>
                <Status intent={UCLetters ? 'info' : 'inactive'} compact={true}>
                    <Translate content="account.settings.passwordRules.UCLetters" />
                </Status>
            </PasswordStrengthRow>
            <PasswordStrengthRow>
                <Status intent={numbers ? 'info' : 'inactive'} compact={true}>
                    <Translate content="account.settings.passwordRules.numbers" />
                </Status>
            </PasswordStrengthRow>
        </PasswordStrengthContainer>
    );
};

export default PasswordStrength;
