// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceActions from 'admin/computeResource/actions';
import { connect } from 'react-redux';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Loader } from 'common/components';
import PageHeader from 'admin/computeResource/page/components/PageHeader/PageHeader';
import SettingsDialog from 'admin/computeResource/page/containers/dialogs/SettingsDialog/SettingsDialog';
import ComputeResourceVmTab from 'admin/computeResource/page/components/ComputeResourceVmTab/ComputeResourceVmTab';
import TasksTab from 'admin/computeResource/page/components/TasksTab/TasksTab';
import StoragesTab from 'admin/computeResource/page/components/StoragesTab/StoragesTab';
import UsageTab from 'admin/computeResource/page/components/UsageTab/UsageTab';
import { Translate } from '@plesk/ui-library';
import { Dialog } from 'common/components/Dialog/Dialog';
import { COMPUTE_RESOURCE_DIALOG } from 'admin/computeResource/constants';
import ComputeResourceEditForm from 'admin/computeResource/containers/ComputeResourceEditForm/ComputeResourceEditForm';
import { WithErrorPages } from 'common/components/WithPageNotFound/WithErrorPages';
import { TabsHeader } from 'admin/computeResource/page/containers/Styles';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { Button } from 'admin/common/components/Button/Button';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';

export type ComputeResourcePageProps =
    RouteComponentProps<{ id: string }> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

enum TABS {
    SERVERS = '#servers',
    STORAGES = '#storages',
    TASKS = '#tasks',
    USAGE = '#usage',
}

const tabs = Object.values(TABS);

const DIALOG = {
    NONE: 0,
    SETTINGS: 1,
    COMPUTE_RESOURCE_VM: 2,
    STORAGE: 3,
    COMPUTE_RESOURCE: 4,
};

export const ComputeResourcePage: React.FC<ComputeResourcePageProps> = ({
    computeResource,
    computeResourceActions: {
        getComputeResource,
        getComputeResourceIpBlocks,
    },
    isLoading,
    history,
    match: { params },
    location: { hash },
}) => {
    if (!hash) {
        history.replace(TABS.SERVERS);
    }

    const [ dialog, setDialog ] = React.useState(DIALOG.NONE);

    const computeResourceId = parseInt(params.id, 10);

    const closeDialog = () => {
        setDialog(DIALOG.NONE);
    };

    const onMount = async() => await Promise.all([
        getComputeResource(computeResourceId),
        getComputeResourceIpBlocks(computeResourceId),
    ]);

    const openSettingsDialog = () => {
        setDialog(DIALOG.SETTINGS);
    };

    const openEditDialog = () => {
        setDialog(DIALOG.COMPUTE_RESOURCE);
    };

    const openComputeResourceVmCreateDialog = () => {
        setDialog(DIALOG.COMPUTE_RESOURCE_VM);
    };
    const openStorageCreateDialog = () => {
        setDialog(DIALOG.STORAGE);
    };

    const handleTabSelect = (index: number) => history.replace(tabs[index]);

    const renderHeaderButton = () => {
        if (hash === TABS.SERVERS && computeResource.vms_count > 0) {
            return (
                <Button
                    onClick={openComputeResourceVmCreateDialog}
                    size={SIZE.LG}
                    intent={INTENT_TYPE.PRIMARY}
                    icon={ICONS.SCREEN}
                >
                    <Translate content="computeResource.servers.addBtn" />
                </Button>
            );
        }

        if (hash === TABS.STORAGES) {
            return (
                <Button
                    onClick={openStorageCreateDialog}
                    size={SIZE.LG}
                    intent={INTENT_TYPE.PRIMARY}
                    icon={ICONS.STORAGE}
                >
                    <Translate content="computeResource.storages.addBtn" />
                </Button>
            );
        }

        return null;
    };

    return (
        <WithErrorPages onMount={onMount} deps={[computeResourceId]}>
            <Loader isLoading={isLoading}>
                <PageHeader
                    computeResource={computeResource}
                    onSettingsClick={openSettingsDialog}
                    onEditClick={openEditDialog}
                />
                <Tabs
                    selectedIndex={tabs.findIndex(tab => tab === hash)}
                    onSelect={handleTabSelect}
                >
                    <TabsHeader>
                        <TabList>
                            <Tab><Translate content="computeResource.page.tabs.servers" /></Tab>
                            <Tab><Translate content="computeResource.page.tabs.storages" /></Tab>
                            <Tab><Translate content="computeResource.page.tabs.tasks" /></Tab>
                            <Tab><Translate content="computeResource.page.tabs.usageAndLimits" /></Tab>
                        </TabList>
                        <div>
                            {renderHeaderButton()}
                        </div>
                    </TabsHeader>
                    <TabPanel>
                        <ComputeResourceVmTab
                            computeResourceId={computeResourceId}
                            isOpened={dialog === DIALOG.COMPUTE_RESOURCE_VM}
                            onOpen={openComputeResourceVmCreateDialog}
                            onClose={closeDialog}
                        />
                    </TabPanel>
                    <TabPanel>
                        <StoragesTab
                            isOpened={dialog === DIALOG.STORAGE}
                            onClose={closeDialog}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TasksTab />
                    </TabPanel>
                    <TabPanel>
                        <UsageTab />
                    </TabPanel>
                </Tabs>
                <Dialog
                    heading={
                        <Translate content="computeResource.settings.form.title"/>
                    }
                    closeHandler={closeDialog}
                    isOpen={dialog === DIALOG.SETTINGS}
                    size="xs"
                >
                    <SettingsDialog computeResource={computeResource} onClose={closeDialog} />
                </Dialog>
                <Dialog
                    heading={<Translate content="computeResource.actionDialog.titleEdit" />}
                    closeHandler={closeDialog}
                    isOpen={dialog === COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_EDIT}
                    size="xs"
                >
                    <ComputeResourceEditForm onClose={closeDialog} computeResource={computeResource} />
                </Dialog>
            </Loader>
        </WithErrorPages>
    );
};

const mapStateToProps = (state: RootState) => ({
    computeResource: state.computeResource.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComputeResourcePage));
