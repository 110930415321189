// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const ServersMigrationOperationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 985px;
`;

export const ServersMigrationActionContainer = styled.div`
  margin-bottom: 10px;
  display: inline-block;

  .pul-button {
    margin-right: 8px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

export const ServersMigrationRowWrapper = styled.div`
    padding: 15px 28px 0 0;
`;
