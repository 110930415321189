// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IAppState } from 'admin/core/store';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Dispatch } from 'redux';
import * as types from 'admin/sshKey/constants/constants';
import { createCustomAction } from 'typesafe-actions';
import {
    create,
    loadOnScroll,
    paginateList,
    remove,
} from 'common/actions/actionsWrapper';
import {
    ISshKeyRequest,
    ISshKeyResponse,
    sshKeys,
} from 'common/api/resources/SshKey';

export const appendSshKeyList = createCustomAction(
    types.APPEND_SSH_KEY_LIST,
    (data: IPaginateApiResponse<ISshKeyResponse[]>) => ({ payload: data })
);
export const setSshKeyList = createCustomAction(
    types.SET_SSH_KEY_LIST,
    (data: IPaginateApiResponse<ISshKeyResponse[]>) => ({ payload: data })
);
export const addSshKeyItem = createCustomAction(
    types.ADD_SSH_KEY_ITEM,
    (data: ISshKeyResponse) => ({ payload: data })
);
export const removeSshKeyItem = createCustomAction(
    types.REMOVE_SSH_KEY_ITEM,
    (id: number) => ({ payload: id })
);
export const setSshKeyItemIsDeleting = createCustomAction(
    types.SET_SSH_KEY_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const getSshKeys = () => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.SSH_KEY_LIST,
    action: setSshKeyList,
    apiCall: sshKeys.list,
});

export const loadSshKeysOnScroll = () => async (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.sshKey.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.SSH_KEY_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendSshKeyList,
        loadingFlag: LOADING_FLAGS.SSH_KEY_LIST,
    });
};

export const createSshKey = (data: ISshKeyRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_SSH_KEY_ITEM,
    action: addSshKeyItem,
    apiCall: sshKeys.create,
    translations: {
        success: 'sshKey.toasts.itemSaved',
    },
});

export const removeSshKey = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: sshKeys.remove,
    setLoadingAction: setSshKeyItemIsDeleting,
    action: removeSshKeyItem,
    loadingFlag: LOADING_FLAGS.REMOVE_SSH_KEY_ITEM,
    translations: {
        success: 'sshKey.toasts.itemDeleted',
    },
});
