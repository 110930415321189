// Copyright 2025. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';
import { HTTP_CODES } from 'common/api/constants';
import {
    computeResources,
    IVmCreateRequest,
} from 'common/api/resources/ComputeResource';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import {
    addVm,
    setVms,
} from 'common/modules/computeResourceVm/actions';

export const getComputeResourceVms = (id: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_LIST));

    try {
        const result = await computeResources.vms.list(id);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setVms(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_LIST));
    }
};

export const createComputeResourceVm = (id: number, data: IVmCreateRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM));
    try {
        const result = await computeResources.vms.create(id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.servers.itemCreateSuccess')(dispatch);
        dispatch(clearFormErrors());
        dispatch(addVm(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM));
    }
};
