// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as actions from 'common/modules/app/lastUsedSshKeys/actions';

export type LastUsedSshKeysAction = ActionType<typeof actions>;
export type LastUsedSshKeysState = number[];

export default (state: LastUsedSshKeysState = [], action: LastUsedSshKeysAction) => {
    switch (action.type) {
    case getType(actions.setLastUsedSshKeys):
        return action.payload;
    default:
        return state;
    }
};
