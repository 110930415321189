// Copyright 2025. WebPros International GmbH. All rights reserved.
import * as React from 'react';

export const useInterval = (callback: () => void, delay: number | undefined) => {
    const savedCallback = React.useCallback(callback, [callback]);
    const [intervalId, setIntervalId] = React.useState<number>();

    React.useEffect(() => {
        const tick = () => savedCallback();

        if (delay === undefined || intervalId) {
            clearInterval(intervalId);
            setIntervalId(undefined);
        } else {
            setIntervalId(window.setInterval(tick, delay));
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay]);
};
