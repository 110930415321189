// Copyright 2025. WebPros International GmbH. All rights reserved.

import { ISelectOption } from 'common/components';
import * as React from 'react';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import {
    ILocationResponse,
    locations,
} from 'common/api/resources/Location';
import { Translate } from '@plesk/ui-library';
import { createOptionsLoader } from 'common/components/Select/helpers';

export interface ILocationFilterProps {
    onChange?: (option: ISelectOption) => void;
    margin_left?: string;
    value?: ISelectOption;
    isLoading?: boolean;
}

export const LocationFilter: React.FC<ILocationFilterProps & React.ComponentProps<typeof FixedFormField>> = ({
    onChange,
    margin_left,
    value,
    isLoading,
    ...props
}) => {
    const loadLocations = createOptionsLoader(
        locations.list,
        (item: ILocationResponse) => ({
            label: item.name,
            value: item.id,
        })
    );

    return (
        <FixedFormField margin_left={margin_left} data-cy={FILTERS.LOCATION} {...props}>
            <AsyncSelectInput
                inputId="location-filter"
                isClearable={true}
                placeholder={<Translate content="filters.location"/>}
                loadOptions={loadLocations}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
                value={value}
                isLoading={isLoading}
            />
        </FixedFormField>
    );
};
