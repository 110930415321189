// Copyright 2025. WebPros International GmbH. All rights reserved.

export const HTTP_CODES = {
    CREATED: 201,
    NO_CONTENT: 204,
    OK: 200,
    VALIDATION_ERROR: 422,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    INTERNAL: 500,
    NETWORK_ERROR: 100,
    TOO_MANY_REQUESTS: 429,
    NOT_FOUND: 404,
    GATEWAY_TIMEOUT: 504,

    // Special error code for invalid auth link
    AUTH_LINK_INVALID: 1000,
};

export const NOT_SELECTED_ID = 0;
