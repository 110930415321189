// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { OnboardingStepper } from 'admin/onboarding/components/OnboardingStepper';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';
import {
    OnboardingHeader,
    OnboardingLead,
    OnboardingContentFooter,
} from 'admin/onboarding/components/Styled';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import MailSettings from 'admin/settings/containers/Settings/MailSettings';
import {
    FormFieldCheckbox,
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';

export interface IOnboardingStepMailSettingsProps {
    onNext: () => void;
}

export type OnboardingStepMailSettingsProps = IOnboardingStepMailSettingsProps
    & ReturnType<typeof mapStateToProps>;

export const OnboardingStepMailSettings: React.FC<OnboardingStepMailSettingsProps> = ({
    onNext,
    isLoading,
}) => {
    const formRef = React.useRef<FormInstanceHandles>(null);
    const { close } = useOnboarding();

    return (
        <OnboardingStep
            sidebar={(<OnboardingStepper/>)}
        >
            <>
                <OnboardingHeader>
                    <Translate content={'onboarding.clusterSetup.steps.mailSettings.header'}/>
                </OnboardingHeader>
                <OnboardingLead>
                    <Translate content={'onboarding.clusterSetup.steps.mailSettings.lead'}/>
                </OnboardingLead>
                <p>
                    <Translate content={'onboarding.clusterSetup.steps.mailSettings.paragraphs.0'}/>
                </p>

                <MailSettings
                    ref={formRef}
                    showTitle={false}
                    submitButton={false}
                    hideIfDisabled={true}
                    extraFields={(
                        <FormFieldCheckbox
                            name="features[allow_registration]"
                            label={<Translate content="settings.features.authorization.registration.label" />}
                        />
                    )}
                    onSubmitted={onNext}
                />

                <p>
                    <Translate
                        content={'onboarding.clusterSetup.steps.mailSettings.hints.advancedConfiguration'}
                        params={{
                            mailSettingsLink: (
                                <Link
                                    to={pathTo('plans')}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate
                                        content={'onboarding.clusterSetup.steps.mailSettings.hints.mailSettingsLinkTitle'}
                                    />
                                </Link>
                            ),
                        }}
                    />
                </p>

                <OnboardingContentFooter>
                    <Button
                        size={SIZE.LG}
                        intent={'secondary'}
                        onClick={close}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.mailSettings.buttons.close'}/>
                    </Button>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                    }}>
                        <Button
                            size={SIZE.LG}
                            intent={'secondary'}
                            onClick={onNext}
                        >
                            <Translate content={'onboarding.clusterSetup.steps.mailSettings.buttons.skip'}/>
                        </Button>
                        <Button
                            size={SIZE.LG}
                            intent={'primary'}
                            onClick={() => {
                                formRef.current?.submit();
                            }}
                            isLoading={isLoading}
                        >
                            <Translate content={'onboarding.clusterSetup.steps.mailSettings.buttons.continue'}/>
                        </Button>
                    </div>
                </OnboardingContentFooter>
            </>
        </OnboardingStep>
    );
};

const mapStateToProps = (state: RootState) => ({
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.APP_SETTINGS)
        || state.app.loadingFlags.has(LOADING_FLAGS.SAVE_APP_SETTINGS),
});

export default connect(mapStateToProps)(OnboardingStepMailSettings);