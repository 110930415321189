// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled,
{ createGlobalStyle } from 'styled-components';
import { ISettingsTheme } from 'common/api/resources/Settings';

interface IStyledTabsProps {
    theme: ISettingsTheme;
}

export const StyledTabs = createGlobalStyle<IStyledTabsProps>`
    .react-tabs__tab-list {
        list-style: none;
        display: inline-flex;
        flex-direction: row;
        box-sizing: border-box;
        border-bottom: 1px solid #ececec;
        padding: 0;
        margin-bottom: 27px;
        color: #4b4b4b;

        & > li:not(:last-of-type) {
            margin-right: 40px;
        }
    }

    .react-tabs {
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .react-tabs__tab {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 8px;
        position: relative;
        top: 1px;
        cursor: pointer;
        white-space: nowrap;
        color: #222;
        min-height: 19px;

        &:focus {
            outline: unset;
        }
    }

    .react-tabs__tab:last-child {
        margin-right: 0;
    }

    .react-tabs__tab:hover {
        border-bottom: 1px solid #222;
    }

    .react-tabs__tab--selected, .react-tabs__tab--selected:hover {
        color: #222;
        border-bottom: 1px solid ${props => props.theme.primary_color};
    }

    @media (max-width: 560px) {
        .pul-grid__col {
            padding-bottom: 12px !important;
        }

        .react-tabs {
            margin: 0 0 16px;
        }
    }
`;

export const TabsExpander = styled.div`
    flex-grow: 1;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
`;
