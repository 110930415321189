// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    firstName,
    alphaNumeric,
} from 'common/helpers/random';

export const hostnameRegexp = /[^a-zA-Z0-9\-!@#$%^&*]/g;

export const generateName = (template: string | null) => {
    const randomPrefix = `${firstName().toLowerCase()}-${alphaNumeric(6).toLowerCase()}`.replace(hostnameRegexp, '').trim();

    if (template === null) {
        return randomPrefix;
    }

    return template.replace(/\{\{(\s+)?(random-prefix)(\s+)?\}\}/, randomPrefix);
};

const randomString = (upperCaseLetters: number, lowerCaseLetters: number, numbers: number, specials: number) => {
    const chars = [
        /* cspell:disable-next-line */
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        /* cspell:disable-next-line */
        'abcdefghijklmnopqrstuvwxyz',
        '0123456789',
        '!@#$%^&*?_',
    ];

    return [upperCaseLetters, lowerCaseLetters, numbers, specials]
        .map((len, i) => Array(len)
            .fill(chars[i])
            .map(x => x[Math.floor(Math.random() * x.length)])
            .join('')
        )
        .concat()
        .join('')
        .split('')
        .sort(() => 0.5 - Math.random())
        .join('');
};

export const generatePassword = () => randomString(4, 4, 4, 4);
