// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IApplicationCreateRequest,
    IApplicationPatchRequest,
    IApplicationResponse,
} from 'common/api/resources/Application/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const applications = {
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IApplicationResponse[]>>('applications', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IApplicationResponse>>(`applications/${id}`),
    remove: (id: number) => api.delete(`applications/${id}`),
    removeBatch: (ids: number[]) => api.delete('applications', { data: { ids } }),
    create: (data: IApplicationCreateRequest) => api.post<IApiResponse<IApplicationResponse>>('applications', data),
    update: (id: number, data: IApplicationCreateRequest) => api.put<IApiResponse<IApplicationResponse>>(`applications/${id}`, data),
    patch: (id: number, data: IApplicationPatchRequest) => api.patch<IApiResponse<IApplicationResponse>>(`applications/${id}`, data),
};
