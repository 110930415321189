// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import * as serversMigrationActions from 'admin/serversMigration/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Translate } from '@plesk/ui-library';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import CopyText from 'common/containers/CopyText/CopyText';
import { formatTableDate } from 'common/date';
import ServersMigrationRow from 'admin/serversMigration/containers/ServersMigrationRow';
import ServersMigrationStatus from 'admin/serversMigration/components/ServersMigrationStatus';
import { IListRow } from 'common/list';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { ACTIONS } from 'admin/serversMigration/constants/tests';
import {
    ServersMigrationActionContainer,
    ServersMigrationOperationContainer,
} from 'admin/serversMigration/containers/Styles';
import List from 'common/components/List/List';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import { ICONS } from 'common/constants';

export type ServersMigrationTableProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="serversMigration.list.id" />,
}, {
    width: '15%',
    key: 'colDestination',
    title: <Translate content="serversMigration.list.destination" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '15%',
    key: 'colStatus',
    title: <Translate content="serversMigration.list.status" />,
}, {
    width: '15%',
    key: 'colStarted',
    title: <Translate content="serversMigration.list.started" />,
}, {
    width: '15%',
    key: 'colFinished',
    title: <Translate content="serversMigration.list.finished" />,
}, {
    key: 'colDuration',
    title: <Translate content="serversMigration.list.duration" />,
}, getActionColumnProps({ width: '5%' }),
];

export const ServersMigrationTable: React.FC<ServersMigrationTableProps> = ({
    isLoading,
    serversMigrations,
    isServersMigrationBatchRemoving,
    serversMigrationActions: {
        getServersMigrations,
        removeServersMigration,
        removeServersMigrations,
    },
}) => {
    const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
    const [selection, setSelection] = React.useState<string[]>([]);

    const isFirstLoading = useRequestCancellationEffect(token => getServersMigrations({}, token), []);

    const loadPaginated = (page: number) => getServersMigrations({ page });

    const handleRemove = (id: number) => async () => {
        await removeServersMigration(id);
        reloadListData(serversMigrations, loadPaginated);
    };

    const handleBatchDelete = async () => {
        const result = await removeServersMigrations(selection.map(id => parseInt(id, 10)));
        setSelection([]);
        reloadListData(serversMigrations, loadPaginated, result.length);
    };

    const listData = serversMigrations.data.map((item) => ({
        key: item.id.toString(),
        colId: item.id,
        colDestination: item.destination_compute_resource
            ? <CopyText>{item.destination_compute_resource.name}</CopyText>
            : '',
        colStatus: item.task && <ServersMigrationStatus serverMigration={item}/>,
        colStarted: item.task
            ? formatTableDate(item.task.started_at)
            : '',
        colFinished: item.task && item.task.finished_at
            ? formatTableDate(item.task.finished_at)
            : '',
        colDuration: item.task ? item.task.duration : '',
        colActions: <>
            <ButtonWithConfirmation
                isLoading={item.is_deleting}
                translations={{
                    title: (
                        <Translate content="serversMigration.actions.delete.title" />
                    ),
                    text: (
                        <Translate content="serversMigration.actions.delete.text" />
                    ),
                    button: (
                        <Translate content="serversMigration.actions.delete.button" />
                    ),
                    tooltip: (
                        <Translate content="serversMigration.actions.delete.tooltip" />
                    ),
                }}
                handleConfirm={handleRemove(item.id)}
                data-cy={dataCySelector(item.id, ACTIONS.REMOVE)}
                icon={ICONS.RECYCLE}
            />
        </>,
    }));

    const renderRow = (row: IListRow) => {
        const serversMigration = serversMigrations.data.find(item => item.id.toString() === row.key);

        return serversMigration !== undefined
            ? (<ServersMigrationRow serverMigration={serversMigration} />)
            : null;
    };

    return (
        <>
            <ServersMigrationOperationContainer>
                <ServersMigrationActionContainer>
                    <ButtonWithConfirmation
                        data-cy={ACTIONS.BATCH_REMOVE}
                        disabled={selection.length === 0}
                        isLoading={isServersMigrationBatchRemoving}
                        confirmationButtonGhost={false}
                        confirmationButtonText={<Translate content="serversMigration.actions.batchDelete.button" />}
                        translations={{
                            title: (
                                <Translate content="serversMigration.actions.batchDelete.title" />
                            ),
                            text: (
                                <Translate content="serversMigration.actions.batchDelete.text" />
                            ),
                            button: (
                                <Translate content="serversMigration.actions.batchDelete.button" />
                            ),
                            tooltip: (
                                <Translate content="serversMigration.actions.batchDelete.tooltip" />
                            ),
                        }}
                        handleConfirm={handleBatchDelete}
                        icon={ICONS.RECYCLE}
                    />
                </ServersMigrationActionContainer>
            </ServersMigrationOperationContainer>
            <List
                isLoading={isLoading}
                isFirstLoading={isFirstLoading}
                meta={serversMigrations.meta}
                loadItems={loadPaginated}
                emptyView={
                    <EmptyView
                        title="serversMigration.emptyView.title"
                        description="serversMigration.emptyView.description"
                        icon="transfer"
                    />
                }
                columns={columns}
                data={listData}
                renderRowBody={renderRow}
                onExpandedRowsChange={setExpandedRows}
                expandedRows={expandedRows}
                withSelection={true}
                selection={selection}
                onSelectionChange={setSelection}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    serversMigrations: state.serversMigration.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.SERVERS_MIGRATION_LIST),
    isServersMigrationBatchRemoving: state.app.loadingFlags.has(LOADING_FLAGS.SERVERS_MIGRATION_BATCH_REMOVE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    serversMigrationActions: bindActionCreators(serversMigrationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServersMigrationTable);
