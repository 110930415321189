// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { COMPUTE_RESOURCE_DIALOG } from 'admin/computeResource/constants';
import ComputeResourceTable from 'admin/computeResource/list/containers/ComputeResourceTable';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as computeResourceActions from 'admin/computeResource/actions';
import ComputeResource from 'admin/computeResource/list/containers/ComputeResource';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import { Translate } from '@plesk/ui-library';
import { Loader } from 'common/components';
import { getSelectedComputeResource } from 'admin/computeResource/selectors';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { Dialog } from 'common/components/Dialog/Dialog';
import ComputeResourceEditForm from 'admin/computeResource/containers/ComputeResourceEditForm/ComputeResourceEditForm';
import SettingsDialog from 'admin/computeResource/page/containers/dialogs/SettingsDialog/SettingsDialog';
import { SIZE } from 'common/constants';

export type ComputeResourcesProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResources: React.FC<ComputeResourcesProps> = ({
    canGetComputeResources,
    loadingFlags: {
        isLoadingList,
    },
    list,
    selectedItem,
    computeResourceActions: {
        loadComputeResourcesOnScroll,
        getComputeResources,
        getComputeResource,
        unsetItem,
        removeComputeResource,
        setItemId,
    },
}) => {
    const [dialog, setDialog] = React.useState(COMPUTE_RESOURCE_DIALOG.NONE);

    React.useEffect(() => {
        getComputeResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreate = () => {
        unsetItem();
        setDialog(COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_CREATE);
    };

    const handleEdit = (id: number) => {
        setItemId(id);
        setDialog(COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_EDIT);
    };

    const handleSettingsClick = (id: number) => {
        setItemId(id);
        setDialog(COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_SETTINGS);
    };

    const handleClose = () => {
        setDialog(COMPUTE_RESOURCE_DIALOG.NONE);
    };

    const handleDetailsClick = async (id: number) => {
        setDialog(COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_CREATE);
        getComputeResource(id);
        setItemId(id);
    };

    const handleRemove = async (id: number) => await removeComputeResource(id, { force: false });

    if (!canGetComputeResources) {
        return <Translate content="accessDenied" />;
    }

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0}
                title={<Translate content="computeResource.title"/>}
                buttonText="computeResource.addBtn"
                buttonIcon="card-row"
                onButtonClick={handleCreate}
            />
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadComputeResourcesOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <ComputeResourceTable
                            items={list.data}
                            onCreate={handleCreate}
                            onEdit={handleEdit}
                            onRemove={handleRemove}
                            onSettingsClick={handleSettingsClick}
                            onDetailsClick={handleDetailsClick}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={<Translate content={selectedItem.id === 0 ? 'computeResource.actionDialog.titleAdd' : 'computeResource.actionDialog.titleView'}/>}
                closeHandler={handleClose}
                isOpen={dialog === COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_CREATE}
                size={SIZE.XS}
            >
                <ComputeResource onClose={handleClose} item={selectedItem} />
            </Dialog>
            <Dialog
                heading={<Translate content="computeResource.actionDialog.titleEdit" />}
                closeHandler={handleClose}
                isOpen={dialog === COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_EDIT}
                size={SIZE.XS}
            >
                <ComputeResourceEditForm onClose={handleClose} computeResource={selectedItem}/>
            </Dialog>
            <Dialog
                heading={<Translate content="computeResource.settings.form.title" />}
                closeHandler={handleClose}
                isOpen={dialog === COMPUTE_RESOURCE_DIALOG.COMPUTE_RESOURCE_SETTINGS}
                size={SIZE.XS}
            >
                <SettingsDialog onClose={handleClose} computeResource={selectedItem}/>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.computeResource.list,
    selectedItem: getSelectedComputeResource(state),
    canGetComputeResources: hasPermission(state, PERMISSION_LIST.GET_COMPUTE_RESOURCES) || hasPermission(state, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES),
    loadingFlags: {
        isLoadingList: !state.computeResource.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_LIST),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResources);
