// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const CurrentPrice = styled.div`
  padding: 5px 0;
`;

export const MonthPrice = styled.div`
  height: 32px;
  font-weight: 600;
  font-size: 24px;
`;

export const HourPrice = styled.div`
    color: #737373;
    font-size: 12px;
    line-height: 16px;
`;

export const PackageBox = styled.div`
    display: flex;
    align-items: center;
`;

export const PackageRow = styled.div`
  div {
    padding: 5px;
  }
`;

export const ServerParam = styled.div`
  color: #737373;
  font-size: 14px;
`;

export const NetworkScaleContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-left: -20px;
`;

export const NetworkScale = styled.div`
    position: relative;
    display: inline-block;
    min-width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #80AF26;
    margin: 8px 20px 12px 0;
    text-align: center;
    flex: 0;
`;

export const Inner = styled.div`
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  height: 45px;
  div {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Wrapper = styled.div`
    .pul-icon {
       color: ${props => props.theme.primary_color};
    }
`;
