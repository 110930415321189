// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    OnboardingContentContainer,
    OnboardingSidebarContainer,
    OnboardingWrapper,
} from 'admin/onboarding/components/Styled';

export interface IOnboardingStepProps {
    sidebar: React.ReactNode;
    children: React.ReactNode;
}

export const OnboardingStep: React.FC<IOnboardingStepProps> = ({
    sidebar,
    children,
}) => (
    <OnboardingWrapper>
        <OnboardingSidebarContainer>{sidebar}</OnboardingSidebarContainer>
        <OnboardingContentContainer>{children}</OnboardingContentContainer>
    </OnboardingWrapper>
);