// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IEventHandlerListRequest,
    IEventHandlerPatchRequest,
    IEventHandlerRequest,
    IEventHandlerResponse,
} from 'common/api/resources/EventHandler/model';

export const eventHandlers = {
    list: (params?: IEventHandlerListRequest) => api.get<IPaginateApiResponse<IEventHandlerResponse[]>>('event_handlers', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IEventHandlerResponse>>(`event_handlers/${id}`),
    remove: (id: number) => api.delete(`event_handlers/${id}`),
    create: (data: IEventHandlerRequest) => api.post<IApiResponse<IEventHandlerResponse>>('event_handlers', data),
    update: (id: number, data: IEventHandlerRequest) => api.put<IApiResponse<IEventHandlerResponse>>(`event_handlers/${id}`, data),
    patch: (id: number, data: IEventHandlerPatchRequest) => api.patch<IApiResponse<IEventHandlerResponse>>(`event_handlers/${id}`, data),
};
