// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Switch } from '@plesk/ui-library';
import ConfirmationPopover,
{ IConfirmationTranslation } from 'common/components/ConfirmationPopover';

export const TEST = {
    SWITCH_CONFIRM: 'switch-confirm',
};

export interface IConfirmOn {
    enabled: boolean;
    translations: IConfirmationTranslation;
}

export interface ISwitchWithConfirmationProps extends Omit<React.HTMLProps<HTMLElement>, 'onChange'> {
    loading?: boolean;
    checked?: boolean;
    onChange?: (value: boolean) => void;
    confirmOnDisable?: IConfirmOn;
    confirmOnEnable?: IConfirmOn;
    disabled?: boolean;
    tooltip?: React.ReactNode;
    'data-cy'?: string;
}

enum POPOVER_STATUS {
    CLOSED,
    BEFORE_ENABLE,
    BEFORE_DISABLE,
}

export const SwitchWithConfirmation: React.FC<ISwitchWithConfirmationProps> = ({
    loading,
    checked,
    onChange,
    confirmOnDisable,
    confirmOnEnable,
    disabled,
    tooltip,
    'data-cy': dataCy,
    children,
}) => {
    const [popoverStatus, setPopoverStatus] = React.useState(POPOVER_STATUS.CLOSED);

    const safeOnChange = (v: boolean) => {
        onChange?.(v);
    };

    const shouldConfirmOnDisable = confirmOnDisable && confirmOnDisable.enabled;
    const shouldConfirmOnEnable = confirmOnEnable && confirmOnEnable.enabled;

    if (shouldConfirmOnDisable || shouldConfirmOnEnable) {
        const handleClick = () => {
            safeOnChange(!checked);
            setPopoverStatus(POPOVER_STATUS.CLOSED);
        };
        const handleClose = () => {
            setPopoverStatus(POPOVER_STATUS.CLOSED);
        };

        const handleChange = (v: boolean) => {
            if (v) {
                // Switch changed to true.
                if (shouldConfirmOnEnable) {
                    setPopoverStatus(POPOVER_STATUS.BEFORE_ENABLE);
                } else {
                    safeOnChange(v);
                }
                return;
            }

            // Switch changed to false.
            if (shouldConfirmOnDisable) {
                setPopoverStatus(POPOVER_STATUS.BEFORE_DISABLE);
            } else {
                safeOnChange(v);
            }
        };

        let translations:IConfirmationTranslation = {
            button: '',
        };
        switch (popoverStatus) {
        case POPOVER_STATUS.BEFORE_DISABLE:
            translations = confirmOnDisable!.translations;
            break;

        case POPOVER_STATUS.BEFORE_ENABLE:
            translations = confirmOnEnable!.translations;
            break;
        }

        return (
            <ConfirmationPopover
                opened={popoverStatus !== POPOVER_STATUS.CLOSED}
                translations={translations}
                onButtonClick={handleClick}
                onClose={handleClose}
                buttonDataCy={TEST.SWITCH_CONFIRM}
            >
                <Switch
                    loading={loading}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                    tooltip={tooltip}
                    data-cy={dataCy}
                >
                    {children}
                </Switch>
            </ConfirmationPopover>
        );
    }

    return (
        <Switch
            loading={loading}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            tooltip={tooltip}
            data-cy={dataCy}
        >
            {children}
        </Switch>
    );
};

export default SwitchWithConfirmation;
