// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    IVmISOImageSettings,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    INTENT_TYPE,
    OS_TYPES,
    SIZE,
} from 'common/constants';
import {
    Link,
    Section,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { ILoadOptions } from 'common/components/SelectInput';
import { isoImage } from 'common/api/resources/IsoImage';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { Paragraph } from 'common/components/ServerTabs/RescueTab/Styles';
import CellIcon from 'admin/icon/components/CellIcon/CellIcon';
import { IconType } from 'common/api/resources/Icon';
import { IsoImageDescription } from 'common/components/IsoImageDescription/IsoImageDescription';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';

export interface IIsoImageDialogProps {
    server: IVmResponse;
    os?: OS_TYPES;
    isoImageSetting?: IIsoImageOption;
    handleSelect: (item: IIsoImageOption) => void;
}

export type IsoImageDialogProps = IIsoImageDialogProps
    & ReturnType<typeof mapStateToProps>;

export interface IIsoImageOption extends IVmISOImageSettings {
    id: number;
    size?: number;
}

interface ISelectRequiredOption {
    label: string;
    value: IIsoImageOption;
}

export const IsoImageDialog: React.FC<IsoImageDialogProps> = ({
    server,
    termsAndConditionsUrl,
    isoImageSetting,
    handleSelect,
}) => {
    const [isoImageOption, setIsoImageOption] = React.useState<IIsoImageOption|undefined>(isoImageSetting);

    const load: ILoadOptions = async (search, loadedOptions, { page }) => {
        const response = await isoImage.list({
            page,
            filters: {
                search,
            },
        });

        return {
            options: response.data.data.map(item => ({
                label: (<CellIcon icon={item.icon} name={item.name} />),
                value: {
                    id: item.id,
                    name: item.name,
                    url: item.iso_url,
                    icon: item.icon?.url,
                    os_type: item.os_type,
                    use_tls: item.use_tls,
                    checksum_method: item.iso_checksum_method,
                    checksum: item.iso_checksum,
                    show_url_and_checksum: item.show_url_and_checksum,
                    show_tls: item.show_tls,
                    size: item.size,
                },
            })),
            hasMore: !!response.data.links.next,
            additional: {
                page: page + 1,
            },
        };
    };

    const onChange = (option: ISelectRequiredOption) => {
        setIsoImageOption(option ? option.value : undefined);
    };

    const sizeGiB = isoImageOption?.size ? convertToDataUnit(isoImageOption.size, DataUnit.GiB) : 0;

    const onSubmit = () => {
        if (!isoImageOption) {
            return;
        }

        handleSelect(isoImageOption);
    };

    return (
        <>
            <Section>
                <Paragraph>
                    <AsyncSelectInput
                        inputId="iso-image-select"
                        isClearable={true}
                        placeholder={<Translate content="servers.tabs.rescue.rescue.isoImageDialog.placeholder"/>}
                        loadOptions={load}
                        onChange={onChange}
                        menuPosition="fixed"
                        debounceTimeout={1000}
                        additional={{ page: 1 }}
                        value={
                            isoImageOption
                                ? {
                                    label: (
                                        <CellIcon
                                            icon={
                                                isoImageOption.icon
                                                    ? {
                                                        id: 0,
                                                        url: isoImageOption.icon,
                                                        name: isoImageOption.name,
                                                        is_deleting: false,
                                                        type: IconType.OS,
                                                    }
                                                    : null
                                            }
                                            name={isoImageOption.name}
                                        />
                                    ),
                                    value: isoImageOption,
                                }
                                : null
                        }
                    />
                </Paragraph>
                {isoImageOption && (
                    <IsoImageDescription
                        width="427px"
                        url={isoImageOption.url}
                        useTls={isoImageOption.use_tls}
                        checksum={isoImageOption.checksum}
                        showUrlAndChecksum={isoImageOption.show_url_and_checksum}
                        showTls={isoImageOption.show_tls}
                    />
                )}
                {server.project?.token_pricing && isoImageOption && (
                    <Paragraph>
                        <Translate
                            content="servers.tabs.rescue.rescue.isoImageDialog.description.price"
                            params={{
                                monthPrice: formatPricePerMonth(server.plan.iso_image_tokens_per_month * sizeGiB, server.project.token_pricing),
                                hourPrice: formatPricePerHour(server.plan.iso_image_tokens_per_hour * sizeGiB, server.project.token_pricing),
                            }}
                        />
                        {server.project.token_pricing.taxes.length > 0 && (
                            <>
                                &nbsp;
                                <Translate
                                    content={`servers.tabs.rescue.rescue.isoImageDialog.description.${server.project.token_pricing.taxes_inclusive ? 'taxesInclusive' : 'taxesExclusive'}`}
                                    params={{
                                        taxes: server.project.token_pricing.taxes.map(tax => `${tax.rate}% ${tax.label}`).join(', '),
                                    }}
                                />
                            </>
                        ) }
                        {termsAndConditionsUrl && (
                            <>
                                &nbsp;
                                <Translate
                                    content="servers.tabs.rescue.rescue.isoImageDialog.description.termsAndConditions.text"
                                    params={{
                                        link: (
                                            <Link href={termsAndConditionsUrl} target="blank">
                                                <Translate content="servers.tabs.rescue.rescue.isoImageDialog.description.termsAndConditions.link"/>
                                            </Link>
                                        ),
                                    }}
                                />
                            </>
                        ) }
                    </Paragraph>
                ) }
            </Section>
            <Button
                fill={true}
                disabled={!isoImageOption}
                onClick={onSubmit}
                size={SIZE.LG}
                intent={INTENT_TYPE.PRIMARY}
            >
                <Translate content="servers.tabs.rescue.rescue.isoImageDialog.button" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    termsAndConditionsUrl: state.settings.theme.terms_and_conditions_url,
});

export default connect(mapStateToProps)(IsoImageDialog);