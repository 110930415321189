// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as offerActions from 'common/modules/offer/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    IOfferParameters,
    IOfferResponse,
    OfferPriceMode,
    OfferType,
} from 'common/api/resources/Offer';
import {
    DataUnit,
    DiskBandwidthUnit,
    IOpsUnit,
} from 'common/helpers/units';

interface IOffer {
    list: IPaginateApiResponse<IOfferResponse[]>;
    item: IOfferResponse;
}

export type OfferAction = ActionType<typeof offerActions>;
export type OfferState = IOffer;

export const getInitialParameters = (offerType: OfferType): IOfferParameters => {
    switch (offerType) {
    case OfferType.PRIMARY_DISK:
        return {
            iops: {
                is_enabled: false,
                limit: 1,
                unit: IOpsUnit.iops,
            },
            bandwidth: {
                is_enabled: false,
                limit: 1,
                unit: DiskBandwidthUnit.Bps,
            },
        };
    case OfferType.ADDITIONAL_DISK:
        return {
            max_size: {
                is_enabled: false,
                limit: 1,
                unit: DataUnit.GiB,
            },
            iops: {
                is_enabled: false,
                limit: 1,
                unit: IOpsUnit.iops,
            },
            bandwidth: {
                is_enabled: false,
                limit: 1,
                unit: DiskBandwidthUnit.Bps,
            },
        };
    default: return null;
    }
};

export const initialOfferState: IOfferResponse = {
    id: 0,
    name: '',
    description: '',
    type: OfferType.PRIMARY_DISK,
    position: 0,
    price_mode: OfferPriceMode.PER_UNIT,
    per_hour: 0,
    per_month: 0,
    is_visible: true,
    is_deleting: false,
    parameters: getInitialParameters(OfferType.PRIMARY_DISK),
};

export default combineReducers<OfferState, OfferAction>({
    list: (state = paginateInitialState, action: OfferAction) => {
        switch (action.type) {
        case getType(offerActions.addOfferItem):
            return addToPaginated(state, action.payload);
        case getType(offerActions.appendOffers):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(offerActions.setOfferList):
            return action.payload;
        case getType(offerActions.removeOfferItem):
            return deleteFromPaginated(state, action.payload);
        case getType(offerActions.updateOfferItem):
            return updateInPaginated(state, action.payload);
        case getType(offerActions.setOfferItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialOfferState, action) => {
        switch (action.type) {
        case getType(offerActions.setOfferItem):
            return action.payload;
        case getType(offerActions.unsetOfferItem):
            return { ...initialOfferState };
        default:
            return state;
        }
    },
});
