// Copyright 2025. WebPros International GmbH. All rights reserved.
import React from 'react';


export type SetValue<T> = React.SetStateAction<T>;
export type ValueDispatch<T> = React.Dispatch<SetValue<T>>;

export type ObjectStorage<T> = [T, ValueDispatch<T>];

/**
 * Provides the same interface as `React.useState`, but for a property of an object.
 *
 * @example
 * const [object, setObject] = React.useState({ a: 1, b: 2 }); // or passed as a prop
 * const [a, setA] = useObjectProperty('a', [object, setObject]);
 */
const useObjectPropertyState = <S, T = S[keyof S] >(
    key: keyof S,
    objectStorage: ObjectStorage<S>
): ObjectStorage<T> => {
    const [object, setObject] = objectStorage;

    return [
        object[key] as T,
        React.useCallback((value: SetValue<T>) => {
            setObject((prevObject) => ({
                ...prevObject,
                [key]: typeof value === 'function' ? (value as (prevValue: T) => T)(prevObject[key] as T) : value,
            }));
        }, [key, setObject]),
    ];
};

export default useObjectPropertyState;