// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Menu,
    MenuHeader,
    MenuItem,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

interface ISearchBarHintProps {
    prepend: (value: string) => void;
    append: (value: string) => void;
}

export type SearchBarHintProps =
    ISearchBarHintProps &
    ReturnType<typeof mapStateToProps>;

export const SearchBarHint: React.FC<SearchBarHintProps> = ({
    canSearchServers,
    canSearchUsers,
    canComputeResources,
    prepend,
}) => {
    const menuRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        menuRef.current?.focus();
    }, []);

    return (
        <Menu
            ref={menuRef}
        >
            <MenuHeader>
                <Translate content={'search.hint.title'} />
            </MenuHeader>
            {canSearchServers && (
                <MenuItem onClick={() => prepend('s:')}>
                    <Translate
                        content={'search.hint.prefixServer'}
                        params={{
                            code: <code>server:</code>,
                            alias: <code>s:</code>,
                        }}
                    />
                </MenuItem>
            )}
            {canSearchUsers && (
                <MenuItem onClick={() => prepend('u:')}>
                    <Translate
                        content={'search.hint.prefixUser'}
                        params={{
                            code: <code>user:</code>,
                            alias: <code>u:</code>,
                        }}
                    />
                </MenuItem>
            )}
            {canComputeResources && (
                <MenuItem onClick={() => prepend('cr:')}>
                    <Translate
                        content={'search.hint.prefixComputeResource'}
                        params={{
                            code: <code>compute-resource:</code>,
                            alias: <code>cr:</code>,
                        }}
                    />
                </MenuItem>
            )}
        </Menu>
    );
};

const mapStateToProps = (state: RootState) => ({
    canSearchServers: hasPermission(state, PERMISSION_LIST.GET_SERVERS)
        || hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
    canSearchUsers: hasPermission(state, PERMISSION_LIST.GET_USERS)
        || hasPermission(state, PERMISSION_LIST.MANAGE_USERS),
    canComputeResources: hasPermission(state, PERMISSION_LIST.GET_COMPUTE_RESOURCES)
        || hasPermission(state, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES),
});

export default connect(mapStateToProps)(SearchBarHint);