// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { List } from '@plesk/ui-library';

// @ts-expect-error
export const StyledList = styled(List)`
  .pul-list__table-thead th {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  .pul-list__table {
    border-bottom: none;
  
    td {
        background-color: inherit !important;
        border-top: none !important;
        padding-left: 0;
        padding-right: 0;
    }
    
    tr:hover {
      td {
        background-color: inherit !important;
      }
    }
  }
`;
