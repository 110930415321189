// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Layout } from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';
import { CLASS_COLUMN_FIXED_RIGHT } from 'common/styles/variables';

export const LayoutContainer = styled(Layout)`
    .pul-layout__header-inner {
        border-bottom: 1px solid ${COLORS.GREY_30} !important;
        background: ${COLORS.WHITE};
        box-shadow: unset !important;
    }

    .pul-layout__main {
        background-color: ${COLORS.GREY_10} !important;

        .pul-list th.${CLASS_COLUMN_FIXED_RIGHT} {
            background-color: ${COLORS.GREY_10};
        }

        .pul-item .pul-list th.${CLASS_COLUMN_FIXED_RIGHT} {
            background-color: ${COLORS.WHITE};
        }
    }
`;
