// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { FormFieldText } from '@plesk/ui-library';
import {
    ColorPickerCover,
    Popover,
} from 'common/components/Form/FormFieldColorPicker/Styles';
import {
    ColorChangeHandler,
    SketchPicker,
} from 'react-color';

interface IForFieldColorPickerProps {
    color: string;
    onChangeColor: ColorChangeHandler;
    fieldName: string;
    label: React.ReactNode;
    description: React.ReactNode | string;
}

export const FormFieldColorPicker: React.FC<IForFieldColorPickerProps> = ({
    fieldName,
    label,
    onChangeColor,
    description,
    color,
}) => {
    const [colorPickerShown, setColorPickerShown] = React.useState(false);
    const handleToggleColorPicker = () => setColorPickerShown(!colorPickerShown);
    const handleCloseColorPicker = () => setColorPickerShown(false);

    return (
        <>
            <FormFieldText
                name={fieldName}
                description={description}
                size="xl"
                label={label}
                required={true}
                inputProps={{
                    onClick: handleToggleColorPicker,
                }}
            />
            {colorPickerShown && (
                <Popover>
                    <ColorPickerCover onClick={handleCloseColorPicker} />
                    <SketchPicker
                        color={color}
                        onChangeComplete={onChangeColor}
                    />
                </Popover>
            )}
        </>
    );
};
