// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    FC,
    useEffect,
    useState,
} from 'react';
import {
    DiskCacheMode,
    DiskDriver,
    IKVMVirtualServerSettingsRequest,
    IVmResponse,
    OsFirmware,
    VirtualServerSettingsRequest,
} from 'common/api/resources/ComputeResourceVm';
import {
    Alert,
    Grid,
    Icon,
    Paragraph,
    Popover,
    Status,
    Translate,
} from '@plesk/ui-library';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import SettingsPropertyCard from 'common/components/ServerTabs/SettingsTab/SettingsPropertyCard';
import SuspendResumeControl from 'common/components/ServerTabs/components/Controls/SuspendResumeControl';
import { VerticalIndent } from 'common/components/styles/VerticalIndent';
import { ItemsLine } from 'common/components/styles/ItemsLine';
import SelectWithConfirmation from 'common/components/SelectWithConfirmation/SelectWithConfirmation';
import { ISelectOption } from 'common/components/SelectInput';
import { ValueType } from 'react-select';

export interface IKVMSettingsTabProps {
    server: IVmResponse;
    isUpdating: boolean;
    updateSettings: (settings: VirtualServerSettingsRequest) => void;
    isInstallingGuestTools: boolean;
    installGuestTools: () => void;
    isSuspendToggling: boolean;
    toggleSuspendStatus: (isSuspended: boolean) => void;
    canManageServers: boolean;
}

const diskCacheModeOptions: ISelectOption[] = Object.values(DiskCacheMode).map(mode => ({
    value: mode,
    label: mode,
}));

const getDiskCacheModeValue = (value?: DiskCacheMode) =>
    diskCacheModeOptions.find((option) => option.value === value);

const diskDriverOptions: ISelectOption[] = Object.values(DiskDriver).map(mode => ({
    value: mode,
    label: mode,
}));

const getDiskDriverValue = (value?: DiskDriver) =>
    diskDriverOptions.find((option) => option.value === value);

const firmwareOptions: ISelectOption[] = Object.values(OsFirmware).map(mode => ({
    value: mode,
    label: mode,
}));

const getFirmwareValue = (value?: OsFirmware) =>
    firmwareOptions.find((option) => option.value === value);

export const KVMSettingsTab: FC<IKVMSettingsTabProps> = ({
    server,
    isUpdating,
    updateSettings,
    isInstallingGuestTools,
    installGuestTools,
    isSuspendToggling,
    toggleSuspendStatus,
    canManageServers,
}) => {
    const [loadingProperty, setLoadingProperty] =
        useState<keyof IKVMVirtualServerSettingsRequest>();

    const diskCacheModeChange = (option: ValueType<ISelectOption>) => {
        setLoadingProperty('disk_cache_mode');
        updateSettings({ disk_cache_mode: (option as ISelectOption).value as DiskCacheMode });
    };

    const diskDriverChange = (option: ValueType<ISelectOption>) => {
        setLoadingProperty('disk_driver');
        updateSettings({ disk_driver: (option as ISelectOption).value as DiskDriver });
    };

    const firmwareChange = (option: ValueType<ISelectOption>) => {
        setLoadingProperty('firmware');
        updateSettings({ firmware: (option as ISelectOption).value as OsFirmware });
    };

    useEffect(() => {
        if (!isUpdating) {
            setLoadingProperty(undefined);
        }
    }, [isUpdating]);

    return (
        <>
            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.diskCacheMode.title" />}
                    titleId="disk-cache-mode-label"
                >
                    <SelectWithConfirmation
                        inputId="disk-cache-mode"
                        aria-labelledby="disk-cache-mode-label"
                        minHeight="40px"
                        width="350px"
                        onChange={diskCacheModeChange}
                        value={getDiskCacheModeValue(server.settings.disk_cache_mode)}
                        options={diskCacheModeOptions}
                        isDisabled={isUpdating}
                        isLoading={isUpdating && loadingProperty === 'disk_cache_mode'}
                        dialog={{
                            title: <Translate content="servers.tabs.settings.diskCacheMode.confirmation.title" />,
                            text: <Translate content="servers.tabs.settings.diskCacheMode.confirmation.text" />,
                            button: <Translate content="servers.tabs.settings.diskCacheMode.confirmation.button" />,
                        }}
                    />
                </SettingsPropertyCard>
            </Grid>

            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.diskDriver.title" />}
                    titleId="disk-driver-label"
                >
                    <SelectWithConfirmation
                        inputId="disk-driver"
                        aria-labelledby="disk-driver-label"
                        minHeight="40px"
                        width="350px"
                        onChange={diskDriverChange}
                        value={getDiskDriverValue(server.settings.disk_driver)}
                        options={diskDriverOptions}
                        isDisabled={isUpdating}
                        isLoading={isUpdating && loadingProperty === 'disk_driver'}
                        dialog={{
                            title: <Translate content="servers.tabs.settings.diskDriver.confirmation.title" />,
                            text: <Translate content="servers.tabs.settings.diskDriver.confirmation.text" />,
                            button: <Translate content="servers.tabs.settings.diskDriver.confirmation.button" />,
                        }}
                    />
                    {server.settings.disk_driver === DiskDriver.VIRTIO &&
                        server.plan.is_thin_provisioned &&
                        server.compute_resource?.capabilities.is_virtio_discard_supported === false &&
                        <Alert intent={INTENT_TYPE.WARNING}>
                            <Icon intent={INTENT_TYPE.WARNING} name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED} />
                            &nbsp;
                            <Translate content="servers.tabs.settings.diskDriver.warning" />
                        </Alert>
                    }
                </SettingsPropertyCard>
            </Grid>

            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.firmware.title" />}
                    titleId="firmware-label"
                >
                    <SelectWithConfirmation
                        inputId="firmware"
                        aria-labelledby="firmware-label"
                        minHeight="40px"
                        width="350px"
                        onChange={firmwareChange}
                        value={getFirmwareValue(server.settings.firmware ? server.settings.firmware : OsFirmware.BIOS)}
                        options={firmwareOptions}
                        isDisabled={isUpdating}
                        isLoading={isUpdating && loadingProperty === 'firmware'}
                        dialog={{
                            title: <Translate content="servers.tabs.settings.firmware.confirmation.title" />,
                            text: <Translate content="servers.tabs.settings.firmware.confirmation.text" />,
                            button: <Translate content="servers.tabs.settings.firmware.confirmation.button" />,
                        }}
                    />
                </SettingsPropertyCard>
            </Grid>

            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.suspendResume.title" />}
                    text={<Translate content="servers.tabs.settings.suspendResume.text" />}
                >
                    <SuspendResumeControl
                        value={server.is_suspended}
                        disabled={isSuspendToggling || !canManageServers}
                        loading={isSuspendToggling}
                        onChange={toggleSuspendStatus}
                    />
                </SettingsPropertyCard>
            </Grid>

            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.guestTools.title" />}
                >
                    <Paragraph>
                        <Status
                            intent={server.settings.guest_tools_installed ? INTENT_TYPE.SUCCESS : INTENT_TYPE.INACTIVE}
                            compact={true}
                        >
                            {server.settings.guest_tools_installed
                                ? <Translate content="servers.tabs.settings.guestTools.installed" />
                                : <Translate content="servers.tabs.settings.guestTools.notInstalled" />}
                        </Status>
                    </Paragraph>
                    <Paragraph>
                        <Status
                            intent={server.settings.guest_agent_available ? INTENT_TYPE.SUCCESS : INTENT_TYPE.INACTIVE}
                            compact={true}
                        >
                            <ItemsLine>
                                {server.settings.guest_agent_available
                                    ? <Translate content="servers.tabs.settings.guestTools.agentAvailable" />
                                    : <Translate content="servers.tabs.settings.guestTools.agentNotAvailable" />}
                                {<Popover
                                    target={(
                                        <Icon name={ICONS.INFO_CIRCLE} size="12"/>
                                    )}>
                                    <Translate content="servers.tabs.settings.guestTools.agentAvailabilityUpdatingInfo" />
                                </Popover>}
                            </ItemsLine>
                        </Status>
                    </Paragraph>
                    <VerticalIndent />
                    <ButtonWithConfirmation
                        isLoading={isInstallingGuestTools}
                        disabled={isInstallingGuestTools}
                        buttonSize={SIZE.LG}
                        buttonIntent={INTENT_TYPE.PRIMARY}
                        confirmationButtonGhost={false}
                        translations={{
                            title: <Translate content="servers.tabs.settings.guestTools.confirmationDialog.title" />,
                            text: <Translate content="servers.tabs.settings.guestTools.confirmationDialog.content" />,
                            button: <Translate content="servers.tabs.settings.guestTools.confirmationDialog.button" />,
                        }}
                        confirmationButtonText={
                            <Translate content='servers.tabs.settings.guestTools.button' />
                        }
                        handleConfirm={installGuestTools}
                    />
                </SettingsPropertyCard>
            </Grid>
        </>
    );
};

export default KVMSettingsTab;
