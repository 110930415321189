// Copyright 2025. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const BackupsTableContainer = styled.div`
    margin-top: 36px;
`;

export const ReinstallContainer = styled.div`
  padding-bottom: 75px;
  
  >div:first-child {
    margin-top: 0;
    padding-top: 0;
  }
`;
