// Copyright 2025. WebPros International GmbH. All rights reserved.

export const ROLES = {
    TABLE: 'roles-table',
};

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
    FILTER: 'filter',
};
