// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IRoleResponse } from 'common/api/resources/Role';
import { IPermissionResponse } from 'common/api/resources/Permission';
import { ICommonState } from 'common/store';

export const hasPermission = (state: ICommonState, ...permissions: string[]): boolean =>
    hasPermissionInRoles(state.auth.user.roles, ...permissions);

export const hasPermissionInRoles = (roles: IRoleResponse[], ...permissions: string[]): boolean =>
    roles.some((role: IRoleResponse): boolean =>
        role.permissions.some((rolePermission: IPermissionResponse): boolean =>
            permissions.includes(rolePermission.name)
        )
    );
