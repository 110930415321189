// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as defaultTemplateActions from 'admin/defaultTemplate/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import { IDefaultTemplateResponse } from 'common/api/resources/DefaultTemplate';

interface IDefaultTemplate {
    list: IPaginateApiResponse<IDefaultTemplateResponse[]>;
    item: IDefaultTemplateResponse;
}

export type DefaultTemplateAction = ActionType<typeof defaultTemplateActions>;
export type DefaultTemplateState = IDefaultTemplate;

export const initialDefaultTemplateState: IDefaultTemplateResponse = {
    id: 0,
    name: '',
    is_deleting: false,
};

export default combineReducers<DefaultTemplateState, DefaultTemplateAction>({
    list: (state = paginateInitialState, action: DefaultTemplateAction) => {
        switch (action.type) {
        case getType(defaultTemplateActions.addDefaultTemplateItem):
            return addToPaginated(state, action.payload);
        case getType(defaultTemplateActions.setDefaultTemplateList):
            return action.payload;
        case getType(defaultTemplateActions.removeDefaultTemplateItem):
            return deleteFromPaginated(state, action.payload);
        case getType(defaultTemplateActions.updateDefaultTemplateItem):
            return updateInPaginated(state, action.payload);
        case getType(defaultTemplateActions.setDefaultTemplateItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialDefaultTemplateState, action) => {
        switch (action.type) {
        case getType(defaultTemplateActions.setDefaultTemplateItem):
            return action.payload;
        case getType(defaultTemplateActions.unsetDefaultTemplateItem):
            return { ...initialDefaultTemplateState };
        default:
            return state;
        }
    },
});
