// Copyright 2025. WebPros International GmbH. All rights reserved.

import {
    Hint,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as settingsActions from 'common/modules/settings/actions';
import React from 'react';
import {
    Card,
    CardRow,
    CardsContainer,
} from 'common/components/CardWithSwitch/Styles';
import * as managementNodeBackupActions from 'admin/managementNodeBackup/actions';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    backupNodes,
    IBackupNodeResponse,
} from 'common/api/resources/BackupNode';
import { ISelectOption } from 'common/components';
import { ValueType } from 'react-select';
import * as backupNodeActions from 'admin/backupNode/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import AsyncSelectWithConfirmation from 'common/components/AsyncSelectWithConfirmation/AsyncSelectWithConfirmation';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export type BackupNodeCardProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const BackupNodeCard: React.FC<BackupNodeCardProps> = ({
    backupSettings,
    backupNode,
    isBackupNodeLoading,
    isBackupNodeSaving,
    canManageBackups,
    backupNodeActions: {
        getBackupNode,
        unsetBackupNodeItem,
    },
    settingsActions: {
        saveSettings,
    },
}) => {
    const loadBackupNodeOptions = createOptionsLoader<IBackupNodeResponse>(
        backupNodes.list,
        (node: IBackupNodeResponse) => ({
            label: node.name,
            value: node.id,
        })
    );

    const [selectedBackupNode, setSelectedBackupNode] = React.useState<ISelectOption>();

    const handleBackupNodeChange = (option: ValueType<ISelectOption>) => {
        const value = option ? (option as ISelectOption).value as number : null;
        saveSettings({
            management_node_backup: {
                ...backupSettings,
                backup_node_id: value,
            },
        }, LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_BACKUP_NODE_SAVE);
        setSelectedBackupNode(option as ISelectOption);
    };

    React.useEffect(() => {
        if (backupNode.id === 0 && backupSettings.backup_node_id) {
            getBackupNode(backupSettings.backup_node_id);
        }

        return () => {
            unsetBackupNodeItem();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setSelectedBackupNode({
            label: backupNode.name,
            value: backupNode.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backupNode]);

    return (
        <CardsContainer>
            <Card size="351px">
                <CardRow>
                    <h3 id="backup-node-label">
                        <Translate content="managementNodeBackup.backupNodeCard.title"/>
                    </h3>
                </CardRow>
                <CardRow>
                    <AsyncSelectWithConfirmation
                        aria-labelledby="backup-node-label"
                        inputId="backup-node-select"
                        loadOptions={loadBackupNodeOptions}
                        debounceTimeout={1000}
                        additional={{ page: 1 }}
                        required={backupSettings.is_enabled}
                        onChange={handleBackupNodeChange}
                        value={selectedBackupNode}
                        isClearable={!backupSettings.is_enabled && backupSettings.backup_node_id}
                        isLoading={isBackupNodeLoading || isBackupNodeSaving || undefined}
                        isDisabled={isBackupNodeSaving || !canManageBackups}
                        confirmDialog={{
                            title: <Translate content="managementNodeBackup.backupNodeCard.confirmationDialog.title"/>,
                            text: <Translate content="managementNodeBackup.backupNodeCard.confirmationDialog.content"/>,
                            button: <Translate content="managementNodeBackup.backupNodeCard.confirmationDialog.button"/>,
                        }}
                        resetDialog={{
                            title: <Translate content="managementNodeBackup.backupNodeCard.resetDialog.title"/>,
                            text: <Translate content="managementNodeBackup.backupNodeCard.resetDialog.content"/>,
                            button: <Translate content="managementNodeBackup.backupNodeCard.resetDialog.button"/>,
                        }}
                    />
                </CardRow>
                <CardRow>
                    <Hint><Translate content="managementNodeBackup.backupNodeCard.hint"/></Hint>
                </CardRow>
            </Card>
        </CardsContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    backupSettings: state.settings.management_node_backup,
    backupNode: state.backupNode.item,
    isBackupNodeLoading: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_NODE_GET),
    isBackupNodeSaving: state.app.loadingFlags.has(LOADING_FLAGS.MANAGEMENT_NODE_BACKUP_BACKUP_NODE_SAVE),
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_MANAGEMENT_NODE_BACKUPS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    backupNodeActions: bindActionCreators(backupNodeActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    managementNodeBackupActions: bindActionCreators(managementNodeBackupActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupNodeCard);
