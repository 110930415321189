// Copyright 2025. WebPros International GmbH. All rights reserved.

import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IStorageDiskListRequest,
    IStorageDiskResponse,
    IStorageListRequest,
    IStorageMountRequest,
    IStoragePatchRequest,
    IStorageRequest,
    IStorageResponse,
} from 'common/api/resources/Storage/model';

export const storages = {
    update: (id: number, data: IStorageRequest) => api.put<IApiResponse<IStorageResponse>>(`storages/${id}`, data),
    item: (id: number) => api.get<IApiResponse<IStorageResponse>>(`storages/${id}`),
    list: (params?: IStorageListRequest) => api.get<IPaginateApiResponse<IStorageResponse[]>>('storages', {
        params: new QueryBuilder(params).build(),
    }),
    create: (data: IStorageRequest) => api.post<IApiResponse<IStorageResponse>>('storages', data),
    patch: (id: number, data: IStoragePatchRequest) => api.patch<IApiResponse<IStorageResponse>>(`storages/${id}`, data),
    remove: (id: number) => api.delete(`storages/${id}`),

    mount: (id: number, data: IStorageMountRequest) => api.post<IApiResponse<IStorageResponse>>(`storages/${id}/mount`, data),
    unmount: (id: number, data: IStorageMountRequest) => api.post(`storages/${id}/unmount`, data),

    disks: {
        list: (id: number, params?: IStorageDiskListRequest) => api.get<IPaginateApiResponse<IStorageDiskResponse[]>>(`storages/${id}/disks`, {
            params: new QueryBuilder(params).build(),
        }),
    },
};
