// Copyright 2025. WebPros International GmbH. All rights reserved.

import { SET_SETTINGS } from 'admin/settings/constants/constants';
import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { create } from 'common/actions/actionsWrapper';
import {
    ISettingsRequest,
    ISettingsResponse,
    settings,
} from 'common/api/resources/Settings';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';

export const setSettings = createCustomAction(
    SET_SETTINGS,
    (data: ISettingsResponse) => ({ payload: data })
);

export const getSettings = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.APP_SETTINGS));

    try {
        const result = await settings.list();

        if (result.status === HTTP_CODES.OK) {
            dispatch(setSettings(result.data.data));
            dispatch(setIsLoading(LOADING_FLAGS.APP_SETTINGS_LOADED));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.APP_SETTINGS));
    }
};

export const saveSettings = (data: ISettingsRequest, loadingFlag = LOADING_FLAGS.SAVE_APP_SETTINGS) =>
    async (dispatch: Dispatch) => {
        dispatch(clearFormErrors());

        return await create({
            data,
            dispatch,
            loadingFlag,
            action: setSettings,
            apiCall: settings.save,
            translations: {
                success: 'settings.toasts.settingsSaved',
            },
        });
    };
