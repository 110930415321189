// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    List,
    Translate,
} from '@plesk/ui-library';
import { IActivityLogResponse } from 'common/api/resources/ActivityLog';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ACTIVITY_LOG_EVENTS,
    activityLogBatchEvents,
    activityLogEventMap,
} from 'admin/activityLog/constants/activityLogEvents';
import { NotificationTranslationMap } from 'admin/settings/containers/Settings/NotificationsSettings';
import { SIZE } from 'common/constants';

export interface IActivityLogDataDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    logData: IActivityLogResponse;
}

const singleActivityLog = (logData: IActivityLogResponse) => {
    const columns = [{
        key: 'colKey',
        width: '35%',
        title: <Translate content="activityLog.data.list.key" />,
    },
    {
        key: 'colValue',
        title: <Translate content="activityLog.data.list.value" />,
    }];

    if (logData.data.length === 0) {
        return [columns, []];
    }

    let listData = [];
    for (let [key, value] of Object.entries(logData.data[0])) {
        if (logData.event === ACTIVITY_LOG_EVENTS.FAILED_TO_SEND_EMAIL_NOTIFICATION && key === 'notification') {
            listData.push({
                key: logData.id.toString(),
                colKey: key,
                colValue: <Translate content={NotificationTranslationMap[value as string]} />,
            });
            continue;
        }

        listData.push({
            key: logData.id.toString(),
            colKey: key,
            colValue: Array.isArray(value) ? value.map((v, k) => <div key={k}>{v}</div>) : value,
        });
    }

    return [columns, listData];
};

const batchActivityLog = (logData: IActivityLogResponse) => {
    const listData = logData.data.map((item, i) => {
        let object = {
            key: `${logData.id}-${i}`,
        };

        for (let key in item) {
            if (item.hasOwnProperty(key)) {
                const value = item[key];
                object[key] = Array.isArray(value) ? value.map((v, k) => <div key={k}>{v}</div>) : value;
            }
        }

        return object;
    });

    let columns = [];
    if (logData.data.length) {
        for (let key of Object.keys(logData.data[0])) {
            columns.push({
                key,
                title: key,
            });
        }
    }

    return [columns, listData];
};

export const ActivityLogDataDialog: React.FC<IActivityLogDataDialogProps> = ({
    isOpen,
    handleClose,
    logData,
}) => {
    let columns;
    let listData;
    [columns, listData] = activityLogBatchEvents.includes(logData.event as ACTIVITY_LOG_EVENTS)
        ? batchActivityLog(logData)
        : singleActivityLog(logData);

    return (
        <Dialog
            heading={<Translate content={activityLogEventMap[logData.event]} />}
            closeHandler={handleClose}
            isOpen={isOpen}
            size={SIZE.MD}
        >
            <List
                columns={columns}
                data={listData}
            />
        </Dialog>
    );
};
