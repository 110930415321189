// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    Button,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/modules/vpcNetwork/constants/tests';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { IVpcNetworkResponse } from 'common/api/resources/VpcNetwork';

export interface IVpcNetworkProps {
    item: IVpcNetworkResponse;
    canEdit?: boolean;
    canRemove?: boolean;
    handleEdit: () => void;
    handleRemove: () => void;
}

export const VpcNetworkActions: React.FC<IVpcNetworkProps> = ({
    item,
    canEdit = true,
    canRemove = true,
    handleEdit,
    handleRemove,
}) => (
    <StyledActions>
        <Button
            ghost={true}
            disabled={!canEdit}
            icon={<Icon name={ICONS.PENCIL} size={18} />}
            tooltip={<Translate content="vpcNetwork.tooltip.edit"/>}
            onClick={handleEdit}
            data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
        />
        <ButtonWithConfirmation
            disabled={!canRemove}
            isLoading={item.is_deleting}
            translations={{
                title: (
                    <Translate content="vpcNetwork.removePopover.title" />
                ),
                button: (
                    <Translate content="vpcNetwork.removePopover.button" />
                ),
                tooltip: (
                    <Translate content="vpcNetwork.removePopover.tooltip" />
                ),
            }}
            handleConfirm={handleRemove}
            data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
            icon={ICONS.RECYCLE}
        />
    </StyledActions>
);