// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Icon } from '@plesk/ui-library';
import {
    FormErrorsTitle,
    FormErrorsWrapper,
} from 'common/components/Form/FormErrors/Style';

export type FormErrorsProps = IFormErrorsProps;

interface IFormErrorsProps {
    title?: string | JSX.Element;
    errors: Array<string | JSX.Element>;
}

const FormErrors: React.FC<FormErrorsProps> = ({
    title,
    errors,
}) => (
    <FormErrorsWrapper>
        {title && (<FormErrorsTitle>{title}</FormErrorsTitle>)}
        {
            errors.map((error: string | JSX.Element, idx: number) => (
                <span key={idx} className="pul-form-field__error">
                    <Icon className="pul-form-field__error-icon" name="exclamation-mark-circle-filled" size="12" />
                    {error}
                </span>
            ))
        }
    </FormErrorsWrapper>
);

export default FormErrors;
