// Copyright 2025. WebPros International GmbH. All rights reserved.
import * as React from 'react';
import {
    IComputeResourceSearchItemResponse,
    IEntityTypeToSearchItemResponseMap,
    IServerSearchItemResponse,
    IUserSearchItemResponse,
    SearchEntityType,
    SearchItemResponse,
} from 'common/api/resources/Search';
import {
    Menu,
    MenuDivider,
    MenuHeader,
    MenuItem,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { ReactNode } from 'react';
import { SearchBarResultIcon } from 'common/modules/search/components/Styles';
import { Link } from 'react-router-dom';

type KeyedSearchItemResponse = SearchItemResponse & { key: string; url: string };

const TITLES : Record<SearchEntityType, (item: SearchItemResponse) => ReactNode> = {
    [SearchEntityType.Server]: (item) => (item as IServerSearchItemResponse).name,
    [SearchEntityType.ComputeResource]: (item) => (item as IComputeResourceSearchItemResponse).name,
    [SearchEntityType.User]: (item) => (item as IUserSearchItemResponse).email,
};

const TYPE_ICONS: Record<SearchEntityType, ICONS> = {
    [SearchEntityType.Server]: ICONS.SERVERS,
    [SearchEntityType.ComputeResource]: ICONS.RESOURCE,
    [SearchEntityType.User]: ICONS.USER,
};

export interface ISearchBarResultsHandle {
    handleArrowNavigation: (event: React.KeyboardEvent) => void;
}
export interface ISearchBarResultsProps {
    displayResults?: number;
    isLoading?: boolean;
    results: SearchItemResponse[];
    onSelected?: (item: SearchItemResponse) => void;
    formatPath: Record<SearchEntityType, (item: SearchItemResponse) => string>;
}
const SearchBarResults = React.forwardRef<ISearchBarResultsHandle, ISearchBarResultsProps>(({
    results,
    onSelected,
    isLoading,
    formatPath,
    ...props
}, ref) => {
    const displayResults = props.displayResults || 10;

    const displayEntries = React.useMemo<Array<[SearchEntityType, KeyedSearchItemResponse[]]>>(() => {
        type GroupedSearchResults = {
            [key in keyof IEntityTypeToSearchItemResponseMap]: Array<IEntityTypeToSearchItemResponseMap[key]>;
        };

        const groupedResults = results
            .reduce((acc, item) => {
                if (!acc[item.type]) {
                    acc[item.type] = [];
                }
                acc[item.type].push(item);
                return acc;
            }, {}) as GroupedSearchResults;
        const numGroups = Object.keys(groupedResults).length;
        let perGroup = Math.floor(displayResults / numGroups);

        const entries: Array<[SearchEntityType, KeyedSearchItemResponse[]]> = (Object.entries(groupedResults) as Array<[SearchEntityType, SearchItemResponse[]]>)
            // sort by entries count
            .sort(([, a], [, b]) => b.length - a.length)
            .map(([key, value]) => [
                key,
                value.splice(0, perGroup).map((item) => ({
                    ...item,
                    key: `${item.type}-${item.id}`,
                    url: formatPath[item.type](item),
                })),
            ]);

        return entries;
    }, [results, displayResults, formatPath]);
    const displayEntriesFlat = React.useMemo<KeyedSearchItemResponse[]>(
        () => displayEntries.flatMap(([, items]) => items),
        [displayEntries]
    );
    const displayEntriesKeys = React.useMemo<string[]>(
        () => displayEntriesFlat.map((item) => item.key),
        [displayEntriesFlat]
    );

    const [activeKey, setActiveKey] = React.useState<string>();
    React.useEffect(() => {
        if (displayEntriesKeys.length > 0) {
            setActiveKey(displayEntriesKeys[0]);
        } else {
            setActiveKey(undefined);
        }
    }, [displayEntriesKeys, setActiveKey]);

    React.useImperativeHandle<ISearchBarResultsHandle, ISearchBarResultsHandle>(ref, () => ({
        handleArrowNavigation: (event: React.KeyboardEvent) => {
            if (activeKey === undefined) {
                return;
            }

            if (event.key === 'Enter') {
                event.preventDefault();
                const item = displayEntriesFlat.find((i) => i.key === activeKey);

                if (item) {
                    onSelected?.(item);
                }
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                const currentIndex = displayEntriesKeys.indexOf(activeKey);
                if (currentIndex === -1) {
                    setActiveKey(displayEntriesKeys[0]);
                } else {
                    const nextIndex = currentIndex + 1;
                    if (nextIndex < displayEntriesKeys.length) {
                        setActiveKey(displayEntriesKeys[nextIndex]);
                    }
                }
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                const currentIndex = displayEntriesKeys.indexOf(activeKey);
                if (currentIndex === -1) {
                    setActiveKey(displayEntriesKeys[displayEntriesKeys.length - 1]);
                } else {
                    const nextIndex = currentIndex - 1;
                    if (nextIndex >= 0) {
                        setActiveKey(displayEntriesKeys[nextIndex]);
                    }
                }
            }
        },
    }), [activeKey, displayEntriesFlat, onSelected, displayEntriesKeys]);

    const handleClick = React.useCallback((item: KeyedSearchItemResponse) => {
        setActiveKey(item.key);
        onSelected?.(item);
    }, [onSelected, setActiveKey]);

    if (results.length === 0) {
        return (
            <Menu>
                <MenuItem disabled>
                    <Translate content={isLoading ? 'search.loading' : 'search.noResults'} />
                </MenuItem>
            </Menu>
        );
    }

    return (
        <Menu>
            {displayEntries.map(([type, entities], index, array) => (
                <React.Fragment key={`type-${type}`}>
                    <MenuHeader>
                        <Translate content={`search.type.${type}`} />
                    </MenuHeader>
                    {entities.map((item) => (
                        <MenuItem
                            component={Link}
                            to={item.url}
                            key={item.key}
                            active={item.key === activeKey}
                            icon={
                                <SearchBarResultIcon
                                    name={TYPE_ICONS[item.type]}
                                />
                            }
                            onClick={() => handleClick(item)}
                        >
                            {TITLES[item.type](item)}
                        </MenuItem>
                    ))}
                    {index < array.length - 1 && <MenuDivider/>}
                </React.Fragment>
            ))}
        </Menu>
    );
});
SearchBarResults.displayName = 'SearchBarResults';

export default SearchBarResults;