// Copyright 2025. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    IUpdateComputeResource,
    IUpdateComputeResourceInstallStep,
    IUpdateUser,
} from 'common/api/resources/ComputeResource';
import {
    patchStorage,
    updateInstallStep,
    updateItem,
} from 'admin/computeResource/actions';
import {
    moveVm,
    setNewPassword,
    setServerLimits,
    updateVm,
    updateVmDiskUsage,
    updateVmProgress,
} from 'common/modules/computeResourceVm/actions';
import {
    IMoveComputeResourceVm,
    IServerLimitResponse,
    IUpdateComputeResourceVm,
} from 'common/api/resources/ComputeResourceVm';
import { updateTask } from 'admin/task/actions';
import {
    setIsAuth,
    updateUser,
} from 'common/modules/auth/actions';
import {
    INotificationResponse,
    NOTIFICATION_TYPE,
} from 'common/api/resources/Notification';
import { setAlertedItem } from 'common/modules/app/menu/actions';
import { addNotificationItem } from 'admin/notification/actions';
import { setNotificationAlert } from 'common/modules/app/notificationAlert/actions';
import {
    IUpdateUpdateData,
    IUpgradeResult,
    UPDATE_STATUS,
} from 'common/api/resources/Update';
import { versionCheck } from 'common/services/AppLoadingService';
import {
    addUpgradeResult,
    setState,
} from 'admin/update/actions';
import { IUpdateBackupResource } from 'common/api/resources/Backup';
import {
    updateItem as updateBackupItem,
    updateItemProgress,
} from 'common/modules/backup/actions';
import { INTENT_TYPE } from 'common/constants';
import {
    bakeBackgroundToast,
    bakeForegroundToast,
} from 'common/modules/app/toaster/actions';
import { IUpdateTask } from 'common/api/resources/Task';
import { updateServersMigrationTask } from 'admin/serversMigration/actions';
import { IUpdateStorageResource } from 'common/api/resources/Storage';
import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import { remove as keypairCacheRemove } from 'admin/computeResource/services/cache';
import { IUpdateSolusVMLicense } from 'common/api/resources/License/solusVM';
import { setLicenseItem } from 'admin/license/solusVM/actions';
import { IUpdateManagementNodeBackupResource } from 'common/api/resources/ManagementNodeBackup';
import {
    updateManagementNodeBackupItem,
    updateManagementNodeBackupItemProgress,
} from 'admin/managementNodeBackup/actions';
import { updateClusterImportItem } from 'admin/clusterImport/actions';
import { IClusterImportResource } from 'common/api/resources/ClusterImport';
import { ISnapshotResponse } from 'common/api/resources/Snapshot';
import { updateSnapshotItem } from 'common/modules/snapshot/actions';

export const logout = () => async (dispatch: Dispatch) => dispatch(setIsAuth(false));

export const updateComputeResourceStorageAction = (data: IUpdateStorageResource) => async (dispatch: Dispatch) => {
    dispatch(patchStorage(data.storage));
};

export const updateItemAction = (data: IUpdateComputeResource) => async (dispatch: Dispatch) => {
    dispatch(updateItem(data.computeResource));
    if (data.computeResource.status === COMPUTE_RESOURCE_STATUS.CONFIGURE_NETWORK) {
        keypairCacheRemove(data.computeResource.id);
    }
};

export const updateVmAction = (data: IUpdateComputeResourceVm) => async (dispatch: Dispatch) => {
    dispatch(updateVm(data.computeResourceVm));
};

// todo remove after https://webpros.atlassian.net/browse/SIO-2993
export const updateVmDiskUsageAction = (id: number, size: number) => async (dispatch: Dispatch) => {
    dispatch(updateVmDiskUsage({ id, size }));
};

export const updateVmPasswordAction = (id: number, password: string) => async (dispatch: Dispatch) => {
    dispatch(setNewPassword(id, password));
};

export const moveVmAction = (data: IMoveComputeResourceVm) => async (dispatch: Dispatch) => {
    dispatch(moveVm(data));
};

export const updateInstallStepAction = (data: IUpdateComputeResourceInstallStep) => async (dispatch: Dispatch) => {
    dispatch(updateInstallStep(data.installStep));
};

export const updateTaskAction = (data: IUpdateTask) => async (dispatch: Dispatch) => {
    dispatch(updateTask(data.task));
    dispatch(updateServersMigrationTask(data.task));
    if (data.task.backup_id && data.task.progress > 0) {
        dispatch(updateItemProgress(data.task.backup_id, data.task.progress));
    }
    if (data.task.management_node_backup_id && data.task.progress > 0) {
        dispatch(updateManagementNodeBackupItemProgress(data.task.management_node_backup_id, data.task.progress));
    }
    if (data.task.compute_resource_vm_id && data.task.progress > 0) {
        dispatch(updateVmProgress(
            data.task.compute_resource_vm_id,
            data.task.progress
        ));
    }
};

export const updateUserAction = (data: IUpdateUser) => async (dispatch: Dispatch) => {
    dispatch(updateUser(data.user));
};

export const notificationAction = (notification: INotificationResponse) => async (dispatch: Dispatch) => {
    switch (notification.type) {
    case NOTIFICATION_TYPE.NEW_VERSION_AVAILABLE:
        dispatch(setAlertedItem('/admin/updates', true));
        break;

    case NOTIFICATION_TYPE.BACKUP_CREATED:
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResourceVm.backups.toasters.createSuccess', {
            computeResourceVm: notification.data.compute_resource_vm.name,
        })(dispatch);
        break;

    case NOTIFICATION_TYPE.BACKUP_RESTORED:
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'backup.toasters.restoreSuccess', {
            computeResourceVm: notification.data.compute_resource_vm.name,
        })(dispatch);
        break;

    case NOTIFICATION_TYPE.MANAGEMENT_NODE_BACKUP_RECOVERED:
        bakeBackgroundToast(INTENT_TYPE.SUCCESS, 'managementNodeBackup.toasters.recovered', {
            mnbCount: notification.data.management_node_backup_count,
        })(dispatch);
        break;
    }

    dispatch(addNotificationItem(notification));
    dispatch(setNotificationAlert(true));
};

export const checkVersionAction = (data: IUpdateUpdateData) => async (dispatch: Dispatch) => {
    if (data.data.status === UPDATE_STATUS.UP_TO_DATE && data.data.task !== null) {
        versionCheck(data.data.version);
    }

    dispatch(setState(data.data));
};

export const upgradeResultAction = (data: IUpgradeResult) => async (dispatch: Dispatch) => {
    dispatch(addUpgradeResult(data));
};

export const updateBackupItemAction = (data: IUpdateBackupResource) => async (dispatch: Dispatch) => {
    dispatch(updateBackupItem(data.backup));
};

export const updateManagementNodeBackupAction =
    (data: IUpdateManagementNodeBackupResource) => async (dispatch: Dispatch) => {
        dispatch(updateManagementNodeBackupItem(data.backup));
    };

export const updateSolusVMLicenseAction = (data: IUpdateSolusVMLicense) => async (dispatch: Dispatch) => {
    dispatch(setLicenseItem(data.license));
};

export const updateClusterImportAction = (data: IClusterImportResource) => async (dispatch: Dispatch) => {
    dispatch(updateClusterImportItem(data.clusterImport.id, data.clusterImport));
};

export const updateServerLimitsAction = (serverLimits: IServerLimitResponse[]) => async (dispatch: Dispatch) => {
    dispatch(setServerLimits(serverLimits));
};

export const updateSnapshotItemAction = (snapshot: ISnapshotResponse) => async (dispatch: Dispatch) => {
    dispatch(updateSnapshotItem(snapshot));
};
