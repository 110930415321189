// Copyright 2025. WebPros International GmbH. All rights reserved.

import { IPaginateApiResponse } from 'common/api/resources/Response';
import { AxiosPromise } from 'axios';
import {
    IAdditionalOptions,
    ILoadOptions,
    ISelectOption,
} from 'common/components/SelectInput';
import {
    Dispatch,
    SetStateAction,
} from 'react';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

/**
 * Helper factory for creating options loader functions for async select input.
 *
 * @param api Function to fetch data.
 * @param mapper Function to map fetched data to select options.
 * @param allowed Flag to allow or disallow fetching data.
 * @param filters Filters for the request.
 * @param query Additional query parameters.
 */
export const createOptionsLoader = <
    TItem,
    Filters = unknown,
    TRequest extends IPaginatedWithSearch = IPaginatedWithSearch<Filters>,
>(
        api: (params?: TRequest) => AxiosPromise<IPaginateApiResponse<TItem[]>>,
        mapper: (item: TItem) => ISelectOption,
        allowed: boolean = true,
        filters?: TRequest['filters'],
        query?: Omit<TRequest, 'filters'>
    ): ILoadOptions =>
        async (search, _, { page } : IAdditionalOptions) => {
            if (!allowed) {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const {
                data: { data, links },
            } = await api({
                ...query,
                page,
                filters: {
                    ...filters,
                    search,
                },
            } as TRequest);

            return {
                options: data.map(mapper),
                hasMore: !!links.next,
                additional: {
                    page: page + 1,
                },
            };
        };

/**
 * Wrapper for options loader with filter.
 *
 * @param loader
 * @param filter
 */
export const filteredOptionsLoader = (loader: ILoadOptions, filter: (item: ISelectOption) => boolean): ILoadOptions =>
    async (search, selectOpt, additionalOpt) => {
        const options = await loader(search, selectOpt, additionalOpt);
        return {
            ...options,
            options: options.options.filter(filter),
        };
    };
/**
 * Helper factory for AsyncSelectInput and SelectInput `onChange` event in form.
 * Works in React.FC only.
 *
 * Prerequisites:
 *  - Selection state should has ISelectOption[] type.
 *
 * @param submitValuesSetter Setter for form values state.
 * @param selectionSetter    Setter for selection options state.
 * @param property           Property in form values state which should be updated.
 */
export const createChangeHandler = <S>(
    submitValuesSetter: Dispatch<SetStateAction<S>>,
    selectionSetter: Dispatch<SetStateAction<ISelectOption[]>>,
    property: keyof S
) => (options: ISelectOption[]) => {
        submitValuesSetter((prevState: S) => ({
            ...prevState,
            [property]: options !== null ? options.map((option) => option.value) : [],
        }));
        selectionSetter(options !== null ? [...options] : []);
    };
